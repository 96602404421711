import {
  DataGrid,
  Column,
  Selection,
  Editing,
  Item,
} from "devextreme-react/data-grid";
import { SearchPanel, Pager, Paging } from "devextreme-react/tree-list";
import "devextreme/dist/css/dx.light.css";
import CircularProgress from "@mui/material/CircularProgress";
import { VezanaKupnja } from "./UnosAkcijePrilikeTypes";
import { locale, loadMessages } from "devextreme/localization";
import hrMessages from "./hr.json";
import { Toolbar } from "@mui/material";

type TablicaProps = {
  sifreProizvodaZaPopust: VezanaKupnja[];
  sifreArtikala: VezanaKupnja[];
  dodajProizvod: (value: string) => void;
  referenca: string;
  dodajUvjet: (value: string) => void;
  brisiProizvod: (value: string) => void;
  brisiUvjet: (value: string) => void;
  updateProizvod: (value: any) => void;
  updateUvjet: (value: any) => void;
};

const PopustVezanaKupnjaTablica = (props: TablicaProps) => {
  loadMessages(hrMessages);
  locale("hr");

  const allowedPageSizes = [20, 50, 100];


  return (
    <>
      {true ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <DataGrid
            id="dataGrid"
            dataSource={
              props.sifreArtikala.length > 0
                ? props.sifreArtikala
                : props.sifreProizvodaZaPopust
            }
            rowAlternationEnabled
            allowColumnReordering
            allowColumnResizing
            showBorders
            onRowUpdating={(e: any) => {
              e.cancel = true;
              if (props.referenca === "proizvodi") {
                props.updateProizvod({ new: e.newData, old: e.oldData });
              } else {
                props.updateUvjet({ new: e.newData, old: e.oldData });
              }
            }}
            onRowRemoving={(e: any) => {
              e.cancel = true;
              if (props.referenca === "proizvodi") {
                props.brisiProizvod(e.data.SifraArtikla);
              } else {
                props.brisiUvjet(e.data.SifraArtikla);
              }
            }}
            onRowInserting={(e) => {
              e.cancel = true;
              if (props.referenca === "proizvodi") {
                if (e.data.SifraArtikla !== "") {
                  props.dodajProizvod(e.data.SifraArtikla);
                  e.component.cancelEditData();
                }
              } else {
                if (e.data.SifraArtikla !== "") {
                  props.dodajUvjet(e.data.SifraArtikla);
                  e.component.cancelEditData();
                }
              }
            }}
          >
            <Paging defaultPageSize={20} />
            <Editing
              mode="row"
              allowUpdating={true}
              allowAdding={true}
              allowDeleting={true}
            />
            <Toolbar>
              <Item name="addRowButton" />
            </Toolbar>
            <Pager
              visible={true}
              showPageSizeSelector={true}
              showInfo={true}
              allowedPageSizes={allowedPageSizes}
              infoText={`Stranica {0} od {1} ({2} artikala)`}
            />
            <SearchPanel visible={true} />
            <Selection mode="single" />
            <Column dataField="SifraArtikla" caption="Šifra artikla" />
          </DataGrid>
        </div>
      ) : (
        <CircularProgress
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
          }}
        />
      )}
    </>
  );
};

export default PopustVezanaKupnjaTablica;
