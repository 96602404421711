import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

type InputProps = {
  inputLabel: string;
  marginBottom: string;
  handleChange: (value: string) => void;
  value: string;
  multiline: boolean;
  rows: number;
  children: any; 
  obavezno: boolean;
  error: boolean;
  inputPropsType: string;
  onBlur: () => void;
  disabled: boolean;
};

export default function Input(props: InputProps) {
  return (
    <>
    <Box
     className="bg-white"
      component="form"
      noValidate
      autoComplete="off"
    >
      <TextField
        size="small"
        fullWidth
        id={props.inputLabel}
        label={props.inputLabel}
        variant="outlined"
        onChange={(event) => {
          props.handleChange(event.target.value);
        }}
        value={props.value}
        multiline={props.multiline}
        rows={props.rows}
        error={props.error}
        inputProps={{type:props.inputPropsType}}
        onBlur={props.onBlur}
        disabled={props.disabled}
      />
    </Box>
    {(props.obavezno && !props.error) ? <label style={{
        marginBottom: props.marginBottom,
      }} className="lightBlue small-text">Obavezno polje *</label> : ""}
    </>
    
  );
}
