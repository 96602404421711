import produce from "immer";
import { FormaZaSlikeModel } from "./FormaZaUnosSlika";
import { FormaZaUnosSlikaActions } from "./FormaZaSlikeActions";

const formaZaSlikaReducer = produce((oldState: FormaZaSlikeModel, action:FormaZaUnosSlikaActions) => {       
    switch (action.action) {
        case "SLIKA_UCITANA":
            const fileName = oldState.slikeZaSpremanje.find(file => {
                return file.name === action.value.name
            })
            if (!fileName) {
                oldState.slikeZaSpremanje.push(action.value)
            }
            oldState.spremljeneSlike = [];
            break;
        case "SLIKE_UCITANE":
            oldState.slikeZaSpremanje = [...new Set([...oldState.slikeZaSpremanje, ...action.value])];
            oldState.spremljeneSlike = [];
            break;
        case "SLIKA_ODBACENA":
            const filtered = oldState.slikeZaSpremanje.filter(slika => slika.name !== action.value.name)
            oldState.slikeZaSpremanje = filtered;            
        break;
        case "SLIKE_SPREMLJENE":
            oldState.slikeZaSpremanje = [];
            // oldState.nespremljeneSlike = action.value;    
            oldState.spremljeneSlike = action.value;        
            oldState.progress = 0;
            oldState.isSaving = false;
            break;
        case "PROGRESS_UPDATE":
            oldState.spremljeneSlike.push(action.value.slika);
            oldState.progress += action.value.progress;
            break;
        case "POST_STARTED":
            oldState.isSaving = true;
            break;
        default:
            break;
    }
})

export default formaZaSlikaReducer;