import produce, { Draft } from "immer";
import { CijenaDostaveActions } from "./CijenaDostaveActions";
import { CijenaDostaveModel } from "./CijenaDostaveTypes";

const unosAkcijePrilikeReducer = produce(
  (oldState: Draft<CijenaDostaveModel>, action: CijenaDostaveActions) => {
    switch (action.action) {
      case "GET_CIJENE_DOSTAVE":
        if (action.value.length > 0) {
          oldState.cijenaDostave = action.value;
        }
        break;
      case "GET_PRIJEVOZNIK":
        if (action.value.length > 0) {
          oldState.prijevoznik = action.value;
        }
        break;
      default:
        break;
    }
  }
);

export default unosAkcijePrilikeReducer;
