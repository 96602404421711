import React from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "@mui/material";
import { IPublicClientApplication } from "@azure/msal-browser";
import LogoutIcon from '@mui/icons-material/Logout';

function handleLogout(instance: IPublicClientApplication) {
    instance.logoutRedirect().then(() => {
        window.location.href='/'
    }).catch(e => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will redirect the page to the logout prompt
 */
export const SignOutButton = () => {
    const { instance } = useMsal();

    return (
        <Button
            onClick={() => handleLogout(instance)}
        >
            <LogoutIcon fontSize="small"/>
            <div style={{textTransform:'none', marginLeft:'0.5em'}}>Odjava</div>
        </Button>
    );
}