import { Modal, TextField, Button, Dialog, DialogContent } from "@mui/material";
import Box from "@mui/material/Box";
import { useState } from "react";
import { AlertModel } from "../UnosAkcijePrilike/UnosAkcijePrilikeTypes";
import classes from "./Placanja.module.css";
import useApi from "../Hooks/useApi";
import BackDrop from "../UnosAkcijePrilike/BackDrop";
import Swal from "sweetalert2";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "98%",
  height: "98%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  //   alignItems: "center",
  flexDirection: "column",
};

type AddModalProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

const AddPlacanja = (props: AddModalProps) => {
  const apiCall = useApi();
  const [naziv, setNaziv] = useState<string>("");
  const [rateMin, setRateMin] = useState<number | string>(0);
  const [rateMax, setRateMax] = useState<number | string>(0);
  const [odgodaMin, setOdgodaMin] = useState<number | string>(0);
  const [odgodaMax, setOdgodaMax] = useState<number | string>(0);
  const [iznosMin, setIznosMin] = useState<number | string>(0);
  const [iznosMax, setIznosMax] = useState<number | string>(0);
  const [napomena, setNapomena] = useState<string>("");
  const [vrstaPlacanja, setVrstaPlacanja] = useState<string>("");
  const [prodavaona, setProdavaona] = useState<string>("");
  const [dialog, setOpen] = useState<AlertModel>({ open: false, message: "" });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [nazivNotValid, setNazivNotValid] = useState<boolean>(false);
  // const [napomenaNotValid, setNapomenaNotValid] = useState<boolean>(false);

  // const [rateMinNotValid, setRateMinNotValid] = useState<boolean>(false);
  // const [rateMaxNotValid, setRateMaxNotValid] = useState<boolean>(false);
  // const [odgodaMinNotValid, setOdgodaMinNotValid] = useState<boolean>(false);
  // const [odgodaMaxNotValid, setOdgodaMaxNotValid] = useState<boolean>(false);
  // const [iznosMinNotValid, setIznosMinNotValid] = useState<boolean>(false);
  // const [iznosMaxNotValid, setIznosMaxNotValid] = useState<boolean>(false);
  const [vrstaPlacanjaNotValid, setVrstaPlacanjaNotValid] =
    useState<boolean>(false);
  const [prodavaonaNotValid, setProdavaonaNotValid] = useState<boolean>(false);

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleDialogClose = () => {
    setOpen({ open: false, message: "" });
  };

  const post = (accessToken: string) => {
    let counter: number = 0;
    if (naziv.length === 0) {
      setNazivNotValid(true);
      counter++;
    }
    // if (rateMin === "") {
    //   setRateMinNotValid(true);
    //   counter++;
    // }
    // if (rateMax === "") {
    //   setRateMaxNotValid(true);
    //   counter++;
    // }
    // if (!iznosMin) {
    //   setIznosMinNotValid(true);
    //   counter++;
    // }
    // if (!iznosMax) {
    //   setIznosMaxNotValid(true);
    //   counter++;
    // }
    // if (!odgodaMin) {
    //   setOdgodaMinNotValid(true);
    //   counter++;
    // }
    // if (!odgodaMax) {
    //   setOdgodaMaxNotValid(true);
    //   counter++;
    // }
    // if(napomena.length === 0){
    //   setNapomenaNotValid(false);
    //   counter++;
    // }
    if (vrstaPlacanja.length === 0) {
      setVrstaPlacanjaNotValid(true);
      counter++;
    }
    if (prodavaona.length === 0) {
      setProdavaonaNotValid(true);
      counter++;
    }

    if (counter === 0) {
      setIsLoading(true);
      let formData = new FormData();
      formData.append("sif", "0");
      formData.append("naziv", naziv);
      formData.append("minimalnoRata", rateMin.toString());
      formData.append("maksimalnoRata", rateMax.toString());
      formData.append("iznosMinimalno", iznosMin.toString());
      formData.append("iznosMaksimalno", iznosMax.toString());
      formData.append("odgodaMinimalno", odgodaMin.toString());
      formData.append("odgodaMaksimalno", odgodaMax.toString());
      formData.append("napomena", napomena);
      formData.append("vrstaPlacanja", vrstaPlacanja);
      formData.append("prodavaona", prodavaona);

      fetch("api/Placanja/NewPlacanja", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "post",
        body: formData,
      }).then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          handleClose();

          Swal.fire({
            icon: "success",
            title: "Spremljeno",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        } else {
          setIsLoading(false);
          handleClose();

          Swal.fire({
            title: response.statusText,
            showConfirmButton: false,
            toast: true,
            customClass: {
              container: classes.swalContainer,
              popup: classes.swal2IconError,
            },
            timer: 1500,
            iconColor: "white",
            timerProgressBar: true,
            icon: "warning",
            position: "top-right",
          });
        }
      });
    }
  };

  const apiCallPost = () => {
    apiCall(post);
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <TextField
            label="Naziv"
            size="small"
            // style={{ marginBottom: "1em" }}
            value={naziv}
            onChange={(e: any) => {
              setNazivNotValid(false);
              setNaziv(e.target.value);
            }}
          />
          <label
            style={{ marginBottom: "1em" }}
            className={nazivNotValid ? classes.warning : "lightBlue small-text"}
          >
            Obavezno polje *
          </label>
          <div className={classes.odDoInput}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <TextField
                label="Rate min"
                size="small"
                type="number"
                style={{
                  marginRight: "1em",
                }}
                value={rateMin}
                onChange={(e: any) => {
                  // setRateMinNotValid(false);
                  if (rateMin.toString() === "0" && e.target.value !== "") {
                    setRateMin(e.target.value.substring(1));
                  } else if (e.target.value === "") {
                    setRateMin(0);
                  } else {
                    setRateMin(e.target.value);
                  }
                }}
                onBlur={(e: any) => {
                  if (rateMax.toString() !== "0") {
                    if (
                      parseInt(rateMin.toString()) >
                      parseInt(rateMax.toString())
                    ) {
                      setOpen({
                        open: true,
                        message:
                          "Minimalni broj rata ne može biti veći od maksimalnog",
                      });
                      setRateMin(0);
                    }
                  }
                }}
              />
              {/* <label
                className={
                  rateMinNotValid ? classes.warning : "lightBlue small-text"
                }
              >
                Obavezno polje *
              </label> */}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <TextField
                type="number"
                label="Rate max"
                size="small"
                value={rateMax}
                onChange={(e: any) => {
                  // setRateMaxNotValid(false);
                  if (rateMax.toString() === "0" && e.target.value !== "") {
                    setRateMax(e.target.value.substring(1));
                  } else if (e.target.value === "") {
                    setRateMax(0);
                  } else {
                    setRateMax(e.target.value);
                  }
                }}
                onBlur={(e: any) => {
                  if (
                    parseInt(rateMax.toString()) <
                      parseInt(rateMin.toString()) &&
                    rateMax !== "0"
                  ) {
                    setOpen({
                      open: true,
                      message:
                        "Maksimalni broj rata ne može biti manji od minimalnog",
                    });
                    setRateMax(0);
                  }
                  if (parseInt(rateMax.toString()) > 60) {
                    setOpen({
                      open: true,
                      message: "Maksimalni broj rata ne može biti veći od 60",
                    });
                    setRateMax(0);
                  }
                }}
              />
              {/* <label
                className={
                  rateMaxNotValid ? classes.warning : "lightBlue small-text"
                }
              >
                Obavezno polje *
              </label> */}
            </div>
          </div>
          <div className={classes.odDoInput}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <TextField
                label="Iznos min"
                size="small"
                type="number"
                style={{
                  marginRight: "1em",
                }}
                value={iznosMin}
                onChange={(e: any) => {
                  // setIznosMinNotValid(false);
                  if (iznosMin.toString() === "0" && e.target.value !== "") {
                    setIznosMin(e.target.value.substring(1));
                  } else if (e.target.value === "") {
                    setIznosMin(0);
                  } else {
                    setIznosMin(e.target.value);
                  }
                }}
                onBlur={(e: any) => {
                  if (iznosMax.toString() !== "0") {
                    if (
                      parseInt(iznosMin.toString()) >
                      parseInt(iznosMax.toString())
                    ) {
                      setOpen({
                        open: true,
                        message:
                          "Minimalni iznos ne može biti veći od maksimalnog",
                      });
                      setIznosMin(0);
                    }
                  }
                }}
              />
              {/* <label
                className={
                  iznosMinNotValid ? classes.warning : "lightBlue small-text"
                }
              >
                Obavezno polje *
              </label> */}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <TextField
                type="number"
                label="Iznos max"
                size="small"
                value={iznosMax}
                onChange={(e: any) => {
                  // setIznosMaxNotValid(false);
                  if (iznosMax.toString() === "0" && e.target.value !== "") {
                    setIznosMax(e.target.value.substring(1));
                  } else if (e.target.value === "") {
                    setIznosMax(0);
                  } else {
                    setIznosMax(e.target.value);
                  }
                }}
                onBlur={(e: any) => {
                  if (
                    parseInt(iznosMax.toString()) <
                      parseInt(iznosMin.toString()) &&
                    iznosMax.toString() !== "0"
                  ) {
                    setOpen({
                      open: true,
                      message:
                        "Maksimalni iznos ne može biti manji od minimalnog",
                    });
                    setIznosMax(0);
                  }
                }}
              />
              {/* <label
                className={
                  iznosMaxNotValid ? classes.warning : "lightBlue small-text"
                }
              >
                Obavezno polje *
              </label> */}
            </div>
          </div>
          <div className={classes.odDoInput}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <TextField
                label="Odgoda min"
                size="small"
                type="number"
                style={{
                  marginRight: "1em",
                }}
                value={odgodaMin}
                onChange={(e: any) => {
                  // setOdgodaMinNotValid(false);
                  if (odgodaMin.toString() === "0" && e.target.value !== "") {
                    setOdgodaMin(e.target.value.substring(1));
                  } else if (e.target.value === "") {
                    setOdgodaMin(0);
                  } else {
                    setOdgodaMin(e.target.value);
                  }
                }}
                onBlur={(e: any) => {
                  if (
                    parseInt(odgodaMax.toString()) <
                      parseInt(odgodaMin.toString()) &&
                    odgodaMax.toString() !== "0"
                  ) {
                    setOpen({
                      open: true,
                      message:
                        "Iznos minimalne odgode ne može biti veći od maksimalne",
                    });
                    setOdgodaMin(0);
                  }
                }}
              />
              {/* <label
                className={
                  odgodaMinNotValid ? classes.warning : "lightBlue small-text"
                }
              >
                Obavezno polje *
              </label> */}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <TextField
                type="number"
                label="Odgoda max"
                size="small"
                value={odgodaMax}
                onChange={(e: any) => {
                  // setOdgodaMaxNotValid(false);
                  if (odgodaMax.toString() === "0" && e.target.value !== "") {
                    setOdgodaMax(e.target.value.substring(1));
                  } else if (e.target.value === "") {
                    setOdgodaMax(0);
                  } else {
                    setOdgodaMax(e.target.value);
                  }
                }}
                onBlur={(e: any) => {
                  if (
                    parseInt(odgodaMax.toString()) <
                      parseInt(odgodaMin.toString()) &&
                    odgodaMax.toString() !== "0"
                  ) {
                    setOpen({
                      open: true,
                      message:
                        "Iznos maksimalne odgode ne može biti manji od minimalne",
                    });
                    setOdgodaMax(0);
                  }
                  if (parseInt(odgodaMax.toString()) > 12) {
                    setOpen({
                      open: true,
                      message:
                        "Iznos maksimalne odgode ne može biti veći od 12",
                    });
                    setOdgodaMax(0);
                  }
                }}
              />
              {/* <label
                className={
                  odgodaMaxNotValid ? classes.warning : "lightBlue small-text"
                }
              >
                Obavezno polje *
              </label> */}
            </div>
          </div>
          <TextField
            label="Napomena"
            size="small"
            value={napomena}
            onChange={(e: any) => {
              setNapomena(e.target.value);
            }}
          />
          <label
              style={{ marginBottom: "1em" }}
              className={
                vrstaPlacanjaNotValid ? classes.warning : "lightBlue small-text"
              }
            >
              Obavezno polje *
            </label>
          <TextField
            label="Vrsta plaćanja"
            size="small"
            // style={{
            //   marginBottom: "1em",
            // }}
            value={vrstaPlacanja}
            onChange={(e: any) => {
              setVrstaPlacanjaNotValid(false);
              setVrstaPlacanja(e.target.value);
            }}
          />
          <label
            style={{ marginBottom: "1em" }}
            className={
              vrstaPlacanjaNotValid ? classes.warning : "lightBlue small-text"
            }
          >
            Obavezno polje *
          </label>

          <TextField
            label="Prodavaona"
            size="small"
            // style={{
            //   marginBottom: "1em",
            // }}
            value={prodavaona}
            onChange={(e: any) => {
              setProdavaonaNotValid(false);
              setProdavaona(e.target.value);
            }}
          />
          <label
            style={{ marginBottom: "1em" }}
            className={
              prodavaonaNotValid ? classes.warning : "lightBlue small-text"
            }
          >
            Obavezno polje *
          </label>

          <div className={classes.btnSpremiWrapper}>
            <Button
              style={{ backgroundColor: "rgb(26, 179, 148)" }}
              variant="contained"
              onClick={() => {
                apiCallPost();
              }}
            >
              Spremi
            </Button>
          </div>
        </Box>
      </Modal>
      <Dialog onClose={handleDialogClose} open={dialog.open}>
        <DialogContent className={classes.dialog}>
          <label>{dialog.message}</label>
          <div style={{ textAlign: "end" }}>
            <Button onClick={handleDialogClose}>Zatvori</Button>
          </div>
        </DialogContent>
      </Dialog>
      {isLoading && <BackDrop />}
    </>
  );
};

export default AddPlacanja;
