import {
  DataGrid,
  Column,
  Selection,
  Editing,
  Item,
} from "devextreme-react/data-grid";
import { SearchPanel, Pager, Paging } from "devextreme-react/tree-list";
import "devextreme/dist/css/dx.light.css";
import CircularProgress from "@mui/material/CircularProgress";
import { Poklon } from "./UnosAkcijePrilikeTypes";
import { locale, loadMessages } from "devextreme/localization";
import hrMessages from "./hr.json";
import { Toolbar } from "@mui/material";

type TablicaProps = {
  pokloni: Poklon[];
  brisiPoklon: (sifra: string) => void;
  updatePoklon: (value: any) => void;
  dodajPoklon: (value: string) => void;
};

const PokloniTablica = (props: TablicaProps) => {
  loadMessages(hrMessages);
  locale("hr");

  const allowedPageSizes = [20, 50, 100];


  return (
    <>
      {props.pokloni ? (
        <div
          style={{
            marginBottom: "20px",
            display:'flex',
            justifyContent:'center',
            flexDirection:'row'
          }}
        >
          <DataGrid
            id="dataGrid"
            dataSource={props.pokloni}
            rowAlternationEnabled
            allowColumnReordering
            allowColumnResizing
            showBorders
            onRowUpdating={(e: any) => {
              e.cancel = true;
              props.updatePoklon({ new: e.newData, old: e.oldData });
            }}
            onRowRemoving={(e: any) => {
              e.cancel = true;
              props.brisiPoklon(e.data.SifraArtikla);
            }}
            onRowInserting={(e) => {
              e.cancel = true;
              if (e.data.SifraArtikla !== "") {
                props.dodajPoklon(e.data.SifraArtikla);
                e.component.cancelEditData();
              }
            }}
          >
            <Paging defaultPageSize={20} />
            <Editing
              mode="row"
              allowUpdating={true}
              allowAdding={true}
              allowDeleting={true}
            />
            <Toolbar>
              <Item name="addRowButton" />
            </Toolbar>
            <Pager
              visible={true}
              showPageSizeSelector={true}
              showInfo={true}
              allowedPageSizes={allowedPageSizes}
              infoText={`Stranica {0} od {1} ({2} artikala)`}
            />
            <SearchPanel visible={true} />
            <Selection mode="single" />
            <Column dataField="SifraArtikla" caption="Šifra artikla" />
          </DataGrid>
        </div>
      ) : (
        <CircularProgress
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
          }}
        />
      )}
    </>
  );
};

export default PokloniTablica;
