import * as React from 'react';
import Modal from '@mui/material/Modal';
import CardMedia from '@mui/material/CardMedia';

type SlikaModalProps = {
    slikaModalOpen: boolean;
    setslikaModalOpen: (value: boolean) => void;
    image: string | null;    
}

export default function SlikaModal(props: SlikaModalProps) {
  const handleClose = () => {props.setslikaModalOpen(false);};

  return (
    <div>
      <Modal
        open={props.slikaModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
            display:'grid',
            gridTemplateColumns: 'auto',
            justifyContent: 'center',
            alignItems:'center'
        }}
      >
        <CardMedia
        component="img"
        height="100%"
        image={props.image??""}
        alt={""}
        style={{
            objectFit:'contain',
            maxWidth:'100%',
            height:'auto',
            width:'fit-content'
        }}
        />
      </Modal>
    </div>
  );
}
