import { createStore } from "devextreme-aspnet-data-nojquery";
import DataGrid, { Column, FilterRow, Item, Paging, SearchPanel, Toolbar, Selection } from "devextreme-react/data-grid";
import { useCallback, useMemo, useRef, useState } from "react";
import { PlusSquare } from "react-bootstrap-icons";
import useApiPromise from "../Hooks/useApiPromise";
import classes from "./Placanja.module.css";
import { Button as ButtonMui } from "@mui/material";
import AddPlacanja from "./AddPlacanja";
import AkcijeMenu from "./AkcijeMenu";
import PlacanjaEdit from "./PlacanjaEdit";

const Placanja = () => {
  const dataGrid = useRef<DataGrid>(null);
  const [rowData, setRowData] = useState<any>("");
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);


    const accessTokenHandler = useCallback((accessToken: string) => {
        return (async () => {
          return accessToken;
        })();
      }, []);
      
  const apiCallPromise = useApiPromise();

  const dataSource = useMemo(() => {
    return createStore({
      loadUrl: `api/Placanja/GetPlacanja`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
        return apiCallPromise(accessTokenHandler).then((token: any) => {
          return (ajaxOptions.headers = {
            Authorization: `Bearer ${token}`,
          });
        });
      },
    });
  }, [accessTokenHandler, apiCallPromise]);

  const akcijeRender = (data: any) => {
    return (
      <AkcijeMenu
      data={data}
      setEditOpen={(e) => {
        setEditModalOpen(true);
        setRowData(data);
      }}
      />
    );
  };


    return(
        <>
        <DataGrid
        className={classes.dataGrid}
        ref={dataGrid}
        id="dataGrid"
        // keyExpr="imeAkcije"
        dataSource={dataSource}
        rowAlternationEnabled
        allowColumnReordering
        allowColumnResizing
        showBorders
        remoteOperations={true}
        // onInitNewRow={(e: any) => {
        //   e.component._options.endChangeCallbacks(true);
        // }}
      >
        {/* <Editing
        // mode="popup"
        allowAdding={true}
        /> */}
        {/* <Popup
          title="Cijena dostave"
          showTitle={true}
          width={700}
          height={525}
        /> */}
        <Paging defaultPageSize={20} />
        <FilterRow visible={true} />
        <SearchPanel visible={true} />
        <Selection mode="single" />
        {/* <Column
          dataField="sif"
          caption="Šifra"
          allowEditing={false}
        //   defaultSortIndex={0}
        //   defaultSortOrder="asc"
        /> */}
        <Column
          dataField="naziv"
          caption="Naziv"
          allowEditing={false}
          defaultSortIndex={0}
          defaultSortOrder="desc"
        />
        <Column
          dataField="minimalnoRata"
          caption="Minimalno rata"
          allowEditing={false}
        //   defaultSortIndex={0}
        //   defaultSortOrder="asc"
        />
        <Column
          dataField="maksimalnoRata"
          caption="Maksimalno rata"
          allowEditing={false}
        //   defaultSortIndex={0}
        //   defaultSortOrder="asc"
        />
        <Column
          dataField="iznosMinimalno"
          caption="Iznos minimalno"
          allowEditing={false}
        //   defaultSortIndex={0}
        //   defaultSortOrder="asc"
        />
        <Column
          dataField="iznosMaksimalno"
          caption="Iznos maksimalno"
          allowEditing={false}
        //   defaultSortIndex={0}
        //   defaultSortOrder="asc"
        />
        <Column
          dataField="odgodaMinimalno"
          caption="Odgoda minimalno"
          allowEditing={false}
        //   defaultSortIndex={0}
        //   defaultSortOrder="asc"
        />
        <Column
          dataField="odgodaMaksimalno"
          caption="Odgoda maksimalno"
          allowEditing={false}
        //   defaultSortIndex={0}
        //   defaultSortOrder="asc"
        />
        <Column
          dataField="napomena"
          caption="Napomena"
          allowEditing={false}
        //   defaultSortIndex={0}
        //   defaultSortOrder="asc"
        />
        <Column
          dataField="vrstaPlacanja"
          caption="Vrsta plaćanja"
          allowEditing={false}
        //   defaultSortIndex={0}
        //   defaultSortOrder="asc"
        />
        <Column
          dataField="prodavaona"
          caption="Prodavaona"
          allowEditing={false}
        //   defaultSortIndex={0}
        //   defaultSortOrder="asc"
        />
        
          {/* <Lookup
            dataSource={data.prijevoznik}
            valueExpr="naziv"
            displayExpr="naziv"
          /> */}
        {/* <Column
          dataField="dostavaCijena"
          caption="Dostava cijena"
          defaultSortIndex={1}
          defaultSortOrder="desc"
        /> */}
        {/* <Column
          dataField="tezinaKoleta"
          caption="Težina koleta"
          width={150}
          defaultSortIndex={2}
          defaultSortOrder="desc"
        /> */}

        
          <Column
            allowReordering={false}
            allowSorting={false}
            cellRender={akcijeRender}
            width={180}
            alignment="center"
          />
        
        <Toolbar>
          <Item location="after">
            <ButtonMui
              onClick={() => {
                setAddModalOpen(true);
              }}
              variant="contained"
            >
              <PlusSquare size={25} />
            </ButtonMui>
          </Item>
        </Toolbar>
      </DataGrid>
      {addModalOpen && 
        <AddPlacanja
          // data={data}
          open={addModalOpen}
          setOpen={setAddModalOpen}
        />
      }
      {editModalOpen && <PlacanjaEdit open={editModalOpen} setOpen={setEditModalOpen} data={rowData}/>}
        </>
    )
}

export default Placanja;