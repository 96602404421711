import { useState } from 'react'
import classes from './Detalji.module.css';
import SlikaModal from './SlikaModal';

const Detalji = (props: any) => {
    const [slikaModalOpen, setslikaModalOpen] = useState(false);    
    const prikaziSliku = () => {
        setslikaModalOpen(true);
    };

    return <>
        <div className={classes.container}>
            <div onClick={prikaziSliku}>
                <img className={classes.slika} src={props.data.data.slikaUrl} alt={props.data.data.naziv} />
            </div>
            <div className={classes.sadrzaj}>
                <p>{props.data.data.sadrzaj}</p>
            </div>
        </div>
        {slikaModalOpen && <SlikaModal
            slikaModalOpen={slikaModalOpen}
            setslikaModalOpen={setslikaModalOpen}
            image={props.data.data.slikaUrl}            
        />}
    </>
    
}

export default Detalji;