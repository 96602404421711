const grupa1 = [
    "imaric@zgdata.hr",
    "mantic@zgdata.hr",
    "filip.sepetanc@elipso.hr",
    "nikola.krpan@elipso.hr",
    "ivan.lajh@elipso.hr",
    "iva.telisman@elipso.hr",
    "ldugalic@zgdata.hr",
    "vedstar@zgdata.hr"
]

const grupa2 = [
    "jelena.gazda@elipso.hr",
    "bernard.sepetanc@elipso.hr"
]

const grupa3  = [
    "magdalena.csucs@elipso.hr",
    "anita.cicak@elipso.hr",
    "daniel.primorac@elipso.hr",
    "jasna.strmecki@elipso.hr",
    "lana.knapic@elipso.hr",
    "laura.erceg@elipso.hr",
    "matej.kolocaj@elipso.hr",
    "matija.novak@elipso.hr",
    "matija.sulentic@elipso.hr",
    "nikolina.huljak@elipso.hr",
    "sandra.persin@elipso.hr",
    "tomislav.premec@elipso.hr",
    "tomislav.teskera@elipso.hr",
    "vedrana.perisic@elipso.hr",
    "zeljka.matas@elipso.hr",
    "lovorka.pavlekovic@elipso.hr",
    "ana.brcic@elipso.hr",
    "ines.lovrovic@elipso.hr",
    "danijel.rastija@elipso.hr"
]

const devToolsGrupa = [
    "imaric@zgdata.hr",
    "mantic@zgdata.hr",
    "ldugalic@zgdata.hr",
    "vedstar@zgdata.hr"
] 

export {grupa1,grupa2,grupa3,devToolsGrupa}