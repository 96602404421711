import Select from "./Select";
import Input from "./Input";
import {
  KategorijaProizvodaNaAkciji,
  OsnoveModel,
  RobnaMarka,
  SelectOpcije,
  TipAkcije,
  Voditelj,
} from "./UnosAkcijePrilikeTypes";
import classes from "./UnosAkcijePrilike.module.css";

type OsnoveProps = {
  osnoveModel: OsnoveModel;
  tipoviAkcije: TipAkcije[];
  robneMarke: RobnaMarka[];
  kategorijeProizvodaNaAkciji: KategorijaProizvodaNaAkciji[];
  voditeljiBrenda: Voditelj[];
  osnoveHandler: (osnoveModel: OsnoveModel) => void;
  tekstPrilikeNotValid: boolean;
  voditeljNotValid: boolean;
  tipNotValid: boolean;
  imePrilikeNotValid: boolean;
  emailNotValid: boolean;
  robnaMarkaNotValid: boolean;
  kategorijeProizvodaNotValid: boolean;
  setTekstPrilikeNotValid: (value: boolean) => void;
  setVoditeljNotValid: (value: boolean) => void;
  setTipNotValid: (value: boolean) => void;
  setImePrilikeNotValid: (value: boolean) => void;
  setEmailNotValid: (value: boolean) => void;
  setRobnaMarkaNotValid: (value: boolean) => void;
  setKategorijaProizvodaNotValid: (value: boolean) => void;
  checkIme: () => void;
};

const Osnovne = (props: OsnoveProps) => {
  return (
    <>
      <h4 className={classes.stepsNaslov}>Osnovne informacije prilike</h4>
      <Select
        inputLabel="Voditelj brenda"
        marginBottom={props.voditeljNotValid ? "" : "1em"}
        voditeljiBrenda={props.voditeljiBrenda}
        handleChange={(value: SelectOpcije | null) => {
          props.osnoveHandler({
            ...props.osnoveModel,
            odabraniVoditelj: value,
          });
          props.setVoditeljNotValid(false);
        }}
        value={props.osnoveModel?.odabraniVoditelj ?? null}
        tipoviAkcije={[]}
        robneMarke={[]}
        kategorijeProizvodaNaAkciji={[]}
        vezanaKupnjaSnizenja={[]}
        naciniSnizenjaCijena={[]}
        vrstePoklonOsiguranja={[]}
        naciniPlacanjaNaRate={[]}
        registracija={[]}
        obavezno={true}
        error={props.voditeljNotValid}
        referenca="voditeljiBrenda"
        popustPromoIznos={[]}
        popustPromoPaymentTypes={[]}
        popustPromoPaymentTypeCodes={[]}
        popustPromoPaymentTypeGroups={[]}
        disabled={false}
      />
      {props.voditeljNotValid && (
        <div className={classes.warning}>
          <span>Obavezno polje!</span>
        </div>
      )}
      <Select
        inputLabel="Tip prilike"
        marginBottom={props.tipNotValid ? "" : "1em"}
        tipoviAkcije={props.tipoviAkcije}
        handleChange={(value: SelectOpcije | null) => {
          props.osnoveHandler({
            ...props.osnoveModel,
            odabraniTipAkcije: value,
          });
          props.setTipNotValid(false);
        }}
        value={props.osnoveModel?.odabraniTipAkcije ?? null}
        voditeljiBrenda={[]}
        robneMarke={[]}
        kategorijeProizvodaNaAkciji={[]}
        vezanaKupnjaSnizenja={[]}
        naciniSnizenjaCijena={[]}
        vrstePoklonOsiguranja={[]}
        naciniPlacanjaNaRate={[]}
        registracija={[]}
        obavezno={true}
        error={props.tipNotValid}
        referenca="tipPrilike"
        popustPromoIznos={[]}
        popustPromoPaymentTypes={[]}
        popustPromoPaymentTypeCodes={[]}
        popustPromoPaymentTypeGroups={[]}
        disabled={false}
      />
      {props.tipNotValid && (
        <div className={classes.warning}>
          <span>Obavezno polje!</span>
        </div>
      )}
      <Input
        inputLabel="Ime prilike (nije moguće kasnije mijenjati, do 41 znak max)"
        marginBottom={props.imePrilikeNotValid ? "" : "1em"}
        handleChange={(value: string) => {
          if (props.osnoveModel.imePrilike.length < 41) {
            props.osnoveHandler({ ...props.osnoveModel, imePrilike: value });
            props.setImePrilikeNotValid(false);
          }
          if (props.osnoveModel.imePrilike.length > value.length) {
            props.osnoveHandler({ ...props.osnoveModel, imePrilike: value });
            props.setImePrilikeNotValid(false);
          }
        }}
        value={props.osnoveModel?.imePrilike ?? ""}
        multiline={false}
        rows={0}
        children={undefined}
        obavezno={true}
        error={props.imePrilikeNotValid}
        inputPropsType=""
        onBlur={props.checkIme}
        disabled={false}
      />
      {props.imePrilikeNotValid && (
        <div className={classes.warning}>
          <span>Obavezno polje!</span>
          <span>
            Naziv promocije ne smije biti jednak nazivima klas1 i klas2
            klasifikacije (npr. televizori, hladnjaci)!
          </span>
        </div>
      )}
      <Input
        inputLabel="Vaš email"
        marginBottom={props.emailNotValid ? "" : "1em"}
        handleChange={(value: string) => {
          props.osnoveHandler({ ...props.osnoveModel, vasEmail: value });
          props.setEmailNotValid(false);
        }}
        value={props.osnoveModel?.vasEmail ?? ""}
        multiline={false}
        rows={0}
        children={undefined}
        obavezno={true}
        error={props.emailNotValid}
        inputPropsType=""
        onBlur={() => {}}
        disabled={false}
      />
      {props.emailNotValid && (
        <div className={classes.warning}>
          <span>Obavezno polje!</span>
          <span>Email nije ispravnog formata!</span>
        </div>
      )}
      <Select
        inputLabel="Robna marka"
        marginBottom={props.robnaMarkaNotValid ? "" : "1em"}
        robneMarke={props.robneMarke}
        handleChange={(value: SelectOpcije | null) => {
          props.osnoveHandler({
            ...props.osnoveModel,
            odabranaRobnaMarka: value,
          });
          props.setRobnaMarkaNotValid(false);
        }}
        value={props.osnoveModel?.odabranaRobnaMarka ?? null}
        voditeljiBrenda={[]}
        tipoviAkcije={[]}
        kategorijeProizvodaNaAkciji={[]}
        vezanaKupnjaSnizenja={[]}
        naciniSnizenjaCijena={[]}
        vrstePoklonOsiguranja={[]}
        naciniPlacanjaNaRate={[]}
        registracija={[]}
        obavezno={true}
        error={props.robnaMarkaNotValid}
        referenca="robnaMarka"
        popustPromoIznos={[]}
        popustPromoPaymentTypes={[]}
        popustPromoPaymentTypeCodes={[]}
        popustPromoPaymentTypeGroups={[]}
        disabled={false}
      />
      {props.robnaMarkaNotValid && (
        <div className={classes.warning}>
          <span>Obavezno polje!</span>
        </div>
      )}
      <Input
        inputLabel="Tekst prilike na Hrvatskom (do 500 znakova max)"
        marginBottom={props.tekstPrilikeNotValid ? "" : "1em"}
        handleChange={(value: string) => {
          if (props.osnoveModel.tekstPrilike.length < 500) {
            props.osnoveHandler({ ...props.osnoveModel, tekstPrilike: value });
            props.setTekstPrilikeNotValid(false);
          }
          if (props.osnoveModel.tekstPrilike.length > value.length) {
            props.osnoveHandler({ ...props.osnoveModel, tekstPrilike: value });
            props.setTekstPrilikeNotValid(false);
          }
        }}
        value={props.osnoveModel?.tekstPrilike ?? ""}
        multiline={true}
        rows={5}
        children={undefined}
        obavezno={true}
        error={props.tekstPrilikeNotValid}
        inputPropsType=""
        onBlur={() => {}}
        disabled={false}
      />
      {props.tekstPrilikeNotValid && (
        <div className={classes.warning}>
          <span>Obavezno polje!</span>
          <span>
            Tekst akcije ne smije sadržavati slijedeće riječi: Registracija,
            Registracije, Registraciju, Prijavu, Prijave, Prijava!
          </span>
        </div>
      )}
      <Select
        inputLabel="Kategorije proizvoda na akciji"
        marginBottom={props.kategorijeProizvodaNotValid ? "" : "1em"}
        kategorijeProizvodaNaAkciji={props.kategorijeProizvodaNaAkciji}
        handleChange={(value: SelectOpcije | null) => {
          props.osnoveHandler({
            ...props.osnoveModel,
            odabranaKategorijaProizvodaNaAkciji: value,
          });
          props.setKategorijaProizvodaNotValid(false);
        }}
        value={props.osnoveModel?.odabranaKategorijaProizvodaNaAkciji ?? null}
        voditeljiBrenda={[]}
        tipoviAkcije={[]}
        robneMarke={[]}
        vezanaKupnjaSnizenja={[]}
        naciniSnizenjaCijena={[]}
        vrstePoklonOsiguranja={[]}
        naciniPlacanjaNaRate={[]}
        registracija={[]}
        obavezno={true}
        error={props.kategorijeProizvodaNotValid}
        referenca="kategorijeProizvoda"
        popustPromoIznos={[]}
        popustPromoPaymentTypes={[]}
        popustPromoPaymentTypeCodes={[]}
        popustPromoPaymentTypeGroups={[]}
        disabled={false}
      />
      {props.kategorijeProizvodaNotValid && (
        <div className={classes.warning}>
          <span>Obavezno polje!</span>
        </div>
      )}
    </>
  );
};

export default Osnovne;
