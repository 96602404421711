import React, { useReducer, useEffect, useState } from 'react';
import classes from './NoviZadatak.module.css';
import noviZadatakReducer from './noviZadatakReducer'
import useApi from '../Hooks/useApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Dialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

type Zadatak = {
    id: number,
    prioritet: number,
    sifra: string,
    voditeljID: number,
    brandID: number,
    opis: string
}

type Opcija = {
    id: number,
    text: string
}

const NoviZadatak = () => {

    const [noviZadatak, dispatcher] = useReducer(noviZadatakReducer, {
        sifra: '',
        id: 0,
        prioritet: 0,
        voditeljID: -1,
        brandID: -1,
        opis: ''
    });

    const [voditelji, setVoditelji] = useState<Opcija[]>([]);
    const [brendovi, setBrendovi] = useState<Opcija[]>([]);
    const [isSaving, setIsSaving] = useState(false);
    const [savingComplete, setSavingComplete] = useState(false);
    const [postSuccess, setPostSuccess] = useState(false);

    const apiCall = useApi();

    const submitHandler = (event: React.SyntheticEvent) => {
        event.preventDefault();
        setIsSaving(true);
        apiCall((accessToken: string) => {
            fetch("/api/VoditeljZadatak/InsertUpdate", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${accessToken}`
                },
                body: JSON.stringify(noviZadatak)
            })
            .then(response => response.json())
            .then((data) => { setIsSaving(false); setSavingComplete(true); if(!data.error) setPostSuccess(true); })
            .catch(() => { setIsSaving(false); setSavingComplete(true); setPostSuccess(false); })
        });

    }

    const mapirajVoditelje = (data: any) => {
        const voditeljiArray: Opcija[] = []
        for (let i = 0; i < data.responseData.length; i++) {
            const voditelj = data.responseData[i];
            voditeljiArray.push({
                id: voditelj.id,
                text: voditelj.nazivKorisnika
            })
        }

        return voditeljiArray;
    }

    const mapirajBrendove = (data: any) => {
        const brendoviArray: Opcija[] = []
        for (let i = 0; i < data.responseData.length; i++) {
            const brend = data.responseData[i];
            brendoviArray.push({
                id: brend.id,
                text: brend.naziv
            })
        }
        return brendoviArray;
    }

    const closeDialogHandler = () => {
        setSavingComplete(false);
        window.location.href = "/GrupaZadaci";
    }

    useEffect(() => {
        let isSubscribed = true;
        (async () => {
            const voditeljiResponse = await fetch('api/MasterData/GetReferenti');
            const brendoviResponse = await fetch('api/MasterData/GetRobneMarke');

            if (isSubscribed) {
                setVoditelji(mapirajVoditelje(await voditeljiResponse.json()));
                setBrendovi(mapirajBrendove(await brendoviResponse.json()));
            }

        })().catch((e) => console.error(e));

        return () => {
            isSubscribed = false;
        }
    }, [])

    return (
        <div className={classes.container}>
            <h4 className="lightBlue p-4" style={{ textTransform: "uppercase", textAlign: "center", marginBottom: "1em", fontWeight: "bold" }}>Novi zadatak</h4>
            <form onSubmit={submitHandler}>
                <div className='mb-3'>
                    <label htmlFor="txtSifra"><strong>Šifra: </strong></label>
                    <input value={noviZadatak.sifra} onChange={(e) => dispatcher({ action: 'SIFRA_UNESENA', value: e.target.value })} className={classes.input} type="text" required id="txtSifra" />
                    <label className="lightBlue small-text">Obavezno polje *</label>
                </div>

                <div className='mb-3'>
                    <label htmlFor="ddlVoditelj"><strong>Voditelj: </strong></label>
                    <select value={noviZadatak.voditeljID} onChange={(e) => dispatcher({ action: 'VODITELJ_ODABRAN', value: parseInt(e.target.value) })} className={`${classes.input} bg-white`} required id="ddlVoditelj">
                        <option value="-1">-- Odaberite voditelja --</option>
                        {voditelji.map(voditelj => {
                            return <option key={voditelj.id} value={voditelj.id}>{voditelj.text}</option>
                        })}
                    </select>
                    <label className="lightBlue small-text">Obavezno polje *</label>
                </div>

                <div className='mb-3'>
                    <label htmlFor="ddlBrend"><strong>Brend: </strong></label>
                    <select value={noviZadatak.brandID} onChange={(e) => dispatcher({ action: 'BREND_ODABRAN', value: parseInt(e.target.value) })} className={`${classes.input} bg-white`} required id="ddlBrend">
                        <option value="-1">-- Odaberite brend --</option>
                        {brendovi.map(brend => {
                            return <option key={brend.id} value={brend.id}>{brend.text}</option>
                        })}
                    </select>
                    <label className="lightBlue small-text">Obavezno polje *</label>
                </div>

                <div className='mb-5'>
                    <label htmlFor="txtOpis"><strong>Opis: </strong></label>
                    <textarea value={noviZadatak.opis} onChange={(e) => dispatcher({ action: 'OPIS_UNESEN', value: e.target.value })} className={classes.input} cols={35} rows={5} id="txtOpis"></textarea>
                    <label className="lightBlue small-text">Obavezno polje *</label>
                </div>

                <button type="submit" className={classes.saveButton}>
                    <FontAwesomeIcon className={classes.saveIcon} icon={faCheck} />
                    Spremi</button>
            </form>

            <Dialog open={savingComplete}>
                <DialogContent className={classes.dialog}><label></label>
                    <div>
                        {postSuccess ?
                            <Alert severity="success">Zadatak uspješno spremljen.</Alert> :
                            <Alert severity="error">Došlo je do greške, zadatak nije spremljen.</Alert>
                        }
                    </div>
                    <div style={{ textAlign: "end" }}>
                        <Button onClick={closeDialogHandler}>Zatvori</Button>

                    </div>
                </DialogContent>
            </Dialog>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isSaving}
                onClick={() => false}>
                <CircularProgress />
            </Backdrop>
        </div>
    )
}


export default NoviZadatak;

export type { Zadatak };