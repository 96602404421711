import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
  KategorijaProizvodaNaAkciji,
  NacinPlacanjaNaRate,
  NacinSnizenjaCijena,
  PopustPromoIznos,
  PopustPromoPaymentType,
  PopustPromoPaymentTypeCode,
  PopustPromoPaymentTypeGroup,
  RobnaMarka,
  SelectOpcije,
  TipAkcije,
  Voditelj,
  VrstaPoklonOsiguranja,
} from "./UnosAkcijePrilikeTypes";

type SelectProps = {
  inputLabel: string;
  marginBottom: string;
  voditeljiBrenda: Voditelj[];
  tipoviAkcije: TipAkcije[];
  handleChange: (value: SelectOpcije | null) => void;
  value: SelectOpcije | null;
  robneMarke: RobnaMarka[];
  kategorijeProizvodaNaAkciji: KategorijaProizvodaNaAkciji[];
  vezanaKupnjaSnizenja: SelectOpcije[];
  naciniSnizenjaCijena: NacinSnizenjaCijena[];
  vrstePoklonOsiguranja: VrstaPoklonOsiguranja[];
  naciniPlacanjaNaRate: NacinPlacanjaNaRate[];
  registracija: SelectOpcije[];
  obavezno: boolean;
  error: boolean;
  referenca: string;
  popustPromoIznos: PopustPromoIznos[];
  popustPromoPaymentTypes: PopustPromoPaymentType[];
  popustPromoPaymentTypeCodes: PopustPromoPaymentTypeCode[];
  popustPromoPaymentTypeGroups: PopustPromoPaymentTypeGroup[];
  disabled: boolean;
};

export default function Select(props: SelectProps) {
  let lista: SelectOpcije[] = [];

  if (props.voditeljiBrenda.length > 0) {
    props.voditeljiBrenda.forEach((element: Voditelj) => {
      lista.push({ label: element.nazivKorisnika, id: element.id });
    });
  }
  if (props.tipoviAkcije) {
    props.tipoviAkcije.forEach((element: TipAkcije) => {
      lista.push({ label: element.naziv, id: element.id });
    });
  }
  if (props.robneMarke) {
    props.robneMarke.forEach((element: RobnaMarka) => {
      lista.push({ label: element.naziv, id: element.id });
    });
  }
  if (props.kategorijeProizvodaNaAkciji) {
    props.kategorijeProizvodaNaAkciji.forEach(
      (element: KategorijaProizvodaNaAkciji) => {
        lista.push({ label: element.naziv, id: element.id });
      }
    );
  }
  if (props.naciniSnizenjaCijena) {
    props.naciniSnizenjaCijena.forEach((element: NacinSnizenjaCijena) => {
      lista.push({ label: element.opis, id: element.id });
    });
  }
  if (props.vrstePoklonOsiguranja) {
    props.vrstePoklonOsiguranja.forEach((element: VrstaPoklonOsiguranja) => {
      lista.push({ label: element.naziv, id: element.artiklID });
    });
  }
  if (props.vezanaKupnjaSnizenja) {
    props.vezanaKupnjaSnizenja.forEach((element: SelectOpcije) => {
      lista.push({ label: element.label, id: element.id });
    });
  }
  if (props.naciniPlacanjaNaRate) {
    props.naciniPlacanjaNaRate.forEach((element: NacinPlacanjaNaRate) => {
      lista.push({ label: element.naziv, id: element.id });
    });
  }
  if (props.registracija) {
    props.registracija.forEach((element: SelectOpcije) => {
      lista.push({ label: element.label, id: element.id });
    });
  }
  if(props.popustPromoIznos) {
    props.popustPromoIznos.forEach((element: PopustPromoIznos) => {
      lista.push({label: element.naziv, id: element.id})
    })
  }
  if(props.popustPromoPaymentTypes) {
    props.popustPromoPaymentTypes.forEach((element: PopustPromoPaymentType) => {
      lista.push({label: element.naziv, id: element.id})
    })
  }
  if(props.popustPromoPaymentTypeCodes) {
    props.popustPromoPaymentTypeCodes.forEach((element: PopustPromoPaymentTypeCode) => {
      lista.push({label: element.naziv, id: element.id})
    })
  }
  if(props.popustPromoPaymentTypeGroups) {
    props.popustPromoPaymentTypeGroups.forEach((element: PopustPromoPaymentTypeGroup) => {
      lista.push({label: element.naziv, id: element.id})
    })
  }

  return (
    <>
    <div>
        <Autocomplete
      className="bg-white m-0"
      disablePortal
      id="combo-box-demo"
      options={lista}
      renderInput={(params) => (
        <TextField error={props.error} {...params} label={props.inputLabel} />
      )}
      size="small"
      sx={{
        marginBottom: props.marginBottom,
      }}
      value={props.value}
      onChange={(event, value, reason) => {
        if (reason === "selectOption") {
          props.handleChange(value);
        }
        if (reason === "clear") {
          props.handleChange(null);
        }
      }}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      getOptionLabel={(option) => {
        if(props.referenca === "snizenjaCijena" || props.referenca === "vrstaOsiguranja" || props.referenca === "promo"){
        return `${option.label}`;
        }else{
          return `${option.id} - ${option.label}`;
        }
      }}
      disabled={props.disabled}
      // defaultValue={props.referenca === "vrstaOsiguranja" ? {id: -1, label: "Nema poklona"} : null}
    />
    </div>
    {(props.obavezno && !props.error) ? <label  style={{marginBottom:props.marginBottom}} className="lightBlue small-text">Obavezno polje *</label> : ""}
    </>
    
    
  );
}
