import {
  AlertModel,
  Artikl,
  KategorijaProizvodaNaAkciji,
  ProizvodiModel,
  SelectOpcije,
  TipAkcije,
} from "../UnosAkcijePrilike/UnosAkcijePrilikeTypes";
import * as XLSX from "xlsx";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";
import ProizvodiTablica from "./ProizvodiTablica";
import classes from "../UnosAkcijePrilike/UnosAkcijePrilike.module.css";
import useApi from "../Hooks/useApi";
import { Button, Dialog, DialogContent, TextField } from "@mui/material";
import Select from "../UnosAkcijePrilike/Select";
import DatePicker from "../UnosAkcijePrilike/Datepicker";

const TestExcelTablice = () => {
  const [uneseniProizvodiNaPrilici, setUneseniProizvodiNaPrilici] = useState<
    Artikl[]
  >([]);
  const [proizvodiNakonCheckCijena, setProizvodiNakonCheckCijena] = useState<
    Artikl[]
  >([]);
  //   const [pocetakPrilike, setPocetakPrilike] = useState<string | null>(null);
  //   const [krajPrilike, setKrajPrilike] = useState<string | null>(null);
  const [odabraniTip, setOdabraniTip] = useState<number | undefined>();
  //   const [kategorijaID, setKategorijaID] = useState<number | null>(null);
  const [kljuc, setKljuc] = useState<string>("");
  const [proizvodiNotValid, setProizvodiNotValid] = useState<boolean>(false);
  const [kategorijeProizvodaNotValid, setKategorijaProizvodaNotValid] =
    useState<boolean>(false);
  const [tipNotValid, setTipNotValid] = useState<boolean>(false);
  const [tipoviAkcije, setTipoveAkcije] = useState<TipAkcije[]>([]);
  const [odabraniTipAkcije, setOdabraniTipAkcije] =
    useState<SelectOpcije | null>(null);
  const [odabraniDatumPocetkaPrilike, setOdabraniDatumPocetkaPrilike] =
    useState<string | null>(null);
  const [odabraniDatumKrajaPrilike, setOdabraniDatumKrajaPrilike] = useState<
    string | null
  >(null);
  const [pocetakNotValid, setPocetakNotValid] = useState<boolean>(false);
  const [krajNotValid, setKrajNotValid] = useState<boolean>(false);
  const [kategorijeProizvodaNaAkciji, setKategorijeProizvodaNaAkciji] =
    useState<KategorijaProizvodaNaAkciji[]>([]);
  const [
    odabranaKategorijaProizvodaNaAkciji,
    setOdabranaKategorijaProizvodaNaAkciji,
  ] = useState<SelectOpcije | null>(null);
  const [dialog, setOpen] = useState<AlertModel>({ open: false, message: "" });
  const handleDialogClose = () => {
    setOpen({ open: false, message: "" });
  };

  let kategorijeCancel = false;
  let tipoviCancel = false;

  const apiCall = useApi();
  const inputFile = useRef<HTMLInputElement>(null);
  const odabirExcelDatoteke = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  const getTipoviAkcije = (accessToken: string) => {
    fetch("/api/MasterData/GetTipoviAkcije", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (tipoviCancel) return;
        setTipoveAkcije(data.responseData);
      });
  };

  useEffect(() => {
    apiCall(getTipoviAkcije);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      tipoviCancel = true;
    };
  }, [apiCall]);

  const getKategorijeProizvoda = (accessToken: string) => {
    fetch("/api/MasterData/GetKategorijeProizvoda", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (kategorijeCancel) return;
        setKategorijeProizvodaNaAkciji(data.responseData);
      });
  };

  useEffect(() => {
    apiCall(getKategorijeProizvoda);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      kategorijeCancel = true;
    };
  }, [apiCall]);

  const readExcel = (file: Blob) => {
    if (file) {
      const promise = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onload = (e) => {
          if (e.target?.result) {
            let bufferArray = e.target.result;
            const wb = XLSX.read(bufferArray, { type: "buffer" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws);
            resolve(data);
          }
          fileReader.onerror = (error) => {
            reject(error);
          };
        };
      });
      promise.then((d: any) => {
        setUneseniProizvodiNaPrilici(
          d.map((artikl: Artikl) => {
            if (artikl.BesplatnaDostava) {
              artikl.BesplatnaDostava = true;
            } else {
              artikl.BesplatnaDostava = false;
            }
            if (artikl.Cijena) {
              // temp.push({
              //   ...element,
              //   Cijena: parseFloat(element.Cijena.toString()).toFixed(2),
              // });
              artikl.Cijena = parseFloat(artikl.Cijena.toFixed(2));
            } else {
              artikl.Cijena = 0.0;
            }
            return artikl;
          })
        );
      });
    }
  };

  const post = (accessToken: string) => {
    let danPocetak;
    let mjesecPocetak;
    let godinaPocetak;
    let danKraj;
    let mjesecKraj;
    let godinaKraj;
    if (odabraniDatumPocetkaPrilike) {
      danPocetak = (
        "0" + new Date(odabraniDatumPocetkaPrilike).getDate()
      ).slice(-2);
      mjesecPocetak = (
        "0" +
        (new Date(odabraniDatumPocetkaPrilike).getMonth() + 1)
      ).slice(-2);
      godinaPocetak = new Date(odabraniDatumPocetkaPrilike).getFullYear();
    }

    if (odabraniDatumKrajaPrilike) {
      danKraj = ("0" + new Date(odabraniDatumKrajaPrilike).getDate()).slice(-2);
      mjesecKraj = (
        "0" +
        (new Date(odabraniDatumKrajaPrilike).getMonth() + 1)
      ).slice(-2);
      godinaKraj = new Date(odabraniDatumKrajaPrilike).getFullYear();
    }

    let formData = new FormData();
    let vrstaAkcije = "";
    if (odabraniTip === 1) {
      vrstaAkcije = "akcija";
    } else if (odabraniTip === 2) {
      vrstaAkcije = "prilika";
    } else {
      vrstaAkcije = "rasprodaja";
    }
    if (odabraniDatumPocetkaPrilike)
      formData.append(
        "akcijaOd",
        `${godinaPocetak}-${mjesecPocetak}-${danPocetak}`
      );
    if (odabraniDatumKrajaPrilike)
      formData.append("akcijaDo", `${godinaKraj}-${mjesecKraj}-${danKraj}`);
    if (uneseniProizvodiNaPrilici.length > 0) {
      for (let i = 0; i < uneseniProizvodiNaPrilici.length; i++) {
        const element = uneseniProizvodiNaPrilici[i];
        formData.append(`artikli[${i}].SifraArtikla`, element.SifraArtikla);
        if (element.Cijena) {
          formData.append(
            `artikli[${i}].Cijena`,
            element.Cijena.toString().replace(".", ",")
          );
        }
        formData.append(
          `artikli[${i}].BesplatnaDostava`,
          element.BesplatnaDostava.toString()
        );
        formData.append(
          `artikli[${i}].SifraOsiguranja`,
          element.SifraOsiguranja?.toString() ?? ""
        );
        formData.append(
          `artikli[${i}].Support`,
          element.Support?.toString().replace(".", ",") ?? ""
        );
        formData.append(
          `artikli[${i}].ReferentniBroj`,
          element.ReferentniBroj ?? ""
        );
        formData.append(
          `artikli[${i}].DodatniRabatKomisija`,
          element.DodatniRabatKomisija?.toString().replace(".", ",") ?? ""
        );
        formData.append(
          `artikli[${i}].AkcijskaNettoCijenaKomisija`,
          element.AkcijskaNettoNabavnaCijenaKomisija?.toString().replace(
            ".",
            ","
          ) ?? ""
        );
      }
    }
    formData.append("vrstaAkcije", vrstaAkcije);
    if (odabranaKategorijaProizvodaNaAkciji) {
      formData.append(
        "kategorijaID",
        odabranaKategorijaProizvodaNaAkciji.id.toString()
      );
    }
    formData.append("kljuc", kljuc);
    fetch("api/Akcija/CheckArtiklCijena", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      method: "post",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseData) {
          let arr =
            proizvodiNakonCheckCijena.length > 0
              ? proizvodiNakonCheckCijena
              : uneseniProizvodiNaPrilici;
          let arr2 = data.responseData;
          let tempArr: Artikl[] = [];
          let tempOrderBy: Artikl[] = [];
          for (let i = 0; i < arr.length; i++) {
            const element = arr[i];
            let test = arr2.find(
              (i: any) => i.artikl === parseInt(element.SifraArtikla)
            );
            let tempObj: Artikl = {
              Cijena: element.Cijena,
              SifraArtikla: element.SifraArtikla,
              greska: "",
              isOk: true,
              PromoKod: element.PromoKod,
              naziv: "",
              katBroj: "",
              BesplatnaDostava: element.BesplatnaDostava,
              SifraOsiguranja: element.SifraOsiguranja,
              Support: element.Support,
              ReferentniBroj: element.ReferentniBroj,
              AkcijskaNettoNabavnaCijenaKomisija:
                element.AkcijskaNettoNabavnaCijenaKomisija,
              DodatniRabatKomisija: element.DodatniRabatKomisija,
            };
            if (test) {
              tempObj.greska = test?.greska;
              tempObj.isOk = test?.isOk;
              tempObj.katBroj = test?.katBroj;
              tempObj.naziv = test?.naziv;
            }
            tempArr.push(tempObj);
          }
          tempArr.forEach((element) => {
            if (element.isOk) {
              tempOrderBy.push(element);
            } else {
              tempOrderBy.unshift(element);
            }
          });
          if (tempOrderBy.length > 0) {
            // props.setProizvodiCheckCijena(tempOrderBy);
            // props.proizvodiCheckCijenaHandler({
            //   ...props.proizvodiModel,
            //   proizvodiNakonCheckCijena: tempOrderBy,
            // });
            setProizvodiNakonCheckCijena(tempOrderBy);
          }
        }
      });
  };

  const apiCallPost = () => {
    if (validacija()) {
      apiCall(post);
    }
  };

  const validacija = () => {
    let counter = 0;
    if (odabraniTip === undefined) {
      setTipNotValid(true);
      counter++;
    }
    if (odabraniDatumPocetkaPrilike === null) {
      setPocetakNotValid(true);
      counter++;
    }
    if (odabraniDatumKrajaPrilike === null) {
      setKrajNotValid(true);
      counter++;
    }
    if (odabranaKategorijaProizvodaNaAkciji === null) {
      setKategorijaProizvodaNotValid(true);
      counter++;
    }

    if (counter > 0) {
      return false;
    } else {
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      let razlikaUDanima = 0;
      // a and b are javascript Date objects
      function dateDiffInDays(a: Date, b: Date) {
        // Discard the time and time-zone information.
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
      }
      razlikaUDanima = dateDiffInDays(
        new Date(odabraniDatumPocetkaPrilike ?? ""),
        new Date(odabraniDatumKrajaPrilike ?? "")
      );
      if (razlikaUDanima < 0) {
        setOpen({
          open: true,
          message:
            "Datum kraja prilike mora biti veći ili jednak od datuma početka prilike",
        });
      } else {
        if (razlikaUDanima + 1 > 35) {
          setOpen({
            open: true,
            message: "Promocija ne smije trajati duže od 35 dana",
          });
        } else {
          if (odabraniTipAkcije?.id === 1) {
            if (new Date(odabraniDatumPocetkaPrilike ?? "").getDay() !== 1) {
              setOpen({
                open: true,
                message: "Akcija može početi samo u Ponedeljak!",
              });
            } else if (
              new Date(odabraniDatumKrajaPrilike ?? "").getDay() !== 0
            ) {
              setOpen({
                open: true,
                message: "Akcija može završiti samo u Nedelju!",
              });
            } else {
              return true;
            }
          } else {
            return true;
          }
        }
      }
    }
  };

  return (
    <>
      <div style={{ padding: "30px 20px" }}>
        <h4 className={classes.stepsNaslov}>Test excel tablice</h4>
        <div className={classes.container}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div style={{ width: "300px", marginRight: "20px" }}>
              <Select
                inputLabel="Tip prilike"
                marginBottom={tipNotValid ? "" : "1em"}
                tipoviAkcije={tipoviAkcije}
                handleChange={(value: SelectOpcije | null) => {
                  setOdabraniTipAkcije(value);
                  setOdabraniTip(value?.id);
                  setTipNotValid(false);
                }}
                value={odabraniTipAkcije}
                voditeljiBrenda={[]}
                robneMarke={[]}
                kategorijeProizvodaNaAkciji={[]}
                vezanaKupnjaSnizenja={[]}
                naciniSnizenjaCijena={[]}
                vrstePoklonOsiguranja={[]}
                naciniPlacanjaNaRate={[]}
                registracija={[]}
                obavezno={true}
                error={tipNotValid}
                referenca="tipPrilike"
                popustPromoIznos={[]}
                popustPromoPaymentTypes={[]}
                popustPromoPaymentTypeCodes={[]}
                popustPromoPaymentTypeGroups={[]}
                disabled={false}
              />
              {tipNotValid && (
                <div className={classes.warning}>
                  <span>Obavezno polje!</span>
                </div>
              )}
            </div>
            <div style={{ marginRight: "20px", width: "300px" }}>
              <Select
                inputLabel="Kategorije proizvoda na akciji"
                marginBottom={kategorijeProizvodaNotValid ? "" : "1em"}
                kategorijeProizvodaNaAkciji={kategorijeProizvodaNaAkciji}
                handleChange={(value: SelectOpcije | null) => {
                  setOdabranaKategorijaProizvodaNaAkciji(value);
                  setKategorijaProizvodaNotValid(false);
                }}
                value={odabranaKategorijaProizvodaNaAkciji ?? null}
                voditeljiBrenda={[]}
                tipoviAkcije={[]}
                robneMarke={[]}
                vezanaKupnjaSnizenja={[]}
                naciniSnizenjaCijena={[]}
                vrstePoklonOsiguranja={[]}
                naciniPlacanjaNaRate={[]}
                registracija={[]}
                obavezno={true}
                error={kategorijeProizvodaNotValid}
                referenca="kategorijeProizvoda"
                popustPromoIznos={[]}
                popustPromoPaymentTypes={[]}
                popustPromoPaymentTypeCodes={[]}
                popustPromoPaymentTypeGroups={[]}
                disabled={false}
              />
              {kategorijeProizvodaNotValid && (
                <div className={classes.warning}>
                  <span>Obavezno polje!</span>
                </div>
              )}
            </div>
            <div style={{ marginRight: "20px" }}>
              <DatePicker
                value={odabraniDatumPocetkaPrilike}
                handleChange={(value: string | null) => {
                  setOdabraniDatumPocetkaPrilike(value);
                  setPocetakNotValid(false);
                }}
                label="Početak prilike"
                marginBottom={pocetakNotValid ? "" : "1em"}
                obavezno={true}
                error={pocetakNotValid}
                disabled={false}
                maxDate={undefined}
                minDate={undefined}
              />
              {pocetakNotValid && (
                <div className={classes.warning}>
                  <span>Obavezno polje!</span>
                </div>
              )}
            </div>
            <div>
              <DatePicker
                value={odabraniDatumKrajaPrilike}
                handleChange={(value: string | null) => {
                  setOdabraniDatumKrajaPrilike(value);
                  setKrajNotValid(false);
                }}
                label="Kraj prilike"
                marginBottom={krajNotValid ? "" : "1em"}
                obavezno={true}
                error={krajNotValid}
                disabled={false}
                maxDate={undefined}
                minDate={undefined}
              />
              {krajNotValid && (
                <div className={classes.warning}>
                  <span>Obavezno polje!</span>
                </div>
              )}
            </div>
          </div>
          <input
            accept={
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"'
            }
            className={classes.hidden}
            type="file"
            multiple={false}
            onChange={(e) => {
              let file: any = [];
              if (e.target.files) {
                file = e.target.files[0];
              }
              readExcel(file);
              e.target.value = "";
            }}
            ref={inputFile}
          />
          <button
            type="button"
            className={classes.uploadButtonExcel}
            onClick={odabirExcelDatoteke}
          >
            <FontAwesomeIcon
              className={classes.uploadIcon}
              icon={faArrowUpFromBracket}
            />
            Odabir datoteka
          </button>
        </div>
        <div>
          <ProizvodiTablica
            artikli={uneseniProizvodiNaPrilici}
            //   brisiProizvod={props.brisiProizvod}
            //   updateProizvod={props.updateProizvod}
            //   dodajProizvod={props.dodajProizvod}
            pocetakPrilike={new Date(odabraniDatumPocetkaPrilike ?? "")}
            krajPrilike={new Date(odabraniDatumKrajaPrilike ?? "")}
            proizvodiCheckCijena={proizvodiNakonCheckCijena}
            setProizvodiNotValid={setProizvodiNotValid}
            odabraniTip={odabraniTip}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Button
              onClick={() => {
                setProizvodiNotValid(false);
                apiCallPost();
              }}
              variant="contained"
            >
              Provjeri artikle
            </Button>

            <TextField
              value={kljuc}
              onChange={(e) => {
                setKljuc(e.target.value);
              }}
              label="Ključ"
              size="small"
              style={{
                backgroundColor: "white",
                marginLeft: "20px",
              }}
              id="kljuc"
            />
          </div>
          {/* {(props.odabraniTip === 1 || props.odabraniTip === 2) && ( */}

          {/* )} */}
          <Button
            onClick={() => {
              setProizvodiNakonCheckCijena([]);
              setUneseniProizvodiNaPrilici([]);
            }}
            variant="contained"
          >
            Ukloni artikle
          </Button>
        </div>
        {dialog.open && (
          <Dialog onClose={handleDialogClose} open={dialog.open}>
            <DialogContent className={classes.dialog}>
              <label>{dialog.message}</label>
              <div style={{ textAlign: "end" }}>
                <Button onClick={handleDialogClose}>Zatvori</Button>
              </div>
            </DialogContent>
          </Dialog>
        )}
      </div>
    </>
  );
};

export default TestExcelTablice;
