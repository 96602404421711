import classes from "./../UnosAkcijePrilike.module.css";
import PromoPopustTablica from "./PromoPopustTablica";
import { SlikaInfo } from "../../../utils/utils";
import {
  PopustPromoIznos,
  PopustPromoPaymentType,
  PopustPromoPaymentTypeCode,
  PopustPromoPaymentTypeGroup,
  PromoPopustModel,
  Artikl,
  PromoPopustArtikli,
  AkcijaPromoKodViewModel,
} from "../UnosAkcijePrilikeTypes";
import { useEffect, useState } from "react";
import PromoPopustForma from "./PromoPopustForma";

type PromoPopustProps = {
  popustPromoIznos: PopustPromoIznos[];
  popustPromoPaymentType: PopustPromoPaymentType[];
  popustPromoPaymentTypeCode: PopustPromoPaymentTypeCode[];
  popustPromoPaymentTypeGroup: PopustPromoPaymentTypeGroup[];
  promoPopustModel: PromoPopustModel;
  promoPopustHandler: (value: PromoPopustModel) => void;
  artikli: Artikl[];
  brisiProizvod: (sifra: string) => void;
  updateProizvod: (value: {
    new: PromoPopustArtikli;
    old: PromoPopustArtikli;
  }) => void;
  dodajProizvod: (artikl: string) => void;
  odabraniTip: number | undefined;
  promoPopustArtikli: PromoPopustArtikli[];
  artikliPromoPopustHandler: (value: PromoPopustArtikli[]) => void;
  promoKodNotValid: boolean;
  setPromoKodNotValid: (value: boolean) => void;
  promoIznosNotValid: boolean;
  setPromoIznosNotValid: (value: boolean) => void;
  promoPaymentTypeNotValid: boolean;
  setPromoPaymentTypeNotValid: (value: boolean) => void;
  promoPaymentTypeCodesNotValid: boolean;
  setPromoPaymentTypeCodesNotValid: (value: boolean) => void;
  promoPaymentTypeGroupsNotValid: boolean;
  setPromoPaymentTypeGroupsNotValid: (value: boolean) => void;
  pocetakPrilike: string | undefined;
  krajPrilike: string | undefined;
  ucitajSliku: (file: any, fileName: string | null) => void;
  slike151px: SlikaInfo[] | null;
  promoPocetakNotValid: boolean;
  setPromoPocetakNotValid: (value: boolean) => void;
  promoKrajNotValid: boolean;
  setPromoKrajNotValid: (value: boolean) => void;
  slikaPromoNotValid: boolean;
  setSlikaPromoNotValid: (value: boolean) => void;
  listaPromoKodova: AkcijaPromoKodViewModel[];
  filterPromoKodove: (value: Artikl[]) => void;
  brisiSliku: (value: null) => void;
  listaPromoPopustHandler: (value: AkcijaPromoKodViewModel) => void;
  updatePromoKod: (promoKod: string, sifraArtikla: number) => void;
};

const PromoPopust = (props: PromoPopustProps) => {
  const [openForma, setOpenForma] = useState<boolean>(false);
  const [data, setData] = useState<AkcijaPromoKodViewModel | null>(null);

  useEffect(() => {
    props.filterPromoKodove(props.artikli);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  

  return (
    <>
      <h4 className={classes.stepsNaslov}>Promo popust</h4>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div>
          <PromoPopustTablica
            artikli={props.artikli}
            brisiProizvod={props.brisiProizvod}
            updateProizvod={props.updateProizvod}
            dodajProizvod={props.dodajProizvod}
            odabraniTip={props.odabraniTip}
            promoPopustArtikli={props.promoPopustArtikli}
            listaPromoKodova={props.listaPromoKodova}
            setData={setData}
            setOpenForma={setOpenForma}
            openForma={openForma}
          />
        </div>
      </div>
      {openForma && (
        <PromoPopustForma
          pocetakPrilike={props.pocetakPrilike}
          krajPrilike={props.krajPrilike}
          popustPromoIznos={props.popustPromoIznos}
          open={openForma}
          setOpen={setOpenForma}
          popustPromoPaymentType={props.popustPromoPaymentType}
          popustPromoPaymentTypeCode={props.popustPromoPaymentTypeCode}
          popustPromoPaymentTypeGroup={props.popustPromoPaymentTypeGroup}
          promoIznosNotValid={props.promoIznosNotValid}
          promoKodNotValid={props.promoKodNotValid}
          promoKrajNotValid={props.promoKrajNotValid}
          promoPaymentTypeCodesNotValid={props.promoPaymentTypeCodesNotValid}
          promoPaymentTypeGroupsNotValid={props.promoPaymentTypeGroupsNotValid}
          promoPaymentTypeNotValid={props.promoPaymentTypeNotValid}
          promoPocetakNotValid={props.promoPocetakNotValid}
          promoPopustHandler={props.promoPopustHandler}
          promoPopustModel={props.promoPopustModel}
          setPromoIznosNotValid={props.setPromoIznosNotValid}
          setPromoKodNotValid={props.setPromoKodNotValid}
          setPromoKrajNotValid={props.setPromoKrajNotValid}
          setPromoPaymentTypeCodesNotValid={
            props.setPromoPaymentTypeCodesNotValid
          }
          setPromoPaymentTypeGroupsNotValid={
            props.setPromoPaymentTypeGroupsNotValid
          }
          setPromoPaymentTypeNotValid={props.setPromoPaymentTypeNotValid}
          setPromoPocetakNotValid={props.setPromoPocetakNotValid}
          slike151px={props.slike151px}
          slikaPromoNotValid={props.slikaPromoNotValid}
          ucitajSliku={props.ucitajSliku}
          data={data}
          listaPromoKodova={props.listaPromoKodova}
          brisiSliku={props.brisiSliku}
          setSlikaPromoNotValid={props.setSlikaPromoNotValid}
          listaPromoPopustHandler={props.listaPromoPopustHandler}
          updatePromoKod={props.updatePromoKod}
        />
      )}
    </>
  );
};

export default PromoPopust;
