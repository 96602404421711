import React, { useCallback } from 'react'
import useApiPromise from "../Hooks/useApiPromise";
import { Tooltip } from "@mui/material";
import FullCalendar, {
    EventContentArg,
  } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { EventDetalji } from './SlobodniDatumi'

 
  type KalendarProps = {
      setDetaljiHandler: (event: EventDetalji) => void,      
      setDetaljiOpenHandler: (value: boolean) => void
  }

  const padTo2Digits = (num:number) => {
    return num.toString().padStart(2, '0');
  }
  
  const formatDate = (date:any, separator: string) => {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join(separator);
  }

const Kalendar = (props: KalendarProps) => {

    const apiCall = useApiPromise();
    const accessTokenHandler = useCallback((accessToken: string) => {
        return (async () => {
            return accessToken;
        })()
    }, [])
    
    const EventClickHandler = (e:any) => { 
        let start = "";
        let end = "";
        if (e.event.start) {
          start = formatDate(e.event.start, '.');
        }
        if (e.event.end) {
          end = " - " + formatDate(e.event.end, '.');
        }
    
        let detalji: EventDetalji = {
          brend: e.event._def.extendedProps.brand,
          naziv: e.event.title,
          opis: e.event._def.extendedProps.opis,
          trajanje: `${start} ${end}`,
          voditelj: e.event._def.extendedProps.voditelj
        }
        
        props.setDetaljiHandler(detalji);
        props.setDetaljiOpenHandler(true);
        // setDetaljiOpen(true);
      }

    const getAkcijeKalendar = (start:string, end:string) => {
        return apiCall(accessTokenHandler).then(token => {
            return fetch(`api/Akcija/GetAkcijeKalendar?start=${start}&end=${end}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
            .then((response) => response.json())
            .then((data) => {
              return data.responseData;
            });        
        })
        
      };

      const eventContent = (arg: EventContentArg) => {
        return (
          <Tooltip title={arg.event.title}>
            <div>{arg.event.title}</div>
          </Tooltip>
        );
      };      

    return (
        <FullCalendar
            plugins={[dayGridPlugin]}
            locale={"hr"}
            firstDay={1}
            events={(fetchInfo, success, failure) => {
                getAkcijeKalendar(fetchInfo.startStr.substring(0, 10), fetchInfo.endStr.substring(0, 10)).then(events => {
                    // setEventsLoaded(true);
                    // setEvents(events);
                    success(events);
                });
            }}
            initialView={"dayGridMonth"}
            eventContent={eventContent}
            headerToolbar={{
                right: "today,prev,next",
            }}
            // navLinkDayClick={() => {
            // }}
            eventClick={EventClickHandler}
            height="auto"
            buttonText={{
                today: "danas",
                month: "mjesec",
                week: "tjedan",
                day: "dan",
                list: "list",
            }}
            themeSystem="bootstrap5"
        />
    )
}

export default React.memo(Kalendar)