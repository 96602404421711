import { SketchPicker } from "react-color";

type ColorPickerProps = {
  hex: string;
  setHex: (value: string) => void;
};

const ColorPicker = (props: ColorPickerProps) => {
  return (
    <div style={{marginBottom:'10px', width:'100%'}}>
      <SketchPicker
        onChange={(e) => {
          props.setHex(e.hex);
        }}
        color={props.hex}
        width="97%"
        disableAlpha={true}
      />
    </div>
  );
};

export default ColorPicker;
