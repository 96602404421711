import classes from "./CijenaDostave.module.css";
import { useMsal } from "@azure/msal-react";
import useApi from "../Hooks/useApi";
import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  Column,
  DataGrid,
  FilterRow,
  Item,
  Lookup,
  Paging,
  Popup,
  SearchPanel,
  Selection,
  Toolbar,
} from "devextreme-react/data-grid";
import cijeneDostaveReducer from "./cijenaDostaveReducer";
import { defaultState } from "./defaultState";
import { Button as ButtonMui } from "@mui/material";
import EditModal from "./EditCijenaDostaveModal";
import AddCijenaDostaveModal from "./AddCijenaDostaveModal";
import { PlusSquare } from "react-bootstrap-icons";
import { createStore } from "devextreme-aspnet-data-nojquery";
import useApiPromise from "../Hooks/useApiPromise";

const CijenaDostave = () => {
  const [data, dispatcher] = useReducer(cijeneDostaveReducer, defaultState);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [rowData, setRowData] = useState<any>("");
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  const apiCallPromise = useApiPromise();

  const accessTokenHandler = useCallback((accessToken: string) => {
    return (async () => {
      return accessToken;
    })();
  }, []);

  const dataSource = useMemo(() => {
    return createStore({
      loadUrl: `api/Dostava/GetDostavaCijene`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
        return apiCallPromise(accessTokenHandler).then((token: any) => {
          return (ajaxOptions.headers = {
            Authorization: `Bearer ${token}`,
          });
        });
      },
    });
  }, [accessTokenHandler, apiCallPromise]);

  const editUsers = useMemo(() => [
    "Lovro Dugalić",
    "Ivor Marić",
    "Marko Antić",
    "Filip Šepetanc",
    "Jelena Gazda",
    "Ivan Lajh",
    "Iva Telišman",
    "Nikola Krpan"
  ], []);

  const [editUser, setEditUser] = useState<boolean>(false);
  const { accounts } = useMsal();
  const apiCall = useApi();
  // let cijeneCancel = false;
  let prijevoznikCancel = false;
  const dataGrid = useRef<DataGrid>(null);

  const getPrijevoznik = (accessToken: string) => {
    fetch("/api/MasterData/GetPrijevoznik", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (prijevoznikCancel) return;
        if (data.responseData) {
          dispatcher({
            action: "GET_PRIJEVOZNIK",
            value: data.responseData,
          });
        }
      });
  };

  useEffect(() => {
    apiCall(getPrijevoznik);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      prijevoznikCancel = true;
    };
  }, [apiCall]);

  const akcijeRender = (data: any) => {
    return (
      <ButtonMui
        onClick={() => {
          setRowData(data.data);
          setEditModalOpen(true);
        }}
        variant="contained"
      >
        Uredi
      </ButtonMui>
    );
  };

  useEffect(() => {
    if (accounts[0]) {
      if (editUsers.includes(accounts[0].name ?? "")) {
        setEditUser(true);
      } else {
        setEditUser(false);
      }
    }
  }, [accounts, editUsers]);

  return (
    <>
      <DataGrid
        className={classes.dataGrid}
        ref={dataGrid}
        id="dataGrid"
        // keyExpr="imeAkcije"
        dataSource={dataSource}
        rowAlternationEnabled
        allowColumnReordering
        allowColumnResizing
        showBorders
        remoteOperations={true}
        // onInitNewRow={(e: any) => {
        //   e.component._options.endChangeCallbacks(true);
        // }}
      >
        {/* <Editing
        // mode="popup"
        allowAdding={true}
        /> */}
        <Popup
          title="Cijena dostave"
          showTitle={true}
          width={700}
          height={525}
        />
        <Paging defaultPageSize={20} />
        <FilterRow visible={true} />
        <SearchPanel visible={true} />
        <Selection mode="single" />
        <Column
          dataField="prijevoznik"
          caption="Prijevoznik"
          allowEditing={false}
          defaultSortIndex={0}
          defaultSortOrder="asc"
        >
          <Lookup
            dataSource={data.prijevoznik}
            valueExpr="naziv"
            displayExpr="naziv"
          />
        </Column>
        <Column
          dataField="dostavaCijena"
          caption="Dostava cijena"
          defaultSortIndex={1}
          defaultSortOrder="desc"
        />
        <Column
          dataField="tezinaKoleta"
          caption="Težina koleta"
          width={150}
          defaultSortIndex={2}
          defaultSortOrder="desc"
        ></Column>

        {editUser && (
          <Column
            allowReordering={false}
            allowSorting={false}
            cellRender={akcijeRender}
            width={"5%"}
            alignment="center"
          />
        )}
        <Toolbar>
          <Item location="after">
            <ButtonMui
              onClick={() => {
                setAddModalOpen(true);
              }}
              variant="contained"
            >
              <PlusSquare size={25} />
            </ButtonMui>
          </Item>
        </Toolbar>
      </DataGrid>

      {editModalOpen && (
        <EditModal
          setData={setRowData}
          data={rowData}
          open={editModalOpen}
          setOpen={setEditModalOpen}
          masterData={data}
        />
      )}
      {addModalOpen && (
        <AddCijenaDostaveModal
          data={data}
          open={addModalOpen}
          setOpen={setAddModalOpen}
        />
      )}
    </>
  );
};

export default CijenaDostave;
