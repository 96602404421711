import classes from './Slika.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

type SlikaProps = {
    name: string,
    src: string,
    size: number,
    odbaciSlikuHandler: (nazivSlike: string) => void
}

const Slika = ({ name, src, size, odbaciSlikuHandler }: SlikaProps) => {
    return (
        <div className={classes.container}>  
            <div className={classes.removeButton} onClick={() => odbaciSlikuHandler(name)}>
                <FontAwesomeIcon icon={faTimesCircle} />
            </div>          
            <div>
                <img className={classes.slike} src={src} alt={name} />
                {/* <p className={classes.textCenter}>{name}</p> */}
                {/* <p className={classes.textCenter}>{size.toFixed(2)} KB</p> */}
            </div>
            <div className={classes.info}>
                <p className={classes.textCenter}><strong>{name}</strong></p>
                <p className={`${classes.textCenter} ${classes.size}`}>{size.toFixed(2)} KB</p>
            </div>
            <div className={classes.footer}>
                
            </div>            
        </div>

    )
}

export default Slika;
