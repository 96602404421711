import {
  DataGrid,
  Column,
  Selection,
  Editing,
  FilterRow,
  Toolbar,
  Item,
} from "devextreme-react/data-grid";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createStore } from "devextreme-aspnet-data-nojquery";
import useApiPromise from "../../Hooks/useApiPromise";
import { Pager, Paging } from "devextreme-react/tree-list";
import { Button as ButtonMui } from "@mui/material";
import { PlusSquare } from "react-bootstrap-icons";
import useApi from "../../Hooks/useApi";
import Swal from "sweetalert2";
import classes from "../HeaderFooter.module.css";
import BackDrop from "../../UnosAkcijePrilike/BackDrop";
import { Dialog, DialogContent } from "@mui/material";
import { AlertModel } from "../../UnosAkcijePrilike/UnosAkcijePrilikeTypes";
import Button from "@mui/material/Button";
import AddModal from "./AddModal";
import EditModal from "./EditModal";
import { Boja } from "./BojeTypes";

const Boje = () => {
  const allowedPageSizes = [20, 50, 100];

  const dataGrid = useRef<DataGrid>(null);
  const apiCallPromise = useApiPromise();
  const apiCall = useApi();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [bojaId, setBojaId] = useState<number>(-1);
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [dialog, setOpen] = useState<AlertModel>({ open: false, message: "" });
  const handleDialogClose = () => {
    setOpen({ open: false, message: "" });
  };
  const [rowData, setRowData] = useState<Boja>({ id: -1, hex: "", naziv: "" });

  const accessTokenHandler = useCallback((accessToken: string) => {
    return (async () => {
      return accessToken;
    })();
  }, []);

  const dataSource = useMemo(() => {
    return createStore({
      key: "id",
      loadUrl: `api/Boja/GetBoje`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
        return apiCallPromise(accessTokenHandler).then((token: any) => {
          return (ajaxOptions.headers = {
            Authorization: `Bearer ${token}`,
          });
        });
      },
    });
  }, [accessTokenHandler, apiCallPromise]);

  const deleteBoja = useCallback(
    (accessToken: string) => {
      if (bojaId !== -1) {
        setIsLoading(true);
        let formData = new FormData();
        formData.append("id", bojaId.toString());

        fetch("api/Boja/DeleteBoja", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          method: "post",
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.responseData) {
              setIsLoading(false);
              Swal.fire({
                icon: "success",
                title: "Obrisano",
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              });
            } else {
              setIsLoading(false);
              Swal.fire({
                title: data.error,
                showConfirmButton: false,
                toast: true,
                customClass: {
                  container: classes.swalContainer,
                  popup: classes.swal2IconError,
                },
                timer: 1500,
                iconColor: "white",
                timerProgressBar: true,
                icon: "warning",
                position: "top-right",
              });
            }
          });
      }
    },
    [bojaId]
  );

  useEffect(() => {
    apiCall(deleteBoja);
  }, [apiCall, deleteBoja]);

  const provjeraNovaBoja = (accessToken: string) => {
    fetch("/api/Boja/CanCreateNewColor", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseData) {
          setAddModalOpen(true);
        } else {
          setOpen({ open: true, message: data.error });
        }
      });
  };

  const canCreateNewColor = () => {
    apiCall(provjeraNovaBoja);
  };

  return (
    <>
      <div>
        <DataGrid
          id="dataGrid"
          ref={dataGrid}
          dataSource={dataSource}
          rowAlternationEnabled
          allowColumnReordering
          allowColumnResizing
          showBorders
          onEditingStart={(e) => {
            e.cancel = true;
            setRowData(e.data);
            setEditModalOpen(true);
          }}
          remoteOperations={true}
          onRowRemoving={(e: any) => {
            setBojaId(e.data.id);
            e.cancel = true;
          }}
        >
          <FilterRow visible={true} />
          <Paging defaultPageSize={20} />
          <Editing mode="row" allowUpdating allowDeleting />
          <Pager
            visible={true}
            showPageSizeSelector={true}
            showInfo={true}
            allowedPageSizes={allowedPageSizes}
            infoText={`Stranica {0} od {1} ({2} artikala)`}
          />
          <Selection mode="single" />
          <Column
            allowEditing={true}
            // width="10%"
            dataField="naziv"
            caption="Naziv"
          ></Column>
          <Column
            allowEditing={true}
            // width="75%"
            dataField="hex"
            caption="Hex"
          />
          <Toolbar>
            <Item location="after">
              <ButtonMui
                onClick={() => {
                  canCreateNewColor();
                }}
                variant="contained"
              >
                <PlusSquare size={25} />
              </ButtonMui>
            </Item>
          </Toolbar>
        </DataGrid>
      </div>
      {addModalOpen && (
        <AddModal
          open={addModalOpen}
          setOpen={setAddModalOpen}
          setBojaId={setBojaId}
        />
      )}
      {editModalOpen && (
        <EditModal
          open={editModalOpen}
          setOpen={setEditModalOpen}
          data={rowData}
        />
      )}
      {dialog.open && (
        <Dialog onClose={handleDialogClose} open={dialog.open}>
          <DialogContent className={classes.dialog}>
            <label>{dialog.message}</label>
            <div style={{ textAlign: "end" }}>
              <Button onClick={handleDialogClose}>Zatvori</Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
      {isLoading && <BackDrop />}
    </>
  );
};

export default Boje;
