import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

type AkcijeMenuProps = {
  data: any;
  username: string;
  promjenaBannera: (e: any) => void;
  promjenaOpisa: (e: any) => void;
  promjenaArtikala: (e: any) => void;
  odobriAkciju: (e: any) => void;
  odobreno: boolean;
  akcijaPocela: boolean;
};

const useriSaPravomOdobrenja = [
  "magdalena.csucs@elipso.hr",
  "iva.telisman@elipso.hr",
  "jelena.gazda@elipso.hr",
  "imaric@zgdata.hr",
  "ldugalic@zgdata.hr",
  "mantic@zgdata.hr",
  "nikola.krpan@elipso.hr",
];

export default function AkcijeMenu(props: AkcijeMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="contained"
      >
        Administracija
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={props.promjenaBannera}>Promjena bannera</MenuItem>
        <MenuItem onClick={props.promjenaOpisa}>Promjena opisa</MenuItem>
        {!props.odobreno && !props.akcijaPocela && (
          <MenuItem onClick={props.promjenaArtikala}>
            Promjena artikala
          </MenuItem>
        )}
        {useriSaPravomOdobrenja.includes(props.username) && !props.odobreno && (
          <MenuItem onClick={props.odobriAkciju}>Odobri akciju</MenuItem>
        )}
      </Menu>
    </div>
  );
}
