import produce, { Draft } from "immer";
import { PromjenaArtikalaActions } from "./promjenaArtikalaActions";
import { ArtiklViewModel, DefaultState } from "./PromjenaArtikalaTypes";

const promjenaArtikalaReducer = produce(
  (oldState: Draft<DefaultState>, action: PromjenaArtikalaActions) => {
    switch (action.action) {
      case "GET_ARTIKLI":
        oldState.artikli = [];
        action.value.forEach((element) => {
          let temp: ArtiklViewModel = {
            SifraArtikla: "",
            Cijena: 0,
            greska: "",
            isOk: true,
            promoKod: "",
            katBroj: "",
            naziv: "",
            besplatnaDostava: false,
            sifraOsiguranja: parseInt(""),
            nazivOsiguranja: "",
            support: 0,
            referentniBroj: "",
            dodatniRabatKomisija: null,
            akcijskaNettoNabavnaCijenaKomisija: null,
          };
          temp.SifraArtikla = element.sifraArtikla.toString();
          temp.Cijena = element.cijena;
          temp.promoKod = element.promoKod;
          temp.naziv = element.naziv;
          temp.katBroj = element.katBroj;
          temp.besplatnaDostava = element.besplatnaDostava;
          temp.sifraOsiguranja = element.sifraOsiguranja;
          temp.nazivOsiguranja = element.nazivOsiguranja;
          temp.support = element.support;
          temp.referentniBroj = element.referentniBroj;
          temp.dodatniRabatKomisija = element.dodatniRabatKomisija;
          temp.akcijskaNettoNabavnaCijenaKomisija =
            element.akcijskaNettoNabavnaCijenaKomisija;
          oldState.artikli.push(temp);
        });
        break;
      case "DODAJ_ARTIKL":
        oldState.artikli.unshift(action.value);
        break;
      case "BRISI_ARTIKL":
        const arr = oldState.artikli;
        const indexOfObject = arr.findIndex((object) => {
          return object.SifraArtikla === action.value;
        });
        arr.splice(indexOfObject, 1);
        oldState.artikli = arr;
        break;
      case "UPDATE_ARTIKL":
        const arr2 = oldState.artikli;
        const indexOfObject2 = arr2.findIndex((object) => {
          return object.SifraArtikla === action.value.old.SifraArtikla;
        });
        if (action.value.new.SifraArtikla) {
          oldState.artikli[indexOfObject2].SifraArtikla =
            action.value.new.SifraArtikla;
          oldState.artikli[indexOfObject2].isOk = false;
        } else {
          oldState.artikli[indexOfObject2].SifraArtikla =
            action.value.old.SifraArtikla;
        }

        if (action.value.new.Cijena || action.value.new.Cijena === 0) {
          oldState.artikli[indexOfObject2].Cijena = action.value.new.Cijena;
          oldState.artikli[indexOfObject2].isOk = false;
        } else {
          oldState.artikli[indexOfObject2].Cijena = action.value.old.Cijena;
        }

        if (action.value.new.dodatniRabatKomisija) {
          oldState.artikli[indexOfObject2].dodatniRabatKomisija =
            action.value.new.dodatniRabatKomisija;
          oldState.artikli[indexOfObject2].isOk = false;
        } else {
          oldState.artikli[indexOfObject2].dodatniRabatKomisija =
            action.value.old.dodatniRabatKomisija;
        }

        if (action.value.new.akcijskaNettoNabavnaCijenaKomisija) {
          oldState.artikli[indexOfObject2].akcijskaNettoNabavnaCijenaKomisija =
            action.value.new.akcijskaNettoNabavnaCijenaKomisija;
          oldState.artikli[indexOfObject2].isOk = false;
        } else {
          oldState.artikli[indexOfObject2].akcijskaNettoNabavnaCijenaKomisija =
            action.value.old.akcijskaNettoNabavnaCijenaKomisija;
        }

        if (
          action.value.new.besplatnaDostava === true ||
          action.value.new.besplatnaDostava === false
        ) {
          oldState.artikli[indexOfObject2].besplatnaDostava =
            action.value.new.besplatnaDostava;
          oldState.artikli[indexOfObject2].isOk = false;
        } else {
          oldState.artikli[indexOfObject2].besplatnaDostava =
            action.value.old.besplatnaDostava;
        }
        if (action.value.new.sifraOsiguranja) {
          oldState.artikli[indexOfObject2].sifraOsiguranja =
            action.value.new.sifraOsiguranja;
          oldState.artikli[indexOfObject2].isOk = false;
        } else {
          oldState.artikli[indexOfObject2].sifraOsiguranja =
            action.value.old.sifraOsiguranja;
        }
        if (action.value.new.promoKod) {
          oldState.artikli[indexOfObject2].promoKod = action.value.new.promoKod;
          oldState.artikli[indexOfObject2].isOk = false;
        } else {
          oldState.artikli[indexOfObject2].promoKod = action.value.old.promoKod;
        }
        if (
          action.value.new.support ||
          action.value.new.support === 0 ||
          action.value.new.support === ""
        ) {
          oldState.artikli[indexOfObject2].support = action.value.new.support;
          oldState.artikli[indexOfObject2].isOk = false;
        } else {
          oldState.artikli[indexOfObject2].support = action.value.old.support;
        }
        if (action.value.new.referentniBroj) {
          oldState.artikli[indexOfObject2].referentniBroj =
            action.value.new.referentniBroj;
          oldState.artikli[indexOfObject2].isOk = false;
        } else {
          oldState.artikli[indexOfObject2].referentniBroj =
            action.value.old.referentniBroj;
        }
        break;
      case "ARTIKLI_CHECK":
        oldState.artikli = action.value;
        break;
      case "KLJUC_HANDLER":
        oldState.kljuc = action.value;
        break;
      default:
        break;
    }
  }
);

export default promjenaArtikalaReducer;
