import classes from './GlobalFilter.module.css';


const GlobalFilter = ({filter, setFilter}: any) => {
    return(
        <div className={classes.container}>            
            <input type="search" placeholder="Pretraži..." className={classes.search} value={filter || ''} onChange={(e) => setFilter(e.target.value)}></input>
        </div>
    )
}

export default GlobalFilter