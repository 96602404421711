import { DataGrid, Column, Selection, Item } from "devextreme-react/data-grid";
import { SearchPanel, Pager, Paging } from "devextreme-react/tree-list";
import "devextreme/dist/css/dx.light.css";
import CircularProgress from "@mui/material/CircularProgress";
import {
  AkcijaPromoKodViewModel,
  Artikl,
  PromoPopustArtikli,
} from "../UnosAkcijePrilikeTypes";
import { locale, loadMessages } from "devextreme/localization";
import hrMessages from "../hr.json";
import { Toolbar } from "@mui/material";
import UrediPromoButton from "./UrediPromoButton";

type TablicaProps = {
  artikli: Artikl[];
  brisiProizvod: (sifra: string) => void;
  updateProizvod: (artikl: any) => void;
  dodajProizvod: (artikl: string) => void;
  odabraniTip: number | undefined;
  promoPopustArtikli: PromoPopustArtikli[];
  listaPromoKodova: AkcijaPromoKodViewModel[];
  setData: (value: AkcijaPromoKodViewModel) => void;
  setOpenForma: (value: boolean) => void;
  openForma: boolean;
};

const PromoPopustTablica = (props: TablicaProps) => {
  loadMessages(hrMessages);
  locale("hr");
  const allowedPageSizes = [20, 50, 100];

  const akcijeRender = (data: any) => {
    return (
      <UrediPromoButton
        data={data}
        openForma={props.openForma}
        setOpenForma={(value: boolean) => props.setOpenForma(value)}
        setData={props.setData}
      />
    );
  };

  return (
    <>
      {props.artikli ? (
        <div
          style={{
            marginBottom: "20px",
          }}
        >
          <DataGrid
            id="dataGrid"
            dataSource={props.listaPromoKodova}
            rowAlternationEnabled
            allowColumnReordering
            allowColumnResizing
            showBorders
            onRowPrepared={(e) => {
              if (e.data) {
                if (e.data.isOk === false) {
                  e.rowElement.style.backgroundColor = "rgba(255, 0, 0, 0.2)";
                  e.rowElement.className = e.rowElement.className.replace(
                    "dx-row-alt",
                    ""
                  );
                }
                if (e.data.isOk === true) {
                  e.rowElement.style.backgroundColor =
                    "rgba(61, 193, 78, 0.47)";
                  e.rowElement.className = e.rowElement.className.replace(
                    "dx-row-alt",
                    ""
                  );
                }
              }
            }}
            columnAutoWidth
          >
            <Paging defaultPageSize={20} />
            <Toolbar>
              <Item name="addRowButton" />
            </Toolbar>
            <Pager
              visible={true}
              showPageSizeSelector={true}
              showInfo={true}
              allowedPageSizes={allowedPageSizes}
              infoText={`Stranica {0} od {1} ({2} artikala)`}
            />
            <SearchPanel visible={true} />
            <Selection mode="single" />
            <Column dataField="popustPromoCode" caption="Promo kod" />
            <Column
              allowReordering={false}
              allowSorting={false}
              cellRender={akcijeRender}
              width="20%"
              alignment={"center"}
            />
          </DataGrid>
        </div>
      ) : (
        <CircularProgress
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
          }}
        />
      )}
    </>
  );
};

export default PromoPopustTablica;
