import { TextMobileStepperModel } from "./UnosAkcijePrilikeTypes";

const defaultState: TextMobileStepperModel = {
  //OSNOVE
  voditelji: [],
  tipoviAkcije: [],
  robneMarke: [],
  kategorijeProizvodaNaAkciji: [],
  osnoveModel: {
    imePrilike: "",
    odabranaKategorijaProizvodaNaAkciji: null,
    odabranaRobnaMarka: null,
    odabraniTipAkcije: null,
    odabraniVoditelj: null,
    tekstPrilike: "",
    vasEmail: "",
    id: -1,
    label: "",
  },
  //TRAJANJE
  trajanjeModel: {
    odabraniDatumKrajaPrilike: "",
    odabraniDatumPocetkaPrilike: "",
  },
  //PROIZVODI
  proizvodiModel: {
    uneseniProizvodiNaPrilici: [],
    proizvodiNakonCheckCijena:[],
    kljuc: ""
  },
  //POKLONI
  pokloniModel: {
    sifraPoklona: "",
    uzStoSeDajePoklon: [],
  },
  //POPUSTVEZANAKUPNJA
  naciniSnizenjaCijena: [],
  popustVezanaKupnjaModel: {
    uneseneSifreProizvodaZaPopust: [],
    uneseneSifreArtiklaZaOstvarenjePopusta: [],
    odabraniNacinSnizenjaCijena: null,
  },
  // POKLON OSIGURANJE
  vrstePoklonOsiguranja: [],
  poklonOsiguranjaModel: {
    odabranaVrstaPoklonOsiguranja: {id: -1, label: "Nema poklona"},
  },
  // CIJENE
  cijeneModel: {
    vezanaKupnjaOdabranoSnizenje: null,
  },
  // vezanaKupnjaOdabranoSnizenje: "",
  // vezanaKupnjaOdabranoSnizenjeID: "",
  //RATE
  naciniPlacanjaNaRate: [],
  rateModel: {
    odabraniNacinPlacanja: null,
  },
  //REGISTRACIJA
  registracijaModel: {
    odabranaRegistracija: null,
    linkZaPrijavuRegistraciju: "",
  },
  slika506px: null,
  slika1140px: null,
  slika1200px: null,
  slika600px: null,
  // VALIDACIJA MODEL
  validacijaModel:{
    tekstPrilikeNotValid: false,
    emailNotValid: false,
    imePrilikeNotValid: false,
    kategorijeProizvodaNotValid: false,
    krajNotValid: false,
    pocetakNotValid: false,
    robnaMarkaNotValid: false,
    tipNotValid: false,
    voditeljNotValid: false
  },
  //POPUST PROMO MODEL
  popustPromoIznos: [],
  popustPromoPaymentType: [],
  popustPromoPaymentTypeCode: [],
  popustPromoPaymentTypeGroup: [],
  promoPopustModel: {
    odabraniPopustPromoIznos: null,
    odabraniPopustPromoType: null,
    odabraniPopustPromoTypeCode: null,
    odabraniPopustPromoTypeGroup: null,
    kodZaPopustPromo: "",
    odabraniDatumKrajaPromo: "",
    odabraniDatumPocetkaPromo: "",
  },
  listaPromoKodova: [],
  artikliPromoPopust: [],
  slike151px: []
};

export { defaultState };
