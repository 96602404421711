import DatePicker from "./Datepicker";
import { TrajanjeModel } from "./UnosAkcijePrilikeTypes";
import classes from "./UnosAkcijePrilike.module.css";

type TrajanjeProps = {
  trajanjeHandler: (trajanjeModel: TrajanjeModel) => void;
  trajanjeModel: TrajanjeModel;
  pocetakNotValid: boolean;
  krajNotValid: boolean;
  setPocetakNotValid: (value: boolean) => void;
  setKrajNotValid: (value: boolean) => void;
  tipAkcije: number | undefined;
};

const Trajanje = (props: TrajanjeProps) => {
  return (
    <>
      <h4 className={classes.stepsNaslov}>Početak i kraj prilike</h4>
      <label style={{ marginBottom: "10px" }}>
        <strong>Početak prilike:</strong>
      </label>
      <DatePicker
        value={props.trajanjeModel.odabraniDatumPocetkaPrilike}
        handleChange={(value: string | null) => {
          props.trajanjeHandler({
            ...props.trajanjeModel,
            odabraniDatumPocetkaPrilike: value,
          });
          props.setPocetakNotValid(false);
        } }
        label="Početak prilike (nije moguće kasnije mijenjati)"
        marginBottom={props.pocetakNotValid ? "" : "1em"}
        obavezno={true}
        error={props.pocetakNotValid}
        disabled={false}
        maxDate={undefined}
        minDate={undefined}        
      />
      {props.pocetakNotValid && (
        <div className={classes.warning}>
          <span>Obavezno polje!</span>
        </div>
      )}
      <label style={{ marginBottom: "10px" }}>
        <strong>Kraj prilike:</strong>
      </label>
      <DatePicker
        value={props.trajanjeModel.odabraniDatumKrajaPrilike}
        handleChange={(value: string | null) => {
          props.trajanjeHandler({
            ...props.trajanjeModel,
            odabraniDatumKrajaPrilike: value,
          });
          props.setKrajNotValid(false);
        } }
        label="Kraj prilike (nije moguće kasnije mijenjati)"
        marginBottom={props.krajNotValid ? "" : "1em"}
        obavezno={true}
        error={props.krajNotValid}
        disabled={false} maxDate={undefined} minDate={undefined}  

      />
      {props.krajNotValid && (
        <div className={classes.warning}>
          <span>Obavezno polje!</span>
        </div>
      )}
    </>
  );
};

export default Trajanje;
