import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useCallback, useEffect, useState } from "react";
import useApi from "../../Hooks/useApi";
import Swal from "sweetalert2";
import classes from "../HeaderFooter.module.css";
import BackDrop from "../../UnosAkcijePrilike/BackDrop";
import { AlertModel } from "../../UnosAkcijePrilike/UnosAkcijePrilikeTypes";
import { Dialog, DialogContent, TextField } from "@mui/material";
import { TekstPolje } from "./TekstPoljaTypes";
import HrLocale from "date-fns/locale/hr";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
};

type EditModalProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  data: TekstPolje;
};

const EditModal = (props: EditModalProps) => {
  const apiCall = useApi();
  const [end, setEnd] = useState<string | null>(props.data.end);
  const [start, setStart] = useState<string | null>(props.data.start);
  const [textBottom, setTextBottom] = useState<string>(props.data.textBottom);
  const [textTop, setTextTop] = useState<string>(props.data.textTop);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dialog, setOpen] = useState<AlertModel>({ open: false, message: "" });
  const [showUredi, setShowUredi] = useState<boolean>(false);
  const handleDialogClose = () => {
    setOpen({ open: false, message: "" });
  };
  const handleClose = () => {
    setEnd("");
    setStart("");
    setTextBottom("");
    setTextTop("");
    props.setOpen(false);
  };

  const editTextBar = useCallback(
    (accessToken: string) => {
      setIsLoading(true);
      let formData = new FormData();
      let danStart;
      let mjesecStart;
      let godinaStart;
      let danEnd;
      let mjesecEnd;
      let godinaEnd;
      if (start) {
        danStart = ("0" + new Date(start).getDate()).slice(-2);
        mjesecStart = ("0" + (new Date(start).getMonth() + 1)).slice(-2);
        godinaStart = new Date(start).getFullYear();
      }

      if (end) {
        danEnd = ("0" + new Date(end).getDate()).slice(-2);
        mjesecEnd = ("0" + (new Date(end).getMonth() + 1)).slice(-2);
        godinaEnd = new Date(end).getFullYear();
      }

      formData.append("topText", textTop);
      formData.append("bottomText", textBottom);
      formData.append(
        "start",
        `${godinaStart}-${mjesecStart}-${danStart}` ?? ""
      );
      formData.append("end", `${godinaEnd}-${mjesecEnd}-${danEnd}` ?? "");
      formData.append("id", props.data.id.toString());

      fetch("api/TextBar/CheckTextBarDate", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "post",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.responseData) {
            setIsLoading(false);
            setOpen({ open: true, message: "Zapis već postoji!" });
          } else {
            fetch("api/TextBar/EditTextBar", {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
              method: "post",
              body: formData,
            }).then((response) => {
              if (response.status === 200) {
                props.setOpen(false);
                setIsLoading(false);
                Swal.fire({
                  icon: "success",
                  title: "Spremljeno",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.location.reload();
                  }
                });
              } else {
                setIsLoading(false);
                Swal.fire({
                  title: response.statusText,
                  showConfirmButton: false,
                  toast: true,
                  customClass: {
                    container: classes.swalContainer,
                    popup: classes.swal2IconError,
                  },
                  timer: 1500,
                  iconColor: "white",
                  timerProgressBar: true,
                  icon: "warning",
                  position: "top-right",
                });
              }
            });
          }
        });
    },
    [end, props, start, textBottom, textTop]
  );

  const edit = () => {
    apiCall(editTextBar);
  };

  useEffect(() => {
    if (start === "" || end === "" || textBottom === "" || textTop === "") {
      setShowUredi(false);
    } else {
      setShowUredi(true);
    }
  }, [end, start, textBottom, textTop]);

  function MaxChar255(oldValue: number, newValue: number) {
    if (oldValue < 256 && newValue < 256) {
      return true;
    } else {
      return false;
    }
  }

  function MaxChar11(oldValue: number, newValue: number) {
    if (oldValue < 12 && newValue < 12) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      <div>
        <Modal
          open={props.open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <TextField
              value={textTop}
              style={{ marginBottom: "10px" }}
              fullWidth
              id="top"
              label="Text top"
              variant="outlined"
              onChange={(e) => {
                if (MaxChar255(textTop.length, e.target.value.length))
                  setTextTop(e.target.value);
              }}
              size="small"
            />
            <TextField
              value={textBottom}
              style={{ marginBottom: "10px" }}
              fullWidth
              id="bottom"
              label="Tekst bottom"
              variant="outlined"
              onChange={(e) => {
                if (MaxChar11(textBottom.length, e.target.value.length))
                  setTextBottom(e.target.value);
              }}
              size="small"
            />
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={HrLocale}
            >
              <MobileDatePicker
                label="Start"
                inputFormat="yyyy-MM-dd"
                value={start || null}
                onChange={(newValue: string | null) => setStart(newValue)}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    style={{ marginBottom: "10px" }}
                  />
                )}
                clearable
                InputProps={{ style: { backgroundColor: "white" } }}
                ToolbarComponent={() => <div />}
                showToolbar={false}
                clearText="Poništi"
                cancelText="Odustani"
                //   disabled={props.disabled}
                disableCloseOnSelect={false}
                maxDate={end ?? ""}
                //   minDate={props.minDate}
              />
            </LocalizationProvider>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={HrLocale}
            >
              <MobileDatePicker
                label="End"
                inputFormat="yyyy-MM-dd"
                value={end || null}
                onChange={(newValue: string | null) => setEnd(newValue)}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    style={{ marginBottom: "10px" }}
                  />
                )}
                clearable
                InputProps={{ style: { backgroundColor: "white" } }}
                ToolbarComponent={() => <div />}
                showToolbar={false}
                clearText="Poništi"
                cancelText="Odustani"
                //   disabled={props.disabled}
                disableCloseOnSelect={false}
                //   maxDate={props.maxDate}
                minDate={start ?? ""}
              />
            </LocalizationProvider>
            {showUredi && (
              <Button
                style={{ backgroundColor: "rgb(26, 179, 148)" }}
                variant="contained"
                onClick={() => {
                  edit();
                }}
              >
                Uredi
              </Button>
            )}
          </Box>
        </Modal>
        {isLoading && <BackDrop />}
      </div>
      {dialog.open && (
        <Dialog onClose={handleDialogClose} open={dialog.open}>
          <DialogContent className={classes.dialog}>
            <label>{dialog.message}</label>
            <div style={{ textAlign: "end" }}>
              <Button onClick={handleDialogClose}>Zatvori</Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default EditModal;
