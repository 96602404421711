import {
  DataGrid,
  Column,
  Selection,
  Editing,
  Item,
} from "devextreme-react/data-grid";
import { SearchPanel, Pager, Paging } from "devextreme-react/tree-list";
import "devextreme/dist/css/dx.light.css";
import CircularProgress from "@mui/material/CircularProgress";
import { Artikl } from "./UnosAkcijePrilikeTypes";
import { locale, loadMessages } from "devextreme/localization";
import hrMessages from "./hr.json";
import { Toolbar } from "@mui/material";
import { useEffect } from "react";

type TablicaProps = {
  artikli: Artikl[];
  brisiProizvod: (sifra: string) => void;
  updateProizvod: (artikl: any) => void;
  dodajProizvod: (artikl: Artikl) => void;
  pocetakPrilike: Date | null;
  krajPrilike: Date | null;
  proizvodiCheckCijena: Artikl[];
  setProizvodiNotValid: (value: boolean) => void;
  odabraniTip: number | undefined;
};

const ProizvodiTablica = (props: TablicaProps) => {
  loadMessages(hrMessages);
  locale("hr");

  const allowedPageSizes = [20, 50, 100];


  useEffect(() => {
    if (props.artikli.length === 0 && props.proizvodiCheckCijena.length === 0) {
      props.setProizvodiNotValid(false);
    }
  });

  useEffect(() => {
    if (props.proizvodiCheckCijena.length > 0) {
      props.proizvodiCheckCijena.forEach((element) => {
        if (element.isOk === false) {
          props.setProizvodiNotValid(true);
          return;
        } else {
          props.setProizvodiNotValid(false);
        }
      });
    } else {
      props.artikli.forEach((element) => {
        if (element.isOk === false) {
          props.setProizvodiNotValid(true);
          return;
        } else {
          props.setProizvodiNotValid(false);
        }
      });
    }
  });

  return (
    <>
      {props.artikli ? (
        <div
          style={{
            marginBottom: "20px",
          }}
        >
          <DataGrid
            id="dataGrid"
            dataSource={
              props.proizvodiCheckCijena.length > 0
                ? props.proizvodiCheckCijena
                : props.artikli
            }
            rowAlternationEnabled
            allowColumnReordering
            allowColumnResizing
            showBorders
            onRowUpdating={(e: any) => {
              e.cancel = true;
              props.updateProizvod({ new: e.newData, old: e.oldData });
            }}
            onRowRemoving={(e: any) => {
              e.cancel = true;
              props.brisiProizvod(e.data.SifraArtikla);
            }}
            onRowInserting={(e) => {
              props.setProizvodiNotValid(true);
              e.cancel = true;
              if (props.odabraniTip === 1 || props.odabraniTip === 3) {
                if (e.data.Cijena) {
                  if (e.data.Cijena >= 0 && e.data.SifraArtikla !== "") {
                    props.dodajProizvod({
                      Cijena: e.data.Cijena,
                      SifraArtikla: e.data.SifraArtikla,
                      greska: "",
                      isOk: false,
                      PromoKod: e.data.PromoKod,
                      katBroj: "",
                      naziv: "",
                      BesplatnaDostava: e.data.BesplatnaDostava
                        ? e.data.BesplatnaDostava
                        : false,
                      SifraOsiguranja: e.data.SifraOsiguranja,
                      Support: e.data.Support,
                      ReferentniBroj: e.data.ReferentniBroj,
                      AkcijskaNettoNabavnaCijenaKomisija:
                        e.data.AkcijskaNettoNabavnaCijenaKomisija,
                      DodatniRabatKomisija: e.data.DodatniRabatKomisija,
                    });
                    e.component.cancelEditData();
                  }
                }
              } else {
                props.dodajProizvod({
                  Cijena: null,
                  SifraArtikla: e.data.SifraArtikla,
                  greska: "",
                  isOk: false,
                  PromoKod: e.data.PromoKod,
                  katBroj: "",
                  naziv: "",
                  BesplatnaDostava: e.data.BesplatnaDostava
                    ? e.data.BesplatnaDostava
                    : false,
                  SifraOsiguranja: e.data.SifraOsiguranja,
                  Support: e.data.Support,
                  ReferentniBroj: e.data.ReferentniBroj,
                  AkcijskaNettoNabavnaCijenaKomisija:
                    e.data.AkcijskaNettoNabavnaCijenaKomisija,
                  DodatniRabatKomisija: e.data.DodatniRabatKomisija,
                });
                e.component.cancelEditData();
              }
            }}
            onRowPrepared={(e) => {
              if (e.data) {
                if (e.data.isOk === false) {
                  e.rowElement.style.backgroundColor = "rgba(255, 0, 0, 0.2)";
                  e.rowElement.className = e.rowElement.className.replace(
                    "dx-row-alt",
                    ""
                  );
                }
                if (e.data.isOk === true) {
                  e.rowElement.style.backgroundColor =
                    "rgba(61, 193, 78, 0.47)";
                  e.rowElement.className = e.rowElement.className.replace(
                    "dx-row-alt",
                    ""
                  );
                }
              }
            }}
            columnAutoWidth
            // onEditingStart={(e: EditingStartEvent) => {
            //   e.component.columnOption(
            //     "PromoKod",
            //     "allowEditing",
            //     e.data.PromoKod === true
            //   );
            // }}
          >
            <Paging defaultPageSize={20} />
            <Editing
              mode="row"
              allowUpdating={true}
              allowAdding={true}
              allowDeleting={true}
            />
            <Toolbar>
              <Item name="addRowButton" />
            </Toolbar>
            <Pager
              visible={true}
              showPageSizeSelector={true}
              showInfo={true}
              allowedPageSizes={allowedPageSizes}
              infoText={`Stranica {0} od {1} ({2} artikala)`}
            />
            <SearchPanel visible={true} />
            <Selection mode="single" />
            <Column
              width="5%"
              dataField="SifraArtikla"
              caption="Šifra artikla"
            />
            {props.odabraniTip !== 2 && (
              <Column width="5%" dataField="Cijena" caption="Cijena" />
            )}
            <Column
              width="5%"
              allowEditing={true}
              dataField="Support"
              caption="Support"
            ></Column>
            <Column
              width="5%"
              allowEditing={true}
              dataField="DodatniRabatKomisija"
              caption="Dodatni rabat komisija"
            ></Column>
            <Column
              width="5%"
              allowEditing={true}
              dataField="AkcijskaNettoNabavnaCijenaKomisija"
              caption="Akcijska netto cijena komisija"
            ></Column>
            <Column
              width="5%"
              allowEditing={true}
              dataField="PromoKod"
              caption="Promo kod"
              dataType={"string"}
            ></Column>
            <Column
              width="5%"
              dataType={"boolean"}
              allowEditing={true}
              dataField="BesplatnaDostava"
              caption="Besplatna dostava"
            />
            <Column
              width="5%"
              allowEditing={true}
              dataField="SifraOsiguranja"
              caption="Šifra osiguranja"
              dataType={"number"}
            />
            <Column
              width="5%"
              allowEditing={true}
              dataField="ReferentniBroj"
              caption="Referentni broj"
              dataType={"string"}
            />
            <Column
              width="55%"
              allowEditing={false}
              dataField="greska"
              caption="Greška"
            />
            <Column
              width="10%"
              allowEditing={false}
              dataField="naziv"
              caption="Naziv"
            />
            <Column
              width="10%"
              allowEditing={false}
              dataField="katBroj"
              caption="Kataloški broj"
            />
          </DataGrid>
        </div>
      ) : (
        <CircularProgress
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
          }}
        />
      )}
    </>
  );
};

export default ProizvodiTablica;
