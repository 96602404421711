import { loadImageAsync } from "../../utils/utils";
import { UnosAkcijePrilikeAction } from "./UnosAkcijePrilikeActions";

type setOpenTypes = {
  open: boolean;
  message: string;
};

const provjeraDatoteke = async (
  files: FileList,
  inputLabel: string | null = "",
  setOpen: ({ open, message }: setOpenTypes) => void,
  dispatcher: ({ action, value }: UnosAkcijePrilikeAction) => void,
  fileName: string | null
) => {
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const fileSizeKB = Math.round(file.size / 1024);
    const naziv = file.name;
    const ekstenzija = naziv.replace(/^.*\./, "").toLowerCase();
    const slikaInfo = await loadImageAsync(file, inputLabel);
    if (slikaInfo.inputLabel === "Slika 151px*151px png") {
      if (ekstenzija === "png") {
        if (151 !== slikaInfo.width) {
          setOpen({
            open: true,
            message: "Širina slike nije u ispravnoj veličini.",
          });
          continue;
        } else if (151 !== slikaInfo.height) {
          setOpen({
            open: true,
            message: "Visina slike nije u ispravnoj veličini.",
          });
          continue;
        }
        // else if (fileSizeKB > 1000) {
        //   setOpen({
        //     open: true,
        //     message: `Slika je prevelika. ${fileSizeKB} KB`,
        //   });
        //   continue;
        // }
        else {
          dispatcher({ action: "UPLOAD_SLIKE", value: {file: slikaInfo, fileName: fileName} });
        }
      } else {
        setOpen({ open: true, message: `${ekstenzija} nije podržana.` });
      }
    } else {
      if (ekstenzija === "jpg") {
        if (
          slikaInfo.inputLabel ===
          "Banner 506px*230px jpg, obavezno bez CTA gumba, minimalna veličina slova 12px"
        ) {
          if (506 !== slikaInfo.width) {
            setOpen({
              open: true,
              message: "Širina slike nije u ispravnoj veličini.",
            });
            continue;
          } else if (230 !== slikaInfo.height) {
            setOpen({
              open: true,
              message: "Visina slike nije u ispravnoj veličini.",
            });
            continue;
          } else if (fileSizeKB > 1000) {
            setOpen({
              open: true,
              message: `Slika je prevelika. ${fileSizeKB} KB`,
            });
            continue;
          } else {
            dispatcher({ action: "UPLOAD_SLIKE", value: {file: slikaInfo, fileName: fileName} });
          }
        }

        if (
          slikaInfo.inputLabel ===
          "Banner 1140px*230px jpg, obavezno bez CTA gumba, minimalna veličina slova 12p"
        ) {
          if (1140 !== slikaInfo.width) {
            setOpen({
              open: true,
              message: "Širina slike nije u ispravnoj veličini.",
            });
            continue;
          } else if (230 !== slikaInfo.height) {
            setOpen({
              open: true,
              message: "Visina slike nije u ispravnoj veličini.",
            });
            continue;
          } else if (fileSizeKB > 1000) {
            setOpen({
              open: true,
              message: `Slika je prevelika. ${fileSizeKB} KB`,
            });
            continue;
          } else {
            dispatcher({ action: "UPLOAD_SLIKE", value: {file: slikaInfo, fileName: fileName} });
          }
        }

        if (
          slikaInfo.inputLabel ===
          "Banner 1200px x 628px jpg, Banner za Facebook, obavezno bez CTA gumba, manje od 20% površine slike je text obavezno, po mogučnosti savjetuje se izbjegavati sva velika slova, velike uskličnike, ne koristiti riječi kao što su 'Sigurnost', 'Osiguranje', 'sigurno', 'garantiramo','zajamčeno','uz registraciju' ,'uz obaveznu registraciju', 'popust do xx%', 'do xx%'."
        ) {
          if (1200 !== slikaInfo.width) {
            setOpen({
              open: true,
              message: "Širina slike nije u ispravnoj veličini.",
            });
            continue;
          } else if (628 !== slikaInfo.height) {
            setOpen({
              open: true,
              message: "Visina slike nije u ispravnoj veličini.",
            });
            continue;
          } else if (fileSizeKB > 1000) {
            setOpen({
              open: true,
              message: `Slika je prevelika. ${fileSizeKB} KB`,
            });
            continue;
          } else {
            dispatcher({ action: "UPLOAD_SLIKE", value: {file: slikaInfo, fileName: fileName}});
          }
        }

        if (
          slikaInfo.inputLabel ===
          "Banner 600px*500px jpg, obavezno sa CTA gumbom, minimalna veličina slova 12px, obavezno navesti puno trajanje akcije."
        ) {
          if (600 !== slikaInfo.width) {
            setOpen({
              open: true,
              message: "Širina slike nije u ispravnoj veličini.",
            });
            continue;
          } else if (500 !== slikaInfo.height) {
            setOpen({
              open: true,
              message: "Visina slike nije u ispravnoj veličini.",
            });
            continue;
          } else if (fileSizeKB > 1000) {
            setOpen({
              open: true,
              message: `Slika je prevelika. ${fileSizeKB} KB`,
            });
            continue;
          } else {
            dispatcher({ action: "UPLOAD_SLIKE", value: {file: slikaInfo, fileName: fileName} });
          }
        }
      } else {
        setOpen({ open: true, message: `${ekstenzija} nije podržana.` });
      }
    }
  }
};

export { provjeraDatoteke };
