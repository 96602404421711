import { useEffect, useReducer, useState } from "react";
import useApi from "../Hooks/useApi";
import zadaciVoditeljReducer from "./zadaciVoditeljReducer";
import ZadaciVoditeljTable, { ZadaciTable } from "./ZadaciVoditeljTable";
import classes from "./ZadaciVoditelj.module.css";
import CircularProgress from "@mui/material/CircularProgress";

const ZadaciVoditelj = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [zadaci, dispatcher] = useReducer(zadaciVoditeljReducer, []);
  const apiCall = useApi();
  const getZadaci = (accessToken: string) => {
    fetch(
      "/api/VoditeljZadatak/GetVoditeljZadaci?startingPage=0&pageSize=500",
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        zadaciHandler(mapZadaci(data.responseData));
      })
      .then(() => setIsLoading(false));
  };

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      apiCall(getZadaci);
    }

    return () => {
      isSubscribed = false;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const zadaciHandler = (value: any) => {
    dispatcher({
      action: "GET_VODITELJ_ZADACI",
      value: value,
    });
  };

  const mapZadaci = (data: any) => {
    const zadaci: ZadaciTable[] = [];
    for (let i = 0; i < data.length; i++) {
      const zadatak = data[i];
      zadaci.push({
        id: zadatak.id,
        voditelj: zadatak.voditelj,
        prioritet: zadatak.prioritet,
        sifra: zadatak.sifra,
        brand: zadatak.brand,
        datum: zadatak.datumUnosa,
        korisnik: zadatak.korisnikUnosa,
      });
    }
    return zadaci;
  };

  return (
    <div className={classes.container}>
      <h4
        className="lightBlue p-4"
        style={{
          textTransform: "uppercase",
          textAlign: "center",
          marginBottom: "1em",
          fontWeight: "bold",
        }}
      >
        Zadaci za voditelje
      </h4>
      {isLoading ? (
        <div className={classes.loader}>
          <CircularProgress style={{ width: "80px", height: "80px" }} />
        </div>
      ) : (
        <ZadaciVoditeljTable zadaci={zadaci} />
      )}
    </div>
  );
};

export default ZadaciVoditelj;
