import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useCallback, useEffect, useState } from "react";
import useApi from "../Hooks/useApi";
import Swal from "sweetalert2";
import classes from "./DostavaUStan.module.css";
import BackDrop from "../UnosAkcijePrilike/BackDrop";
import { AlertModel } from "../UnosAkcijePrilike/UnosAkcijePrilikeTypes";
import { Checkbox, Dialog, DialogContent, TextField } from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
};

type AddModalProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

const AddModal = (props: AddModalProps) => {
  const apiCall = useApi();
  const [zip, setZip] = useState<number | null>(null);
  const [dostavaUStan, setDostavaUStan] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [dialog, setOpen] = useState<AlertModel>({ open: false, message: "" });
  const [showSpremi, setShowSpremi] = useState<boolean>(false);
  const handleDialogClose = () => {
    setOpen({ open: false, message: "" });
  };
  const handleClose = () => {
    setZip(null);
    setDostavaUStan(false);
    props.setOpen(false);
  };

  const spremiDostavuUStan = useCallback(
    (accessToken: string) => {
      setIsLoading(true);

      let formData = new FormData();
      formData.append("zip", zip?.toString() ?? "");
      formData.append("dostavaUStan", dostavaUStan ? "true" : "false");

      fetch("api/Dostava/ExistsDostavaUStan", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "post",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.responseData) {
            setIsLoading(false);
            setOpen({ open: true, message: "Zapis već postoji!" });
          } else {
            fetch("api/Dostava/NewDostavaStan", {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
              method: "post",
              body: formData,
            }).then((response) => {
              if (response.status === 200) {
                props.setOpen(false);
                setIsLoading(false);
                Swal.fire({
                  icon: "success",
                  title: "Spremljeno",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.location.reload();
                  }
                });
              } else {
                setIsLoading(false);
                Swal.fire({
                  title: response.statusText,
                  showConfirmButton: false,
                  toast: true,
                  customClass: {
                    container: classes.swalContainer,
                    popup: classes.swal2IconError,
                  },
                  timer: 1500,
                  iconColor: "white",
                  timerProgressBar: true,
                  icon: "warning",
                  position: "top-right",
                });
              }
            });
          }
        });
    },
    [dostavaUStan, props, zip]
  );

  const post = () => {
    apiCall(spremiDostavuUStan);
  };

  useEffect(() => {
    if (zip === null) {
      setShowSpremi(false);
    } else {
      setShowSpremi(true);
    }
  }, [zip]);

  return (
    <>
      <div>
        <Modal
          open={props.open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <TextField
              value={zip}
              style={{ marginBottom: "10px" }}
              fullWidth
              id="zip"
              label="Zip"
              variant="outlined"
              type={"number"}
              onChange={(e) => {
                if (e.target.value.length === 0) {
                  setZip(null);
                } else {
                  setZip(parseInt(e.target.value));
                }
              }}
            />
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <span>Dostava u stan</span>
              <Checkbox
                value={dostavaUStan}
                onChange={(e) => {
                  setDostavaUStan(e.target.checked);
                }}
              />
            </div>
            {showSpremi && (
              <Button
                style={{ backgroundColor: "rgb(26, 179, 148)" }}
                variant="contained"
                onClick={() => {
                  post();
                }}
              >
                Spremi
              </Button>
            )}
          </Box>
        </Modal>
      </div>
      {dialog.open && (
        <Dialog onClose={handleDialogClose} open={dialog.open}>
          <DialogContent className={classes.dialog}>
            <label>{dialog.message}</label>
            <div style={{ textAlign: "end" }}>
              <Button onClick={handleDialogClose}>Zatvori</Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
      {isLoading && <BackDrop />}
    </>
  );
};

export default AddModal;
