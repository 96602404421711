import React, { useState } from "react";
import classes from "./SlobodniDatumi.module.css";
import "./slobodniDatumi.css";
// import useApiPromise from "../Hooks/useApiPromise";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import ModalDetalji from "./ModalDetalji";
import Kalendar from './Kalendar'

type EventDetalji = {
  naziv: string,
  trajanje: string,
  brend: string,
  voditelj: string,
  opis: string
}

const SlobodniDatumi = () => {  
  const [detaljiOpen, setDetaljiOpen] = useState<boolean>(false);
  const [eventDetalji, setEventDetalji] = useState<EventDetalji>({
    brend: "",
    naziv: "",
    opis: "",
    trajanje: "",
    voditelj: ""
  })          
  
  return (
    <>
    <div className={classes.container}>      
      <Kalendar setDetaljiOpenHandler={setDetaljiOpen} setDetaljiHandler={setEventDetalji}  />
    </div>
    {detaljiOpen && <ModalDetalji
      detaljiOpen={detaljiOpen}
      setDetaljiOpen={setDetaljiOpen}
      event={eventDetalji}
    />}
    </>
  );
};

export default React.memo(SlobodniDatumi);
export type { EventDetalji }
