import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createStore } from "devextreme-aspnet-data-nojquery";
import useApiPromise from "../Hooks/useApiPromise";
import {
  DataGrid,
  Column,
  Selection,
  Editing,
  FilterRow,
  Toolbar,
  Item,
} from "devextreme-react/data-grid";
import { Pager, Paging } from "devextreme-react/tree-list";
import { Button as ButtonMui } from "@mui/material";
import { PlusSquare } from "react-bootstrap-icons";
import AddModal from "./AddModal";
import { PostanskiBroj } from "./PostanskiBrojeviTypes";
import EditModal from "./EditModal";
import Swal from "sweetalert2";
import classes from "./PostanskiBrojevi.module.css";
import useApi from "../Hooks/useApi";
import BackDrop from "../UnosAkcijePrilike/BackDrop";

const PostanskiBrojevi = () => {
  const dataGrid = useRef<DataGrid>(null);
  const allowedPageSizes = [20, 50, 100];

  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [rowData, setRowData] = useState<PostanskiBroj>({
    zip: "",
    grad: "",
    mjesto: "",
    udaljenjaLokacija: false,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deleteData, setDeleteData] = useState<PostanskiBroj>({
    zip: "",
    grad: "",
    mjesto: "",
    udaljenjaLokacija: false,
  });
  const apiCall = useApi();

  const apiCallPromise = useApiPromise();
  const accessTokenHandler = useCallback((accessToken: string) => {
    return (async () => {
      return accessToken;
    })();
  }, []);

  const dataSource = useMemo(() => {
    return createStore({
      loadUrl: `api/PostanskiBroj/GetPostanskiBroj`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
        return apiCallPromise(accessTokenHandler).then((token: any) => {
          return (ajaxOptions.headers = {
            Authorization: `Bearer ${token}`,
          });
        });
      },
    });
  }, [accessTokenHandler, apiCallPromise]);

  const deletePostanskiBroj = useCallback(
    (accessToken: string) => {
      if (deleteData.zip !== "") {
        setIsLoading(true);
        let formData = new FormData();
        formData.append("zip", deleteData.zip.toString());
        formData.append("mjesto", deleteData.mjesto);
        formData.append("grad", deleteData.grad);

        fetch("api/PostanskiBroj/DeletePostanskiBroj", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          method: "post",
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.responseData) {
              setIsLoading(false);
              Swal.fire({
                icon: "success",
                title: "Obrisano",
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              });
            } else {
              setIsLoading(false);
              Swal.fire({
                title: data.error,
                showConfirmButton: false,
                toast: true,
                customClass: {
                  container: classes.swalContainer,
                  popup: classes.swal2IconError,
                },
                timer: 1500,
                iconColor: "white",
                timerProgressBar: true,
                icon: "warning",
                position: "top-right",
              });
            }
          });
      }
    },
    [deleteData.grad, deleteData.mjesto, deleteData.zip]
  );

  useEffect(() => {
    apiCall(deletePostanskiBroj);
  }, [apiCall, deletePostanskiBroj]);

  return (
    <>
      <div>
        <DataGrid
          id="dataGrid"
          ref={dataGrid}
          dataSource={dataSource}
          rowAlternationEnabled
          allowColumnReordering
          allowColumnResizing
          showBorders
          onEditingStart={(e) => {
            e.cancel = true;
            setRowData({
              zip: e.data.zip,
              grad: e.data.grad,
              mjesto: e.data.mjesto,
              udaljenjaLokacija: e.data === 0 ? false : true,
            });
            setEditModalOpen(true);
          }}
          remoteOperations={true}
          onRowRemoving={(e: any) => {
            // setSeoId(e.data.id);
            setDeleteData({
              grad: e.data.grad,
              mjesto: e.data.mjesto,
              zip: e.data.zip,
              udaljenjaLokacija: e.data.udaljenjaLokacija,
            });
            e.cancel = true;
          }}
        >
          <FilterRow visible={true} />
          <Paging defaultPageSize={20} />
          <Editing mode="row" allowUpdating={true} allowDeleting />
          <Pager
            visible={true}
            showPageSizeSelector={true}
            showInfo={true}
            allowedPageSizes={allowedPageSizes}
            infoText={`Stranica {0} od {1} ({2} artikala)`}
          />
          <Selection mode="single" />
          <Column
            allowFiltering={false}
            width="5%"
            dataField="zip"
            caption="Zip"
          />
          <Column
            allowEditing={true}
            width="10%"
            dataField="grad"
            caption="Grad"
          />
          <Column
            allowEditing={true}
            width="75%"
            dataField="mjesto"
            caption="Mjesto"
          />
          <Column
            allowEditing={true}
            width="75%"
            dataField="udaljenaLokacija"
            caption="Udaljenja lokacija"
            dataType={"boolean"}
          />
          <Toolbar>
            <Item location="after">
              <ButtonMui
                onClick={() => {
                  setAddModalOpen(true);
                }}
                variant="contained"
              >
                <PlusSquare size={25} />
              </ButtonMui>
            </Item>
          </Toolbar>
        </DataGrid>
      </div>
      {addModalOpen && (
        <AddModal open={addModalOpen} setOpen={setAddModalOpen} />
      )}
      {editModalOpen && (
        <EditModal
          open={editModalOpen}
          setOpen={setEditModalOpen}
          data={rowData}
        />
      )}
      {isLoading && <BackDrop />}
    </>
  );
};

export default PostanskiBrojevi;
