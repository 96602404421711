import { useIsAuthenticated } from "@azure/msal-react";
import HideAppBar from "./Navbar/Navbar";
import { Route, Switch } from "react-router";
import ZadaciVoditelj from "./ZadaciVoditelj/ZadaciVoditelj";
import NoviZadatak from "./ZadaciVoditelj/NoviZadatak";
import FormaContainer from "./FormaContainer/FormaContainer";
import SlobodniDatumi from "../components/SlobodniDatumi/SlobodniDatumi";
import NavigationCardContainer from "./NavigationCardContainer/NavigationCardContainer";
import UnosAkcijePrilike from "./UnosAkcijePrilike/UnosAkcijePrilike";
import AkcijePrikazTablica from "./AkcijePrikaz/AkcijePrikazTablica";
// import { useLocation } from "react-router-dom";
import { SignInButton } from "./SignInButton";
import classes from "./PageLayout.module.css";
import CijenaDostave from "./CijenaDostave/CijenaDostave";
import Placanja from "./Placanja/Placanja";
import PosebneOsobine from "./PosebneOsobine/PosebneOsobine";
import Poslovnice from "./Poslovnice/Poslovnice";
import Pages from "./Pages/Pages";
import NewPage from "./Pages/NewPage";
import EditPage from "./Pages/EditPage";
import Seo from "./Seo/Seo";
import NavbarKlas from "./HeaderFooter/NavbarKlas/NavbarKlas";
import Boje from "./HeaderFooter/Boje/Boje";
import TekstPolja from "./HeaderFooter/TekstPolja/TekstPolja";
import { useMsal } from "@azure/msal-react";
import { grupa1, grupa2, grupa3 } from "../utils/grupePrava";
import NeRadniDani from "./NeRadniDani/NeRadniDani";
// import useApi from "./Hooks/useApi";
// import { useEffect } from "react";
import PostanskiBrojevi from "./PostanskiBrojevi/PostanskiBrojevi";
import InTime from "./InTime/InTime";
import TestExcelTablice from "./TestExcelTablice/TestExcelTablice";
import DostavaStan from "./DostavaUStan/DostavaUStan";

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
type PageLayoutProps = {
  children: JSX.Element;
};

export const PageLayout = ({ children }: PageLayoutProps) => {
  // const apiCall = useApi();
  const isAuthenticated = useIsAuthenticated();
  let username = "";
  const { accounts } = useMsal();
  if(isAuthenticated){
    username = accounts[0].username;
  }
  // let pravaCancel = false;

  const navigationMenuPrava = () => {
    if (grupa1.includes(username)) {
      return [
        "grupadokumenti",
        "grupaakcije",
        "posebneOsobine",
        "poslovnice",
        "grupaPlacanja",
        "grupaCijene",
        "pages",
        "seo",
        "grupaHeaderFooter"
      ];
    } else if (grupa2.includes(username)) {
      return ["poslovnice", "grupaPlacanja", "grupaCijene", "pages"];
    } else {
      return ["grupadokumenti", "grupaakcije", "posebneOsobine"];
    }
  };

  const grupa1Check = () => {
    if (grupa1.includes(username)) {
      return true;
    } else {
      return false;
    }
  };

  const grupa2Check = () => {
    if (grupa2.includes(username)) {
      return true;
    } else {
      return false;
    }
  };

  const grupa3Check = () => {
    if (grupa3.includes(username)) {
      return true;
    } else {
      return false;
    }
  };

  // const getPrava = (accessToken: string) => {
  //   fetch("/api/Prava/GetPrava", {
  //     headers: {
  //       Authorization: `Bearer ${accessToken}`,
  //     },
  //   })
  //     .then((response) => console.log(response))
      
  // };

  // useEffect(() => {
  //   apiCall(getPrava);
  //   return () => {
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //     pravaCancel = true;
  //   };
  // }, [apiCall]);

  return (
    <div
      style={{
        display: isAuthenticated ? "grid" : "",
        gridTemplateRows: isAuthenticated ? "min-content 0" : "",
        height: "100%",
        backgroundColor: "#f3f3f4",
      }}
    >
      <HideAppBar>
        <></>
      </HideAppBar>
      {isAuthenticated && (
        <div className={classes.routeContainer}>
          <Switch>
            {(grupa1Check() || grupa3Check()) && (
              <Route exact path="/GrupaAkcije/UnosAkcijePrilike">
                <UnosAkcijePrilike />
              </Route>
            )}
            <Route exact path="/GrupaZadaci/ZadaciVoditelj">
              <ZadaciVoditelj />
            </Route>
            <Route exact path="/GrupaZadaci/NoviZadatak">
              <NoviZadatak />
            </Route>
            {(grupa1Check() || grupa3Check()) && (
              <Route
                key="EnergetskeNaljepnice"
                exact
                path="/GrupaDokumenti/EnergetskeNaljepnice"
              >
                <FormaContainer />
              </Route>
            )}
            {(grupa1Check() || grupa3Check()) && (
              <Route key="UnosSlika" exact path="/GrupaDokumenti/UnosSlika">
                <FormaContainer />
              </Route>
            )}
            {(grupa1Check() || grupa3Check()) && (
              <Route
                key="UnosInformacijskihListova"
                exact
                path="/GrupaDokumenti/UnosInformacijskihListova"
              >
                <FormaContainer />
              </Route>
            )}

            {(grupa1Check() || grupa3Check()) && (
              <Route exact path="/GrupaAkcije/SlobodniDatumi">
                <SlobodniDatumi />
              </Route>
            )}
            {(grupa1Check() || grupa3Check()) && (
              <Route exact path="/GrupaAkcije/TestExcelTablice">
                <TestExcelTablice />
              </Route>
            )}
            {(grupa1Check() || grupa3Check()) && (
              <Route exact path="/GrupaAkcije/AkcijePrikazTablica">
                <AkcijePrikazTablica />
              </Route>
            )}
            {(grupa1Check() || grupa3Check()) && (
              <Route exact path="/GrupaDokumenti">
                <NavigationCardContainer
                  navigacija={[
                    "unosslika",
                    "energetskenaljepnice",
                    "informacijskilistovi",
                  ]}
                />
              </Route>
            )}
            {(grupa1Check() || grupa3Check()) && (
              <Route exact path="/GrupaAkcije">
                <NavigationCardContainer
                  navigacija={["pregledAkcija", "akcije", "slobodnidatumi","testExcelTablice"]}
                />
              </Route>
            )}
            <Route exact path="/GrupaZadaci">
              <NavigationCardContainer navigacija={["zadaci", "novizadatak"]} />
            </Route>
            <Route exact path="/">
              <NavigationCardContainer navigacija={navigationMenuPrava()} />
            </Route>
            {(grupa1Check() || grupa2Check()) && (
              <Route exact path="/GrupaCijene">
                <NavigationCardContainer navigacija={["cijenaDostave","neRadniDani","postanskiBrojevi","inTime", "dostavaUStan"]} />
              </Route>
            )}
            {(grupa1Check() || grupa2Check()) && (
              <Route exact path="/GrupaCijene/DostavaUStan">
                <DostavaStan />
              </Route>
            )}
            {(grupa1Check() || grupa2Check()) && (
              <Route exact path="/GrupaCijene/CijenaDostave">
                <CijenaDostave />
              </Route>
            )}
            {(grupa1Check() || grupa2Check()) && (
              <Route exact path="/GrupaCijene/InTime">
                <InTime />
              </Route>
            )}
            {(grupa1Check() || grupa2Check()) && (
              <Route exact path="/GrupaCijene/NeRadniDani">
                <NeRadniDani />
              </Route>
            )}
            {(grupa1Check() || grupa2Check()) && <Route exact path="/GrupaCijene/GrupaPostanskiBrojevi">
              <PostanskiBrojevi/>
            </Route>}
            {(grupa1Check() || grupa2Check()) && (
              <Route exact path="/GrupaPlacanja">
                <NavigationCardContainer navigacija={["placanja"]} />
              </Route>
            )}
            {(grupa1Check() || grupa2Check()) && (
              <Route exact path="/Pages/Pages">
                <Pages />
              </Route>
            )}
            {(grupa1Check() || grupa2Check()) && (
              <Route exact path="/GrupaPlacanja/Placanja">
                <Placanja />
              </Route>
            )}
            {(grupa1Check() || grupa3Check()) && (
              <Route exact path="/PosebneOsobine/PosebneOsobine">
                <PosebneOsobine />
              </Route>
            )}
            {(grupa1Check() || grupa2Check()) && (
              <Route exact path="/Poslovnice/Poslovnice">
                <Poslovnice />
              </Route>
            )}
            {(grupa1Check() || grupa2Check()) && (
              <Route exact path="/Pages/NewPage">
                <NewPage />
              </Route>
            )}
            {(grupa1Check() || grupa2Check()) && (
              <Route exact path="/Pages/EditPage">
                <EditPage />
              </Route>
            )}
            {grupa1Check() && (
              <Route exact path="/Seo/Seo">
                <Seo />
              </Route>
            )}
            {grupa1Check() && (
              <Route exact path="/GrupaHeaderFooter">
                <NavigationCardContainer
                  navigacija={["navbarKlas", "boje", "tekstPolja", "logo"]}
                />
              </Route>
            )}
            {grupa1Check() && (
              <Route exact path="/GrupaHeaderFooter/Logo">
                <FormaContainer />
              </Route>
            )}
            {grupa1Check() && (
              <Route exact path="/GrupaHeaderFooter/NavbarKlas">
                <NavbarKlas />
              </Route>
            )}
            {grupa1Check() && (
              <Route exact path="/GrupaHeaderFooter/Boje">
                <Boje />
              </Route>
            )}
            {grupa1Check() && (
              <Route exact path="/GrupaHeaderFooter/TekstPolja">
                <TekstPolja />
              </Route>
            )}
          </Switch>
        </div>
      )}
      {!isAuthenticated && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
            alignItems: "center",
          }}
        >
          <img
            style={{ width: "25em", marginBottom: "2em" }}
            src="images/ElipsoLogoSvg.svg"
            alt="ElipsoGif"
          ></img>
          <div>
            <SignInButton />
          </div>
        </div>
      )}
    </div>
  );
};
