import Select from "./Select";
import {
  NacinSnizenjaCijena,
  PopustVezanaKupnjaModel,
  SelectOpcije,
  VezanaKupnja,
} from "./UnosAkcijePrilikeTypes";
import PopustVezanaKupnjaTablica from "./PopustVezanaKupnjaTablica";
import classes from "./UnosAkcijePrilike.module.css";
import { useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";

type PopustVezanaKupnjaProps = {
  naciniSnizenjaCijena: NacinSnizenjaCijena[];
  popustVezanaKupnjaModel: PopustVezanaKupnjaModel;
  popustVezanaKupanjaHandler: (
    popustVezanaKupnjaModel: PopustVezanaKupnjaModel
  ) => void;
  dodajProizvod: (value: string) => void;
  dodajUvjet: (value: string) => void;
  brisiProizvod: (value: string) => void;
  brisiUvjet: (value: string) => void;
  updateProizvod: (value: string) => void;
  updateUvjet: (value: string) => void;
};

const PopustVezanaKupnja = (props: PopustVezanaKupnjaProps) => {
  const readExcel = (file: Blob, ref: string) => {
    if (file) {
      const promise = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onload = (e) => {
          if (e.target?.result) {
            let bufferArray = e.target.result;
            const wb = XLSX.read(bufferArray, { type: "buffer" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws);
            resolve(data);
          }
          fileReader.onerror = (error) => {
            reject(error);
          };
        };
      });
      promise.then((d: any) => {
        let temp: VezanaKupnja[] = [];
        d.forEach((element: any) => {
          temp.push({ SifraArtikla: element.SifraArtikla });
        });
        if (ref === "vezanaKupnja") {
          props.popustVezanaKupanjaHandler({
            ...props.popustVezanaKupnjaModel,
            uneseneSifreProizvodaZaPopust: temp,
          });
        } else {
          props.popustVezanaKupanjaHandler({
            ...props.popustVezanaKupnjaModel,
            uneseneSifreArtiklaZaOstvarenjePopusta: temp,
          });
        }
      });
    }
  };

  const inputFile = useRef<HTMLInputElement>(null);
  const odabirExcelDatoteke = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  const inputFile2 = useRef<HTMLInputElement>(null);
  const odabirExcelDatoteke2 = () => {
    if (inputFile2.current) {
      inputFile2.current.click();
    }
  };

  useEffect(() => {
    if(!props.popustVezanaKupnjaModel.odabraniNacinSnizenjaCijena){
      props.popustVezanaKupanjaHandler({
        ...props.popustVezanaKupnjaModel,
        odabraniNacinSnizenjaCijena: {id:100, label: "Nema popusta"}
      })
    }
  },[props])

  return (
    <>
      <h4 className={classes.stepsNaslov}>Popust za vezanu kupnju</h4>
      <div style={{ margin: "0px 10px" }}>
        <Select
          inputLabel="Izaberi način sniženja cijena"
          marginBottom="1em"
          naciniSnizenjaCijena={props.naciniSnizenjaCijena}
          handleChange={(value: SelectOpcije | null) =>
            props.popustVezanaKupanjaHandler({
              ...props.popustVezanaKupnjaModel,
              odabraniNacinSnizenjaCijena: value,
            })
          }
          value={props.popustVezanaKupnjaModel.odabraniNacinSnizenjaCijena}
          voditeljiBrenda={[]}
          tipoviAkcije={[]}
          robneMarke={[]}
          kategorijeProizvodaNaAkciji={[]}
          vezanaKupnjaSnizenja={[]}
          vrstePoklonOsiguranja={[]}
          naciniPlacanjaNaRate={[]}
          registracija={[]}
          obavezno={false}
          error={false}
          referenca="snizenjaCijena"
          popustPromoIznos={[]}
          popustPromoPaymentTypes={[]}
          popustPromoPaymentTypeCodes={[]}
          popustPromoPaymentTypeGroups={[]}
          disabled={false}
        />
      </div>
      <div className={classes.tabliceWrapper}>
        <div className={classes.tablicaWrapper}>
          <h4 className={classes.tablicaLabel}>Šifre proizvoda</h4>
          <div className={classes.container}>
            <input
              accept={
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"'
              }
              className={classes.hidden}
              type="file"
              multiple={false}
              onChange={(e) => {
                let file: any = [];
                if (e.target.files) {
                  file = e.target.files[0];
                }
                readExcel(file, "vezanaKupnja");
                e.target.value = "";
              }}
              ref={inputFile}
            />
            <button
              type="button"
              className={classes.uploadButtonExcel}
              onClick={odabirExcelDatoteke}
            >
              <FontAwesomeIcon
                className={classes.uploadIcon}
                icon={faArrowUpFromBracket}
              />
              Odabir datoteka
            </button>
          </div>
          <PopustVezanaKupnjaTablica
            sifreProizvodaZaPopust={
              props.popustVezanaKupnjaModel.uneseneSifreProizvodaZaPopust
            }
            sifreArtikala={[]}
            dodajProizvod={props.dodajProizvod}
            referenca="proizvodi"
            dodajUvjet={props.dodajUvjet}
            brisiProizvod={props.brisiProizvod}
            brisiUvjet={props.brisiUvjet}
            updateProizvod={props.updateProizvod}
            updateUvjet={props.updateUvjet}
          />
        </div>
        <div className={classes.tablicaWrapper}>
          {/* <div className="bg-grey br-lightgrey mb-5 p-3">
            Uvjeti za dobivanje popusta vezane kupnje
          </div> */}
          <h4 className={classes.tablicaLabel}>
            Šifre artikala za ostvarenje popusta
          </h4>
          <div className={classes.container}>
            <input
              accept={
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"'
              }
              className={classes.hidden}
              type="file"
              multiple={false}
              onChange={(e) => {
                let file: any = [];
                if (e.target.files) {
                  file = e.target.files[0];
                }
                readExcel(file, "uvjeti");
                e.target.value = "";
              }}
              ref={inputFile2}
            />
            <button
              type="button"
              className={classes.uploadButtonExcel}
              onClick={odabirExcelDatoteke2}
            >
              <FontAwesomeIcon
                className={classes.uploadIcon}
                icon={faArrowUpFromBracket}
              />
              Odabir datoteka
            </button>
          </div>
          <PopustVezanaKupnjaTablica
            sifreArtikala={
              props.popustVezanaKupnjaModel
                .uneseneSifreArtiklaZaOstvarenjePopusta
            }
            sifreProizvodaZaPopust={[]}
            dodajProizvod={props.dodajProizvod}
            referenca="uvjeti"
            dodajUvjet={props.dodajUvjet}
            brisiProizvod={props.brisiProizvod}
            brisiUvjet={props.brisiUvjet}
            updateProizvod={props.updateProizvod}
            updateUvjet={props.updateUvjet}
          />
        </div>
      </div>
    </>
  );
};

export default PopustVezanaKupnja;
