import Select from "./Select";
import {
  NacinPlacanjaNaRate,
  RateModel,
  SelectOpcije,
} from "./UnosAkcijePrilikeTypes";
import classes from "./UnosAkcijePrilike.module.css";

type RateProps = {
  rateHandler: (rateModel: RateModel) => void;
  rateModel: RateModel;
  naciniPlacanjaNaRate: NacinPlacanjaNaRate[];
  rateNotValid: boolean;
  setRateNotValid: (value: boolean) => void;
};

const Rate = (props: RateProps) => {
  return (
    <>
      <h4 className={classes.stepsNaslov}>Rate</h4>
      <Select
        inputLabel="Plaćanja na rate"
        marginBottom="1em"
        naciniPlacanjaNaRate={props.naciniPlacanjaNaRate}
        handleChange={(value: SelectOpcije | null) => {
          props.setRateNotValid(false);
          props.rateHandler({
            ...props.rateModel,
            odabraniNacinPlacanja: value,
          });
        }}
        value={props.rateModel.odabraniNacinPlacanja}
        voditeljiBrenda={[]}
        tipoviAkcije={[]}
        robneMarke={[]}
        kategorijeProizvodaNaAkciji={[]}
        vezanaKupnjaSnizenja={[]}
        naciniSnizenjaCijena={[]}
        vrstePoklonOsiguranja={[]}
        registracija={[]}
        obavezno={false}
        error={false}
        referenca="rate"
        popustPromoIznos={[]}
        popustPromoPaymentTypes={[]}
        popustPromoPaymentTypeCodes={[]}
        popustPromoPaymentTypeGroups={[]}
        disabled={false}
      />
      {props.rateNotValid && (
        <div style={{ fontSize: "0.8em", color: "red" }}>Obavezan unos!</div>
      )}
    </>
  );
};

export default Rate;
