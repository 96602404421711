import { useMemo } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import classes from './Map.module.css';

type MapType = {
    lat: number,
    lng: number
  }

const ZagrebKoordinate: MapType = {
    lat: 45.815399,
    lng: 15.966568
} 

const Map = (props: MapType) => {
    const mapCenter = useMemo(() => props, [props]);
    return <>
        { 
            <GoogleMap options={{disableDefaultUI:true, gestureHandling: "none", zoomControl:false}} zoom={14} center={mapCenter.lat !== 0 && mapCenter.lng !== 0 ? mapCenter : ZagrebKoordinate} mapContainerClassName={classes.container}>
                {mapCenter.lat !== 0 && mapCenter.lng !== 0 && <Marker position={mapCenter} />}
            </GoogleMap>}
    </>
}

export { Map };
export type { MapType };
export { ZagrebKoordinate };