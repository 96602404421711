import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useCallback, useEffect, useRef, useState } from "react";
import useApi from "../../Hooks/useApi";
import Swal from "sweetalert2";
import classes from "./Navbar.module.css";
import BackDrop from "../../UnosAkcijePrilike/BackDrop";
import { AlertModel } from "../../UnosAkcijePrilike/UnosAkcijePrilikeTypes";
import { Dialog, DialogContent } from "@mui/material";
import { Redoslijed } from "./NavbarKlasTypes";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "98%",
  height: "98%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
};

type PromjenaRedoslijeda = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

const PromjenaRedoslijeModal = (props: PromjenaRedoslijeda) => {
  const apiCall = useApi();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dialog, setOpen] = useState<AlertModel>({ open: false, message: "" });
  const [redoslijedi, setRedoslijedi] = useState<Redoslijed[]>([]);
  const dragItem: any = useRef();
  const dragOverItem: any = useRef();
  let redoslijedCancel = false;

  const dragStart = (e: any, position: any) => {
    dragItem.current = position;
  };

  const dragEnter = (e: any, position: any) => {
    dragOverItem.current = position;
  };

  const drop = (e: any) => {
    const copyListItems = [...redoslijedi];
    const redoslijedDragItema = copyListItems[dragItem.current].redoslijed;
    copyListItems[dragItem.current].redoslijed =
      copyListItems[dragOverItem.current].redoslijed;
    copyListItems[dragOverItem.current].redoslijed = redoslijedDragItema;
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setRedoslijedi(copyListItems);
  };

  const handleDialogClose = () => {
    setOpen({ open: false, message: "" });
  };
  const handleClose = () => {
    props.setOpen(false);
  };

  const getRedoslijed = (accessToken: string) => {
    fetch("/api/Navbar/GetRedoslijed", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (redoslijedCancel) return;
        setRedoslijedi(data.responseData);
        // dostupni();
      });
  };

  useEffect(() => {
    apiCall(getRedoslijed);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      redoslijedCancel = true;
    };
  }, [apiCall]);

  const spremiRedoslijed = useCallback(
    (accessToken: string) => {
      setIsLoading(true);
      let formData = new FormData();
      for (let i = 0; i < redoslijedi.length; i++) {
        const element = redoslijedi[i];
        formData.append(`reorder[${i}].Id`, element.id.toString());
        formData.append(
          `reorder[${i}].Redoslijed`,
          element.redoslijed.toString()
        );
      }
      fetch("api/Navbar/ReorderRedoslijed", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "post",
        body: formData,
      }).then((response) => {
        if (response.status === 200) {
          props.setOpen(false);
          setIsLoading(false);
          Swal.fire({
            icon: "success",
            title: "Spremljeno",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        } else {
          setIsLoading(false);
          Swal.fire({
            title: response.statusText,
            showConfirmButton: false,
            toast: true,
            customClass: {
              container: classes.swalContainer,
              popup: classes.swal2IconError,
            },
            timer: 1500,
            iconColor: "white",
            timerProgressBar: true,
            icon: "warning",
            position: "top-right",
          });
        }
      });
    },
    [props, redoslijedi]
  );

  const post = () => {
    apiCall(spremiRedoslijed);
  };

  return (
    <>
      <div>
        <Modal
          open={props.open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div style={{ height: "100%", width: "100%" }}>
              <div>
                {redoslijedi &&
                  redoslijedi.map((item, index) => (
                    <div
                      style={{
                        backgroundColor: "lightblue",
                        margin: "20px 25%",
                        textAlign: "center",
                        fontSize: "40px",
                      }}
                      key={index}
                      draggable
                      onDragStart={(e) => dragStart(e, index)}
                      onDragEnter={(e) => dragEnter(e, index)}
                      onDragEnd={drop}
                    >
                      {`${item.redoslijed} - ${item.naziv}`}
                    </div>
                  ))}
              </div>
            </div>

            <Button
              style={{ backgroundColor: "rgb(26, 179, 148)" }}
              variant="contained"
              onClick={() => {
                post();
              }}
            >
              Spremi
            </Button>
          </Box>
        </Modal>
      </div>
      {dialog.open && (
        <Dialog onClose={handleDialogClose} open={dialog.open}>
          <DialogContent className={classes.dialog}>
            <label>{dialog.message}</label>
            <div style={{ textAlign: "end" }}>
              <Button onClick={handleDialogClose}>Zatvori</Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
      {isLoading && <BackDrop />}
    </>
  );
};

export default PromjenaRedoslijeModal;
