import { Button } from "@mui/material";
import { SlikaInfo } from "../../utils/utils";
import useApi from "../Hooks/useApi";
import {
  AkcijaPromoKodViewModel,
  CijeneModel,
  NacinPlacanjaNaRate,
  OsnoveModel,
  Poklon,
  PokloniModel,
  PoklonOsiguranjaModel,
  PopustPromoIznos,
  PopustPromoPaymentType,
  PopustPromoPaymentTypeCode,
  PopustPromoPaymentTypeGroup,
  PopustVezanaKupnjaModel,
  ProizvodiModel,
  PromoPopustModel,
  RateModel,
  RegistracijaModel,
  TrajanjeModel,
  VezanaKupnja,
} from "./UnosAkcijePrilikeTypes";
import Summary from "./Summary";
import Swal from "sweetalert2";
import classes from "./UnosAkcijePrilike.module.css";
import { useState } from "react";
import BackDrop from "./BackDrop";

type ZavrsiProps = {
  osnoveModel: OsnoveModel;
  trajanjeModel: TrajanjeModel;
  proizvodiModel: ProizvodiModel;
  pokloniModel: PokloniModel;
  popustVezanaKupnjaModel: PopustVezanaKupnjaModel;
  poklonOsiguranjaModel: PoklonOsiguranjaModel;
  cijeneModel: CijeneModel;
  rateModel: RateModel;
  registracijaModel: RegistracijaModel;
  slika506px: SlikaInfo | null;
  slika1200px: SlikaInfo | null;
  slika1140px: SlikaInfo | null;
  slika600px: SlikaInfo | null;
  naciniPlacanjaNaRate: NacinPlacanjaNaRate[];
  promoPopustModel: PromoPopustModel;
  popustPromoIznos: PopustPromoIznos[];
  popustPromoPaymentType: PopustPromoPaymentType[];
  popustPromoPaymentTypeCode: PopustPromoPaymentTypeCode[];
  popustPromoPaymentTypeGroup: PopustPromoPaymentTypeGroup[];
  slike151px: SlikaInfo[] | null;
  listaPromo: AkcijaPromoKodViewModel[];
};

const Zavrsi = (props: ZavrsiProps) => {
  const apiCall = useApi();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const post = (accessToken: string) => {
    setIsLoading(true);
    let formData = new FormData();
    let danPocetak;
    let mjesecPocetak;
    let godinaPocetak;
    let danKraj;
    let mjesecKraj;
    let godinaKraj;
    let danPocetakPromo;
    let mjesecPocetakPromo;
    let godinaPocetakPromo;
    let danKrajPromo;
    let mjesecKrajPromo;
    let godinaKrajPromo;

    if (props.trajanjeModel.odabraniDatumPocetkaPrilike) {
      danPocetak = (
        "0" +
        new Date(props.trajanjeModel.odabraniDatumPocetkaPrilike).getDate()
      ).slice(-2);
      mjesecPocetak = (
        "0" +
        (new Date(props.trajanjeModel.odabraniDatumPocetkaPrilike).getMonth() +
          1)
      ).slice(-2);
      godinaPocetak = new Date(
        props.trajanjeModel.odabraniDatumPocetkaPrilike
      ).getFullYear();
    }
    if (props.trajanjeModel.odabraniDatumKrajaPrilike) {
      danKraj = (
        "0" + new Date(props.trajanjeModel.odabraniDatumKrajaPrilike).getDate()
      ).slice(-2);
      mjesecKraj = (
        "0" +
        (new Date(props.trajanjeModel.odabraniDatumKrajaPrilike).getMonth() + 1)
      ).slice(-2);
      godinaKraj = new Date(
        props.trajanjeModel.odabraniDatumKrajaPrilike
      ).getFullYear();
    }
    formData.append(
      "ReferentID",
      props.osnoveModel.odabraniVoditelj?.id.toString() ?? ""
    );
    formData.append(
      "ReferentNaziv",
      props.osnoveModel.odabraniVoditelj?.label.toString() ?? ""
    );
    formData.append("ImePrilike", props.osnoveModel.imePrilike);
    formData.append("Email", props.osnoveModel.vasEmail);
    formData.append(
      "RobnaMarkaID",
      props.osnoveModel.odabranaRobnaMarka?.id.toString() ?? ""
    );
    formData.append(
      "RobnaMarkaNaziv",
      props.osnoveModel.odabranaRobnaMarka?.label.toString() ?? ""
    );
    formData.append("TextPrilike", props.osnoveModel.tekstPrilike);
    formData.append(
      "KategorijaProizvodaID",
      props.osnoveModel.odabranaKategorijaProizvodaNaAkciji?.id.toString() ?? ""
    );
    formData.append(
      "KategorijaProizvodaNaziv",
      props.osnoveModel.odabranaKategorijaProizvodaNaAkciji?.label.toString() ??
        ""
    );
    formData.append(
      "Pocetak",
      `${godinaPocetak}-${mjesecPocetak}-${danPocetak}`
    );
    formData.append("Kraj", `${godinaKraj}-${mjesecKraj}-${danKraj}`);
    formData.append(
      "NacinSnizenjaCijeneID",
      props.popustVezanaKupnjaModel.odabraniNacinSnizenjaCijena?.id.toString() ??
        ""
    );
    formData.append(
      "NacinSnizenjaCijeneNaziv",
      props.popustVezanaKupnjaModel.odabraniNacinSnizenjaCijena?.label.toString() ??
        ""
    );
    formData.append(
      "VrstaOsiguranjaPoklonaID",
      props.poklonOsiguranjaModel.odabranaVrstaPoklonOsiguranja?.id.toString() ??
        ""
    );
    formData.append(
      "VrstaOsiguranjaPoklonaNaziv",
      props.poklonOsiguranjaModel.odabranaVrstaPoklonOsiguranja?.label.toString() ??
        ""
    );
    // formData.append(
    //   "NacinSnizenjaCijeneVezanaKupnjaID",
    //   props.cijeneModel.vezanaKupnjaOdabranoSnizenje?.id.toString() ?? ""
    // );
    // formData.append(
    //   "NacinSnizenjaCijeneVezanaKupnja",
    //   props.cijeneModel.vezanaKupnjaOdabranoSnizenje?.label.toString() ?? ""
    // );
    formData.append(
      "NacinPlacanjaNaRateID",
      props.naciniPlacanjaNaRate
        .find((x) => x.id === props.rateModel.odabraniNacinPlacanja?.id)
        ?.brojRata.toString()!
      // props.rateModel.odabraniNacinPlacanja?.id.toString() ?? ""
    );
    formData.append(
      "NacinPlacanjaNaRateNaziv",
      props.rateModel.odabraniNacinPlacanja?.label.toString() ?? ""
    );
    if (props.registracijaModel.odabranaRegistracija?.id === 0) {
      formData.append("Registracija", "false");
    } else {
      formData.append("Registracija", "true");
    }

    formData.append(
      "LinkZaPrijavu",
      props.registracijaModel.linkZaPrijavuRegistraciju
    );
    if (props.proizvodiModel.uneseniProizvodiNaPrilici.length > 0) {
      for (
        let i = 0;
        i < props.proizvodiModel.uneseniProizvodiNaPrilici.length;
        i++
      ) {
        const element = props.proizvodiModel.uneseniProizvodiNaPrilici[i];
        formData.append(
          `ProizvodiNaPrilici[${i}].SifraArtikla`,
          element.SifraArtikla
        );
        if (props.osnoveModel.odabraniTipAkcije?.id !== 2 && element.Cijena) {
          formData.append(
            `ProizvodiNaPrilici[${i}].Cijena`,
            element.Cijena.toString().replace(".", ",")
          );
        }
        formData.append(
          `ProizvodiNaPrilici[${i}].BesplatnaDostava`,
          element.BesplatnaDostava.toString()
        );
        formData.append(
          `ProizvodiNaPrilici[${i}].SifraOsiguranja`,
          element.SifraOsiguranja?.toString() ?? ""
        );
        formData.append(
          `ProizvodiNaPrilici[${i}].PromoKod`,
          element.PromoKod?.toString() ?? ""
        );
        formData.append(
          `ProizvodiNaPrilici[${i}].Support`,
          element.Support?.toString().replace(".", ",") ?? ""
        );
        formData.append(
          `ProizvodiNaPrilici[${i}].ReferentniBroj`,
          element.ReferentniBroj?.trim() ?? ""
        );
        formData.append(
          `ProizvodiNaPrilici[${i}].DodatniRabatKomisija`,
          element.DodatniRabatKomisija?.toString().replace(".", ",") ?? ""
        );
        formData.append(
          `ProizvodiNaPrilici[${i}].AkcijskaNettoNabavnaCijenaKomisija`,
          element.AkcijskaNettoNabavnaCijenaKomisija?.toString().replace(".", ",") ?? ""
        );
        if (element.BesplatnaDostava) {
          formData.append(
            `BesplatnaDostavaArtikli`,
            element.SifraArtikla.toString()
          );
        }
      }
    }
    if (props.pokloniModel.sifraPoklona.length > 0) {
      formData.append("SifraPoklona", props.pokloniModel.sifraPoklona);
    }
    if (props.pokloniModel.uzStoSeDajePoklon.length > 0) {
      props.pokloniModel.uzStoSeDajePoklon.forEach((element: Poklon) => {
        formData.append("UvjetiPoklona", element.SifraArtikla);
      });
    }
    if (
      props.popustVezanaKupnjaModel.uneseneSifreProizvodaZaPopust.length > 0
    ) {
      props.popustVezanaKupnjaModel.uneseneSifreProizvodaZaPopust.forEach(
        (element: VezanaKupnja) => {
          formData.append("PopustProizvodaSifre", element.SifraArtikla);
        }
      );
    }
    if (
      props.popustVezanaKupnjaModel.uneseneSifreArtiklaZaOstvarenjePopusta
        .length > 0
    ) {
      props.popustVezanaKupnjaModel.uneseneSifreArtiklaZaOstvarenjePopusta.forEach(
        (element: VezanaKupnja) => {
          formData.append("PopustProizvodaUvjet", element.SifraArtikla);
        }
      );
    }
    formData.append(
      "TipPrilikeID",
      props.osnoveModel.odabraniTipAkcije?.id.toString() ?? ""
    );
    formData.append(
      "TipPrilikeNaziv",
      props.osnoveModel.odabraniTipAkcije?.label.toString() ?? ""
    );
    if (props.slika506px) {
      formData.append(`Slike`, props.slika506px.file);
    }
    if (props.slika600px) {
      formData.append(`Slike`, props.slika600px.file);
    }
    if (props.slika1200px) {
      formData.append(`Slike`, props.slika1200px.file);
    }
    if (props.slika1140px) {
      formData.append(`Slike`, props.slika1140px.file);
    }
    if (props.slike151px) {
      props.slike151px.forEach((slika) => {
        formData.append(`Slike`, slika.file);
      });
    }
    if (props.listaPromo.length > 0) {
      for (let i = 0; i < props.listaPromo.length; i++) {
        const element = props.listaPromo[i];

        danPocetakPromo = (
          "0" + new Date(element.pocetakPromo).getDate()
        ).slice(-2);
        mjesecPocetakPromo = (
          "0" +
          (new Date(element.pocetakPromo).getMonth() + 1)
        ).slice(-2);
        godinaPocetakPromo = new Date(element.pocetakPromo).getFullYear();

        danKrajPromo = ("0" + new Date(element.krajPromo).getDate()).slice(-2);
        mjesecKrajPromo = (
          "0" +
          (new Date(element.krajPromo).getMonth() + 1)
        ).slice(-2);
        godinaKrajPromo = new Date(element.krajPromo).getFullYear();

        if (
          element.popustPromoCode &&
          element.popustPromoCode?.toString().length > 0
        ) {
          formData.append(
            `promoKodovi[${i}].PopustPromoCode`,
            element.popustPromoCode?.toString().toLocaleUpperCase() ?? ""
          );
        }
        if (
          element.popustPromoIznos &&
          element.popustPromoIznos?.toString().length > 0
        ) {
          formData.append(
            `promoKodovi[${i}].PopustPromoIznos`,
            props.popustPromoIznos
              .find(
                (x) =>
                  x.naziv.substring(0, x.naziv.indexOf("%")).toString() ===
                  element.popustPromoIznos?.toString()
              )
              ?.vrijednost.toString()
              .replace(".", ",") ?? ""
            // element.popustPromoIznos?.toString() ?? ""
          );
        }
        if (
          element.popustPromoPaymentType &&
          element.popustPromoPaymentType.length > 0
        ) {
          formData.append(
            `promoKodovi[${i}].PopustPromoPaymentType`,
            props.popustPromoPaymentType
              .find(
                (x) =>
                  x.naziv.toString() ===
                  element.popustPromoPaymentType?.toString()
              )
              ?.vrijednost.toString() ?? ""
            // element.popustPromoPaymentType?.toString() ?? ""
          );
        }
        if (
          element.popustPromoPaymentTypeCode &&
          element.popustPromoPaymentTypeCode.length > 0
        ) {
          formData.append(
            `promoKodovi[${i}].PopustPromoPaymentTypeCode`,
            props.popustPromoPaymentTypeCode
              .find(
                (x) =>
                  x.naziv.toString() ===
                  element.popustPromoPaymentTypeCode?.toString()
              )
              ?.vrijednost.toString() ?? ""
            // element.popustPromoPaymentTypeCode?.toString() ?? ""
          );
        }
        if (
          element.popustPromoPaymentTypeGroup &&
          element.popustPromoPaymentTypeGroup.length > 0
        ) {
          formData.append(
            `promoKodovi[${i}].PopustPromoPaymentTypeGroup`,
            props.popustPromoPaymentTypeGroup
              .find(
                (x) =>
                  x.naziv.toString() ===
                  element.popustPromoPaymentTypeGroup?.toString()
              )
              ?.vrijednost.toString() ?? ""
            // element.popustPromoPaymentTypeGroup?.toString() ?? ""
          );
        }
        formData.append(
          `promoKodovi[${i}].PocetakPromo`,
          `${danPocetakPromo}/${mjesecPocetakPromo}/${godinaPocetakPromo}` ?? ""
        );
        formData.append(
          `promoKodovi[${i}].KrajPromo`,
          `${danKrajPromo}/${mjesecKrajPromo}/${godinaKrajPromo}` ?? ""
        );
      }
    }

    fetch("api/Akcija/NewAkcija", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      method: "post",
      body: formData,
    }).then((response) => {
      if (response.status === 200) {
        setIsLoading(false);
        Swal.fire({
          icon: "success",
          title: "Spremljeno",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/";
          }
        });
      } else {
        setIsLoading(false);
        Swal.fire({
          title: response.statusText,
          showConfirmButton: false,
          toast: true,
          customClass: {
            container: classes.swalContainer,
            popup: classes.swal2IconError,
          },
          timer: 1500,
          iconColor: "white",
          timerProgressBar: true,
          icon: "warning",
          position: "top-right",
        });
      }
    });
  };

  const apiCallPost = () => {
    apiCall(post);
  };

  return (
    <div>
      <Summary
        osnoveModel={props.osnoveModel}
        trajanjeModel={props.trajanjeModel}
        proizvodiModel={props.proizvodiModel}
        pokloniModel={props.pokloniModel}
        popustVezanaKupnjaModel={props.popustVezanaKupnjaModel}
        poklonOsiguranjaModel={props.poklonOsiguranjaModel}
        cijeneModel={props.cijeneModel}
        rateModel={props.rateModel}
        registracijaModel={props.registracijaModel}
        slika506px={props.slika506px}
        slika1140px={props.slika1140px}
        slika1200px={props.slika1200px}
        slika600px={props.slika600px}
        promoPopustModel={props.promoPopustModel}
        slike151px={props.slike151px}
        listaPromo={props.listaPromo}
      />
      {!isLoading && (
        <Button
          variant="contained"
          onClick={() => {
            apiCallPost();
          }}
          style={{
            width: "100%",
            margin: "2em 0",
          }}
        >
          Završi
        </Button>
      )}
      {isLoading && <BackDrop />}
    </div>
  );
};

export default Zavrsi;
