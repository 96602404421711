import {
    DataGrid,
    Column,
    Selection,
    Editing,
    FilterRow,
    Toolbar,
    Item,
  } from "devextreme-react/data-grid";
  import { useCallback, useEffect, useMemo, useRef, useState } from "react";
  import { createStore } from "devextreme-aspnet-data-nojquery";
  import useApiPromise from "../../Hooks/useApiPromise";
  import { Pager, Paging } from "devextreme-react/tree-list";
  import { Button as ButtonMui } from "@mui/material";
  import { PlusSquare } from "react-bootstrap-icons";
  import useApi from "../../Hooks/useApi";
  import Swal from "sweetalert2";
  import classes from "../HeaderFooter.module.css";
  import BackDrop from "../../UnosAkcijePrilike/BackDrop";
import AddModal from "./AddModal";
import EditModal from "./EditModal";
import { TekstPolje } from "./TekstPoljaTypes";
  
  const TekstPoljaTablica = () => {
    const allowedPageSizes = [20, 50, 100];

    const dataGrid = useRef<DataGrid>(null);
    const apiCallPromise = useApiPromise();
    const apiCall = useApi();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tekstPoljeId, setTekstPoljeId] = useState<number>(-1);
    const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
    const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
    const accessTokenHandler = useCallback((accessToken: string) => {
      return (async () => {
        return accessToken;
      })();
    }, []);
    const [rowData, setRowData] = useState<TekstPolje>({id: -1, end: "", start: "", textBottom: "", textTop: ""});
  
    const dataSource = useMemo(() => {
      return createStore({
        key: "id",
        loadUrl: `api/TextBar/GetTextBars`,
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.xhrFields = { withCredentials: true };
          return apiCallPromise(accessTokenHandler).then((token: any) => {
            return (ajaxOptions.headers = {
              Authorization: `Bearer ${token}`,
            });
          });
        },
      });
    }, [accessTokenHandler, apiCallPromise]);
  
    const deleteTextBar = useCallback(
      (accessToken: string) => {
        if (tekstPoljeId !== -1) {
          setIsLoading(true);
          let formData = new FormData();
          formData.append("id", tekstPoljeId.toString());
  
          fetch("api/Seo/DeleteSeo", {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            method: "post",
            body: formData,
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.responseData) {
                setIsLoading(false);
                Swal.fire({
                  icon: "success",
                  title: "Obrisano",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.location.reload();
                  }
                });
              } else {
                setIsLoading(false);
                Swal.fire({
                  title: data.error,
                  showConfirmButton: false,
                  toast: true,
                  customClass: {
                    container: classes.swalContainer,
                    popup: classes.swal2IconError,
                  },
                  timer: 1500,
                  iconColor: "white",
                  timerProgressBar: true,
                  icon: "warning",
                  position: "top-right",
                });
              }
            });
        }
      },
      [tekstPoljeId]
    );
  
    useEffect(() => {
      apiCall(deleteTextBar);
    },[apiCall, deleteTextBar])
  
    return (
      <>
        <div>
          <DataGrid
            id="dataGrid"
            ref={dataGrid}
            dataSource={dataSource}
            rowAlternationEnabled
            allowColumnReordering
            allowColumnResizing
            showBorders
            onEditingStart={(e) => {
              e.cancel = true;
              setRowData(e.data);
              setEditModalOpen(true);
            }}
            remoteOperations={true}
            onRowRemoving={(e: any) => {
              setTekstPoljeId(e.data.id);
              e.cancel = true;
            }}
          >
            <FilterRow visible={true} />
            <Paging defaultPageSize={20} />
            <Editing mode="row" allowUpdating />
            <Pager
              visible={true}
              showPageSizeSelector={true}
              showInfo={true}
              allowedPageSizes={allowedPageSizes}
              infoText={`Stranica {0} od {1} ({2} artikala)`}
            />
            <Selection mode="single" />
            <Column
              allowEditing={true}
              // width="10%"
              dataField="textBottom"
              caption="Text bottom"
            ></Column>
            <Column
              allowEditing={true}
              // width="75%"
              dataField="textTop"
              caption="Text top"
            />
              <Column
                allowEditing={true}
                // width="75%"
                dataField="start"
                caption="Start"
                dataType="date"
              />
            <Column
              allowEditing={true}
              // width="75%"
              dataField="end"
              caption="End"
              dataType="date"
            />
            <Toolbar>
              <Item location="after">
                <ButtonMui
                  onClick={() => {
                    setAddModalOpen(true);
                  }}
                  variant="contained"
                >
                  <PlusSquare size={25} />
                </ButtonMui>
              </Item>
            </Toolbar>
          </DataGrid>
        </div>
        {addModalOpen && (
          <AddModal open={addModalOpen} setOpen={setAddModalOpen} setTekstPoljeId={setTekstPoljeId}/>
        )}
        {editModalOpen && (
          <EditModal open={editModalOpen} setOpen={setEditModalOpen} data={rowData}/>
        )}
          {isLoading && <BackDrop />}
      </>
    );
  };
  
  export default TekstPoljaTablica;
  