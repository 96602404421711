import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function SimpleBackdrop() {
  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => 9999 }}
        open={true}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </div>
  );
}
