type InputModel = {
  label: string;
  multiple: boolean;
  extensionInput: string;
  extensions: string[];
  maxsizeKB: number | null;
  minHeight: number | null;
  showImage: boolean;
  exactWidth: number | null;
  exactHeight: number | null;
};

type FormaZaSlikeTipovi = {
  oznaka: string;
  naslov: string;
  inputi: InputModel[];
  uvjeti: string[];
  url: string;
};

const forme: FormaZaSlikeTipovi[] = [
  {
    url: "/api/Slika/SpremiSlikuArtikla",
    oznaka: "unosslika",
    naslov: "Unos slika proizvoda",
    inputi: [
      {
        label: "Slike proizvoda",
        multiple: true,
        extensionInput: "image/jpeg",
        extensions: ["jpg", "jpeg"],
        maxsizeKB: 3000,
        minHeight: null,
        showImage: true,
        exactHeight: null,
        exactWidth: null,
      },
    ],
    uvjeti: ["Slike manje od 3MB. Isključivo .jpg format nazvan po šifri."],
  },
  {
    url: "/api/Slika/SpremiSlikuEnergetskeNaljepnice",
    oznaka: "energetskenaljepnice",
    naslov: "Unos energetskih naljepnica za proizvode",
    inputi: [
      {
        label: "Slike proizvoda",
        multiple: true,
        extensionInput: "image/png",
        extensions: ["png"],
        maxsizeKB: 250,
        minHeight: 800,
        showImage: true,
        exactHeight: null,
        exactWidth: null,
      },
    ],
    uvjeti: [
      "Slike manje od 250KB. Isključivo .png format nazvan E_šifra.png",
      "Ciljano 800px visine, minimalno 800px visine, ne prevelike.",
    ],
  },
  {
    url: "",
    oznaka: "slikebannera",
    naslov: "Unos novog bannera",
    inputi: [
      {
        label: "Banner 506x230px",
        multiple: false,
        extensionInput: "image/*",
        extensions: [],
        maxsizeKB: null,
        minHeight: null,
        showImage: false,
        exactHeight: 230,
        exactWidth: 506,
      },
      {
        label: "Banner 1140x230px",
        multiple: false,
        extensionInput: "image/*",
        extensions: [],
        maxsizeKB: null,
        minHeight: null,
        showImage: false,
        exactHeight: 230,
        exactWidth: 1140,
      },
      {
        label: "Banner 600x500px",
        multiple: false,
        extensionInput: "image/*",
        extensions: [],
        maxsizeKB: null,
        minHeight: null,
        showImage: false,
        exactHeight: 500,
        exactWidth: 600,
      },
      {
        label: "Banner 1200x628px",
        multiple: false,
        extensionInput: "image/*",
        extensions: [],
        maxsizeKB: null,
        minHeight: null,
        showImage: false,
        exactHeight: 628,
        exactWidth: 1200,
      },
    ],
    uvjeti: [],
  },
  {
    url: "/api/Slika/SpremiInfomacijskiList",
    oznaka: "unosinformacijskihlistova",
    naslov: "Unos informacijskih listova",
    inputi: [
      {
        label: "Listovi",
        multiple: true,
        extensionInput: "application/pdf",
        maxsizeKB: 300,
        exactHeight: null,
        exactWidth: null,
        minHeight: null,
        showImage: false,
        extensions: ["pdf"],
      },
    ],
    uvjeti: [".pdf manji od 300 KB (pdf malim slovima u nazivu fajla)."],
  },
  {
    url: "/api/Slika/SpremiLogoTip",
    oznaka: "logo",
    naslov: "Unos logotipa",
    inputi: [
      {
        label: "Logotip",
        multiple: false,
        extensionInput: "application/gif",
        maxsizeKB: 100,
        exactHeight: 76,
        exactWidth: 240,
        minHeight: null,
        showImage: false,
        extensions: ["gif"],
      },
    ],
    uvjeti: [
      "Logotip manji od 100 KB. Isključivo .gif format nazvan elipso_logo.gif",
      "Dimenzije isključivo 240x76px.",
    ],
  },
];

export type { FormaZaSlikeTipovi };
export { forme };
