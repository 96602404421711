import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

type AkcijeMenuProps = {
  poslovnicaId: number;
  setEditOpen: (e: any) => void;  
  setPoslovnicaId: (e: any) => void;
  obrisiPoslovnicu: () => void;
  setNeradneNedeljeModalOpen: (value: boolean) => void;
}

const AkcijeMenu = (props:AkcijeMenuProps) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        props.setPoslovnicaId(props.poslovnicaId);
        setAnchorEl(event.currentTarget);
      };
    const handleClose = () => {
        setAnchorEl(null);
      };
            
    return <>
        <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant='contained'
      >
        Administracija
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={props.setEditOpen}>Uredi</MenuItem>
        <MenuItem onClick={props.obrisiPoslovnicu}>Obriši</MenuItem>
        <MenuItem onClick={() => props.setNeradneNedeljeModalOpen(true)}>Neradne nedelje</MenuItem>    

      </Menu>      
    </>
}

export default AkcijeMenu;