import {
  DataGrid,
  Column,
  Selection,
  Editing,
  Item,
} from "devextreme-react/data-grid";
import { SearchPanel, Pager, Paging } from "devextreme-react/tree-list";
import "devextreme/dist/css/dx.light.css";
import CircularProgress from "@mui/material/CircularProgress";
import { locale, loadMessages } from "devextreme/localization";
import hrMessages from "../UnosAkcijePrilike/hr.json";
import { Toolbar } from "@mui/material";
import { ArtiklViewModel } from "./PromjenaArtikalaTypes";
import { EditingStartEvent } from "devextreme/ui/data_grid";

type TablicaProps = {
  artikli: ArtiklViewModel[];
  addArtikl: (value: ArtiklViewModel) => void;
  deleteArtikl: (value: string) => void;
  updateArtikl: (value: any) => void;
  tip: string | undefined;
};

const PokloniTablica = (props: TablicaProps) => {
  loadMessages(hrMessages);
  locale("hr");

  const allowedPageSizes = [20, 50, 100];
  return (
    <>
      {props.artikli ? (
        <div
          style={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <DataGrid
            id="dataGrid"
            dataSource={props.artikli}
            rowAlternationEnabled
            allowColumnReordering
            allowColumnResizing
            showBorders
            onRowUpdating={(e: any) => {
              e.cancel = true;
              props.updateArtikl({ new: e.newData, old: e.oldData });
            }}
            onRowRemoving={(e: any) => {
              e.cancel = true;
              props.deleteArtikl(e.data.SifraArtikla);
            }}
            onRowInserting={(e) => {
              e.cancel = true;
              if (e.data.SifraArtikla !== "") {
                props.addArtikl({
                  SifraArtikla: e.data.SifraArtikla,
                  Cijena: e.data.Cijena,
                  greska: "",
                  isOk: false,
                  promoKod: e.data.promoKod ?? "",
                  katBroj: "",
                  naziv: "",
                  besplatnaDostava: e.data.besplatnaDostava ?? false,
                  sifraOsiguranja: e.data.sifraOsiguranja,
                  nazivOsiguranja: e.data.nazivOsiguranja,
                  support: e.data.support,
                  referentniBroj: e.data.referentniBroj,
                  akcijskaNettoNabavnaCijenaKomisija: e.data.akcijskaNettoNabavnaCijenaKomisija,
                  dodatniRabatKomisija: e.data.dodatniRabatKomisija
                });
                e.component.cancelEditData();
              }
            }}
            onRowPrepared={(e) => {
              if (e.data) {
                if (e.data.isOk === false) {
                  e.rowElement.style.backgroundColor = "rgba(255, 0, 0, 0.2)";
                  e.rowElement.className = e.rowElement.className.replace(
                    "dx-row-alt",
                    ""
                  );
                }
                if (e.data.isOk === true) {
                  e.rowElement.style.backgroundColor =
                    "rgba(61, 193, 78, 0.47)";
                  e.rowElement.className = e.rowElement.className.replace(
                    "dx-row-alt",
                    ""
                  );
                }
              }
            }}
            columnAutoWidth
            onEditingStart={(e: EditingStartEvent) => {
              e.component.columnOption(
                "promoKod",
                "allowEditing",
                e.data.promoKod === true
              );
            }}
          >
            <Paging defaultPageSize={20} />
            <Editing
              mode="row"
              allowUpdating={true}
              allowAdding={true}
              allowDeleting={true}
            />
            <Toolbar>
              <Item name="addRowButton" />
            </Toolbar>
            <Pager
              visible={true}
              showPageSizeSelector={true}
              showInfo={true}
              allowedPageSizes={allowedPageSizes}
              infoText={`Stranica {0} od {1} ({2} artikala)`}
            />
            <SearchPanel visible={true} />
            <Selection mode="single" />
            <Column
              width="9%"
              dataField="SifraArtikla"
              caption="Šifra artikla"
            />
            {props.tip !== "prilika" && (
              <Column width="9%" dataField="Cijena" caption="Cijena" />
            )}
            <Column
              width="9%"
              dataField="support"
              caption="Support"
            />
            <Column
              width="9%"
              dataField="dodatniRabatKomisija"
              caption="Dodatni rabat komisija"
            />
            <Column
              width="9%"
              dataField="akcijskaNettoNabavnaCijenaKomisija"
              caption="Akcijska netto nabavna cijena komisija"
            />
            <Column
              allowEditing={true}
              dataField="promoKod"
              caption="Promo kod"
              width="10%"
              // dataType="boolean"
            ></Column>
            <Column
              width="10%"
              allowEditing={true}
              dataField="besplatnaDostava"
              caption="Besplatna dostava"
              dataType="boolean"
            />
            <Column
              width="32%"
              allowEditing={false}
              dataField="greska"
              caption="Greška"
            />
            <Column
              width="20%"
              allowEditing={false}
              dataField="naziv"
              caption="Naziv"
            />
            <Column
              width="20%"
              allowEditing={false}
              dataField="katBroj"
              caption="Kataloški broj"
            />
            <Column
              allowEditing={true}
              dataField="sifraOsiguranja"
              caption="Šifra osiguranja"
            />
            <Column
              allowEditing={false}
              dataField="nazivOsiguranja"
              caption="Naziv osiguranja"
            />
          </DataGrid>
        </div>
      ) : (
        <CircularProgress
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
          }}
        />
      )}
    </>
  );
};

export default PokloniTablica;
