import {
    Column,
    DataGrid,
    Paging,
    Summary,
    TotalItem,
    LoadPanel,
    SearchPanel
} from 'devextreme-react/data-grid'
import classes from './PoklonTab.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGift } from '@fortawesome/free-solid-svg-icons'

type PoklonTabProps = {
    sifraPoklona: number,
    artikli: { id: number, artikl: number }[]
}

const PoklonTab = ({ sifraPoklona, artikli }: PoklonTabProps) => {
    return (
        <div className={classes.container}>
            <div className='text-center'>
                <FontAwesomeIcon icon={faGift} className="tabsIcon" />
            </div>

            {sifraPoklona > 0 ?
                <div>
                    <div className='text-center'>
                        <label style={{ fontWeight: "bold" }}>Šifra poklona:</label>
                        <div className='bigGreen'>{sifraPoklona}</div>
                    </div>
                </div>
                :
                <div style={{display:"flex", alignItems:"center"}}>
                    <div className="bigOrange">Pokloni ne postoje za ovu akciju.</div>
                </div>
            }

            <div className={classes.datagrids}>
                {sifraPoklona > 0 ?
                    <DataGrid
                        dataSource={artikli}
                        showBorders={true}
                        className="padd-0"
                    >
                        <SearchPanel visible={true} />
                        <LoadPanel enabled={true} />
                        <Paging defaultPageSize={10} />
                        <Column dataField="artikl" caption="Artikli uz koje ide poklon" dataType="string" alignment="right" />
                        <Summary>
                            <TotalItem column="artikl" summaryType="count" />
                        </Summary>
                    </DataGrid>
                    :
                    null
                }
            </div>
        </div>
    )
}

export default PoklonTab;