import { Slike } from './AkcijaTypes';
import classes from './BanneriTab.module.css';
import BannerCard from './BannerCard';
import BannerDetailView from './BannerDetailView';
import { useState } from 'react';


type BanneriTabProps = {
    slike: Slike
}

const BanneriTab = ({ slike }: BanneriTabProps) => {
    const [detailViewOpen, setDetailViewOpen] = useState(false);
    const [image, setImage] = useState(null);

    return (
        <>
        <div className={classes.container}>
            {slike.billboard ? <BannerCard  setImage={setImage} setDetailViewOpen={setDetailViewOpen} url={slike.billboard} naslov="Billboard" dimenzije={slike.billboardSize}/> : null}
            {slike.fb ? <BannerCard setImage={setImage} setDetailViewOpen={setDetailViewOpen} url={slike.fb} naslov="Fb" dimenzije={slike.fbSize}/> : null }
            {slike.rect ? <BannerCard setImage={setImage} setDetailViewOpen={setDetailViewOpen} url={slike.rect} naslov="Rect" dimenzije={slike.rectSize}/> : null}
            {slike.mozaik ? <BannerCard setImage={setImage} setDetailViewOpen={setDetailViewOpen} url={slike.mozaik} naslov="Mozaik" dimenzije={slike.mozaikSize}/> : null }
        </div>
        {detailViewOpen && <BannerDetailView
            detailViewOpen={detailViewOpen}
            setDetailViewOpen={setDetailViewOpen}
            image={image}
            setImage={setImage}
        />}
        </>
        
    )
        
}

export default BanneriTab;