import produce from 'immer';
import { Zadatak } from './NoviZadatak';
import { NoviZadatakActions } from './NoviZadatakActions'

const noviZadatakReducer = produce((oldState:Zadatak, action:NoviZadatakActions) => {
    switch (action.action) {       
        case 'SIFRA_UNESENA':
            oldState.sifra = action.value;
            break;    
        case 'VODITELJ_ODABRAN':
            oldState.voditeljID = action.value;
            break;
        case 'BREND_ODABRAN':
            oldState.brandID = action.value;
            break;
        case 'OPIS_UNESEN':
            oldState.opis = action.value;
            break;
        default:
            break;
    }
})

export default noviZadatakReducer;
