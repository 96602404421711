import {
    Column,
    DataGrid,
    Paging,
    Summary,
    TotalItem,
    LoadPanel,
    SearchPanel
} from 'devextreme-react/data-grid'
import classes from './PopustTab.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTags } from '@fortawesome/free-solid-svg-icons'

type PopustTabProps = {
    popust: number | undefined,
    artikliNaKojeSeDajePopust: { id: number, artikl: number }[] | undefined,
    uvjetPopusta: { id: number, uvjet: number }[] | undefined
}

const PopustTab = (props: PopustTabProps) => {
    return (
        <div className={classes.container}>
            <div className='text-center'>
                <FontAwesomeIcon icon={faTags} className='tabsIcon' />
            </div>
            {props.popust && props.popust > 0 ?
                <div className='text-center'>
                    <label style={{ fontWeight: "bold" }}>Popust:</label>
                    <div className='bigGreen'>{props.popust ?? 0}%</div>
                </div>
                :
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="bigOrange">Popusti ne postoje za ovu akciju.</div>
                </div>
            }
            
            {props.popust ?
                <div className={classes.grids}>
                    <DataGrid
                        dataSource={props.artikliNaKojeSeDajePopust}
                        showBorders={true}
                    >
                        <SearchPanel visible={true}/>
                        <LoadPanel enabled={true} />
                        <Paging defaultPageSize={10} />
                        <Column dataField="artikl" caption="Artikli na koje se daje popust" dataType="string" alignment="right" />
                        <Summary>
                            <TotalItem column="artikl" summaryType="count" />
                        </Summary>
                    </DataGrid>

                    <DataGrid
                        dataSource={props.uvjetPopusta}
                        showBorders={true}
                    >
                        <SearchPanel visible={true} />
                        <LoadPanel enabled={true} />
                        <Paging defaultPageSize={10} />
                        <Column dataField="uvjet" caption="Uvjeti popusta" dataType="string" alignment="right" />
                        <Summary>
                            <TotalItem column="uvjet" summaryType="count" />
                        </Summary>
                    </DataGrid>
                </div>
                :
                null
            }

        </div>
    )
}

export default PopustTab;