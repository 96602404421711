import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons'

type SortIconProps = {
    direction: string,
    size: number
}

const SortIcon = (props: SortIconProps) => {
    return (
        <FontAwesomeIcon style={{marginLeft:'0.3rem'}} icon={props.direction === '' ? faSort : (props.direction === 'up' ? faSortUp : faSortDown) } />
    )
}

export default SortIcon;