import { useState, useEffect } from 'react'
import { TextField, Button } from '@mui/material';
import { InputErrorModel, RadnoVrijemeModel } from './PoslovniceTypes';

type InputRadnoVrijemeProps = {
    jednokratnoRV: InputErrorModel | null,
    dvokratnoRV: InputErrorModel | null,
    dan: number,
    prikaziDvokratnoRV: (value: RadnoVrijemeModel) => void,
    radnoVrijemeChange: (value: RadnoVrijemeModel) => void
}

const InputRadnoVrijeme = ( { jednokratnoRV, dvokratnoRV, dan,  prikaziDvokratnoRV, radnoVrijemeChange}: InputRadnoVrijemeProps) => { 
    
    const [jednoKratnoOd, setJednoKratnoOd] = useState<string>('');
    const [jednoKratnoDo, setJednoKratnoDo] = useState<string>('');   
    const [dvoKratnoOd, setDvoKratnoOd] = useState<string>('');
    const [dvoKratnoDo, setDvoKratnoDo] = useState<string>(''); 
    const [inputError, setInputError] = useState<string>('');
    useEffect(() => {
        if(jednokratnoRV && jednokratnoRV.value.length === 11){
            let odDoArray = jednokratnoRV.value.split("-");
            setJednoKratnoOd(odDoArray[0]);
            setJednoKratnoDo(odDoArray[1]);
        }        

        if(jednokratnoRV) setInputError(jednokratnoRV.error);
    },[jednokratnoRV])

    useEffect(() => {
        if(dvokratnoRV && dvokratnoRV.value.length === 11){
            let odDoArray = dvokratnoRV.value.split("-");
            setDvoKratnoOd(odDoArray[0]);
            setDvoKratnoDo(odDoArray[1]);
        }        

        if(dvokratnoRV) setInputError(dvokratnoRV.error);
    },[dvokratnoRV])

    useEffect(() => {
        let result = '';
        if(jednoKratnoOd.length === 5 && jednoKratnoDo.length === 5){
            result = `${jednoKratnoOd}-${jednoKratnoDo}`;         
        } 

        radnoVrijemeChange({dan: dan, prikazi: true, vrijednost: result, tip: 1 });
        

    }, [jednoKratnoOd, jednoKratnoDo, radnoVrijemeChange, dan])

    useEffect(() => {
        let result = '';
        if(dvoKratnoOd.length === 5 && dvoKratnoDo.length === 5){
            result = `${dvoKratnoOd}-${dvoKratnoDo}`;         
        } 

        radnoVrijemeChange({dan: dan, prikazi: true, vrijednost: result, tip: 2 });
        
    }, [dvoKratnoOd, dvoKratnoDo, radnoVrijemeChange, dan])
    

    return <>
        <div style={{display: "inline-block"}}>
            {jednokratnoRV && <>
                <TextField 
                hiddenLabel={true}
                size="small"
                id="time"
                label="Od"
                type="time"                                
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    step: 1800, // 5 min
                }}
                sx={{ width: 100, marginRight: "0.5rem" }}
                value={jednoKratnoOd}
                onChange={(e:any) => setJednoKratnoOd(e.target.value)}
            />
            <TextField                
                hiddenLabel={true}
                size="small"
                id="time"
                label="Do"
                type="time"                
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    step: 1800, // 5 min
                }}
                sx={{ width: 100 }}
                value={jednoKratnoDo}
                onChange={(e:any) => setJednoKratnoDo(e.target.value)}
            />  
            </>  }
            {!dvokratnoRV && jednokratnoRV && <Button onClick={() => prikaziDvokratnoRV({ dan: dan, prikazi: true, vrijednost: '', tip:0})}>Dodaj</Button>}

            { dvokratnoRV && 
            <>
            <TextField                        
            hiddenLabel={true}
            size="small"
            id="time"
            label="Od"
            type="time"                
            InputLabelProps={{
                shrink: true,
            }}
            inputProps={{
                step: 1800, // 5 min
            }}
            sx={{ width: 100, marginRight: "0.5rem", marginLeft: "0.5rem" }}
            value={dvoKratnoOd}
            onChange={(e:any) => setDvoKratnoOd(e.target.value)}
        />
        <TextField
            hiddenLabel={true}
            size="small"
            id="time"
            label="Do"
            type="time"                
            InputLabelProps={{
                shrink: true,
            }}
            inputProps={{
                step: 1800, // 5 min
            }}
            sx={{ width: 100 }}
            value={dvoKratnoDo}
            onChange={(e:any) => setDvoKratnoDo(e.target.value)}
        />  
        </> 
            }  
            {dvokratnoRV && <Button onClick={() => prikaziDvokratnoRV({ dan: dan, prikazi: false, vrijednost: '', tip:0})}>Ukloni</Button>} 
            {((jednokratnoRV && !jednokratnoRV.valid) || (dvokratnoRV && !dvokratnoRV.valid)) && <span className="text-danger small-text">{inputError}</span>}
        </div>
    </>
}

export default InputRadnoVrijeme;