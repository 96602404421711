
import SeoTablica from "./SeoTablica";

const Seo = () => {

    return (
        <SeoTablica/>
    )
}

export default Seo;