import Select from "./Select";
import {
  Artikl,
  PoklonOsiguranjaModel,
  SelectOpcije,
  VrstaPoklonOsiguranja,
} from "./UnosAkcijePrilikeTypes";
import classes from "./UnosAkcijePrilike.module.css";
// import Proizvodi from "./Proizvodi";
// import useApi from "../Hooks/useApi";
// import { useState } from "react";

type PoklonOsiguranjeProps = {
  poklonOsiguranjaHandler: (
    poklonOsiguranjaModel: PoklonOsiguranjaModel
  ) => void;
  poklonOsiguranjaModel: PoklonOsiguranjaModel;
  vrstePoklonOsiguranja: VrstaPoklonOsiguranja[];
  tipAkcije: number | undefined;
  proizvodi: Artikl[];
  setPoklonOsiguranjeNotValid: (value: boolean) => void;
  sifraPoklona: string;
  nacinSnizenjaCijena: SelectOpcije | null
};

const PoklonOsiguranje = (props: PoklonOsiguranjeProps) => {
  // const apiCall = useApi();
  // const [artikliCheckAkcija, setArtikliCheckAkcija] = useState<Artikl[]>([]);

  // const handlePost = useCallback(
  //   (accessToken: string) => {
  //     let formData = new FormData();
  //     if (props.tipAkcije) {
  //       formData.append("tipAkcije", props.tipAkcije.toString());
  //     }
  //     if (props.proizvodi.length > 0) {
  //       props.proizvodi.forEach((element) => {
  //         formData.append("artikli", element.SifraArtikla);
  //       });
  //     }
  //     if (props.poklonOsiguranjaModel.odabranaVrstaPoklonOsiguranja) {
  //       formData.append(
  //         "osiguranjeID",
  //         props.poklonOsiguranjaModel.odabranaVrstaPoklonOsiguranja.id.toString()
  //       );
  //     }
  //     if(props.sifraPoklona.length === 0 && props.poklonOsiguranjaModel.odabranaVrstaPoklonOsiguranja?.id !== -1 ){
  //       fetch("api/Akcija/CheckArtiklAkcija", {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //         },
  //         method: "post",
  //         body: formData,
  //       })
  //         .then((response) => response.json())
  //         .then((data) => setArtikliCheckAkcija(data.responseData));
  //     }
  //   },
  //   [props.poklonOsiguranjaModel.odabranaVrstaPoklonOsiguranja, props.proizvodi, props.sifraPoklona.length, props.tipAkcije]
  // );

  // const handleApiCallPost = useCallback(() => {
  //     apiCall(handlePost);
    
  // }, [apiCall, handlePost]);

  // useEffect(() => {
  //   if (artikliCheckAkcija.length > 0) {
  //     let counter = 0;
  //     artikliCheckAkcija.forEach((element) => {
  //       if (element.isOk === false) {
  //         counter++;
  //       }
  //     });
  //     if (counter === 0) {
  //       props.setPoklonOsiguranjeNotValid(false);
  //     } else {
  //       props.setPoklonOsiguranjeNotValid(true);
  //     }
  //   }
  // }, [artikliCheckAkcija, props]);

  // useEffect(() => {
  //   if (
  //     props.poklonOsiguranjaModel.odabranaVrstaPoklonOsiguranja &&
  //     props.poklonOsiguranjaModel.odabranaVrstaPoklonOsiguranja.id !== -1
  //   ) {
  //     handleApiCallPost();
  //   }
  // }, [
  //   handleApiCallPost,
  //   props.poklonOsiguranjaModel.odabranaVrstaPoklonOsiguranja,
  // ]);

  // useEffect(() => {
  //   if(props.poklonOsiguranjaModel.odabranaVrstaPoklonOsiguranja?.id === -1){
  //     setArtikliCheckAkcija([]);
  //     props.setPoklonOsiguranjeNotValid(false);
  //   }
  // },[props, props.poklonOsiguranjaModel.odabranaVrstaPoklonOsiguranja?.id])

const CheckProizvodiCijena = () => {
  let counter = 0;
  props.proizvodi.forEach(proizvod => {
    let cijena = proizvod.Cijena?.toString();
    if(parseInt(cijena??"") > 3000){
      counter++;
    }
  });
  if(counter > 0){
    return true;
  }else{
    return false;
  }
}

  return (
    <>
      <h4 className={classes.stepsNaslov}>Poklon osiguranje</h4>
      <div className="bg-grey br-lightgrey mb-5 p-3">
        STROGO ZABRANJENO KOMBINIRANJE SA OSTALIM POKLONIMA ILI POPUSTOM NA
        VEZANU KUPNJU
      </div>
      <Select
        inputLabel="Izaberi vrstu osiguranja koje ide na poklon uz sve artikle koji su na"
        marginBottom="1em !important"
        vrstePoklonOsiguranja={props.vrstePoklonOsiguranja}
        handleChange={(value: SelectOpcije | null) =>
          props.poklonOsiguranjaHandler({
            ...props.poklonOsiguranjaModel,
            odabranaVrstaPoklonOsiguranja: value,
          })
        }
        value={props.sifraPoklona.length > 0 ? {id: -1, label: "Nema poklona"} : props.nacinSnizenjaCijena?.label !== "Nema popusta" ? {id: -1, label: "Nema poklona"} : props.poklonOsiguranjaModel.odabranaVrstaPoklonOsiguranja}
        // value={{id:-1, label: "Nema poklona"}}
        voditeljiBrenda={[]}
        tipoviAkcije={[]}
        robneMarke={[]}
        kategorijeProizvodaNaAkciji={[]}
        vezanaKupnjaSnizenja={[]}
        naciniSnizenjaCijena={[]}
        naciniPlacanjaNaRate={[]}
        registracija={[]}
        obavezno={false}
        error={false}
        referenca="vrstaOsiguranja"
        popustPromoIznos={[]}
        popustPromoPaymentTypes={[]}
        popustPromoPaymentTypeCodes={[]}
        popustPromoPaymentTypeGroups={[]}
        disabled={props.sifraPoklona.length > 0 ? true : props.nacinSnizenjaCijena?.label !== "Nema popusta" ? true : (CheckProizvodiCijena()) ? true : false}
      />
      {(CheckProizvodiCijena())&&<label className={classes.warning}>{"Nije moguće odabrati poklon osiguranje(unešeni artikli sa cijenom većom od 3000kn)"}</label>}
      {/* <DataGrid
        id="proizvodi"
        dataSource={artikliCheckAkcija}
        showBorders
        onRowPrepared={(e) => {
          if (e.data) {
            if (e.data.isOk === false) {
              e.rowElement.style.backgroundColor = "rgba(255, 0, 0, 0.2)";
              e.rowElement.className = e.rowElement.className.replace(
                "dx-row-alt",
                ""
              );
            }
            if (e.data.isOk === true) {
              e.rowElement.style.backgroundColor = "rgba(61, 193, 78, 0.47)";
              e.rowElement.className = e.rowElement.className.replace(
                "dx-row-alt",
                ""
              );
            }
          }
        }}
      >
        <Column dataField="artikl" caption="Artikl" />
        <Column dataField="greska" caption="Greška" />
      </DataGrid> */}
    </>
  );
};

export default PoklonOsiguranje;
