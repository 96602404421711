import { useCallback, useReducer, useState } from "react";
import FileInput from "../FileInput/FileInput";
import { loadImageAsync, SlikaInfo } from "../../utils/utils";
import formaZaUnosSlikaReducer from "./formaZaSlikaReducer";
import classes from "./FormaZaUnosSlika.module.css";
import CircularProgressWithLabel from "../CircularStaticProgress/CircularStaticProgress";
import Slika from "../Slika/Slika";
import { forme } from "./FormaZaUnosSlikaTypes";
import Select from "./Select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import Backdrop from "@mui/material/Backdrop";
import SpremljeniDokumenti from "../SpremljeniDokumenti/SpremljeniDokumenti";
import Dialog from "@mui/material/Dialog";
import { DialogContent } from "@mui/material";
import Button from "@mui/material/Button";
import { Tooltip } from "@mui/material";
import Alert from "@mui/material/Alert";
import useApi from "../Hooks/useApi";

type FormaZaSlikeModel = {
  slikeZaSpremanje: SlikaInfo[];
  spremljeneSlike: { file: File; success: boolean; errorMessage: string }[];
  progress: number;
  isSaving: boolean;
};

type FormaZaSlikeProps = {
  oznaka: string;
};

type AlertModel = {
  open: boolean;
  message: string;
};

type AlertSlikeModel = {
  open: boolean;
  content: NespremljeneSlikeAlert[];
};

type NespremljeneSlikeAlert = {
  naziv: string;
  velicina: string;
  tooltip: string;
};

const FormaZaUnosSlika = ({ oznaka }: FormaZaSlikeProps) => {
  const apiCall = useApi();

  const [model, dispatcher] = useReducer(formaZaUnosSlikaReducer, {
    slikeZaSpremanje: [],
    spremljeneSlike: [],
    progress: 0,
    isSaving: false,
  });

  const [alert, setOpenAlert] = useState<AlertModel>({
    open: false,
    message: "",
  });
  const [alertSlike, setOpenAlertSlike] = useState<AlertSlikeModel>({
    open: false,
    content: [],
  });

  const ucitajSliku = (event: React.SyntheticEvent) => {
    if (!(event.target instanceof HTMLInputElement)) return;
    if (event.target.files) {
      const input = event.target.getAttribute("data-input");
      provjeraDatoteke(event.target.files, input);
    }
  };

  const provjeraDatoteke = async (
    files: FileList,
    inputLabel: string | null = ""
  ) => {
    const slike = [];
    const inputSetup = forme
      .find((forma) => forma.oznaka === oznaka)
      ?.inputi.find((input) => input.label === inputLabel);
    const neucitaneSlikeAlert: NespremljeneSlikeAlert[] = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileSizeKB = Math.round(file.size / 1024);
      const naziv = file.name;
      const ekstenzija = naziv.replace(/^.*\./, "").toLowerCase();
      const fileLoaded = model.slikeZaSpremanje.find((slika) => {
        return slika.name === file.name;
      });
      if (fileLoaded) {
        neucitaneSlikeAlert.push({
          naziv: file.name,
          velicina: `${fileSizeKB.toFixed(2)} KB`,
          tooltip: "Slika sa istim imenom je već dodana.",
        });
        continue;
      }
      if (
        inputSetup?.extensions.length === 0 ||
        inputSetup?.extensions.includes(ekstenzija)
      ) {
        const slikaInfo = await loadImageAsync(file, inputLabel);
        if (
          inputSetup.exactWidth &&
          inputSetup.exactWidth !== slikaInfo.width
        ) {
          neucitaneSlikeAlert.push({
            naziv: file.name,
            velicina: `${fileSizeKB.toFixed(2)} KB`,
            tooltip: "Širina slike nije u ispravnoj veličini.",
          });
          continue;
        }
        if (
          inputSetup.exactHeight &&
          inputSetup.exactHeight !== slikaInfo.height
        ) {
          neucitaneSlikeAlert.push({
            naziv: file.name,
            velicina: `${fileSizeKB.toFixed(2)} KB`,
            tooltip: "Visina slike nije u ispravnoj veličini.",
          });
          continue;
        }
        if (
          inputSetup.minHeight &&
          slikaInfo.height < inputSetup.minHeight &&
          !inputSetup.exactHeight &&
          !inputSetup.exactWidth
        ) {
          neucitaneSlikeAlert.push({
            naziv: file.name,
            velicina: `${fileSizeKB.toFixed(2)} KB`,
            tooltip: "Slika nije u ispravnoj veličini.",
          });
          continue;
        }
        if (inputSetup.maxsizeKB && fileSizeKB > inputSetup.maxsizeKB) {
          neucitaneSlikeAlert.push({
            naziv: file.name,
            velicina: `${fileSizeKB.toFixed(2)} KB`,
            tooltip: `Slika je prevelika. ${fileSizeKB} KB`,
          });
          continue;
        }
        slike.push(slikaInfo);
        dispatcher({ action: "SLIKA_UCITANA", value: slikaInfo });
      } else {
        neucitaneSlikeAlert.push({
          naziv: file.name,
          velicina: `${fileSizeKB.toFixed(2)} KB`,
          tooltip: `${ekstenzija} nije podržana.`,
        });
      }
    }
    if (neucitaneSlikeAlert.length > 0) {
      setOpenAlertSlike({ open: true, content: neucitaneSlikeAlert });
    }
    // dispatcher({ action: "SLIKE_UCITANE", value: slike });
  };

  const spremiSlikeHandler = useCallback(
    (accessToken: string) => {
      (async () => {
        if (model.slikeZaSpremanje.length === 0) {
          setOpenAlert({
            open: true,
            message: "Morate učitati bar jedan dokument.",
          });
          return;
        }
        dispatcher({ action: "POST_STARTED", value: true });
        const spremljeneSlike: {
          file: File;
          success: boolean;
          errorMessage: string;
        }[] = [];
        const progressSegment = 100 / model.slikeZaSpremanje.length;
        const url = forme.find((forma) => forma.oznaka === oznaka)?.url;
        for (let i = 0; i < model.slikeZaSpremanje.length; i++) {
          const form = new FormData();
          const slikaInfo = model.slikeZaSpremanje[i];
          form.append("slika", slikaInfo.file);
          let success: boolean = false;
          let errorMessage: string = "";
          try {
            const response = await fetch(url ?? "", {
              method: "POST",
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
              body: form,
            });
            const data = await response.json();
            if (!response.ok) {
              errorMessage = data.error;
              spremljeneSlike.push({
                file: slikaInfo.file,
                success: false,
                errorMessage: errorMessage,
              });
            } else {
              success = true;
              spremljeneSlike.push({
                file: slikaInfo.file,
                success: true,
                errorMessage: errorMessage,
              });
            }
          } catch (error) {
            errorMessage = "Neuspješno spremanje slike.";
            spremljeneSlike.push({
              file: slikaInfo.file,
              success: false,
              errorMessage: errorMessage,
            });
          } finally {
            dispatcher({
              action: "PROGRESS_UPDATE",
              value: {
                progress: progressSegment,
                slika: {
                  file: slikaInfo.file,
                  success: success,
                  errorMessage: errorMessage,
                },
              },
            });
          }
        }
        dispatcher({ action: "SLIKE_SPREMLJENE", value: spremljeneSlike });
      })();
    },
    [model.slikeZaSpremanje, oznaka]
  );

  const odbaciSliku = (nazivSlike: string) => {
    const slika = model.slikeZaSpremanje.find(
      (slika) => slika.name === nazivSlike
    );
    if (slika) {
      dispatcher({ action: "SLIKA_ODBACENA", value: slika });
    }
  };

  // const openAlertHandler = (message: string) => {
  //     setOpenAlert({ open: true, message: message });
  // }

  const closeAlertHandler = () => {
    setOpenAlert({ open: false, message: "" });
  };

  const closeAlertSlikeHandler = () => {
    setOpenAlertSlike({ open: false, content: [] });
  };

  return (
    <div className={classes.container}>
      <h4
        className="p-4 bb-1"
        style={{
          textTransform: "uppercase",
          textAlign: "left",
          marginBottom: "1em",
        }}
      >
        {oznaka === "unosslika"
          ? "Unos slika"
          : oznaka === "energetskenaljepnice"
          ? "Unos energetskih naljepnica"
          : oznaka === "logo"
          ? "Unos logotipa"
          : "Unos informacijskih listova"}
      </h4>
      <div className={classes.inputContainer}>
        {forme
          .find((forma) => forma.oznaka === oznaka)
          ?.inputi.map((input, index) => {
            const slika = model.slikeZaSpremanje.find(
              (slika) => slika.inputLabel === input.label && !input.multiple
            );
            return (
              <div key={index} className={classes.gridContainer}>
                <div className={classes.labelContainer}>
                  <label>{input.label}</label>
                </div>
                <FileInput
                  key={input.label}
                  label={input.label}
                  multiple={input.multiple}
                  extension={input.extensionInput}
                  ucitajSliku={ucitajSliku}
                />
                {slika ? (
                  <label className={classes.labelSingle} key={slika.name}>
                    {slika.name}
                  </label>
                ) : null}
              </div>
            );
          })}
      </div>
      <div className={`${classes.uvjetiContainer} bb-1`}>
        {forme
          .find((forma) => forma.oznaka === oznaka)
          ?.uvjeti.map((uvjet, index) => {
            return (
              <div key={index} className={`${classes.gridContainer}`}>
                <div className={classes.labelContainer}></div>
                <div className={classes.uvjetContainer}>{uvjet}</div>
              </div>
            );
          })}
      </div>
      <div
        className={`${classes.dokumentiContainer} ${classes.gridContainer} mt-4 bb-1`}
      >
        <div className={classes.labelContainer}>
          <label>Učitani dokumenti</label>
        </div>
        {model.spremljeneSlike.length > 0 ? (
          <SpremljeniDokumenti dokumenti={model.spremljeneSlike} />
        ) : (
          <div
            className={`
              ${
                oznaka === "unosinformacijskihlistova"
                  ? ""
                  : classes.containerSlike
              }
            ${model.slikeZaSpremanje.length > 0 ? "" : "p-0"}
          `}
          >
            {model.slikeZaSpremanje.length > 0 ? (
              model.slikeZaSpremanje.map((slika) => {
                const slikaSetup = forme
                  .find((forma) => forma.oznaka === oznaka)
                  ?.inputi.find((input) => input.label === slika.inputLabel);
                return slikaSetup?.showImage ? (
                  <Slika
                    key={slika.name}
                    name={slika.name}
                    src={slika.src}
                    size={slika.size}
                    odbaciSlikuHandler={odbaciSliku}
                  />
                ) : (
                  <div key={slika.name} className={classes.listItem}>
                    <label>{slika.name} </label>
                    <label className={classes.red}>
                      <strong>{slika.size.toFixed(2)} KB</strong>
                    </label>
                  </div>
                );
              })
            ) : (
              <label className={classes.upozorenje}>
                Nema učitanih dokumenata
              </label>
            )}
          </div>
        )}
      </div>
      {oznaka === "slikebannera" ? <Select /> : null}
      <div className={classes.saveButtonContainer}>
        <button
          type="button"
          className={classes.saveButton}
          onClick={() => apiCall(spremiSlikeHandler)}
        >
          <FontAwesomeIcon className={classes.saveIcon} icon={faCheck} />
          Spremi
        </button>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={model.isSaving}
        onClick={() => false}
      >
        <CircularProgressWithLabel
          className={classes.circularProgress}
          value={model.progress}
          size={150}
          color="primary"
        />
      </Backdrop>
      <Dialog onClose={closeAlertHandler} open={alert.open}>
        <DialogContent className={classes.dialog}>
          <label>{alert.message}</label>
          <div style={{ textAlign: "end" }}>
            <Button onClick={closeAlertHandler}>Zatvori</Button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog onClose={() => false} open={alertSlike.open}>
        {/* <DialogTitle className={classes.dialogSlikeTitle}>
                    
                </DialogTitle> */}
        <Alert className={classes.alertSlikeTitle} severity="warning">
          Slijedeći dokumenti nisu učitani:
        </Alert>
        <DialogContent className={classes.dialogSlike}>
          {alertSlike.content.map((slika) => {
            return (
              <Tooltip key={slika.naziv} title={slika.tooltip}>
                <div className={classes.alertSlikeItem}>
                  <div>
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      style={{ color: "#284178", marginRight: "0.5rem" }}
                    />
                    <label>{slika.naziv}</label>
                  </div>
                  <label>{slika.velicina}</label>
                </div>
              </Tooltip>
            );
          })}
          <div style={{ textAlign: "end" }}>
            <Button onClick={closeAlertSlikeHandler}>Zatvori</Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default FormaZaUnosSlika;
export type { FormaZaSlikeModel };
