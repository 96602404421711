import {
  HtmlEditor,
  Item,
  MediaResizing,
  Toolbar,
} from "devextreme-react/html-editor";

type BodyEditorProps = {
  html: string;
  setHtml: (value: string) => void;
};

const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
const fontValues = ["Arial", "Georgia", "Tahoma", "Times New Roman", "Verdana"];
const headerValues = [false, 1, 2, 3, 4, 5];

const BodyEditor = (props: BodyEditorProps) => {
  return (
    <>
      <HtmlEditor
        valueType="html"
        value={props.html}
        onValueChanged={(e) => {
          props.setHtml(e.value);
        }}
        style={{
          backgroundColor: "white",
          height: "90%",
        }}
      >
        <Toolbar multiline={true}>
          <Item name="undo" />
          <Item name="redo" />
          <Item name="separator" />
          <Item name="size" acceptedValues={sizeValues} />
          <Item name="font" acceptedValues={fontValues} />
          <Item name="separator" />
          <Item name="bold" />
          <Item name="italic" />
          <Item name="strike" />
          <Item name="underline" />
          <Item name="separator" />
          <Item name="alignLeft" />
          <Item name="alignCenter" />
          <Item name="alignRight" />
          <Item name="alignJustify" />
          <Item name="separator" />
          <Item name="orderedList" />
          <Item name="bulletList" />
          <Item name="separator" />
          <Item name="header" acceptedValues={headerValues} />
          <Item name="separator" />
          <Item name="color" />
          <Item name="background" />
          <Item name="separator" />
          <Item name="link" />
          <Item name="image" />
          <Item name="separator" />
          <Item name="clear" />
          <Item name="codeBlock" />
          <Item name="blockquote" />
          <Item name="separator" />
        </Toolbar>
        <MediaResizing enabled={true} />
      </HtmlEditor>
    </>
  );
};

export default BodyEditor;
