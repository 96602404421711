import TextField from "@mui/material/TextField";
import HrLocale from "date-fns/locale/hr";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

type DatePickerProps = {
  value: string | null;
  handleChange: (value: string | null) => void;
  label: string;
  marginBottom: string;
  obavezno: boolean;
  error: boolean;
  disabled: boolean;
  maxDate: string | undefined;
  minDate: string | undefined;
};

const DatePicker = (props: DatePickerProps) => {
  return (
    <div
      style={{
        marginBottom: props.marginBottom,
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={HrLocale}>
        <MobileDatePicker
          label={props.label}
          inputFormat="yyyy-MM-dd"
          value={props.value || null}
          onChange={(newValue: string | null) => props.handleChange(newValue)}
          renderInput={(params: any) => (
            <TextField {...params} fullWidth size="small" />
          )}
          clearable
          InputProps={{ style: { backgroundColor: "white" } }}
          ToolbarComponent={() => <div />}
          showToolbar={false}
          clearText="Poništi"
          cancelText="Odustani"
          disabled={props.disabled}
          disableCloseOnSelect={false}
          maxDate={props.maxDate}
          minDate={props.minDate}
        />
      </LocalizationProvider>
      {props.obavezno && !props.error ? (
        <label className="lightBlue small-text">Obavezno polje *</label>
      ) : (
        ""
      )}
    </div>
  );
};

export default DatePicker;
