import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createStore } from "devextreme-aspnet-data-nojquery";
import useApiPromise from "../../Hooks/useApiPromise";
import {
  DataGrid,
  Column,
  Selection,
  Editing,
  FilterRow,
  Toolbar,
  Item,
} from "devextreme-react/data-grid";
import { Pager, Paging } from "devextreme-react/tree-list";
import { Button as ButtonMui } from "@mui/material";
import { PlusSquare, ListUl } from "react-bootstrap-icons";
import Swal from "sweetalert2";
import useApi from "../../Hooks/useApi";
import AddModal from "./AddModal";
import PromjenaRedoslijeModal from "./PromjenaRedoslijeda";
import classes from "./Navbar.module.css";
import EditModal from "./EditModal";
import { NavbarKlas as NavbarKlasType } from "./NavbarKlasTypes";
import BackDrop from "../../UnosAkcijePrilike/BackDrop";

const NavbarKlas = () => {
  const dataGrid = useRef<DataGrid>(null);
  const allowedPageSizes = [20, 50, 100];

  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [promjenaRedoslijeModalOpen, setPromjenaRedoslijeModalOpen] =
    useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [navbarId, setNavbarId] = useState<number>(-1);
  const apiCall = useApi();
  const [rowData, setRowData] = useState<NavbarKlasType>();

  const apiCallPromise = useApiPromise();
  const accessTokenHandler = useCallback((accessToken: string) => {
    return (async () => {
      return accessToken;
    })();
  }, []);

  const dataSource = useMemo(() => {
    return createStore({
      key: "id",
      loadUrl: `api/Navbar/GetNavbars`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
        return apiCallPromise(accessTokenHandler).then((token: any) => {
          return (ajaxOptions.headers = {
            Authorization: `Bearer ${token}`,
          });
        });
      },
    });
  }, [accessTokenHandler, apiCallPromise]);

  const deleteNavbar = useCallback(
    (accessToken: string) => {
      let formData = new FormData();
      formData.append("id", navbarId.toString());
      if (navbarId !== -1) {
        setIsLoading(true);
        fetch("api/Navbar/DeleteNavbar", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          method: "post",
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.responseData) {
              setIsLoading(false);
              Swal.fire({
                icon: "success",
                title: "Obrisano",
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              });
            } else {
              setIsLoading(false);
              Swal.fire({
                title: data.error,
                showConfirmButton: false,
                toast: true,
                customClass: {
                  container: classes.swalContainer,
                  popup: classes.swal2IconError,
                },
                timer: 1500,
                iconColor: "white",
                timerProgressBar: true,
                icon: "warning",
                position: "top-right",
              });
            }
          });
      }
    },
    [navbarId]
  );

  useEffect(() => {
    apiCall(deleteNavbar);
  }, [apiCall, deleteNavbar]);

  return (
    <>
      <div>
        <DataGrid
          id="dataGrid"
          ref={dataGrid}
          dataSource={dataSource}
          rowAlternationEnabled
          allowColumnReordering
          allowColumnResizing
          showBorders
          onEditingStart={(e) => {
            e.cancel = true;
            setRowData(e.data);
            setEditModalOpen(true);
          }}
          remoteOperations={true}
          onRowRemoving={(e: any) => {
            setNavbarId(e.data.id);
            e.cancel = true;
          }}
        >
          <FilterRow visible={true} />
          <Paging defaultPageSize={20} />
          <Editing mode="row" allowUpdating={true} allowDeleting />
          <Pager
            visible={true}
            showPageSizeSelector={true}
            showInfo={true}
            allowedPageSizes={allowedPageSizes}
            infoText={`Stranica {0} od {1} ({2} artikala)`}
          />
          <Selection mode="single" />
          <Column
            allowFiltering={false}
            dataField="klasifikacija"
            caption="Klasifikacija"
          />
          <Column allowEditing={true} dataField="level1" caption="Level1" />
          <Column allowEditing={true} dataField="level2" caption="Level2" />
          <Column allowEditing={true} dataField="type" caption="Type" />
          <Column
            allowEditing={true}
            dataField="linkText"
            caption="Link text"
          />
          <Column
            allowEditing={true}
            dataField="linkTitle"
            caption="Link title"
          />
          <Column
            allowEditing={true}
            dataField="redoslijed"
            caption="Redoslijed"
            defaultSortIndex={0}
            defaultSortOrder='asc'
          />
          <Toolbar>
            <Item location="before">
              <ButtonMui
                onClick={() => {
                  setPromjenaRedoslijeModalOpen(true);
                }}
                variant="contained"
              >
                <ListUl size={25} />
              </ButtonMui>
            </Item>
            <Item location="after">
              <ButtonMui
                onClick={() => {
                  setAddModalOpen(true);
                }}
                variant="contained"
              >
                <PlusSquare size={25} />
              </ButtonMui>
            </Item>
          </Toolbar>
        </DataGrid>
      </div>
      {addModalOpen && (
        <AddModal open={addModalOpen} setOpen={setAddModalOpen} />
      )}
      {editModalOpen && (
        <EditModal
          open={editModalOpen}
          setOpen={setEditModalOpen}
          data={rowData}
        />
      )}
      {promjenaRedoslijeModalOpen && (
        <PromjenaRedoslijeModal
          open={promjenaRedoslijeModalOpen}
          setOpen={setPromjenaRedoslijeModalOpen}
        />
      )}
      {isLoading && <BackDrop />}
    </>
  );
};

export default NavbarKlas;
