import {
  Column,
  DataGrid,
  Paging,
  Summary,
  TotalItem,
  LoadPanel,
  SearchPanel,
} from "devextreme-react/data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartArrowDown } from "@fortawesome/free-solid-svg-icons";
import { ArtikliNaAkciji } from "./AkcijaTypes";
import classes from "./ArtikliTab.module.css";

type ArtikliTabProps = {
  artikli: ArtikliNaAkciji[];
};

const ArtikliTab = (props: ArtikliTabProps) => {
  return (
    <div className={`${classes.container} text-center`}>
      <div>
        <FontAwesomeIcon icon={faCartArrowDown} className="tabsIcon" />
      </div>
      <DataGrid
        dataSource={props.artikli}
        keyExpr="sifraArtikla"
        showBorders={true}
        className="padd-0"
        id="artikliGrid"
        allowColumnResizing
      >
        <SearchPanel visible={true} placeholder="Pretraži..." />
        <LoadPanel enabled={true} />
        <Paging defaultPageSize={10} />
        <Column
          dataField="sifraArtikla"
          caption="Šifra artikla"
          dataType="string"
          alignment="right"
        />
        <Column dataField="naziv" dataType="string" alignment="right" />
        <Column
          dataField="kataloskiBroj"
          caption="Kataloški broj"
          dataType="string"
          alignment="right"
        />
        <Column dataField="cijena" dataType="string" alignment="right" />
        <Column dataField="support" caption="Support" alignment="right" />
        <Column dataField="dodatniRabatKomisija" caption="Dodatni rabat komisija" alignment="right" />
        <Column dataField="akcijskaNettoNabavnaCijenaKomisija" caption="Akcijska netto nabavna cijena komisija" alignment="right" />
        <Column
          dataField="besplatnaDostava"
          dataType="boolean"
          alignment="center"
        />
        <Column dataField="nazivOsiguranja" caption="Naziv osiguranja" />
        <Column dataField="sifraOsiguranja" caption="Šifra osiguranja" />
        <Column dataField="referentniBroj" caption="Referentni broj" />
        <Summary>
          <TotalItem column="sifraArtikla" summaryType="count" />
        </Summary>
      </DataGrid>
    </div>
  );
};

export default ArtikliTab;
