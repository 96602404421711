import { useRef, useMemo, useCallback, useEffect, useState } from 'react'
import {
    DataGrid,
    Column,
    Selection,
    Paging,
    FilterRow,
    Toolbar,
    Item,
    MasterDetail
  } from "devextreme-react/data-grid";
  import { SearchPanel } from "devextreme-react/tree-list";
  import { createStore } from 'devextreme-aspnet-data-nojquery';
  import "devextreme/dist/css/dx.light.css";
  import useApiPromise from "../Hooks/useApiPromise";
  import {Map} from './Map';
  import classes from './Poslovnice.module.css';  
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import { faLocationDot, faPhone, faEnvelope, faClock} from '@fortawesome/free-solid-svg-icons';
  import AddEditPoslovnica from './AddEditPoslovnica';
  import { PlusSquare } from "react-bootstrap-icons";
  import { Button, Dialog, DialogContent } from "@mui/material";  
  import { useLoadScript } from '@react-google-maps/api';
  import { DetaljiModel, DetaljiModelDefault, parseRadnoVrijeme } from './PoslovniceTypes';
  import { AlertModel } from "../UnosAkcijePrilike/UnosAkcijePrilikeTypes";
  import BackDrop from '../UnosAkcijePrilike/BackDrop';
  import AkcijeMenu from './AkcijeMenu';
  import Swal from 'sweetalert2';
import NeradneNedelje from './NeradneNedelje';

const apiKey = "AIzaSyDM5I4-gevjV55fcwKHyOzd3-3S_R8QBB8";

const Poslovnice = () => {
    
    const [addEditModalOpen, setAddEditModalOpen] = useState<boolean>(false);
    const [poslovnicaID, setPoslovnicaId] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dialog, setOpen] = useState<AlertModel>({ open: false, message: "" });
    const [neradneNedeljeModalOpen, setNeradneNedeljeModalOpen] = useState<boolean>(false);

    const dataGrid = useRef<DataGrid>(null);
    const apiCall = useApiPromise();
    const { isLoaded } = useLoadScript({
      googleMapsApiKey: apiKey,
      libraries: ['places']
  })

    const accessTokenHandler = useCallback((accessToken: string) => {
        return (async () => {
          return accessToken;
        })()
      }, [])

    const dataSource = useMemo(() => {
        return createStore({
          key: 'sifra',
          loadUrl: `/api/Poslovnica/GetPoslovnica`,
          onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: true };
            return apiCall(accessTokenHandler).then(token => {
              return ajaxOptions.headers = {
                "Authorization": `Bearer ${token}`
              }
            })
          },
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

      const handleSpremiPoslovnicu = (poslovnica: any) => {
        setIsLoading(true);
        let url = '/api/Poslovnica/NewPoslovnica'
        if(poslovnica.id > 0) url = '/api/Poslovnica/EditPoslovnica';
        return apiCall(accessTokenHandler).then(token => {
          return fetch(url, {
            method: "POST",
            headers: {
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${token}`
                },
            body: JSON.stringify(poslovnica)
          })
          .then(response => {
            setIsLoading(false);
            let poruka = "";
            if(response.ok) {
              poruka = "Posebna osobina uspješno spremljena";
              setAddEditModalOpen(false);
              dataGrid?.current?.instance.refresh();
            } else {
              poruka = "Došlo je do greške kod spremanja";
            }
            setOpen({open: true, message: poruka});
          })                  
          .catch(error => {
            setIsLoading(false);
            setOpen({open: true, message: "Došlo je do greške kod spremanja"});
          })
        })
        
      }

      function obrisiPoslovnicu() {
        Swal.fire({
          title: 'Dali ste sigurni?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Da!',
          cancelButtonText: 'Odustani'
        }).then((result) => {
          if (result.isConfirmed) { 
            setIsLoading(true);
            return apiCall(accessTokenHandler).then(token => {
              let formData = new FormData();
              formData.append("id", poslovnicaID.toString());
              return fetch("api/Poslovnica/DeletePoslovnica", {
                method: "POST",
                headers: {                      
                      "Authorization": `Bearer ${token}`
                    },
                body: formData
              })
              .then(response => {
                let poruka = "";
                if(response.ok) {
                  poruka = "Poslovnica uspješno obrisana";
                  dataGrid?.current?.instance.refresh();
                } else {
                  poruka = "Došlo je do greške kod brisanja";
                }
                setOpen({open: true, message: poruka});
              })                  
              .catch(error => {
                // setIsLoading(false);
                setOpen({open: true, message: "Došlo je do greške kod brisanja"});
              }).finally(() => setIsLoading(false));
            })          
          }
        })
      }

      const handleDialogClose = () => {
        setOpen({ open: false, message: "" });
      };

      const akcijeRender = (data: any) => {
        let id = data.data.sifra;
        // setRowData(data); 
        return (   
          <AkcijeMenu setNeradneNedeljeModalOpen={setNeradneNedeljeModalOpen} obrisiPoslovnicu={obrisiPoslovnicu} poslovnicaId={id} setEditOpen={setAddEditModalOpen} setPoslovnicaId={setPoslovnicaId} />      
        )
      };      

    return <>
        <DataGrid             
            ref={dataGrid}
            id="dataGrid"
            keyExpr="Sifra"
            key="Sifra"
            dataSource={dataSource}
            rowAlternationEnabled
            allowColumnReordering
            allowColumnResizing
            showBorders
            remoteOperations={false}>
        <MasterDetail enabled={true} component={Detalji} />
        <Paging defaultPageSize={20} />
        <FilterRow visible={true} />
        <SearchPanel visible={true} />
        <Selection mode="single" />
        <Column dataField="mjesto" caption="Mjesto" width={150} />                  
        <Column dataField="adresa" caption="Adresa" />
        <Column dataField="brojTelefona" caption="Telefon" />   
        <Column
          allowReordering={false}
          allowSorting={false}
          cellRender={akcijeRender}
          width={180}
        />     
        <Toolbar>
          <Item location="after">
            <Button
              onClick={() => {
                setPoslovnicaId(0);
                setAddEditModalOpen(true);
              }}
              variant="contained"
            >
              <PlusSquare size={25} />
            </Button>
          </Item>
        </Toolbar>
        </DataGrid>
        <Dialog onClose={handleDialogClose} open={dialog.open}>
        <DialogContent className={classes.dialog}>
          <label>{dialog.message}</label>
          <div style={{ textAlign: "end" }}>
            <Button onClick={handleDialogClose}>Zatvori</Button>
          </div>
        </DialogContent>
      </Dialog>
        {addEditModalOpen && <AddEditPoslovnica savePoslovnicu={handleSpremiPoslovnicu} 
        setOpen={setAddEditModalOpen} 
        poslovnica={dataGrid.current?.instance.getDataSource().items().find(x => x.sifra === poslovnicaID)} />}
        {isLoading && <BackDrop />}
        {neradneNedeljeModalOpen && <NeradneNedelje setNeradneNedeljeModalOpen={setNeradneNedeljeModalOpen} poslovnicaId={poslovnicaID}/>}
    </>
}

const Detalji = (props: any, isLoaded:boolean) => {     
    const [detalji, setDetalji] = useState<DetaljiModel>(DetaljiModelDefault);
    useEffect(() => {         
        setDetalji({
          adresa: props.data.data.adresa,
          brojTelefona: props.data.data.brojTelefona,
          email: props.data.data.email,
          homePage: props.data.data.homePage,
          latitude: parseFloat(props.data.data.latitude),
          longitude: parseFloat(props.data.data.longitude),
          mjesto: props.data.data.mjesto,
          poslovnicaRadnoVrijeme: {
            "1": parseRadnoVrijeme(props.data.data.poslovnicaRadnoVrijeme["1"]),
            "2": parseRadnoVrijeme(props.data.data.poslovnicaRadnoVrijeme["2"]),
            "3": parseRadnoVrijeme(props.data.data.poslovnicaRadnoVrijeme["3"]),
            "4": parseRadnoVrijeme(props.data.data.poslovnicaRadnoVrijeme["4"]),
            "5": parseRadnoVrijeme(props.data.data.poslovnicaRadnoVrijeme["5"]),
            "6": parseRadnoVrijeme(props.data.data.poslovnicaRadnoVrijeme["6"]),
            "7": parseRadnoVrijeme(props.data.data.poslovnicaRadnoVrijeme["7"])
          }
        })
    }, [props.data])
    return <>
            <div className={classes.detaljiContainer}>
                <div>
                   <Map lat={detalji.latitude} lng={detalji.longitude} />
                </div>
                <div>
                    <div className={classes.iconContainer}>
                        <FontAwesomeIcon className={classes.icon} icon={faLocationDot} />
                        <p>{detalji.adresa}</p>
                        <p>{detalji.mjesto}</p>
                        <a href={detalji.homePage}  target="_blank" rel="noreferrer">{detalji.homePage}</a>
                    </div>
                    <div className={classes.iconContainer}>
                        <FontAwesomeIcon className={classes.icon} icon={faPhone} />
                        <p>{detalji.brojTelefona}</p>
                    </div>
                    <div className={classes.iconContainer}>
                        <FontAwesomeIcon className={classes.icon} icon={faEnvelope} />
                        <p><a href={`mailto:${detalji.email}`}  target="_blank" rel="noreferrer">{detalji.email}</a></p>
                    </div>
                </div>
                <div>
                    <div className={classes.iconContainer}>
                        <FontAwesomeIcon className={classes.icon} icon={faClock} />
                        {/* <p><label>Radno vrijeme:</label></p> */}
                        <p><label>Ponedjeljak:</label><span>{detalji.poslovnicaRadnoVrijeme["2"]}</span></p>
                        <p><label>Utorak:</label><span>{detalji.poslovnicaRadnoVrijeme["3"]}</span></p>
                        <p><label>Srijeda:</label><span>{detalji.poslovnicaRadnoVrijeme["4"]}</span></p>
                        <p><label>Četvrtak:</label><span>{detalji.poslovnicaRadnoVrijeme["5"]}</span></p>
                        <p><label>Petak:</label><span>{detalji.poslovnicaRadnoVrijeme["6"]}</span></p>
                        <p><label>Subota: </label><span>{detalji.poslovnicaRadnoVrijeme["7"]}</span></p>
                        <p><label>Nedjelja: </label><span>{detalji.poslovnicaRadnoVrijeme["1"]}</span></p>
                    </div>
                </div>
            </div>            
        </>
}

export default Poslovnice;