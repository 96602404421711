import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { useCallback } from 'react'

// type useApiProps = {url:RequestInfo, options: RequestInit | undefined}

const useApiPromise = () : (callback : (accessToken:string) => Promise<any>) => Promise<any> => {
    // const { instance, accounts, inProgress } = useMsal();
    const { instance, accounts } = useMsal();

    // const [accessToken, setAccessToken] = useState<string | null>(null);

    // const name = accounts[0] && accounts[0].name;

    const requestAccessToken = useCallback((callback : (accessToken:string) => Promise<any>) => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        // Silently acquires an access token which is then attached to a request for Microsoft Graph data

        return instance.acquireTokenSilent(request).then((response) => {
            return callback(response.accessToken);
        }).catch((e) => {
           return instance.acquireTokenPopup(request).then((response) => {
            return callback(response.accessToken);
            });
        });
    },[accounts, instance]);
   
    return requestAccessToken; 
};

export default useApiPromise;