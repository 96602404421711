import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import useApi from "../../Hooks/useApi";
import Swal from "sweetalert2";
import classes from "../HeaderFooter.module.css";
import BackDrop from "../../UnosAkcijePrilike/BackDrop";
import { AlertModel } from "../../UnosAkcijePrilike/UnosAkcijePrilikeTypes";
import { Dialog, DialogContent, FormControl, Select } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ColorPicker from "./ColorPicker";
import { Boja } from "./BojeTypes";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
};

type EditModalProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  data: Boja;
};

const EditModal = (props: EditModalProps) => {
  const apiCall = useApi();
  const [naziv, setNaziv] = useState<string>(props.data.naziv);
  const [hex, setHex] = useState<string>(props.data.hex);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dialog, setOpen] = useState<AlertModel>({ open: false, message: "" });
  const [showSpremi, setShowSpremi] = useState<boolean>(false);
  const [boje, setBoje] = useState<string[]>([]);
  const [postojeceBoje, setPostojeceBoje] = useState<string[]>([]);
  let bojeCancel = false;
  let postojeceCancel = false;
  const handleDialogClose = () => {
    setOpen({ open: false, message: "" });
  };
  const handleClose = () => {
    setNaziv("");
    setHex("");
    props.setOpen(false);
  };

  const post = (accessToken: string) => {
    setIsLoading(true);
    let formData = new FormData();
    formData.append("naziv", naziv);
    formData.append("hex", hex);
    formData.append("id", props.data.id.toString());
    fetch("api/Boja/EditBoja", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      method: "post",
      body: formData,
    }).then((response) => {
      if (response.status === 200) {
        setIsLoading(false);
        Swal.fire({
          icon: "success",
          title: "Spremljeno",
          customClass: {
            container: classes.swalContainer,
          },
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      } else {
        setIsLoading(false);
        Swal.fire({
          title: response.statusText,
          showConfirmButton: false,
          toast: true,
          customClass: {
            container: classes.swalContainer,
            popup: classes.swal2IconError,
          },
          timer: 1500,
          iconColor: "white",
          timerProgressBar: true,
          icon: "warning",
          position: "top-right",
        });
      }
    });
  };

  const apiCallPost = () => {
    apiCall(post);
  };

  useEffect(() => {
    if (naziv === "" || hex === "") {
      setShowSpremi(false);
    } else {
      setShowSpremi(true);
    }
  }, [hex, naziv]);

  const getBoje = (accessToken: string) => {
    fetch("/api/MasterData/GetBoje", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (bojeCancel) return;
        setBoje(data.responseData);
      });
  };

  useEffect(() => {
    apiCall(getBoje);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      bojeCancel = true;
    };
  }, [apiCall]);

  const getPostojeceBoje = (accessToken: string) => {
    fetch("/api/Boja/GetExistingBojas", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (postojeceCancel) return;
        setPostojeceBoje(data.responseData);
      });
  };

  useEffect(() => {
    apiCall(getPostojeceBoje);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      postojeceCancel = true;
    };
  }, [apiCall]);

  return (
    <>
      <div>
        <Modal
          open={props.open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <FormControl fullWidth style={{ marginBottom: "10px" }}>
              <InputLabel id="demo-simple-select-label">Boja</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={naziv}
                label="Boja"
                onChange={(e) => {
                  setNaziv(e.target.value);
                }}
                fullWidth
              >
                <MenuItem key={naziv} value={naziv}>
                  {naziv}
                </MenuItem>
                {boje
                  .filter(
                    (item) =>
                      postojeceBoje.findIndex((value) => value === item) < 0
                  )
                  .map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <ColorPicker hex={hex} setHex={setHex} />
            {showSpremi && (
              <Button
                style={{ backgroundColor: "rgb(26, 179, 148)" }}
                variant="contained"
                onClick={() => {
                  apiCallPost();
                }}
              >
                Spremi
              </Button>
            )}
          </Box>
        </Modal>
      </div>
      {dialog.open && (
        <Dialog onClose={handleDialogClose} open={dialog.open}>
          <DialogContent className={classes.dialog}>
            <label>{dialog.message}</label>
            <div style={{ textAlign: "end" }}>
              <Button onClick={handleDialogClose}>Zatvori</Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
      {isLoading && <BackDrop />}
    </>
  );
};

export default EditModal;
