import { PromoPopust } from "./AkcijaTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPercent } from "@fortawesome/free-solid-svg-icons";
import classes from "./PromoPopustTab.module.css";
import {
  Column,
  DataGrid,
  Paging,
  Summary,
  TotalItem,
  LoadPanel,
  SearchPanel,
} from "devextreme-react/data-grid";
import PromoPopustSlika from "./PromoPopustSlika";
import { useState } from "react";
import BannerDetailView from "./BannerDetailView";
import { TabPanel, Item } from "devextreme-react/tab-panel";

type PromoPopustProps = {
  promoPopust: PromoPopust[] | null;
};

const formatPopust = (popust: number | null) => {
  if (popust) {
    return 100 - popust * 100;
  }
  return 0;
};

const PromoPopustTab = ({ promoPopust }: PromoPopustProps) => {
  const [detailViewOpen, setDetailViewOpen] = useState<boolean>(false);
  const renderTabove = (promo: PromoPopust) => {
    return (
      <>
        <div>
          <div className="text-center">
            <label style={{ fontWeight: "bold" }}>Popust:</label>
            <div className="bigGreen">{formatPopust(promo.iznosPopusta)}%</div>
          </div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "60% 40%"
          }}
        >
        {promo && (
          <div className={classes.info}>
            <div>
              <label>Kod popusta:</label>
              <span>{promo.kodPopusta}</span>
            </div>
            <div>
              <label>Način plaćanja:</label>
              <span>{promo.nacinPlacanja}</span>
            </div>
            <div>
              <label>Grupa kartice:</label>
              <span>{promo.grupaKartice}</span>
            </div>
            <div>
              <label>Kartica:</label>
              <span>{promo.kartica}</span>
            </div>
            <div>
              <label>Početak:</label>
              <span>
                {new Date(promo.pocetak ?? "").toLocaleDateString("hr-HR")}
              </span>
            </div>
            <div>
              <label>Kraj:</label>
              <span>
                {new Date(promo.kraj ?? "").toLocaleDateString("hr-HR")}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                height: "auto",
                justifyContent: "flex-start",
                margin: 0
              }}
            >
              <PromoPopustSlika
                slika={promo.bannerUrl}
                setDetailViewOpen={setDetailViewOpen}
              />
              {/* <Button variant="outlined" onClick={() => {downloadImage(promoPopust.bannerUrl)}}>
              Preuzmi
            </Button> */}
            </div>
          </div>
        )}

        {promo && (
          <div>
            <DataGrid
              dataSource={promo.artikli}
              showBorders={true}
              className="padd-0"
            >
              <SearchPanel visible={true} />
              <LoadPanel enabled={true} />
              <Paging defaultPageSize={10} />
              <Column
                dataField="sifraArtikla"
                caption="Artikli za koje vrijedi promo popust"
                dataType="string"
                alignment="right"
              />
              <Summary>
                <TotalItem column="sifraArtikla" summaryType="count" />
              </Summary>
            </DataGrid>
          </div>
        )}
        </div>
        
        {detailViewOpen && (
          <BannerDetailView
            detailViewOpen={detailViewOpen}
            setDetailViewOpen={setDetailViewOpen}
            image={promo?.bannerUrl ?? ""}
            setImage={() => {}}
          />
        )}
      </>
    );
  };

  return (
    <div style={{ display: "flex" }}>
      <div
        className="text-center"
        style={{ marginRight: "2em", marginLeft: "2em" }}
      >
        <FontAwesomeIcon icon={faPercent} className="tabsIcon" />
      </div>
      {promoPopust && promoPopust.length === 0 && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="bigOrange">
            Promo popusti ne postoje za ovu akciju.
          </div>
        </div>
      )}
      {promoPopust && promoPopust?.length > 0 && (
        <div>
          <TabPanel className="tabsContainer">
            {promoPopust?.map((promo: PromoPopust) => {
              return (
                <Item key={promo.kodPopusta} title={promo.kodPopusta??""}>
                  {renderTabove(promo)}
                </Item>
              );
            })}
          </TabPanel>
        </div>
      )}
    </div>
  );
};

export default PromoPopustTab;
