import React, { useMemo } from 'react';
// import styled from 'styled-components';
import { Cell, Column, useSortBy, useTable, useGlobalFilter, usePagination } from "react-table";
import classes from './ZadaciVoditeljTable.module.css'
import GlobalFilter from './GlobalFilter';
import SortIcon from '../SortIcon/SortIcon';

type ZadaciTable = {
  readonly id: number,
  readonly voditelj: string,
  // readonly voditeljID?: number,
  readonly prioritet: number,
  // readonly brandID: number,
  readonly brand: string,
  readonly sifra: number,
  readonly korisnik: string,
  readonly datum: string
}

type ZadaciVoditeljTableProps = {
  zadaci: ZadaciTable[]
}

const ZadaciVoditeljTable = ({ zadaci }: ZadaciVoditeljTableProps) => {
  const data = useMemo<ZadaciTable[]>(() => zadaci, [zadaci]);

  const columns: Column<ZadaciTable>[] = useMemo(() => {
    return Object.keys(data[0]).map((d) => {
      // get cell type from data set
      const cellType = typeof data[0][d as keyof ZadaciTable];
      return {
        Header: () => {
          // set base style for header
          const base = `${classes.capitalize}`;
          // align text depending on whether the data type is number or not
          return (
            <span
              className={
                cellType === "number"
                  ? `${base} text-right`
                  : `${base} text-center`
              }
            >
              {d}
            </span>
          );
        },
        accessor: d as keyof ZadaciTable,
        Cell: (props: Cell<ZadaciTable>) => {
          // set base style for cell
          const base = "py-2 px-2";
          // align text depending on whether the data type is number or not
          return (
            <div
              className={cellType === "number" ? `${base} text-right` : base}
            >
              {cellType === "boolean" ? props.value.toString() : props.value}
            </div>
          );
        }
      };
    });
  }, [data]);

  const tableInstance = useTable({ columns, data }, useGlobalFilter, useSortBy, usePagination);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    state,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state

  return (
    // columns={columns} data={data}      
    <div className={classes.tableContainer}>
      <div>        
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        <table {...getTableProps()} className={`${classes.wFull} mt-3 text-left w-full border-collapse ${classes.table} ${classes.borderless}`}>
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {
                          // Render the header
                          column.render("Header")
                        }
                        <span>
                          {column.isSorted ? (column.isSortedDesc ? <SortIcon direction='down' size={0} /> : <SortIcon direction='up' size={0} />) : <SortIcon direction='' size={0} />}
                        </span>
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              page.map((row) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()} className="hover:bg-gray-200 border-b">
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td {...cell.getCellProps()}>
                            {
                              // Render the cell contents
                              cell.render("Cell")
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
      <div className={classes.tablePageControl}>
        <span>
          Stanica{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <div>
          <button className='' onClick={() => previousPage()} disabled={!canPreviousPage}>Prethodna</button>
          <button className='' onClick={() => nextPage()} disabled={!canNextPage}>Sljedeća</button>
        </div>
      </div>
    </div>
  );
}

export default ZadaciVoditeljTable;
export type { ZadaciTable }