import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { Grid, TextField, Autocomplete, Box } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { AdresaType } from './PoslovniceTypes';

// interface MainTextMatchedSubstrings {
//   offset: number;
//   length: number;
// }
// interface StructuredFormatting {
//   main_text: string;
//   secondary_text: string;
//   main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
// }
// interface PlaceType {
//   description: string;
//   structured_formatting: StructuredFormatting;
// }

type GoogleMapsAutocompleteProps = {  
  setAdresa: (value: AdresaType) => void
}

const GoogleMapsAutocomplete = ( {setAdresa}: GoogleMapsAutocompleteProps ) => {  
    const {
        // ready,
        // value,
        setValue,
        suggestions: {status, data },
        clearSuggestions
    } = usePlacesAutocomplete({
        debounce: 500    
    }); 
        
    const handleSelect = async (address:any) => {
        setValue(address, false);
        clearSuggestions();
        const results = await getGeocode({ address })
        const { lat, lng } = getLatLng(results[0]);
        let postanskiBroj = 0;
        if(results[0].address_components[5]) {
          if(!isNaN(parseInt(results[0].address_components[5].long_name))) postanskiBroj = parseInt(results[0].address_components[5].long_name);
        }
        const adresaModel: AdresaType = {
            kucanskiBroj: results[0].address_components[0].long_name,
            adresa: results[0].address_components[1].long_name,
            mjesto: results[0].address_components[2].long_name,
            postanskiBroj: postanskiBroj,
            sifraZemlje: results[0].address_components[4].short_name,
            zupanija: results[0].address_components[3].long_name,
            latitude: lat,
            longitude: lng,
        } ;

        setAdresa(adresaModel);

    }

    return <Autocomplete id="googleAutocomplete" options={data} size="small" sx={{ width:"100%" }}
    onChange={(event: any, newValue: any | null) => {
      if(newValue)
      handleSelect(newValue.description);
    }}
    onInputChange={(e:any) => setValue(e.target.value) }        
    getOptionLabel={(option) => option.description}
    renderInput={(params) =>  <TextField {...params} label="Traži lokaciju"/>}
    renderOption={(props, option) => {
      if(status === "OK")
        return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item>
                  <Box
                    component={LocationOnIcon}                
                  />
                </Grid>
                <Grid item xs>                  
                    <span
                      key={option.place_id}
                    //   style={{
                    //     fontWeight: part.highlight ? 700 : 400,
                    //   }}
                    >
                      {option.description}
                    </span>                                 
                </Grid>
              </Grid>
            </li>
          );  
      }} /> ;
    
}

export default GoogleMapsAutocomplete;