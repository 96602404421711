import { useCallback, useState, useEffect } from 'react'
import useApi from "../Hooks/useApi";
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import { AkcijaDetalji } from './AkcijaTypes'
import DetaljiTab from './DetaljiTab'
import ArtikliTab from './ArtikliTab'
import PoklonTab from './PoklonTab'
import BanneriTab from './BanneriTab'
import PopustTab from './PopustTab'
import PromoPopustTab from './PromoPopustTab'

const Detalji = (props: any) => {
    const [detaljiAkcije, setDetaljiAkcije] = useState<AkcijaDetalji>({
        id: 0,
        imeAkcije:"",
        maxRate: 0,
        opis: "",
        poklon: {
            sifraPoklona: 0,
            artikliUzKojePoklonIde: []
        },
        popust: {
            popust: 0,
            uvijetPopusta: [],
            artikliNaKojeSeDajePopust: []
        },
        registracija: false,
        artikliNaAkciji: [],
        registracijaLink: "",
        voditelj: "",
        voditeljEmail: "",
        slike: {
            billboard: "",
            billboardSize: "",
            fb: "",
            fbSize: "",
            mozaik: "",
            mozaikSize: "",
            rect: "",
            rectSize: ""
        },
        promoPopust: [],
        poklonOsiguranje: null
    })
    const [isLoaded, setIsLoaded] = useState(false);
    const ApiCall = useApi();
    const encodeUri = encodeURIComponent(props.data.data.nazivAkcije);
    const getAkcijaDetaljiHandler = useCallback((accessToken:string) => {
        (async () => {
            const response = await fetch(`/api/Akcija/GetAkcijaDetails?imeAkcije=${encodeUri}`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }                                
              });
              const data = await response.json();
              if (data.responseData) {
                setDetaljiAkcije(data.responseData);
              }
              setIsLoaded(true);
        })()
    }, [encodeUri])

    useEffect(() => {
        if (!isLoaded) {
            ApiCall(getAkcijaDetaljiHandler);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const renderDetaljiTab = () => {
        return <DetaljiTab 
            id={detaljiAkcije.id}
            imeAkcije={detaljiAkcije.imeAkcije} 
            maxRate={detaljiAkcije.maxRate}
            opis={detaljiAkcije.opis}
            registracija={detaljiAkcije.registracija}
            registracijaLink={detaljiAkcije.registracijaLink}
            voditelj={detaljiAkcije.voditelj}
            voditeljEmail={detaljiAkcije.voditeljEmail}
            poklonOsiguranje={detaljiAkcije.poklonOsiguranje}      
            />
    }

    const renderArtikliTab = () => {
        return <ArtikliTab artikli={detaljiAkcije.artikliNaAkciji} />
    }

    const renderPoklonTab = () => {        
        const artikli = detaljiAkcije.poklon?.artikliUzKojePoklonIde.map((item, index) => {
            return {id:index + 1, artikl:item};
        })
        return <PoklonTab sifraPoklona={detaljiAkcije.poklon?.sifraPoklona} artikli={artikli}/>
    }

    const renderPopustTab = () => {
        const artikliNaKojeSeDajePopust = detaljiAkcije.popust?.artikliNaKojeSeDajePopust.map((item, index) => {
            return {id:index, artikl:item};
        });

        const uvjetiPopusta = detaljiAkcije.popust?.uvijetPopusta.map((item, index) => {
            return {id:index, uvjet:item};
        });

        return <PopustTab popust={detaljiAkcije.popust?.popust} artikliNaKojeSeDajePopust={artikliNaKojeSeDajePopust} uvjetPopusta={uvjetiPopusta} />
    }

    const renderBanneriTab = () => {
        return <BanneriTab slike={detaljiAkcije.slike} />
    }

    const renderPromoPopustTab = () => {
        // let test = {iznosPopusta: 0.8, grupaKartice: null, kodPopusta: null, kartica:null, nacinPlacanja: null, artikli: [{sifraArtikla: 1231},{sifraArtikla:234234},{sifraArtikla:4346},{sifraArtikla:13213},{sifraArtikla:567567},{sifraArtikla:123123}]}; 
        return <PromoPopustTab promoPopust={detaljiAkcije.promoPopust} />
    }

    return <div>
        <TabPanel className='tabsContainer'>
            <Item title="Detalji" render={renderDetaljiTab}></Item>
            <Item title="Artikli" render={renderArtikliTab}></Item>
            <Item title="Poklon" render={renderPoklonTab}></Item>
            <Item title="Popust" render={renderPopustTab}></Item>
            <Item title="Promo popust" render={renderPromoPopustTab}></Item>
            <Item title="Banneri" render={renderBanneriTab}></Item>
        </TabPanel>
    </div>
}

export default Detalji;