import {
  DataGrid,
  Column,
  Selection,
  Editing,
  FilterRow,
  Toolbar,
  Item,
} from "devextreme-react/data-grid";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createStore } from "devextreme-aspnet-data-nojquery";
import useApiPromise from "../Hooks/useApiPromise";
import { Pager, Paging } from "devextreme-react/tree-list";
import { Button as ButtonMui } from "@mui/material";
import { PlusSquare } from "react-bootstrap-icons";
import SeoAddModal from "./SeoAddModal";
import useApi from "../Hooks/useApi";
import Swal from "sweetalert2";
import classes from "./Seo.module.css";
import SeoEditModal from "./SeoEditModal";
import { Seo } from "./SeoTypes";
import BackDrop from "../UnosAkcijePrilike/BackDrop";

const SeoTablica = () => {
  const allowedPageSizes = [20, 50, 100];

  const dataGrid = useRef<DataGrid>(null);
  const apiCallPromise = useApiPromise();
  const apiCall = useApi();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [seoId, setSeoId] = useState<number>(-1);
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const accessTokenHandler = useCallback((accessToken: string) => {
    return (async () => {
      return accessToken;
    })();
  }, []);
  const [rowData, setRowData] = useState<Seo>({id: -1, klas: "", tekst: ""});

  const dataSource = useMemo(() => {
    return createStore({
      key: "id",
      loadUrl: `api/Seo/GetSeos`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
        return apiCallPromise(accessTokenHandler).then((token: any) => {
          return (ajaxOptions.headers = {
            Authorization: `Bearer ${token}`,
          });
        });
      },
    });
  }, [accessTokenHandler, apiCallPromise]);

  const deleteSeo = useCallback(
    (accessToken: string) => {
      if (seoId !== -1) {
        setIsLoading(true);
        let formData = new FormData();
        formData.append("id", seoId.toString());

        fetch("api/Seo/DeleteSeo", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          method: "post",
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.responseData) {
              setIsLoading(false);
              Swal.fire({
                icon: "success",
                title: "Obrisano",
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              });
            } else {
              setIsLoading(false);
              Swal.fire({
                title: data.error,
                showConfirmButton: false,
                toast: true,
                customClass: {
                  container: classes.swalContainer,
                  popup: classes.swal2IconError,
                },
                timer: 1500,
                iconColor: "white",
                timerProgressBar: true,
                icon: "warning",
                position: "top-right",
              });
            }
          });
      }
    },
    [seoId]
  );

  useEffect(() => {
    apiCall(deleteSeo);
  },[apiCall, deleteSeo])

  return (
    <>
      <div>
        <DataGrid
          id="dataGrid"
          ref={dataGrid}
          dataSource={dataSource}
          rowAlternationEnabled
          allowColumnReordering
          allowColumnResizing
          showBorders
          onEditingStart={(e) => {
            e.cancel = true;
            setRowData(e.data);
            setEditModalOpen(true);
          }}
          remoteOperations={true}
          onRowRemoving={(e: any) => {
            setSeoId(e.data.id);
            e.cancel = true;
          }}
        >
          <FilterRow visible={true} />
          <Paging defaultPageSize={20} />
          <Editing mode="row" allowUpdating={true} allowDeleting />
          <Pager
            visible={true}
            showPageSizeSelector={true}
            showInfo={true}
            allowedPageSizes={allowedPageSizes}
            infoText={`Stranica {0} od {1} ({2} artikala)`}
          />
          <Selection mode="single" />
          <Column
            allowFiltering={false}
            width="5%"
            dataField="id"
            caption="ID"
          />
          <Column
            allowEditing={true}
            width="10%"
            dataField="klas"
            caption="Klas"
          ></Column>
          <Column
            allowEditing={true}
            width="75%"
            dataField="tekst"
            caption="Tekst"
          />
          <Toolbar>
            <Item location="after">
              <ButtonMui
                onClick={() => {
                  setAddModalOpen(true);
                }}
                variant="contained"
              >
                <PlusSquare size={25} />
              </ButtonMui>
            </Item>
          </Toolbar>
        </DataGrid>
      </div>
      {addModalOpen && (
        <SeoAddModal open={addModalOpen} setOpen={setAddModalOpen} setSeoId={setSeoId}/>
      )}
      {editModalOpen && (
        <SeoEditModal open={editModalOpen} setOpen={setEditModalOpen} data={rowData}/>
      )}
        {isLoading && <BackDrop />}
    </>
  );
};

export default SeoTablica;
