import { Artikl, ProizvodiModel } from "./UnosAkcijePrilikeTypes";
import * as XLSX from "xlsx";
import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";
import ProizvodiTablica from "./ProizvodiTablica";
import classes from "./UnosAkcijePrilike.module.css";
import useApi from "../Hooks/useApi";
import { Button, TextField } from "@mui/material";

type ProizvodiProps = {
  proizvodiHandler: (proizvodModel: ProizvodiModel) => void;
  proizvodiModel: ProizvodiModel;
  brisiProizvod: (sifra: string) => void;
  updateProizvod: (artikl: Artikl) => void;
  dodajProizvod: (artikl: Artikl) => void;
  proizvodiCheckCijenaHandler: (proizvidModel: ProizvodiModel) => void;
  pocetakPrilike: any;
  krajPrilike: any;
  mergeProizvodi: (value: ProizvodiModel) => void;
  setProizvodiCheckCijena: (value: Artikl[]) => void;
  proizvodiCheckCijena: Artikl[];
  odabraniTip: number | undefined;
  setProizvodiNotValid: (value: boolean) => void;
  kategorijaID: number | undefined;
  ukloniProizvode: (value: []) => void;
};

const Proizvodi = (props: ProizvodiProps) => {
  const apiCall = useApi();
  const inputFile = useRef<HTMLInputElement>(null);
  const odabirExcelDatoteke = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  const readExcel = (file: Blob) => {
    if (file) {
      const promise = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onload = (e) => {
          if (e.target?.result) {
            let bufferArray = e.target.result;
            const wb = XLSX.read(bufferArray, { type: "buffer" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws);
            resolve(data);
          }
          fileReader.onerror = (error) => {
            reject(error);
          };
        };
      });
      promise.then((d: any) => {
        props.proizvodiHandler({
          ...props.proizvodiModel,
          uneseniProizvodiNaPrilici: d,
        });
      });
    }
  };

  const post = (accessToken: string) => {
    let danPocetak;
    let mjesecPocetak;
    let godinaPocetak;
    let danKraj;
    let mjesecKraj;
    let godinaKraj;
    if (props.pocetakPrilike) {
      danPocetak = ("0" + new Date(props.pocetakPrilike).getDate()).slice(-2);
      mjesecPocetak = (
        "0" +
        (new Date(props.pocetakPrilike).getMonth() + 1)
      ).slice(-2);
      godinaPocetak = new Date(props.pocetakPrilike).getFullYear();
    }

    if (props.krajPrilike) {
      danKraj = ("0" + new Date(props.krajPrilike).getDate()).slice(-2);
      mjesecKraj = ("0" + (new Date(props.krajPrilike).getMonth() + 1)).slice(
        -2
      );
      godinaKraj = new Date(props.krajPrilike).getFullYear();
    }

    let formData = new FormData();
    let vrstaAkcije = "";
    if (props.odabraniTip === 1) {
      vrstaAkcije = "akcija";
    } else if (props.odabraniTip === 2) {
      vrstaAkcije = "prilika";
    } else {
      vrstaAkcije = "rasprodaja";
    }
    if (props.pocetakPrilike)
      formData.append(
        "akcijaOd",
        `${godinaPocetak}-${mjesecPocetak}-${danPocetak}`
      );
    if (props.krajPrilike)
      formData.append("akcijaDo", `${godinaKraj}-${mjesecKraj}-${danKraj}`);
    if (props.proizvodiModel.uneseniProizvodiNaPrilici.length > 0) {
      for (
        let i = 0;
        i < props.proizvodiModel.uneseniProizvodiNaPrilici.length;
        i++
      ) {
        const element = props.proizvodiModel.uneseniProizvodiNaPrilici[i];
        formData.append(`artikli[${i}].SifraArtikla`, element.SifraArtikla);
        if (element.Cijena) {
          formData.append(
            `artikli[${i}].Cijena`,
            element.Cijena.toString().replace(".", ",")
          );
        }
        formData.append(
          `artikli[${i}].BesplatnaDostava`,
          element.BesplatnaDostava.toString()
        );
        formData.append(
          `artikli[${i}].SifraOsiguranja`,
          element.SifraOsiguranja?.toString() ?? ""
        );
        formData.append(
          `artikli[${i}].Support`,
          element.Support?.toString().replace(".", ",") ?? ""
        );
        formData.append(
          `artikli[${i}].ReferentniBroj`,
          element.ReferentniBroj ?? ""
        );
        formData.append(
          `artikli[${i}].DodatniRabatKomisija`,
          element.DodatniRabatKomisija?.toString().replace(".", ",") ?? ""
        );
        formData.append(
          `artikli[${i}].AkcijskaNettoCijenaKomisija`,
          element.AkcijskaNettoNabavnaCijenaKomisija?.toString().replace(".", ",") ?? ""
        );
      }
    }
    formData.append("vrstaAkcije", vrstaAkcije);
    if (props.kategorijaID) {
      formData.append("kategorijaID", props.kategorijaID.toString());
    }
    formData.append("kljuc", props.proizvodiModel.kljuc);
    fetch("api/Akcija/CheckArtiklCijena", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      method: "post",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseData) {
          let arr =
            props.proizvodiModel.proizvodiNakonCheckCijena.length > 0
              ? props.proizvodiModel.proizvodiNakonCheckCijena
              : props.proizvodiModel.uneseniProizvodiNaPrilici;
          let arr2 = data.responseData;
          let tempArr: Artikl[] = [];
          let tempOrderBy: Artikl[] = [];
          for (let i = 0; i < arr.length; i++) {
            const element = arr[i];
            let test = arr2.find(
              (i: any) => i.artikl === parseInt(element.SifraArtikla)
            );
            let tempObj: Artikl = {
              Cijena: element.Cijena,
              SifraArtikla: element.SifraArtikla,
              greska: "",
              isOk: true,
              PromoKod: element.PromoKod,
              naziv: "",
              katBroj: "",
              BesplatnaDostava: element.BesplatnaDostava,
              SifraOsiguranja: element.SifraOsiguranja,
              Support: element.Support,
              ReferentniBroj: element.ReferentniBroj,
              AkcijskaNettoNabavnaCijenaKomisija: element.AkcijskaNettoNabavnaCijenaKomisija,
              DodatniRabatKomisija: element.DodatniRabatKomisija,
            };
            if (test) {
              tempObj.greska = test?.greska;
              tempObj.isOk = test?.isOk;
              tempObj.katBroj = test?.katBroj;
              tempObj.naziv = test?.naziv;
            }
            tempArr.push(tempObj);
          }
          tempArr.forEach((element) => {
            if (element.isOk) {
              tempOrderBy.push(element);
            } else {
              tempOrderBy.unshift(element);
            }
          });
          if (tempOrderBy.length > 0) {
            // props.setProizvodiCheckCijena(tempOrderBy);
            props.proizvodiCheckCijenaHandler({
              ...props.proizvodiModel,
              proizvodiNakonCheckCijena: tempOrderBy,
            });
          }
        }
      });
  };

  const apiCallPost = () => {
    apiCall(post);
  };

  return (
    <>
      <h4 className={classes.stepsNaslov}>Proizvodi na prilici</h4>
      <div className={classes.container}>
        <input
          accept={
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"'
          }
          className={classes.hidden}
          type="file"
          multiple={false}
          onChange={(e) => {
            let file: any = [];
            if (e.target.files) {
              file = e.target.files[0];
            }
            readExcel(file);
            e.target.value = "";
          }}
          ref={inputFile}
        />
        <button
          type="button"
          className={classes.uploadButtonExcel}
          onClick={odabirExcelDatoteke}
        >
          <FontAwesomeIcon
            className={classes.uploadIcon}
            icon={faArrowUpFromBracket}
          />
          Odabir datoteka
        </button>
      </div>
      <div>
        <ProizvodiTablica
          artikli={props.proizvodiModel.uneseniProizvodiNaPrilici}
          brisiProizvod={props.brisiProizvod}
          updateProizvod={props.updateProizvod}
          dodajProizvod={props.dodajProizvod}
          pocetakPrilike={props.pocetakPrilike}
          krajPrilike={props.krajPrilike}
          proizvodiCheckCijena={props.proizvodiModel.proizvodiNakonCheckCijena}
          setProizvodiNotValid={props.setProizvodiNotValid}
          odabraniTip={props.odabraniTip}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Button
            onClick={() => {
              props.setProizvodiNotValid(false);
              apiCallPost();
            }}
            variant="contained"
          >
            Provjeri artikle
          </Button>
          <TextField
            value={props.proizvodiModel.kljuc}
            onChange={(e) => {
              props.proizvodiHandler({
                ...props.proizvodiModel,
                kljuc: e.target.value,
              });
            }}
            label="Ključ"
            size="small"
            style={{
              backgroundColor: "white",
              marginLeft: "20px",
            }}
            id="kljuc"
          />
        </div>
        {/* {(props.odabraniTip === 1 || props.odabraniTip === 2) && ( */}

        {/* )} */}
        <Button
          onClick={() => {
            props.ukloniProizvode([]);
          }}
          variant="contained"
        >
          Ukloni artikle
        </Button>
      </div>
    </>
  );
};

export default Proizvodi;
