import { Box, Button, Modal } from "@mui/material";
import { useCallback, useEffect, useReducer, useState } from "react";
import { Calendar } from "react-multi-date-picker";
import useApi from "../Hooks/useApi";
import { NeradnaNedelja, NeradneNedeljeDefault } from "./PoslovniceTypes";
import classes from "./Poslovnice.module.css";
import NeradneNedeljeReducer from "./NeradneNedeljeReducer";
import moment from "moment";
import BackDrop from "../UnosAkcijePrilike/BackDrop";
import Swal from "sweetalert2";

type NeradneNedeljeProps = {
  setNeradneNedeljeModalOpen: (value: boolean) => void;
  poslovnicaId: number;
};

const mjeseci = [
  "Siječanj",
  "Veljača",
  "Ožujak",
  "Travanj",
  "Svibanj",
  "Lipanj",
  "Srpanj",
  "Kolovoz",
  "Rujan",
  "Listopad",
  "Studeni",
  "Prosinac",
];
const dani = ["Ne", "Po", "Ut", "Sr", "Če", "Pe", "Su"];

const style = {
  overflow: "auto",
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "98%",
  height: "98%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  //   alignItems: "center",
  flexDirection: "column",
};

const NeradneNedelje = (props: NeradneNedeljeProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [model, dispatcher] = useReducer(
    NeradneNedeljeReducer,
    NeradneNedeljeDefault
  );

  let neradneNedeljeCancel = false;
  const handleClose = () => {
    props.setNeradneNedeljeModalOpen(false);
  };
  const apiCall = useApi();

  const getNeradneNedelje = useCallback((accessToken: string) => {
    (async () => {
      const form = new FormData();
      form.append("poslovnicaId", props.poslovnicaId.toString());
      fetch("/api/Poslovnica/GetNeradneNedelje", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: form,
      })
        .then((response) => response.json())
        .then((data) => {
          if (neradneNedeljeCancel) return;
          if (data.length > 0) {
            dispatcher({
              action: "GET_NERADNE-NEDELJE",
              value: data.map((x: NeradnaNedelja) => {
                return x.datum;
              }),
            });
          }
        });
    })();
  }, []);

  useEffect(() => {
    apiCall(getNeradneNedelje);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const post = (accessToken: string) => {
    props.setNeradneNedeljeModalOpen(false);
    setIsLoading(true);
    let formData = new FormData();
    for (let index = 0; index < model.neradneNedelje.length; index++) {
      const element = model.neradneNedelje[index];
      formData.append("datumi",element);
    }
    formData.append("poslovnicaId",props.poslovnicaId.toString());

    fetch("api/Poslovnica/SaveNeradneNedelje", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      method: "post",
      body: formData,
    }).then((response) => {
      if (response.status === 200) {
        setIsLoading(false);
        Swal.fire({
          icon: "success",
          title: "Spremljeno",
          customClass: {
            container: classes.swalContainer,
          },
        })
      } else {
        setIsLoading(false);
        Swal.fire({
          title: response.statusText,
          showConfirmButton: false,
          toast: true,
          customClass: {
            container: classes.swalContainer,
            popup: classes.swal2IconError,
          },
          timer: 1500,
          iconColor: "white",
          timerProgressBar: true,
          icon: "warning",
          position: "top-right",
        });
      }
    });
  };

  const apiCallPost = () => {
    apiCall(post);
  };

  return (
    <>
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Calendar
          // numberOfMonths={6}
          // multiple
          months={mjeseci}
          weekDays={dani}
          value={model.neradneNedelje.map((date: string) => {
            return moment(new Date(date)).format("DD.MM.YYYY.");
          })}
          weekStartDayIndex={1}
          onFocusedDateChange={(dateFocused, dateClicked) => {
            if (dateClicked !== undefined) {
              dispatcher({
                action: "NERADNE_NEDELJE_HANDLER",
                value: dateClicked.format("YYYY-MM-DD"),
              });
            }
          }}
          disableMonthPicker={true}
          disableYearPicker={true}
          minDate={new Date(new Date().getFullYear(), 0, 1)}
          maxDate={new Date(new Date().getFullYear(), 11, 31)}
          format={"DD.MM.YYYY."}
          fullYear={true}
          mapDays={({ date }) => {
            let isWeekend = [1, 2, 3, 4, 5, 6].includes(date.weekDay.index);
            if (isWeekend)
              return {
                disabled: true,
                style: { color: "#ccc" },
              };
          }}
          className={classes.kalendar}
        />
        <Button onClick={() => {apiCallPost()}} style={{marginTop:'50px', width:'30%', alignSelf:'center'}}  size="small" variant="contained" color="success">
          Spremi
        </Button>
      </Box>
    </Modal>
      {isLoading && <BackDrop />}
    </>

  );
};

export default NeradneNedelje;
