import * as React from 'react'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Swal from 'sweetalert2';
import useApi from "../Hooks/useApi";
import { useState } from 'react';
import classes from "./Placanja.module.css";
import BackDrop from '../UnosAkcijePrilike/BackDrop';

type AkcijeMenuProps = {
    data: any;
    setEditOpen: (e: any) => void;
}

// const useriSaPravomOdobrenja = ["magdalena.csucs@elipso.hr", "iva.telisman@elipso.hr","jelena.gazda@elipso.hr"]

export default function AkcijeMenu(props: AkcijeMenuProps) {
  const apiCall = useApi();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function obrisi(accessToken: string) {
    Swal.fire({
      title: 'Dali ste sigurni?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Da!'
    }).then((result) => {
      if (result.isConfirmed) {
        let formData = new FormData();
      formData.append("Nazivplacanja", props.data.data.naziv);
      fetch("api/Placanja/DeletePlacanja", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "post",
        body: formData,
      }).then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          Swal.fire({
            icon: "success",
            title: "Spremljeno",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = "/";
            }
          });
        } else {
          setIsLoading(false);
          Swal.fire({
            title: response.statusText,
            showConfirmButton: false,
            toast: true,
            customClass: {
              container: classes.swalContainer,
              popup: classes.swal2IconError,
            },
            timer: 1500,
            iconColor: "white",
            timerProgressBar: true,
            icon: "warning",
            position: "top-right",
            cancelButtonText: "Odustani"
          });
        }
      });
      }
    })



    
  }

  const apiCallPost = () => {
    apiCall(obrisi);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant='contained'
      >
        Administracija
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={props.setEditOpen}>Uredi</MenuItem>
        <MenuItem onClick={apiCallPost}>Obriši</MenuItem>
      </Menu>
      {isLoading && <BackDrop />}
    </div>
  );
}