import {  useReducer, useEffect } from 'react';
import { Box, TextField, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import FileInput from '../FileInput/FileInput';
import classes from './AddEditPosebnaOsobina.module.css';
import { loadImageAsync} from "../../utils/utils";
import { AddEditPosebnaOsobinaType } from './PosebneOsobineTypes';
import posebneOsobineReducer from './PosebneOsobineReducer';

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "98%",
    height: "98%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    display: "flex",
    //   alignItems: "center",
    flexDirection: "column",
};

const AddEditPosebnaOsobina = ({data, setOpen, handleSpremiPosebnuOsobinu}: AddEditPosebnaOsobinaType) => {
    const [model, dispatcher] = useReducer(posebneOsobineReducer, {
        posebnaOsobina: {
            id: 0,
            naziv: "",
            opis: "",
            sadrzaj: "",
            slikaUrl: "",
            slikaInfo: null
        },
        errors: {
            slika: "",
            naziv: "",
            opis: "",
            sadrzaj: ""
        },
        submit: false
    });
    useEffect(() => {
        if(data) {
            dispatcher({action: "ID_PROMIJENJEN", value: data.id});
            dispatcher({action: "NAZIV_PROMIJENJEN", value: data.naziv});
            dispatcher({action: "OPIS_PROMIJENJEN", value: data.opis});
            dispatcher({action: "SADRZAJ_PROMIJENJEN", value: data.sadrzaj});
            dispatcher({action: "SLIKA_SRC_PROMIJENJEN", value: data.slikaUrl});
        }
    }, [data])

    useEffect(() => {
        if(model.submit) {            
            dispatcher({action:"RESETIRAJ_FORMU", value:null});
            handleSpremiPosebnuOsobinu({
                id: model.posebnaOsobina.id,
                naziv: model.posebnaOsobina.naziv,
                opis: model.posebnaOsobina.opis,
                sadrzaj: model.posebnaOsobina.sadrzaj,
                slikaInfo: model.posebnaOsobina.slikaInfo,
                slikaUrl: model.posebnaOsobina.slikaUrl
            });
        }
    }, [model.submit, model.posebnaOsobina, handleSpremiPosebnuOsobinu])
    
    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let name = event.target.name;
        let value = event.target.value;                
        switch(name) {
            case "ime":                
                dispatcher({action: "NAZIV_PROMIJENJEN", value: value});                            
                break;
            case "opis":                
                dispatcher({action: "OPIS_PROMIJENJEN", value: value});                
                break;
            case "sadrzaj":                
                dispatcher({action: "SADRZAJ_PROMIJENJEN", value: value});                
                break;  
            default:
                break;                                
        }        
    }

    const handleSlikaChange = (event: React.SyntheticEvent) => {
        if (!(event.target instanceof HTMLInputElement)) return;
        if (event.target.files && event.target.files.length > 0) {
            ucitajDatoteku(event.target.files[0]).then(slika => {
                dispatcher({ action: "SLIKA_UCITANA", value: slika });   
            }
            );
        } 
    };
    
    const ucitajDatoteku = async (file:File) => {
        return await loadImageAsync(file);
    }

    const handleSpremi = () => {
        dispatcher({action: "VALIDIRAJ_FORMU", value: null});
    }

    return <>
        <Modal
            open={true}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                <h3 className="text-center">Posebna osobina</h3>
                <div className={classes.container}>
                    <div>
                        <div className={classes.slikaContainer}>
                            <FileInput extension="image/jpeg" label="neki" multiple={false} key="naziv" ucitajSliku={handleSlikaChange} />
                            <div className={classes.slikaUploaded}>
                                <img src={model.posebnaOsobina.slikaUrl} alt="" />
                            </div>
                        </div>
                        <div>
                            <label className="lightBlue small-text">Slike manje od 500KB. Isključivo .jpg format nazvan po šifri. 1140px sa 500px.*</label>                                                      
                            {model.errors.slika && <span className="text-danger small-text">{model.errors.slika}</span>}
                        </div>
                    </div>
                    <div>
                        <TextField
                            label="Naziv osobine"
                            size="small"
                            value={model.posebnaOsobina.naziv}
                            name="ime"
                            onChange={handleChange}
                             />
                        <div>
                            <label className="lightBlue small-text">Minimalno 3 znaka, maksimalno 90 znakova.*</label> 
                            {model.posebnaOsobina.naziv.length > 0 && <span className="lightBlue small-text">Broj znakova: {model.posebnaOsobina.naziv.length}</span>}                             
                            {model.errors.naziv && <span className="text-danger small-text">{model.errors.naziv}</span>}
                        </div>
                    </div>
                    <div>
                        <TextField
                            label="Kratki opis osobine"
                            size="small"
                            name="opis"
                            value={model.posebnaOsobina.opis}
                            multiline={true}
                            rows={2}                            
                            onChange={handleChange} />
                        <div>
                            <label className="lightBlue small-text">Minimalno 120 znakova, maksimalno 160 znakova.*</label>
                            {model.posebnaOsobina.opis.length > 0 && <span className="lightBlue small-text">Broj znakova: {model.posebnaOsobina.opis.length}</span>}
                            {model.errors.opis && <span className="text-danger small-text">{model.errors.opis}</span>}                            
                        </div>
                    </div>
                    <div>
                        <TextField
                            label="Detaljni opis osobine"
                            size="small"
                            name="sadrzaj"
                            multiline={true}
                            rows={5}
                            value={model.posebnaOsobina.sadrzaj}
                            onChange={handleChange} />
                        <div>
                            <label className="lightBlue small-text">Minimalno 600 znakova.*</label>
                            {model.posebnaOsobina.sadrzaj.length > 0 && <span className="lightBlue small-text">Broj znakova: {model.posebnaOsobina.sadrzaj.length}</span>}
                            {model.errors.sadrzaj && <span className="text-danger small-text">{model.errors.sadrzaj}</span>}
                        </div>
                    </div>
                </div>
                <div className={classes.buttonContainer}>
                    <Button onClick={handleSpremi} style={{ backgroundColor: "rgb(26, 179, 148)", marginRight: "1rem" }} variant="contained">Spremi</Button>
                    <Button onClick={handleClose} style={{ backgroundColor: "rgb(217, 83, 79)" }} variant="contained">Odustani</Button>
                </div>
            </Box>
        </Modal>
    </>
}

export default AddEditPosebnaOsobina;