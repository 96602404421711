import {
  DataGrid,
  Column,
  Selection,
  Editing,
  FilterRow,
  Toolbar,
  Item,
} from "devextreme-react/data-grid";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createStore } from "devextreme-aspnet-data-nojquery";
import useApiPromise from "../Hooks/useApiPromise";
import { Pager, Paging } from "devextreme-react/tree-list";
import { Button as ButtonMui } from "@mui/material";
import { PlusSquare } from "react-bootstrap-icons";
import useApi from "../Hooks/useApi";
import Swal from "sweetalert2";
import classes from "./InTime.module.css";
import BackDrop from "../UnosAkcijePrilike/BackDrop";
import AddModal from "./AddModal";
import { InTime as InTimeType } from "./InTimeTypes";
import EditModal from "./EditModal";
// import AddModal from "./AddModal";
// import EditModal from "./EditModal";

const InTime = () => {
  const allowedPageSizes = [20, 50, 100];

  const dataGrid = useRef<DataGrid>(null);
  const apiCallPromise = useApiPromise();
  const apiCall = useApi();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inTimeId, setInTimeId] = useState<number>(-1);
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [daniCekanja, setDaniCekanja] = useState<number>();

  let procjenaCancel = false;
  const accessTokenHandler = useCallback((accessToken: string) => {
    return (async () => {
      return accessToken;
    })();
  }, []);
  const [rowData, setRowData] = useState<InTimeType>({id: -1, brojDostava: -1, vrijemeUnosa: ""});

  const dataSource = useMemo(() => {
    return createStore({
      key: "id",
      loadUrl: `api/InTIme/GetInTimeDostava`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
        return apiCallPromise(accessTokenHandler).then((token: any) => {
          return (ajaxOptions.headers = {
            Authorization: `Bearer ${token}`,
          });
        });
      },
    });
  }, [accessTokenHandler, apiCallPromise]);

  const deleteInTime = useCallback(
    (accessToken: string) => {
      if (inTimeId !== -1) {
        setIsLoading(true);
        let formData = new FormData();
        formData.append("id", inTimeId.toString());

        fetch("api/InTIme/DeleteInTimeDostava", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          method: "post",
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.responseData) {
              setIsLoading(false);
              Swal.fire({
                icon: "success",
                title: "Obrisano",
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              });
            } else {
              setIsLoading(false);
              Swal.fire({
                title: data.error,
                showConfirmButton: false,
                toast: true,
                customClass: {
                  container: classes.swalContainer,
                  popup: classes.swal2IconError,
                },
                timer: 1500,
                iconColor: "white",
                timerProgressBar: true,
                icon: "warning",
                position: "top-right",
              });
            }
          });
      }
    },
    [inTimeId]
  );

  useEffect(() => {
    apiCall(deleteInTime);
  }, [apiCall, deleteInTime]);

  const getDaniCekanjaNaPrikup = (accessToken: string) => {
    fetch("/api/InTIme/GetInTimeDostavaProcjena", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (procjenaCancel) return;
        setDaniCekanja(data.responseData);
      });
  };

  useEffect(() => {
    apiCall(getDaniCekanjaNaPrikup);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      procjenaCancel = true;
    };
  }, [apiCall]);

  return (
    <>
      <div>
        <div style={{ backgroundColor: "white", padding: "1em" }}>
          <span style={{ marginRight: "10px", fontWeight: "bold" }}>
            Dani čekanja na prikup
          </span>
          <span
            style={{ marginRight: "10px", fontWeight: "bold", color: "red" }}
          >
            {daniCekanja}
          </span>
        </div>
        <DataGrid
          id="dataGrid"
          ref={dataGrid}
          dataSource={dataSource}
          rowAlternationEnabled
          allowColumnReordering
          allowColumnResizing
          showBorders
          onEditingStart={(e) => {
            e.cancel = true;
              setRowData(e.data);
            setEditModalOpen(true);
          }}
          remoteOperations={true}
          onRowRemoving={(e: any) => {
            setInTimeId(e.data.id);
            e.cancel = true;
          }}
        >
          <FilterRow visible={true} />
          <Paging defaultPageSize={20} />
          <Editing mode="row" allowUpdating allowDeleting />
          <Pager
            visible={true}
            showPageSizeSelector={true}
            showInfo={true}
            allowedPageSizes={allowedPageSizes}
            infoText={`Stranica {0} od {1} ({2} artikala)`}
          />
          <Selection mode="single" />
          <Column
            allowEditing={true}
            // width="10%"
            dataField="brojDostava"
            caption="Broj dostava"
          ></Column>
          <Column
            allowEditing={true}
            // width="75%"
            dataField="vrijemeUnosa"
            caption="Vrijeme unosa"
            defaultSortIndex={0}
            defaultSortOrder="desc"
            dataType="date"
            format="dd.MM.yyyy"
          />
          <Toolbar>
            <Item location="after">
              <ButtonMui
                onClick={() => {
                  setAddModalOpen(true);
                }}
                variant="contained"
              >
                <PlusSquare size={25} />
              </ButtonMui>
            </Item>
          </Toolbar>
        </DataGrid>
      </div>
      {addModalOpen && (
        <AddModal open={addModalOpen} setOpen={setAddModalOpen} />
      )}
      {editModalOpen && (
          <EditModal open={editModalOpen} setOpen={setEditModalOpen} data={rowData}/>
        )}
      {isLoading && <BackDrop />}
    </>
  );
};

export default InTime;
