import classes from "./SpremljeniDokumenti.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@mui/material";

type SpremljeniDokumentiProps = {
  dokumenti: { file: File; success: boolean; errorMessage: string }[];
};

const SpremljeniDokumenti = ({ dokumenti }: SpremljeniDokumentiProps) => {
  return (
    <div className={classes.container}>
      <div className={classes.subContainer}>
        {dokumenti.map((dokument) => {
          if (dokument.success) {
            return (
              <Tooltip key={dokument.file.name} title={dokument.errorMessage}>
                <div
                  className={`${classes.item} ${
                    dokument.success ? classes.success : classes.error
                  }`}
                >
                  <label>{dokument.file.name}</label>
                  <FontAwesomeIcon
                    icon={dokument.success ? faCheckCircle : faTimesCircle}
                  />
                </div>
              </Tooltip>
            );
          }else{
            return null;
          }
        })}
      </div>
      <div className={classes.subContainer}>
        {dokumenti.map((dokument) => {
          if (!dokument.success) {
            return (
              <Tooltip key={dokument.file.name} title={dokument.errorMessage}>
                <div
                  className={`${classes.item} ${
                    dokument.success ? classes.success : classes.error
                  }`}
                >
                  <label>{dokument.file.name}</label>
                  <FontAwesomeIcon
                    icon={dokument.success ? faCheckCircle : faTimesCircle}
                  />
                </div>
              </Tooltip>
            );
          }else{
            return null;
          }
        })}
      </div>
    </div>
  );
};

export default SpremljeniDokumenti;
