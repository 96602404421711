import produce from "immer";
import { SlikaInfo } from "../../utils/utils";
import { PosebneOsobineActions } from './PosebneOsobineActions';
import { PosebnaOsobinaModel } from './PosebneOsobineTypes';

const posebneOsobineReducer = produce((oldState: PosebnaOsobinaModel, action: PosebneOsobineActions) => {
    switch (action.action) {
        case "ID_PROMIJENJEN":
            oldState.posebnaOsobina.id = action.value;
            break;
        case "NAZIV_PROMIJENJEN":
            let errorNaziv = validirajNaziv(action.value);                             
            oldState.posebnaOsobina.naziv = action.value;
            oldState.errors.naziv = errorNaziv;
            break;
        case "OPIS_PROMIJENJEN":
            let errorOpis = validirajOpis(action.value);                            
            oldState.posebnaOsobina.opis = action.value;
            oldState.errors.opis = errorOpis;
            break;
        case "SADRZAJ_PROMIJENJEN":
            let errorSadrzaj = validirajSadrzaj(action.value);                                         
            oldState.posebnaOsobina.sadrzaj = action.value;
            oldState.errors.sadrzaj = errorSadrzaj;
            break;    
        case "SLIKA_SRC_PROMIJENJEN":
            oldState.posebnaOsobina.slikaUrl = action.value;
            break;        
        case "SLIKA_UCITANA":
            let slikaSrc = "";
            let errorSlika = validirajSliku(action.value);       
            if(!errorSlika)  slikaSrc = action.value.src;        
            oldState.posebnaOsobina.slikaUrl = slikaSrc;
            oldState.errors.slika = errorSlika;
            oldState.posebnaOsobina.slikaInfo = action.value;
            break;
        case "ERORRNAZIV_PROMIJENJEN":
            oldState.errors.naziv = action.value;
            break;
        case "ERORROPIS_PROMIJENJEN":
            oldState.errors.opis = action.value;
            break;
        case "ERORRSADRZAJ_PROMIJENJEN":
            oldState.errors.sadrzaj = action.value;
            break;
        case "ERORRSLIKA_PROMIJENJEN":
            oldState.errors.slika = action.value;
            break;
        case "VALIDIRAJ_FORMU":                                       
             if(oldState.posebnaOsobina.id === 0) {
                if(oldState.posebnaOsobina.slikaInfo) {
                    oldState.errors.slika = validirajSliku(oldState.posebnaOsobina.slikaInfo); 
                }else {
                    oldState.errors.slika = "Slika je obavezna.";
                }
             }else {
                if(oldState.posebnaOsobina.slikaInfo)   {
                    oldState.errors.slika = validirajSliku(oldState.posebnaOsobina.slikaInfo);                         
                }
             }
              oldState.errors.naziv = validirajNaziv(oldState.posebnaOsobina.naziv);
              oldState.errors.opis = validirajOpis(oldState.posebnaOsobina.opis);
              oldState.errors.sadrzaj = validirajSadrzaj(oldState.posebnaOsobina.sadrzaj);
             if(oldState.errors.naziv === "" && oldState.errors.opis === "" && oldState.errors.sadrzaj === "" && oldState.errors.slika === ""){
                oldState.submit = true;
             } else {
                oldState.submit = false;
             }
            break;
        case "RESETIRAJ_FORMU": 
             oldState.submit = false;
             break;
        default:
            break;
    }
});

function validirajSliku(slikaInfo: SlikaInfo): string {
    let ekstenzija = slikaInfo.name.replace(/^.*\./, "").toLowerCase();
    if (ekstenzija !== "jpg"){
        return"Isključivo .jpg format.";
    }
    if(slikaInfo.size > 500) {
        return "Obavezno slike manje od 500kb.";
    }
    if(slikaInfo.width !== 1140 || slikaInfo.height !== 500) {
        return "Obavezno 1140px sa 500px format.";
    }
    return "";
}

function validirajNaziv(naziv: string): string {
    if(naziv.length < 3) return "Minimalno 3 znaka.";
    if(naziv.length > 90) return "Maksimalno 90 znakova."; 
    return "";
}

function validirajOpis(opis: string): string {
    if(opis.length > 160) return "Maksimalno 160 znakova.";   
    if(opis.length < 120) return "Minimalno 120 znakova.";
    return "";
}

function validirajSadrzaj(sadrzaj: string): string {
    if(sadrzaj.length < 600) return "Minimalno 600 znakova.";  
    return "";
}

export default posebneOsobineReducer;