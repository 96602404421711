import classes from './DetaljiTab.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark, faInfoCircle } from '@fortawesome/free-solid-svg-icons'

type DetaljiProps = {
    id: number,
    imeAkcije: string,
    opis: string,
    voditelj: string,
    voditeljEmail: string,
    maxRate: number,
    registracija: boolean,
    registracijaLink: string | null,
    poklonOsiguranje: string | null;
}

const DetaljiTab = (props: DetaljiProps) => {
    return (
        <div className={classes.container}>
            <div className={`${classes.column} text-center`}>
                <FontAwesomeIcon icon={faInfoCircle} className="tabsIcon" />
            </div>
            <div className={classes.column}>
                <div>
                    <label>Id:</label>
                    <span>{props.id}</span>
                </div>                
                <div>
                    <label>Voditelj:</label>
                    <span>{props.voditelj}</span>
                </div>
                <div>
                    <label>Voditelj email:</label>
                    <span>{props.voditeljEmail}</span>
                </div>
                <div>
                    <label>Broj rata:</label>
                    <span>{props.maxRate}</span>
                </div>
                <div>
                    <label>Registracija:</label>
                    <span>
                        <FontAwesomeIcon
                            className={props.registracija ? classes.zelena : classes.crvena}
                            icon={props.registracija ? faCheck : faXmark} />
                    </span>
                    <span>{props.registracija}</span>
                </div>
                <div>
                    <label>Registracija link:</label>
                    <span>{props.registracijaLink}</span>
                </div>
                <div>
                    <label>Ime akcije:</label>
                    <span>{props.imeAkcije}</span>
                </div>
                <div>
                    <label>Poklon osiguranje:</label>
                    <span>
                        {!props.poklonOsiguranje ? <FontAwesomeIcon
                            className={classes.crvena}
                            icon={faXmark} /> : props.poklonOsiguranje}
                    </span>
                </div>
            </div>
            <div className={classes.column}>
                <div>
                    <label className={classes.opis}>Opis:</label>
                    <p className={classes.breakSpaces}>
                        {props.opis}
                    </p>
                </div>

            </div>
        </div>

    )
}

export default DetaljiTab;