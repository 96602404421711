type MsalConfig = {
  auth: {
    clientId: string,
    authority: string,
    redirectUri: string
  },
  cache: {
    cacheLocation: string,
    storeAuthStateInCookie: boolean
  }
}

export const msalConfig : MsalConfig = {
    auth: {
        clientId: "ab4d7063-52b7-4b09-94ab-c303c9c25654",
        authority: "https://login.microsoftonline.com/c0506cea-a94b-4f57-b992-a73d08203a18", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: "https://admindata.elipso.hr",
          // redirectUri: "https://localhost:44439",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
      scopes: ["api://810fdae6-537e-46ec-8d23-6841415a49f5/.default"]
      
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com"
  };