import { useEffect, useState } from 'react'
import FormaZaUnosSlika from '../FormaZaUnosSlika/FormaZaUnosSlika'

// type FormaContainerModel = {
//     oznaka: string,
//     naslov: string
// }

// const tipoviFormi: FormaContainerModel[] = [
//     {
//         oznaka: "unosslika",
//         naslov: "Unos slika proizvoda"
//     },
//     {
//         oznaka: "energetskenaljepnice",
//         naslov: "Unos energetskih naljepnica za proizvode"
//     },
//     {
//         oznaka: "slikebannera",
//         naslov: "Unos novog bannera"
//     },
//     {
//         oznaka:"unosinformacijskihlistova",
//         naslov:"Unos informacijskih listova"
//     }
// ]


const FormContainer = () => {

    const [oznaka, setOznaka] = useState("");

    useEffect(() => {      
        const url = window.location.href
        const segment = url.substring(url.lastIndexOf('/') + 1);
        setOznaka(segment.toLowerCase());
    },[oznaka])

    return (
        <div>
            {/* <h2 className={classes.naslov}>
                {tipoviFormi.find(forma => forma.oznaka === oznaka)?.naslov}
            </h2> */}
            
            <FormaZaUnosSlika oznaka={oznaka}/>
        </div>
    )
}

export default FormContainer;