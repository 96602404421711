import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  useRef,
} from "react";
import {
  DataGrid,
  Column,
  Selection,
  MasterDetail,
  Paging,
  FilterRow,
  Lookup,
} from "devextreme-react/data-grid";
import { SearchPanel } from "devextreme-react/tree-list";
import "devextreme/dist/css/dx.light.css";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import classes from "./AkcijePrikazTablica.module.css";
import "./AkcijePrikazTablica.css";
import useApiPromise from "../Hooks/useApiPromise";
import Detalji from "./Detalji";
import PromjenaBannera from "./PromjenaBannera";
import PromjenaOpisa from "./PromjenaOpisa";
import AkcijeMenu from "./AkcijeMenu";
import PromjenaArtikala from "./PromjenaArtikala";
import OdobrenjeAkcijePotvrda from "./OdobrenjeAkcijePotvrda";
import BackDrop from "../UnosAkcijePrilike/BackDrop";
import Swal from "sweetalert2";
import { useMsal } from "@azure/msal-react";

type dropDownType = {
  id: number;
  naziv: string;
};

const odabranoLookup = [
  {
    value: true,
    display: "DA",
  },
  {
    value: false,
    display: "NE",
  },
];

const AkcijePrikazTablica = () => {
  const [openPromjenaBannera, setOpenPromjenaBannera] =
    useState<boolean>(false);
  const [openPromjenaOpisa, setOpenPromjenaOpisa] = useState<boolean>(false);
  const [odobrenjeAkcijaPotvrda, setOdobrenjeAkcijaPotvrda] = useState(false);
  const [rowData, setRowData] = useState<any>(null);
  const [akcijeDropDown, setAkcijeDropDown] = useState<dropDownType[]>([]);
  const [robneMarkeDropDown, setRobneMarkeDropDown] = useState<dropDownType[]>(
    []
  );
  const [referentDropDown, setReferentDropDown] = useState<dropDownType[]>([]);
  let subscription = false;
  const [openPromjenaArtikala, setOpenPromjenaArtikala] =
    useState<boolean>(false);
  const [showBackdrop, setShowBackdrop] = useState(false);

  const { accounts } = useMsal();

  const dataGrid = useRef<DataGrid>(null);

  const odobrenoRender = (data: any) => {
    const odobreno: boolean = data.data.odobreno;
    return (
      <div
        className={`${classes.odobrenoIkona} ${
          odobreno ? classes.zeleno : classes.crveno
        } text-center`}
      >
        <FontAwesomeIcon
          icon={odobreno ? faCircleCheck : faXmarkCircle}
        ></FontAwesomeIcon>
      </div>
    );
  };

  const apiCall = useApiPromise();

  const accessTokenHandler = useCallback((accessToken: string) => {
    return (async () => {
      return accessToken;
    })();
  }, []);

  const getAkcijeDropDownHandler = () => {
    if (subscription) return;
    apiCall(accessTokenHandler).then((token) => {
      fetch("/api/MasterData/GetTipoviAkcijeToLower", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setAkcijeDropDown(data.responseData);
        });
    });
  };
  const getRobneMarkeDropDownHandler = () => {
    if (subscription) return;
    apiCall(accessTokenHandler).then((token) => {
      fetch("/api/MasterData/GetRobneMarke", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setRobneMarkeDropDown(data.responseData);
        });
    });
  };

  const getRefrentDropDownHandler = () => {
    if (subscription) return;
    apiCall(accessTokenHandler).then((token) => {
      fetch("/api/MasterData/GetReferenti", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setReferentDropDown(data.responseData);
        });
    });
  };

  useEffect(() => {
    getAkcijeDropDownHandler();
    getRobneMarkeDropDownHandler();
    getRefrentDropDownHandler();
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      subscription = true;
    };
  }, [subscription]);

  const dataSource = useMemo(() => {
    return createStore({
      key: "id",
      loadUrl: `/api/Akcija/GetAkcije`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
        return apiCall(accessTokenHandler).then((token) => {
          return (ajaxOptions.headers = {
            Authorization: `Bearer ${token}`,
          });
        });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const akcijeRender = (data: any) => {
    let pocetakData = new Date(data.data.pocetak).toLocaleDateString("hr-HR");
    let datumDanas = new Date().toLocaleDateString("hr-HR");
    let odobreno: boolean = data.data.odobreno;
    let akcijaPocela: boolean = false;
    if (pocetakData === datumDanas) {
      akcijaPocela = true;
    } else {
      akcijaPocela = false;
    }
    let username = accounts[0].username;
    // if (odobreno) {
    //   return null;
    // }
    return (
      <AkcijeMenu
        username={username}
        data={data}
        promjenaBannera={(e) => {
          setOpenPromjenaBannera(true);
          setRowData(data);
        }}
        promjenaOpisa={() => {
          setOpenPromjenaOpisa(true);
          setRowData(data);
        }}
        promjenaArtikala={() => {
          setOpenPromjenaArtikala(true);
          setRowData(data);
        }}
        odobriAkciju={() => {
          setOdobrenjeAkcijaPotvrda(true);
          setRowData(data);
        }}
        odobreno={odobreno}
        akcijaPocela={akcijaPocela}
      />
    );
  };

  const porukaOdobrenaAkcija = (rezultat: boolean) => {
    setShowBackdrop(false);
    if (rezultat) {
      dataGrid?.current?.instance.refresh();
      Swal.fire({
        icon: "success",
        title: "Uspjeh",
        text: "Akcija je odobrena.",
      });
    } else {
      Swal.fire({
        title: "Greška!",
        icon: "error",
        text: "Akcija nije odobrena.",
      });
    }
  };

  const odobriAkcijuHandler = (nazivAkcije: string) => {
    setOdobrenjeAkcijaPotvrda(false);
    setShowBackdrop(true);
    apiCall(accessTokenHandler).then((token) => {
      const formData = new FormData();
      formData.append("nazivAkcije", nazivAkcije);
      fetch("/api/Akcija/OdobriAkciju", {
        method: "Post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          porukaOdobrenaAkcija(data.responseData);
        })
        .catch((error) => {
          porukaOdobrenaAkcija(false);
        });
    });
  };

  return (
    <>
      <DataGrid
        className={classes.dataGrid}
        ref={dataGrid}
        id="dataGrid"
        // keyExpr="imeAkcije"
        dataSource={dataSource}
        rowAlternationEnabled
        allowColumnReordering
        allowColumnResizing
        showBorders
        remoteOperations={true}
      >
        <MasterDetail enabled={true} component={Detalji} />
        <Paging defaultPageSize={20} />
        {/* <Pager
      visible={true}
      showPageSizeSelector={true}
      showInfo={true}
      allowedPageSizes={allowedPageSizes}
      infoText={`Stranica {0} od {1} ({2} akcija)`}
    />         */}
        <FilterRow visible={true} />
        <SearchPanel visible={true} />
        <Selection mode="single" />
        <Column dataField="tipAkcije" caption="Tip akcije" width={150}>
          <Lookup
            dataSource={akcijeDropDown}
            valueExpr="naziv"
            displayExpr="naziv"
          />
        </Column>
        <Column dataField="nazivAkcije" caption="Naziv akcije" />
        <Column dataField="brand" caption="Brand">
          <Lookup
            dataSource={robneMarkeDropDown}
            valueExpr="naziv"
            displayExpr="naziv"
          />
        </Column>
        <Column dataField="referent" caption="Referent">
          <Lookup
            dataSource={referentDropDown}
            valueExpr="nazivKorisnika"
            displayExpr="nazivKorisnika"
          />
        </Column>
        <Column
          dataField="pocetak"
          caption="Početak"
          dataType="date"
          format="dd.MM.yyyy"
          defaultSortIndex={0}
          defaultSortOrder="desc"
        />
        <Column
          dataField="kraj"
          caption="Kraj"
          dataType="date"
          format="dd.MM.yyyy"
          defaultSortIndex={1}
          defaultSortOrder="desc"
        />
        <Column
          allowReordering={false}
          allowSorting={false}
          dataField="odobreno"
          cellRender={odobrenoRender}
          caption="Odobreno"
          width={100}
        >
          <Lookup
            dataSource={odabranoLookup}
            valueExpr="value"
            displayExpr="display"
          />
        </Column>
        <Column
          allowReordering={false}
          allowSorting={false}
          cellRender={akcijeRender}
        />
      </DataGrid>
      {openPromjenaBannera && (
        <PromjenaBannera
          openPromjenaBannera={openPromjenaBannera}
          setOpenPromjenaBannera={setOpenPromjenaBannera}
          rowData={rowData}
          dataGrid={dataGrid}
        />
      )}
      {openPromjenaOpisa && (
        <PromjenaOpisa
          openPromjenaOpisa={openPromjenaOpisa}
          setOpenPromjenaOpisa={setOpenPromjenaOpisa}
          rowData={rowData}
        />
      )}
      {openPromjenaArtikala && (
        <PromjenaArtikala
          openPromjenaArtikala={openPromjenaArtikala}
          setOpenPromjenaArtikala={setOpenPromjenaArtikala}
          rowData={rowData}
        />
      )}
      {odobrenjeAkcijaPotvrda && (
        <OdobrenjeAkcijePotvrda
          rowData={rowData}
          closeHandler={(e) => {
            setOdobrenjeAkcijaPotvrda(false);
          }}
          saveHandler={odobriAkcijuHandler}
        />
      )}
      {showBackdrop ? <BackDrop /> : null}
    </>
  );
};

export default React.memo(AkcijePrikazTablica);
