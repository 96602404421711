import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import classes from "./PromjenaBannera.module.css";
import { useState, useCallback, useEffect } from "react";
import useApi from "../Hooks/useApi";
import FileInput from "../FileInput/FileInput";
import { AlertModel } from "../UnosAkcijePrilike/UnosAkcijePrilikeTypes";
import { provjeraDatoteke } from "./provjeraDatotekeAkcije";
import { Dialog, DialogContent } from "@mui/material";
import Swal from "sweetalert2";
import BackDrop from "../UnosAkcijePrilike/BackDrop";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  //   p: 4,
  overflow: "auto",
  height: "95%",
  overflowX: "hidden",
  width: "70%",
  backgroundColor: "rgb(243, 243, 244)",
};

type PromjenaBanneraProps = {
  openPromjenaBannera: boolean;
  setOpenPromjenaBannera: (value: boolean) => void;
  rowData: any;
  dataGrid: any;
};

type SlikaInfo = {
  readonly name: string;
  readonly width: number;
  readonly height: number;
  readonly size: number;
  readonly src: string;
  readonly file: File;
  readonly inputLabel: string | null;
};

export default function PromjenaBanneraModal(props: PromjenaBanneraProps) {
  const [banner506, setBanner506] = useState<SlikaInfo | null>();
  const [banner1140, setBanner1140] = useState<SlikaInfo | null>();
  const [banner1200, setBanner1200] = useState<SlikaInfo | null>();
  const [banner600, setBanner600] = useState<SlikaInfo | null>();
  const [data, setData] = useState<any>(null);
  const [dialog, setOpen] = useState<AlertModel>({ open: false, message: "" });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleDialogClose = () => {
    setOpen({ open: false, message: "" });
  };

  const ApiCall = useApi();
  const getAkcijaDetaljiHandler = useCallback(
    (accessToken: string) => {
      (async () => {
        const response = await fetch(
          `/api/Akcija/GetAkcijaDetails?imeAkcije=${props.rowData.data.nazivAkcije}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        const data = await response.json();
        setData(data.responseData);
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props]
  );

  useEffect(() => {
    if (props.rowData) {
      ApiCall(getAkcijaDetaljiHandler);
    }
  }, [ApiCall, getAkcijaDetaljiHandler, props.rowData]);

  const ucitajSliku = (event: React.SyntheticEvent) => {
    if (!(event.target instanceof HTMLInputElement)) return;
    if (event.target.files) {
      const input = event.target.getAttribute("data-input");
      provjeraDatoteke(
        event.target.files,
        input,
        setOpen,
        setBanner506,
        setBanner1140,
        setBanner1200,
        setBanner600
      );
    }
  };

  const post = (accessToken: string) => {
    // props.setOpenPromjenaBannera(false);
    setIsLoading(true);
    let formData = new FormData();
    formData.append("akcijaID", props.rowData.data.id.toString());
    if (banner506) {
      formData.append("slike", banner506.file);
    }
    if (banner600) {
      formData.append("slike", banner600.file);
    }
    if (banner1140) {
      formData.append("slike", banner1140.file);
    }
    if (banner1200) {
      formData.append("slike", banner1200.file);
    }

    fetch("api/Akcija/UpdateBannera", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      method: "post",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseData) {
          props.dataGrid?.current?.instance.refresh();
          setIsLoading(false);
          Swal.fire({
            icon: "success",
            title: "Spremljeno",
            customClass: {
              container: classes.swalZindex
            },
          }).then((result) => {
            if(result.isConfirmed){
              props.setOpenPromjenaBannera(false);
              setBanner506(null);
              setBanner1140(null);
              setBanner1200(null);
              setBanner600(null);
            }
          })
        }else {
          setIsLoading(false);
          Swal.fire({
            title: "Greška!",
            showConfirmButton: false,
            toast: true,
            customClass: {
              container: classes.swalContainer,
              popup: classes.swal2IconError,
            },
            timer: 1500,
            iconColor: "white",
            timerProgressBar: true,
            icon: "warning",
            position: "top-right",
          });
        }
      });
  };

  const updateBannere = () => {
    ApiCall(post);
  };

  return (
    <>
      <Modal
        open={props.openPromjenaBannera}
        onClose={() => {
          setBanner506(null);
          setBanner1140(null);
          setBanner1200(null);
          setBanner600(null);
          props.setOpenPromjenaBannera(false);
        }}
        aria-labelledby="promjenaBannera"
        aria-describedby="promjenaBannera"
      >
        <Box sx={style}>
          <div className={classes.btnZatvoriWrapper}>
            <Button
              onClick={() => {
                setBanner506(null);
                setBanner1140(null);
                setBanner1200(null);
                setBanner600(null);
                props.setOpenPromjenaBannera(false);
              }}
            >
              Zatvori
            </Button>
          </div>
          {data && (
            <div className={classes.modalContentWrapper}>
              <div className={classes.slikaWrapper}>
                <span className={classes.label}>Banner 1140px*230px</span>
                <img
                  className={classes.slika}
                  src={banner1140 ? banner1140.src : data.slike.billboard}
                  alt=""
                />
                <div className={classes.btnWrapper}>
                  <FileInput
                    label="Banner 1140px*230px jpg, obavezno bez CTA gumba, minimalna veličina slova 12p"
                    // label="Banner 506px*230px jpg, obavezno bez CTA gumba, minimalna veličina slova 12px"
                    multiple={false}
                    extension={".jpg"}
                    ucitajSliku={ucitajSliku}
                  />
                </div>
              </div>
              <div className={classes.slikaWrapper}>
                <span className={classes.label}>Banner 1200px*628px</span>
                <img
                  className={classes.slika}
                  src={banner1200 ? banner1200.src : data.slike.fb}
                  alt=""
                />
                <div className={classes.btnWrapper}>
                  <FileInput
                    label="Banner 1200px x 628px jpg, Banner za Facebook, obavezno bez CTA gumba, manje od 20% površine slike je text obavezno, po mogučnosti savjetuje se izbjegavati sva velika slova, velike uskličnike, ne koristiti riječi kao što su 'Sigurnost', 'Osiguranje', 'sigurno', 'garantiramo','zajamčeno','uz registraciju' ,'uz obaveznu registraciju', 'popust do xx%', 'do xx%'."
                    // label="Banner 1140px*230px jpg, obavezno bez CTA gumba, minimalna veličina slova 12p"
                    multiple={false}
                    extension={".jpg"}
                    ucitajSliku={ucitajSliku}
                  />
                </div>
              </div>
              <div className={classes.slikaWrapper}>
                <span className={classes.label}>Banner 506px*230px*</span>
                <img
                  className={classes.slika}
                  src={banner506 ? banner506.src : data.slike.mozaik}
                  alt=""
                />
                <div className={classes.btnWrapper}>
                  <FileInput
                    label="Banner 506px*230px jpg, obavezno bez CTA gumba, minimalna veličina slova 12px"
                    // label="Banner 1200px x 628px jpg, Banner za Facebook, obavezno bez CTA gumba, manje od 20% površine slike je text obavezno, po mogučnosti savjetuje se izbjegavati sva velika slova, velike uskličnike, ne koristiti riječi kao što su 'Sigurnost', 'Osiguranje', 'sigurno', 'garantiramo','zajamčeno','uz registraciju' ,'uz obaveznu registraciju', 'popust do xx%', 'do xx%'."
                    multiple={false}
                    extension={".jpg"}
                    ucitajSliku={ucitajSliku}
                  />
                </div>
              </div>
              <div className={classes.slikaWrapper}>
                <span className={classes.label}>Banner 600px*500px</span>
                <img
                  className={classes.slika}
                  src={banner600 ? banner600.src : data.slike.rect}
                  alt=""
                />
                <div className={classes.btnWrapper}>
                  <FileInput
                    label="Banner 600px*500px jpg, obavezno sa CTA gumbom, minimalna veličina slova 12px, obavezno navesti puno trajanje akcije."
                    multiple={false}
                    extension={".jpg"}
                    ucitajSliku={ucitajSliku}
                  />
                </div>
              </div>
            </div>
          )}
          {(banner506 || banner1140 || banner1200 || banner600) && (
            <Button
              onClick={() => {
                updateBannere();
              }}
              style={{ marginBottom: "30px" }}
              color="success"
              fullWidth
              variant="contained"
            >
              Spremi slike
            </Button>
          )}
        </Box>
      </Modal>
      <Dialog onClose={handleDialogClose} open={dialog.open}>
        <DialogContent className={classes.dialog}>
          <label>{dialog.message}</label>
          <div style={{ textAlign: "end" }}>
            <Button onClick={handleDialogClose}>Zatvori</Button>
          </div>
        </DialogContent>
      </Dialog>
      {isLoading && <BackDrop />}
    </>
  );
}
