import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useCallback, useEffect, useState } from "react";
import useApi from "../../Hooks/useApi";
import Swal from "sweetalert2";
import classes from "./Navbar.module.css";
import BackDrop from "../../UnosAkcijePrilike/BackDrop";
import { AlertModel } from "../../UnosAkcijePrilike/UnosAkcijePrilikeTypes";
import { Dialog, DialogContent, TextField } from "@mui/material";
import { Kategorija } from "./NavbarKlasTypes";
import Autocomplete from "@mui/material/Autocomplete";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "98%",
  height: "98%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
};

type AddModalProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

type Redoslijed = {
  id: number;
  redoslijed: number;
}

const RedoslijedFull = [
  {
    id: 1,
    redoslijed: 1,
  },
  {
    id: 2,
    redoslijed: 2,
  },
  {
    id: 3,
    redoslijed: 3,
  },
  {
    id: 4,
    redoslijed: 4,
  },
  {
    id: 5,
    redoslijed: 5,
  },
  {
    id: 6,
    redoslijed: 6,
  },
  {
    id: 7,
    redoslijed: 7,
  },
  {
    id: 8,
    redoslijed: 8,
  },
  {
    id: 9,
    redoslijed: 9,
  },
  {
    id: 10,
    redoslijed: 10,
  },
  {
    id: 11,
    redoslijed: 11,
  },
];

const AddModal = (props: AddModalProps) => {
  const apiCall = useApi();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dialog, setOpen] = useState<AlertModel>({ open: false, message: "" });
  const [showSpremi, setShowSpremi] = useState<boolean>(false);
  const [kategorija1, setKategorija1] = useState<Kategorija[]>([]);
  const [kategorija2, setKategorija2] = useState<Kategorija[]>([]);
  const [redoslijedi, setRedoslijedi] = useState<Redoslijed[]>([]);
  // const [dostupniRedoslijedi, setDostupniRedoslijedi] =
  //   useState<Redoslijed[]>(RedoslijedFull);
  const [kategorija, setKategorija] = useState<Kategorija[]>([]);
  const [type, setType] = useState<string>("");
  const [linkText, setLinkText] = useState<string>("");
  const [linkTitle, setLinkTitle] = useState<string>("");

  const [odabranaKategorija, setOdabranaKategorija] = useState<any>(null);
  const [odabraniLvl1, setOdabraniLvl1] = useState<any>(null);
  const [odabraniLvl2, setOdabraniLvl2] = useState<any>(null);
  const [odabraniRedoslijed, setOdabraniRedoslijed] = useState<any>(null);
  let kategorija1Cancel = false;
  let kategorija2Cancel = false;
  let redoslijedCancel = false;
  let kategorijaCancel = false;

  const handleDialogClose = () => {
    setOpen({ open: false, message: "" });
  };
  const handleClose = () => {
    props.setOpen(false);
  };

  useEffect(() =>{
    if(kategorija && odabraniRedoslijed && linkText.length > 0 && linkTitle.length > 0 &&  type.length > 0) {
      setShowSpremi(true);
    }else {
      setShowSpremi(false);

    }
  },[kategorija, linkText.length, linkTitle.length, odabraniRedoslijed, type.length])
  
  // const dostupni = () => {
  //   redoslijedi.forEach(redoslijed => {
  //     let index = dostupniRedoslijedi.findIndex((x) => {x.redoslijed === redoslijed.redoslijed});
  //     if(index !== -1){
  //       dostupniRedoslijedi.splice(index, 1);
  //     }
  //   });
  // }

  const getKategorijeProizvoda1 = (accessToken: string) => {
    fetch("/api/MasterData/GetKategorije1Proizvoda", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (kategorija1Cancel) return;
        setKategorija1(data.responseData);
      });
  };

  useEffect(() => {
    apiCall(getKategorijeProizvoda1);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      kategorija1Cancel = true;
    };
  }, [apiCall]);

  const getKategorijeProizvoda2 = (accessToken: string) => {
    fetch(`/api/MasterData/GetKategorije2Proizvoda?lvl1Id=${odabraniLvl1.id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (kategorija2Cancel) return;
        setKategorija2(data.responseData);
      });
  };

  useEffect(() => {
    if(odabraniLvl1 !== null){
      apiCall(getKategorijeProizvoda2);
      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        kategorija2Cancel = true;
      };
    }
    
  }, [apiCall, odabraniLvl1]);

  const getRedoslijed = (accessToken: string) => {
    fetch("/api/Navbar/GetRedoslijed", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (redoslijedCancel) return;
        setRedoslijedi(data.responseData);
        // dostupni();
      });
  };

  useEffect(() => {
    apiCall(getRedoslijed);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      redoslijedCancel = true;
    };
  }, [apiCall]);

  const getKategorija = (accessToken: string) => {
    fetch("/api/MasterData/GetKategorijeProizvoda", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (kategorijaCancel) return;
        setKategorija(data.responseData);
        // dostupni();
      });
  };

  useEffect(() => {
    apiCall(getKategorija);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      kategorijaCancel = true;
    };
  }, [apiCall]);

  const spremiSeo = useCallback((accessToken: string) => {
    setIsLoading(true);
    let formData = new FormData();
    formData.append("klasifikacija", odabranaKategorija.id);
    formData.append("type", type);
    formData.append("linkTitle", linkTitle);
    formData.append("linkText", linkText);
    formData.append("redoslijed", odabraniRedoslijed.label);
    if(odabraniLvl1){
      formData.append("level1", odabraniLvl1.id);
    }
    if(odabraniLvl2){
      formData.append("level2", odabraniLvl2.id);
    }

    fetch("api/Navbar/NewNavbar", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      method: "post",
      body: formData,
    }).then((response) => {
      if (response.status === 200) {
        props.setOpen(false);
        setIsLoading(false);
        Swal.fire({
          icon: "success",
          title: "Spremljeno",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      } else {
        setIsLoading(false);
        Swal.fire({
          title: response.statusText,
          showConfirmButton: false,
          toast: true,
          customClass: {
            container: classes.swalContainer,
            popup: classes.swal2IconError,
          },
          timer: 1500,
          iconColor: "white",
          timerProgressBar: true,
          icon: "warning",
          position: "top-right",
        });
      }
    });
  }, [linkText, linkTitle, odabranaKategorija, odabraniLvl1, odabraniLvl2, odabraniRedoslijed, props, type]);

  const post = () => {
    apiCall(spremiSeo);
  };

  return (
    <>
      <div>
        <Modal
          open={props.open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div style={{ height: "100%", width: "100%" }}>
              <div>
              <Autocomplete
                  className="bg-white m-0"
                  disablePortal
                  id="combo-box-demo"
                  options={kategorija.map((x) => {
                    return { label: x.naziv, id: x.id };
                  })}
                  renderInput={(params) => (
                    <TextField
                      // error={props.error}
                      {...params}
                      label={"Kategorija"}
                      style={{ marginBottom: "1em" }}
                    />
                  )}
                  size="small"
                  fullWidth
                  // value={}
                  // onChange={(event, value, reason) => {
                  //   if (reason === "selectOption") {
                  //     props.handleChange(value);
                  //   }
                  //   if (reason === "clear") {
                  //     props.handleChange(null);
                  //   }
                  // }}
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value.id;
                  }}
                  getOptionLabel={(option) => {
                    return `${option.id} - ${option.label}`;
                  }}
                  onChange={(event, value, reason) => {
                    if (reason === "selectOption") {
                      if(value){
                        setOdabranaKategorija(value)

                      }
                    }
                    if (reason === "clear") {
                      setOdabranaKategorija(null);
                    }
                  }}
                  value={odabranaKategorija}
                />
                <Autocomplete
                  className="bg-white m-0"
                  disablePortal
                  id="combo-box-demo"
                  options={kategorija1.map((x) => {
                    return { label: x.naziv, id: x.id };
                  })}
                  renderInput={(params) => (
                    <TextField
                      // error={props.error}
                      {...params}
                      label={"Level 1"}
                      style={{ marginBottom: "1em" }}
                    />
                  )}
                  size="small"
                  fullWidth
                  // value={}
                  // onChange={(event, value, reason) => {
                  //   if (reason === "selectOption") {
                  //     props.handleChange(value);
                  //   }
                  //   if (reason === "clear") {
                  //     props.handleChange(null);
                  //   }
                  // }}
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value.id;
                  }}
                  getOptionLabel={(option) => {
                    return `${option.id} - ${option.label}`;
                  }}
                  onChange={(event, value, reason) => {
                    if (reason === "selectOption") {
                      if(value){
                        setOdabraniLvl1(value)

                      }
                    }
                    if (reason === "clear") {
                      setOdabraniLvl1(null);
                    }
                  }}
                />
                <Autocomplete
                  className="bg-white m-0"
                  disablePortal
                  id="combo-box-demo"
                  options={kategorija2.map((x) => {
                    return { label: x.naziv, id: x.id };
                  })}
                  renderInput={(params) => (
                    <TextField
                      // error={props.error}
                      {...params}
                      label={"Level 2"}
                      style={{ marginBottom: "1em" }}
                    />
                  )}
                  size="small"
                  fullWidth
                  // value={}
                  // onChange={(event, value, reason) => {
                  //   if (reason === "selectOption") {
                  //     props.handleChange(value);
                  //   }
                  //   if (reason === "clear") {
                  //     props.handleChange(null);
                  //   }
                  // }}
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value.id;
                  }}
                  getOptionLabel={(option) => {
                    return `${option.id} - ${option.label}`;
                  }}
                  onChange={(event, value, reason) => {
                    if (reason === "selectOption") {
                      if(value){
                        setOdabraniLvl2(value)

                      }
                    }
                    if (reason === "clear") {
                      setOdabraniLvl2(null);
                    }
                  }}
                />
                <Autocomplete
                  className="bg-white m-0"
                  disablePortal
                  id="combo-box-demo"
                  options={RedoslijedFull.filter(
                    (item) =>
                      redoslijedi.findIndex(
                        (value) => value.redoslijed === item.redoslijed
                      ) < 0
                  ).map((x) => ({ label: x.redoslijed, id: x.id }))}
                  renderInput={(params) => (
                    <TextField
                      // error={props.error}
                      {...params}
                      label={"Redoslijed"}
                      style={{ marginBottom: "1em" }}
                    />
                  )}
                  size="small"
                  fullWidth
                  // value={}
                  // onChange={(event, value, reason) => {
                  //   if (reason === "selectOption") {
                  //     props.handleChange(value);
                  //   }
                  //   if (reason === "clear") {
                  //     props.handleChange(null);
                  //   }
                  // }}
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value.id;
                  }}
                  getOptionLabel={(option) => {
                    return `${option.label}`;
                  }}
                  onChange={(event, value, reason) => {
                    if (reason === "selectOption") {
                      if(value){
                        setOdabraniRedoslijed(value)

                      }
                    }
                    if (reason === "clear") {
                      setOdabraniRedoslijed({id:-1, label: ""});
                    }
                  }}
                />
                
                <TextField
                  value={type}
                  style={{ marginBottom: "1em" }}
                  fullWidth
                  id="type"
                  label="Type"
                  variant="outlined"
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                  size="small"
                />
                <TextField
                  value={linkText}
                  style={{ marginBottom: "1em" }}
                  fullWidth
                  id="linkText"
                  label="Link text"
                  variant="outlined"
                  onChange={(e) => {
                    setLinkText(e.target.value);
                  }}
                  size="small"
                />
                <TextField
                  value={linkTitle}
                  style={{ marginBottom: "1em" }}
                  fullWidth
                  id="linkTitle"
                  label="Link title"
                  variant="outlined"
                  onChange={(e) => {
                    setLinkTitle(e.target.value);
                  }}
                  size="small"
                />
              </div>
            </div>
            {showSpremi && (
              <Button
                style={{ backgroundColor: "rgb(26, 179, 148)" }}
                variant="contained"
                onClick={() => {
                  post();
                }}
              >
                Spremi
              </Button>
            )}
          </Box>
        </Modal>
      </div>
      {dialog.open && (
        <Dialog onClose={handleDialogClose} open={dialog.open}>
          <DialogContent className={classes.dialog}>
            <label>{dialog.message}</label>
            <div style={{ textAlign: "end" }}>
              <Button onClick={handleDialogClose}>Zatvori</Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
      {isLoading && <BackDrop />}
    </>
  );
};

export default AddModal;
