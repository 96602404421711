import produce, { Draft } from "immer";
import { NeRadniDaniActions } from "./NeRadniDaniActions";
import { NeRadniDaniModel } from "./NeRadniDaniTypes";

const neRadniDaniReducer = produce(
  (oldState: Draft<NeRadniDaniModel>, action: NeRadniDaniActions) => {
    switch (action.action) {
      case "DATUM_ODABRAN":
        let min = -2;
        let temp;
        let nextMinIndex;
        temp = oldState.addDani.map((object) => {
          return object.id;
        });
        if (temp.length > 0) {
          min = Math.min(...temp);
        }

        nextMinIndex = min - 1;

        let index = oldState.addDani.findIndex((x) => {
          return (
            new Date(x.datum).toString() ===
            new Date(action.value ?? "").toString()
          );
        });

        if (index === -1) {
          oldState.addDani.unshift({
            id: nextMinIndex,
            datum: new Date(action.value ?? "").toString(),
          });
        } else {
          oldState.addDani.splice(index, 1);
        }

        break;
      case "DATUM_CHANGE":
        let index2 = oldState.neRadniDani.findIndex((x) => {
          return x.datum === action.value.old;
        });
        oldState.neRadniDani[index2].datum = action.value.new;
        break;
      case "GET_NERADNI_DANI":
        // oldState.addEditDani = action.value.map((date) => ({
        //   id: date.id,
        //   datum: new Date(date.datum).toString(),
        // }));
        oldState.neRadniDani = action.value.map((date) => ({
          id: date.id,
          datum: new Date(date.datum).toString(),
        }));

        break;
      default:
        break;
    }
  }
);

export default neRadniDaniReducer;
