type SlikaInfo = {
     name: string,
     width: number,
     height: number,
     size: number,
     src: string,
     file: File,
     inputLabel: string | null
}


const loadImageAsync = (file:File, input:string | null = "") => {
    return new Promise<SlikaInfo>((resolve,reject) => {
        const reader = new FileReader();
        const velicinaKB = (file.size / (1024));
        const ekstenzija = file.name.replace(/^.*\./, '').toLowerCase();        
        try {
            switch (ekstenzija) {
                case "pdf":
                    return resolve({
                        file: file,
                        height: 0,
                        width: 0,
                        size: velicinaKB,
                        inputLabel: input,
                        name: file.name,
                        src: ""
                    });
                default:
                    reader.onload = (event) => {
                        const img = new Image()
                        if (event.target && typeof (event.target.result) == "string") {
                            img.src = event.target.result
            
                            img.onload = () => {
                                resolve({
                                    name: file.name,
                                    width:img.width,
                                    height:img.height,
                                    size: velicinaKB,
                                    file: file,
                                    src: img.src,
                                    inputLabel: input
                                });
                            }
                        }
                    }
                    
                    reader.readAsDataURL(file);
                    break;
            }            
        } catch (error) {
            reject(error);
        }
    })
} 


export {loadImageAsync};
export type { SlikaInfo };