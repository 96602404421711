import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import classes from "./PromjenaOpisa.module.css";
import { useState, useCallback, useEffect, useReducer } from "react";
import useApi from "../Hooks/useApi";
import Swal from "sweetalert2";
import BackDrop from "../UnosAkcijePrilike/BackDrop";
import ArtikliTablica from "./ArtikliTablica";
import promjenaArtikalaReducer from "./promjenaArtikalaReducer";
import { ArtiklViewModel, Data, DefaultState } from "./PromjenaArtikalaTypes";
import { TextField } from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  //   p: 4,
  overflow: "auto",
  overflowX: "hidden",
  backgroundColor: "rgb(243, 243, 244)",
  width: "80%",
  height: "100%",
};

type PromjenaArtikalaProps = {
  openPromjenaArtikala: boolean;
  setOpenPromjenaArtikala: (value: boolean) => void;
  rowData: any;
};

export default function PromjenaArtikala(props: PromjenaArtikalaProps) {
  const apiCall = useApi();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<Data | null>(null);
  const [artikliNotValid, setArtikliNotValid] = useState<boolean>(false);

  const defaultState: DefaultState = {
    artikli: [],
    artikliCheckCijena: [],
    kljuc: "",
  };
  const [podatci, dispatcher] = useReducer(
    promjenaArtikalaReducer,
    defaultState
  );

  //   const handleDialogClose = () => {
  //     setOpen({ open: false, message: "" });
  //   };

  const ApiCall = useApi();
  const encodeUri = encodeURIComponent(props.rowData.data.nazivAkcije);

  const getAkcijaDetaljiHandler = useCallback(
    (accessToken: string) => {
      (async () => {
        const response = await fetch(
          `/api/Akcija/AkcijaEditArtikli?imeAkcije=${encodeUri}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        const data = await response.json();
        if (data.responseData) {
          setData(data.responseData);
          dispatcher({
            action: "GET_ARTIKLI",
            value: data.responseData.artiklCijenaViewModels,
          });
        }
      })();
    },
    [encodeUri]
  );

  useEffect(() => {
    if (props.rowData) {
      ApiCall(getAkcijaDetaljiHandler);
    }
  }, [ApiCall, getAkcijaDetaljiHandler, props.rowData]);

  const checkArtikle = (accessToken: string) => {
    let formData = new FormData();
    if (data?.od) {
      formData.append("akcijaOd", data.od);
    }

    if (data?.do) {
      formData.append("akcijaDo", data.do);
    }
    if (data?.imeAkcije) {
      formData.append("vrstaAkcije", data.imeAkcije);
    }
    if (podatci.artikli.length > 0) {
      for (let i = 0; i < podatci.artikli.length; i++) {
        const element = podatci.artikli[i];
        formData.append(`artikli[${i}].SifraArtikla`, element.SifraArtikla);
        formData.append(
          `artikli[${i}].Cijena`,
          element.Cijena.toString().replace(".", ",")
        );
        formData.append(
          `artikli[${i}].Support`,
          element?.support?.toString() ?? ""
        );
        formData.append(
          `artikli[${i}].BesplatnaDostava`,
          element.besplatnaDostava.toString()
        );
        formData.append(
          `artikli[${i}].SifraOsiguranja`,
          element.sifraOsiguranja?.toString() ?? ""
        );
        formData.append(
          `artikli[${i}].ReferentniBroj`,
          element.referentniBroj ?? ""
        );
        formData.append(
          `artikli[${i}].DodatniRabatKomisija`,
          element.dodatniRabatKomisija?.toString().replace(".", ",") ?? ""
        );
        formData.append(
          `artikli[${i}].AkcijskaNettoCijenaKomisija`,
          element.akcijskaNettoNabavnaCijenaKomisija?.toString().replace(".", ",") ?? ""
        );
      }
    }
    if (data?.kategorijaSifra) {
      formData.append("kategorijaID", data.kategorijaSifra);
    }
    if (data?.akcijaID) {
      formData.append("akcijaID", data.akcijaID.toString());
    }
    formData.append("kljuc", podatci.kljuc);
    fetch("api/Akcija/CheckArtiklCijena", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      method: "post",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        let arr = podatci.artikli;
        let arr2 = data.responseData;
        let tempArr: ArtiklViewModel[] = [];
        let tempOrderBy: ArtiklViewModel[] = [];
        for (let i = 0; i < arr.length; i++) {
          const element = arr[i];
          let test = arr2.find(
            (i: any) => i.artikl === parseInt(element.SifraArtikla)
          );
          let tempObj: ArtiklViewModel = {
            Cijena: element.Cijena,
            SifraArtikla: element.SifraArtikla,
            greska: "",
            isOk: true,
            promoKod: element.promoKod,
            katBroj: "",
            naziv: "",
            besplatnaDostava: element.besplatnaDostava,
            sifraOsiguranja: element.sifraOsiguranja,
            nazivOsiguranja: element.nazivOsiguranja,
            support: element.support,
            referentniBroj: element.referentniBroj,
            akcijskaNettoNabavnaCijenaKomisija: element.akcijskaNettoNabavnaCijenaKomisija,
            dodatniRabatKomisija: element.dodatniRabatKomisija
          };
          if (test) {
            tempObj.greska = test?.greska;
            tempObj.isOk = test.isOk;
            tempObj.katBroj = test?.katBroj;
            tempObj.naziv = test?.naziv;
          }
          tempArr.push(tempObj);
        }
        tempArr.forEach((element) => {
          if (element.isOk) {
            tempOrderBy.push(element);
          } else {
            tempOrderBy.unshift(element);
          }
        });
        if (tempOrderBy.length > 0) {
          dispatcher({ action: "ARTIKLI_CHECK", value: tempOrderBy });
        }
      });
  };

  const apiCallCheckArtikle = () => {
    apiCall(checkArtikle);
  };

  useEffect(() => {
    let counter = 0;
    podatci.artikli.forEach((element) => {
      if (element.isOk === false) {
        counter++;
      }
      if (counter > 0) {
        setArtikliNotValid(true);
      } else {
        setArtikliNotValid(false);
      }
    });
  }, [podatci.artikli]);

  const SpremiArtikle = (accessToken: string) => {
    setIsLoading(true);
    let formData = new FormData();
    if (data) {
      formData.append("imeAkcije", data?.imeAkcije);
    }
    if (podatci.artikli.length > 0) {
      for (let i = 0; i < podatci.artikli.length; i++) {
        const element = podatci.artikli[i];
        formData.append(`artikli[${i}].SifraArtikla`, element.SifraArtikla);
        formData.append(
          `artikli[${i}].Cijena`,
          element.Cijena.toString().replace(".", ",")
        );
        formData.append(
          `artikli[${i}].PromoKod`,
          element.promoKod?.toString() ?? ""
        );
        formData.append(
          `artikli[${i}].BesplatnaDostava`,
          element.besplatnaDostava.toString()
        );
        formData.append(`artikli[${i}].KatBroj`, element.katBroj);
        formData.append(`artikli[${i}].Naziv`, element.naziv);
        formData.append(
          `artikli[${i}].SifraOsiguranja`,
          element.sifraOsiguranja?.toString() ?? ""
        );
        formData.append(
          `artikli[${i}].Support`,
          element.support?.toString()?.replace(".", ",") ?? ""
        );
        formData.append(
          `artikli[${i}].ReferentniBroj`,
          element.referentniBroj?.toString() ?? ""
        );
        formData.append(
          `artikli[${i}].DodatniRabatKomisija`,
          element.dodatniRabatKomisija?.toString().replace(".", ",") ?? ""
        );
        formData.append(
          `artikli[${i}].AkcijskaNettoNabavnaCijenaKomisija`,
          element.akcijskaNettoNabavnaCijenaKomisija?.toString().replace(".", ",") ?? ""
        );
      }
    }
    fetch("api/Akcija/UpdateArtikala", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      method: "post",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseData) {
          setIsLoading(false);
          Swal.fire({
            icon: "success",
            title: "Spremljeno",
            customClass: {
              container: classes.containerSwal,
            },
          }).then((result) => {
            if (result.isConfirmed) {
              props.setOpenPromjenaArtikala(false);
            }
          });
        } else {
          setIsLoading(false);
          Swal.fire({
            title: "Greška!",
            showConfirmButton: false,
            toast: true,
            customClass: {
              container: classes.swalContainer,
              popup: classes.swal2IconError,
            },
            timer: 1500,
            iconColor: "white",
            timerProgressBar: true,
            icon: "warning",
            position: "top-right",
          });
        }
      });
  };

  const apiCallSpremiArtikle = () => {
    apiCall(SpremiArtikle);
  };

  return (
    <>
      <Modal
        open={props.openPromjenaArtikala}
        onClose={() => {
          setData(null);
          props.setOpenPromjenaArtikala(false);
        }}
        aria-labelledby="promjenaArtikala"
        aria-describedby="promjenaArtikala"
      >
        <Box sx={style}>
          <div className={classes.headerWrapper}>
            <span className={classes.naslov}>Promjena artikala</span>
            <Button
              onClick={() => {
                props.setOpenPromjenaArtikala(false);
              }}
            >
              Zatvori
            </Button>
          </div>
          <div className={classes.modalContentWrapper}>
            {data && (
              <div className={classes.detaljiWrapper}>
                <div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <div>
                        <span>{data?.brand}</span>
                      </div>
                      <div
                        style={{
                          fontSize: "0.8em",
                        }}
                      >
                        <span>{data?.promoKod}</span>
                      </div>
                    </div>
                    <div style={{ fontWeight: "600" }}>
                      <span>{`${new Date(data?.od).toLocaleDateString(
                        "hr-HR"
                      )} - `}</span>
                      <span>
                        {new Date(data?.do).toLocaleDateString("hr-HR")}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{ fontWeight: 600, textTransform: "uppercase" }}
                    >{`${data?.tip}`}</span>
                    <span>{data?.imeAkcije}</span>
                  </div>
                </div>
              </div>
            )}
            <ArtikliTablica
              artikli={
                podatci.artikliCheckCijena.length > 0
                  ? podatci.artikliCheckCijena
                  : podatci.artikli
              }
              addArtikl={(value: ArtiklViewModel) => {
                dispatcher({ action: "DODAJ_ARTIKL", value: value });
              }}
              deleteArtikl={(value: string) =>
                dispatcher({ action: "BRISI_ARTIKL", value: value })
              }
              updateArtikl={(value: any) =>
                dispatcher({ action: "UPDATE_ARTIKL", value: value })
              }
              tip={data?.tip}
            />
            <div
              style={{
                marginBottom: "20px",
              }}
            >
              <Button
                onClick={() => {
                  apiCallCheckArtikle();
                }}
                variant="contained"
                style={{ marginRight: "20px" }}
              >
                Provjeri artikle
              </Button>
              <TextField
                value={podatci.kljuc}
                onChange={(e) => {
                  dispatcher({
                    action: "KLJUC_HANDLER",
                    value: e.target.value,
                  });
                }}
                size="small"
                label="Ključ"
                id="promjenaArtikalaKluc"
                style={{
                  backgroundColor:"white"
                }}
              />
            </div>

            {!artikliNotValid && (
              <Button
                onClick={() => {
                  apiCallSpremiArtikle();
                }}
                style={{ marginBottom: "10px" }}
                color="success"
                fullWidth
                variant="contained"
              >
                Spremi promjene
              </Button>
            )}
          </div>
        </Box>
      </Modal>
      {/* <Dialog onClose={handleDialogClose} open={dialog.open}>
        <DialogContent className={classes.dialog}>
          <label>{dialog.message}</label>
          <div style={{ textAlign: "end" }}>
            <Button onClick={handleDialogClose}>Zatvori</Button>
          </div>
        </DialogContent>
      </Dialog> */}
      {isLoading && <BackDrop />}
    </>
  );
}
