import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import './AkcijePrikazTablica.css';

type BannerCardProps = {
    url: string;
    naslov: string;
    dimenzije: string;
    setDetailViewOpen: (value: boolean) => void;
    setImage: (value: any) => void;

}

export default function BannerCard(props:BannerCardProps) {
  return (
    <Card onClick={() => {props.setImage(props.url); props.setDetailViewOpen(true);}} sx={{ maxWidth: 545 }} style={{backgroundColor:"gainsboro", fontSize:"0.7rem"}}>
      <CardMedia
        component="img"
        height="230"
        image={props.url}
        alt={props.dimenzije}
      />
      <CardContent
        style={{
          display:'flex',
          flexDirection:'row',
          justifyContent:'space-between',
          padding:'8px 16px',
          borderTop:'1px solid darkgrey'
        }}
      >
        <Typography
          gutterBottom
          variant="h3"
          component="div"
          className="typo"
        >
          {props.naslov}
        </Typography>
        <Typography
          className="typo"
          variant="body2">
          {props.dimenzije}
        </Typography>
      </CardContent>      
    </Card>
  );
}