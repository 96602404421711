import React, { useCallback, useState, useMemo, useRef } from 'react';
import { Dialog, DialogContent, Button } from "@mui/material";
import {
  DataGrid,
  Column,
  Selection,
  Paging,
  FilterRow,
  Toolbar,
  Item,
  MasterDetail
} from "devextreme-react/data-grid";
import { SearchPanel } from "devextreme-react/tree-list";
import "devextreme/dist/css/dx.light.css";
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { PlusSquare } from "react-bootstrap-icons";
import { Button as ButtonMui } from "@mui/material";
import useApiPromise from "../Hooks/useApiPromise";
import classes from './PosebneOsobine.module.css';
import AkcijeMenu from './AkcijeMenu'
import AddEditPosebnaOsobina from './AddEditPosebnaOsobina';
import Detalji from './Detalji';
import BackDrop from '../UnosAkcijePrilike/BackDrop';
import { AlertModel } from "../UnosAkcijePrilike/UnosAkcijePrilikeTypes";
import { PosebnaOsobina} from './PosebneOsobineTypes';

const PosebneOsobine = () => {

    const [addEditModalOpen, setAddEditModalOpen] = useState<boolean>(false);
    const [posebnaOsobinaId, setPosebnaOsobinaId] = useState<number>(0);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [dialog, setOpen] = useState<AlertModel>({ open: false, message: "" });
    
    const dataGrid = useRef<DataGrid>(null);
    const apiCall = useApiPromise();

    const accessTokenHandler = useCallback((accessToken: string) => {
    return (async () => {
      return accessToken;
    })()
  }, [])

  const dataSource = useMemo(() => {
    return createStore({
      key: 'id',
      loadUrl: `/api/PosebnaOsobina/GetPosebneOsobine`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
        return apiCall(accessTokenHandler).then(token => {
          return ajaxOptions.headers = {
            "Authorization": `Bearer ${token}`
          }
        })
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const akcijeRender = (data: any) => {  
    let id = data.data.id;
    // setRowData(data); 
    return (
      <AkcijeMenu posebnaOsobinaId={id} setEditOpen={setAddEditModalOpen} setPosebnaOsobinaId={setPosebnaOsobinaId}/>
    )
  };

  const spremiPosebnuOsobinu = (posebnaOsobina: PosebnaOsobina) => {
    setIsLoading(true);
    let url = "/api/PosebnaOsobina/NewPosebnaOsobina";
    let forma = new FormData();
    if(posebnaOsobina.id > 0) {
      forma.append("id", posebnaOsobina.id.toString());
      url = "/api/PosebnaOsobina/EditPosebnaOsobina";
    }
    forma.append("naziv", posebnaOsobina.naziv);
    forma.append("opis", posebnaOsobina.opis);
    forma.append("sadrzaj", posebnaOsobina.sadrzaj);
    if(posebnaOsobina.slikaInfo && posebnaOsobina.slikaInfo.file){
      forma.append("slika", posebnaOsobina.slikaInfo.file);
    }
    
    return apiCall(accessTokenHandler).then(token => {
      return fetch(url, {
        method: "POST",
        headers: {
              "Authorization": `Bearer ${token}`
            },
        body: forma
      })
      .then(response => {
        setIsLoading(false);
        let poruka = "";
        if(response.ok) {
          poruka = "Posebna osobina uspješno spremljena";
          setAddEditModalOpen(false);
          dataGrid?.current?.instance.refresh();
        } else {
          poruka = "Došlo je do greške kod spremanja";
        }
        setOpen({open: true, message: poruka});
      })                  
      .catch(error => {
        setIsLoading(false);
        setOpen({open: true, message: "Došlo je do greške kod spremanja"});
      })
    })
  }

  const handleDialogClose = () => {
    setOpen({ open: false, message: "" });
  };

    return <>
        <DataGrid
        className={classes.dataGrid}
        ref={dataGrid}
        id="dataGrid"
        // keyExpr="imeAkcije"
        dataSource={dataSource}
        rowAlternationEnabled
        allowColumnReordering
        allowColumnResizing
        showBorders
        remoteOperations={true}
      >
        <MasterDetail enabled={true} component={Detalji} />
        <Paging defaultPageSize={20} />
        {/* <Pager
      visible={true}
      showPageSizeSelector={true}
      showInfo={true}
      allowedPageSizes={allowedPageSizes}
      infoText={`Stranica {0} od {1} ({2} akcija)`}
    />         */}
        <FilterRow visible={true} />
        <SearchPanel visible={true} />
            <Selection mode="single" />
            <Column dataField="id" caption="Šifra" width={50}  defaultSortOrder="desc" />
        <Column dataField="naziv" caption="Naziv" width={200} />                  
        <Column dataField="opis" caption="Opis" />
        <Column
          allowReordering={false}
          allowSorting={false}
          cellRender={akcijeRender}
          width={180}
        />
        <Toolbar>
          <Item location="after">
            <ButtonMui
              onClick={() => {
                setPosebnaOsobinaId(0);
                setAddEditModalOpen(true);
              }}
              variant="contained"
            >
              <PlusSquare size={25} />
            </ButtonMui>
          </Item>
        </Toolbar>
      </DataGrid>
      <Dialog onClose={handleDialogClose} open={dialog.open}>
        <DialogContent className={classes.dialog}>
          <label>{dialog.message}</label>
          <div style={{ textAlign: "end" }}>
            <Button onClick={handleDialogClose}>Zatvori</Button>
          </div>
        </DialogContent>
      </Dialog>
      {addEditModalOpen && <AddEditPosebnaOsobina 
                                setOpen={setAddEditModalOpen} 
                                data={dataGrid.current?.instance.getDataSource().items().find(x => x.id === posebnaOsobinaId)} 
                                handleSpremiPosebnuOsobinu={spremiPosebnuOsobinu}
                                />}
      {isLoading && <BackDrop />}
    </>
}

export default PosebneOsobine;