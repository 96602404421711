import produce from "immer";
import { NeradneNedeljeModel } from "./PoslovniceTypes";
import { NeradneNedeljeActions } from "./NeradneNedeljeActions";

const NeradneNedeljeReducer = produce(
  (oldState: NeradneNedeljeModel, action: NeradneNedeljeActions) => {
    switch (action.action) {
      case "GET_NERADNE-NEDELJE":
        oldState.neradneNedelje = action.value;
        break;
      case "NERADNE_NEDELJE_HANDLER":
        let datumi = oldState.neradneNedelje;
        let odabraniDatum = action.value;
        let index = datumi.findIndex((x) => x === odabraniDatum);
        if (index !== -1) {
          datumi.splice(index, 1);
        } else {
          datumi.push(odabraniDatum);
        }
        break;
      default:
        break;
    }
  }
);

export default NeradneNedeljeReducer;
