import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { EventDetalji } from './SlobodniDatumi'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons'
import classes from './ModalDetalji.module.css'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #e0e0e0',
  boxShadow: 24,
  p: 4,
  color: 'black',
  padding: "5px",
  borderRadius: 1
};

type ModalProps = {
  detaljiOpen: boolean;
  setDetaljiOpen: (value: boolean) => void;
  event: EventDetalji
}

export default function ModalDetalji(props: ModalProps) {
  //   const [open, setOpen] = React.useState(false);
  const handleClose = () => props.setDetaljiOpen(false)

  return (
    <div className={classes.container}>
      <Modal
        open={props.detaljiOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={classes.titleContainer}>
            <FontAwesomeIcon icon={faCalendarDays} className={classes.icon} />
            <div>{props.event.naziv} </div>
          </div>
          <div className={classes.labelGroupContainer}>
            <div className={classes.labelGroup}>
              <label>Trajanje:</label>
              <div className={classes.fontlg}>{props.event.trajanje}</div>
            </div>
            <div className={classes.labelGroup}>
              <label>Brend:</label>
              <div className={classes.fontlg}>{props.event.brend}</div>
            </div>
            <div className={classes.labelGroup}>
              <label>Voditelj:</label>
              <div className={classes.fontlg}>{props.event.voditelj}</div>
            </div>
            <div className={classes.labelGroup}>
              <label>Opis: </label>
              <div className={classes.opis}>
                {props.event.opis}               
              </div>
            </div>

          </div>
          <div className={classes.closeButtonContainer}>
            <Button variant="contained" onClick={handleClose}>Zatvori</Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
