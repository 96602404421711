import produce from "immer";
import { PoslovniceActions } from './PoslovniceActions';
import { PoslovnicaAddEditModel, InputErrorModelDefault, separateFromTo, InputErrorModel } from './PoslovniceTypes';

const PoslovniceReducer = produce((oldState: PoslovnicaAddEditModel, action: PoslovniceActions) => {
    switch (action.action) {
        case "POSLOVNICAID_POSTAVLJEN":
            oldState.id = action.value;
            break;
        case "ADRESA_POSTAVLJENA":
            const adresa = `${action.value.adresa} ${action.value.kucanskiBroj}`;
            oldState.lokacija.adresa = adresa
            oldState.lokacija.mjesto = action.value.mjesto;
            oldState.lokacija.postanskiBroj = action.value.postanskiBroj;
            oldState.lokacija.sifraZemlje = action.value.sifraZemlje;
            oldState.lokacija.longitude = parseFloat(action.value.longitude.toString());
            oldState.lokacija.latitude = parseFloat(action.value.latitude.toString());
            postaviRadnoVrijeme(oldState.mjesto, action.value.mjesto);
            postaviRadnoVrijeme(oldState.adresa, adresa);
            postaviRadnoVrijeme(oldState.postanskiBroj, action.value.postanskiBroj.toString());
            let errorAdresa = '';
            let valid = true;
            if(oldState.lokacija.adresa.length === 0 
                || oldState.lokacija.mjesto.length === 0 
                || (oldState.lokacija.longitude === 0 && oldState.lokacija.latitude === 0 )) {
                    errorAdresa = 'Obavezan podatak';
                    valid = false;
                }
            oldState.adresa.error = errorAdresa;
            oldState.adresa.value = adresa;
            oldState.adresa.valid = valid;
            oldState.adresa.touched = true;

            if(oldState.lokacija.latitude === 0 && oldState.lokacija.longitude === 0) {
                oldState.lokacijaError = "Morate odabrati lokaciju.";
            } else {
                oldState.lokacijaError = "";
            }

            break;
        case "ADRESA_UNESENA":
            postaviRadnoVrijeme(oldState.adresa, action.value);
            break;
        case "MJESTO_UNESENO":
            postaviRadnoVrijeme(oldState.mjesto, action.value);
            break;
        case "POSTANSKIBROJ_UNESEN":
            postaviRadnoVrijeme(oldState.postanskiBroj, action.value.toString());
            break;
        case "EMAIL_UNESEN":
            postaviRadnoVrijeme(oldState.email, action.value);
            break;
        case "TELEFON_UNESEN":
            postaviRadnoVrijeme(oldState.telefon, action.value);
            break;
        case "WEBSTRANICA_UNESENA":
            postaviRadnoVrijeme(oldState.webStranica, action.value);
            break;
        case "DVOKRATNORV_PROMJENA":
            switch (action.value.dan) {
                //nedjelja
                case 1:
                    oldState["1"]["2"] = action.value.prikazi ? JSON.parse(JSON.stringify(InputErrorModelDefault)) as typeof InputErrorModelDefault : null;
                    if(oldState["1"]["2"]) postaviRadnoVrijeme(oldState[1][2], separateFromTo(''));
                    break;
                case 2:
                    oldState["2"]["2"] = action.value.prikazi ? JSON.parse(JSON.stringify(InputErrorModelDefault)) as typeof InputErrorModelDefault : null;
                    if(oldState["2"]["2"]) postaviRadnoVrijeme(oldState[2][2], separateFromTo(''));
                    break;
                case 3:
                    oldState["3"]["2"] = action.value.prikazi ? JSON.parse(JSON.stringify(InputErrorModelDefault)) as typeof InputErrorModelDefault : null;
                    if(oldState["3"]["2"]) postaviRadnoVrijeme(oldState[3][2], separateFromTo(''));
                    break;
                case 4:
                    oldState["4"]["2"] = action.value.prikazi ? JSON.parse(JSON.stringify(InputErrorModelDefault)) as typeof InputErrorModelDefault : null;
                    if(oldState["4"]["2"]) postaviRadnoVrijeme(oldState[4][2], separateFromTo(''));
                    break;
                case 5:
                    oldState["5"]["2"] = action.value.prikazi ? JSON.parse(JSON.stringify(InputErrorModelDefault)) as typeof InputErrorModelDefault : null;
                    if(oldState["5"]["2"]) postaviRadnoVrijeme(oldState[5][2], separateFromTo(''));
                    break;
                case 6:
                    oldState["6"]["2"] = action.value.prikazi ? JSON.parse(JSON.stringify(InputErrorModelDefault)) as typeof InputErrorModelDefault : null;
                    if(oldState["6"]["2"]) postaviRadnoVrijeme(oldState[6][2], separateFromTo(''));
                    break;
                //subota
                case 7:
                    oldState["7"]["2"] = action.value.prikazi ? JSON.parse(JSON.stringify(InputErrorModelDefault)) as typeof InputErrorModelDefault : null;
                    if(oldState["7"]["2"]) postaviRadnoVrijeme(oldState[7][2], separateFromTo(''));
                    break;

                default:
                    break;
            }
            break;
        case "NEDJELJA_PRIKAZANA":
            oldState.prikaziNedjelju = action.value
            oldState[1][1] = action.value ? JSON.parse(JSON.stringify(InputErrorModelDefault)) as typeof InputErrorModelDefault : null;
            oldState[1][2] = null;
            if(oldState[1][1]) postaviRadnoVrijeme(oldState[1][1], separateFromTo(''));
            break;
        case "RADNOVRIJEME_PROMJENJENO":
            switch (action.value.dan) {
                case 1:
                    switch (action.value.tip) {    
                        case 1:
                            if (oldState[1][1]) postaviRadnoVrijeme(oldState[1][1], action.value.vrijednost);
                            break;
                        case 2:
                            if (oldState[1][2]) postaviRadnoVrijeme(oldState[1][2], action.value.vrijednost);
                            break
                        default:
                            break;
                    }
                    break;
                case 2:
                    switch (action.value.tip) {
                        case 1:
                            postaviRadnoVrijeme(oldState[2][1], action.value.vrijednost);
                            break;
                        case 2:
                            if (oldState[2][2]) postaviRadnoVrijeme(oldState[2][2], action.value.vrijednost);
                            break
                        default:
                            break;
                    }
                    break;
                case 3:
                    switch (action.value.tip) {
                        case 1:
                            postaviRadnoVrijeme(oldState[3][1], action.value.vrijednost);
                            break;
                        case 2:
                            if (oldState[3][2]) postaviRadnoVrijeme(oldState[3][2], action.value.vrijednost);
                            break
                        default:
                            break;
                    }
                    break;
                case 4:
                    switch (action.value.tip) {
                        case 1:
                            postaviRadnoVrijeme(oldState[4][1], action.value.vrijednost);
                            break;
                        case 2:
                            if (oldState[4][2]) postaviRadnoVrijeme(oldState[4][2], action.value.vrijednost);
                            break
                        default:
                            break;
                    }
                    break;
                case 5:
                    switch (action.value.tip) {
                        case 1:
                            postaviRadnoVrijeme(oldState[5][1], action.value.vrijednost);
                            break;
                        case 2:
                            if (oldState[5][2]) postaviRadnoVrijeme(oldState[5][2], action.value.vrijednost);
                                
                            break
                        default:
                            break;
                    }
                    break;
                case 6:
                    switch (action.value.tip) {
                        case 1:
                            postaviRadnoVrijeme(oldState[6][1], action.value.vrijednost);
                            break;
                        case 2:
                            if (oldState[6][2]) postaviRadnoVrijeme(oldState[6][2], action.value.vrijednost);
                            break
                        default:
                            break;
                    }
                    break;
                case 7:
                    switch (action.value.tip) {
                        case 1:
                            postaviRadnoVrijeme(oldState[7][1], action.value.vrijednost);
                            break;
                        case 2:
                            if (oldState[7][2]) postaviRadnoVrijeme(oldState[7][2], action.value.vrijednost);
                            break
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
            break;
            case "FORMA_VALIDIRANA":
                let submit = true;

                postaviRadnoVrijeme(oldState.adresa, oldState.adresa.value);
                postaviRadnoVrijeme(oldState.mjesto, oldState.mjesto.value);
                postaviRadnoVrijeme(oldState.postanskiBroj, oldState.postanskiBroj.value);
                postaviRadnoVrijeme(oldState.email, oldState.email.value);
                postaviRadnoVrijeme(oldState.telefon, oldState.telefon.value);
                postaviRadnoVrijeme(oldState.webStranica, oldState.webStranica.value);
                if(oldState.lokacija.latitude === 0 && oldState.lokacija.longitude === 0) {
                    oldState.lokacijaError = "Morate odabrati lokaciju.";
                    submit = false;
                } else {
                    oldState.lokacijaError = "";
                }

                if(!oldState.adresa.valid || !oldState.telefon.valid || !oldState.email.valid || !oldState.webStranica.valid || !oldState.mjesto.valid || !oldState.postanskiBroj.valid) submit = false;
                if(oldState[1][1] && !oldState[1][1].valid) submit = false;
                if(oldState[1][2] && !oldState[1][2].valid) submit = false;

                if(!oldState[2][1].valid) submit = false;
                if(oldState[2][2] && !oldState[2][2].valid) submit = false;

                if(!oldState[3][1].valid) submit = false;
                if(oldState[3][2] && !oldState[3][2].valid) submit = false;

                if(!oldState[4][1].valid) submit = false;
                if(oldState[4][2] && !oldState[4][2].valid) submit = false;

                if(!oldState[5][1].valid) submit = false;
                if(oldState[5][2] && !oldState[5][2].valid) submit = false;

                if(!oldState[6][1].valid) submit = false;
                if(oldState[6][2] && !oldState[6][2].valid) submit = false;

                if(!oldState[7][1].valid) submit = false;
                if(oldState[7][2] && !oldState[7][2].valid) submit = false;

                oldState.submit = submit;

                break;
            case "SUBMIT_ONEMOGUCEN":
                oldState.submit = false;
                break;
            case "RADNOVRIJEME_POSTAVLJENO": 
                if(action.value[1]) {
                    oldState.prikaziNedjelju = true;

                    if(!oldState[1][1]) oldState[1][1] = JSON.parse(JSON.stringify(InputErrorModelDefault)) as typeof InputErrorModelDefault;
                    postaviRadnoVrijeme(oldState[1][1], separateFromTo(action.value[1][0]));
                    
                    if(action.value[1][1]){ 
                        if(!oldState[1][2]) oldState[1][2] = JSON.parse(JSON.stringify(InputErrorModelDefault)) as typeof InputErrorModelDefault;
                        postaviRadnoVrijeme(oldState[1][2], separateFromTo(action.value[1][1]));
                    }
                }

                if(action.value[2]) {
                    postaviRadnoVrijeme(oldState[2][1], separateFromTo(action.value[2][0]));
                    
                    if(action.value[2][1]){
                        if(!oldState[2][2]) oldState[2][2] = JSON.parse(JSON.stringify(InputErrorModelDefault)) as typeof InputErrorModelDefault;
                        postaviRadnoVrijeme(oldState[2][2], separateFromTo(action.value[2][1]));
                    }
                }

                if(action.value[3]) {
                    postaviRadnoVrijeme(oldState[3][1], separateFromTo(action.value[3][0]));
                    
                    if(action.value[3][1]){
                        if(!oldState[3][2]) oldState[3][2] = JSON.parse(JSON.stringify(InputErrorModelDefault)) as typeof InputErrorModelDefault;
                        postaviRadnoVrijeme(oldState[3][2], separateFromTo(action.value[3][1]));
                    }
                }

                if(action.value[4]) {
                    postaviRadnoVrijeme(oldState[4][1], separateFromTo(action.value[4][0]));
                    
                    if(action.value[4][1]){
                        if(!oldState[4][2]) oldState[4][2] = JSON.parse(JSON.stringify(InputErrorModelDefault)) as typeof InputErrorModelDefault;
                        postaviRadnoVrijeme(oldState[4][2], separateFromTo(action.value[4][1]));
                    }
                }

                if(action.value[5]) {
                    postaviRadnoVrijeme(oldState[5][1], separateFromTo(action.value[5][0]));
                    
                    if(action.value[5][1]){
                        if(!oldState[5][2]) oldState[5][2] = JSON.parse(JSON.stringify(InputErrorModelDefault)) as typeof InputErrorModelDefault;
                        postaviRadnoVrijeme(oldState[5][2], separateFromTo(action.value[5][1]));
                    }
                }

                if(action.value[6]) {
                    postaviRadnoVrijeme(oldState[6][1], separateFromTo(action.value[6][0]));
                    
                    if(action.value[6][1]){
                        if(!oldState[6][2]) oldState[6][2] = JSON.parse(JSON.stringify(InputErrorModelDefault)) as typeof InputErrorModelDefault;
                        postaviRadnoVrijeme(oldState[6][2], separateFromTo(action.value[6][1]));
                    }
                }

                if(action.value[7]) {
                    postaviRadnoVrijeme(oldState[7][1], separateFromTo(action.value[7][0]));
                    
                    if(action.value[7][1]){
                        if(!oldState[7][2]) oldState[7][2] = JSON.parse(JSON.stringify(InputErrorModelDefault)) as typeof InputErrorModelDefault;
                        postaviRadnoVrijeme(oldState[7][2], separateFromTo(action.value[7][1]));
                    }
                }
                
                break;
        default:
            break;
    }
});

function postaviRadnoVrijeme(radnoVrijemeDan: InputErrorModel, vrijeme: string): InputErrorModel {
    radnoVrijemeDan.error = validirajUnos(vrijeme, 'Obavezan podatak.');
    radnoVrijemeDan.touched = true;
    radnoVrijemeDan.valid = radnoVrijemeDan.error.length === 0;
    radnoVrijemeDan.value = vrijeme;
    return radnoVrijemeDan;
}

function validirajUnos(value: string, poruka: string): string {
    if (value.length > 0) return '';
    return poruka;
}



export default PoslovniceReducer;