import {
  DataGrid,
  Column,
  Selection,
  Editing,
  MasterDetail,
  FilterRow,
} from "devextreme-react/data-grid";
import { Pager, Paging } from "devextreme-react/tree-list";
import "devextreme/dist/css/dx.light.css";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { useCallback, useMemo, useRef } from "react";
import useApiPromise from "../Hooks/useApiPromise";
import Detalji from "./Detalji";
import { Button as ButtonMui } from "@mui/material";
import { PlusSquare } from "react-bootstrap-icons";

const PagesTablica = () => {
  const allowedPageSizes = [20, 50, 100];

  const apiCallPromise = useApiPromise();
  const dataGrid = useRef<DataGrid>(null);

  const accessTokenHandler = useCallback((accessToken: string) => {
    return (async () => {
      return accessToken;
    })();
  }, []);

  const dataSource = useMemo(() => {
    return createStore({
      key: "id",
      loadUrl: `api/Pages/GetPages`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
        return apiCallPromise(accessTokenHandler).then((token: any) => {
          return (ajaxOptions.headers = {
            Authorization: `Bearer ${token}`,
          });
        });
      },
    });
  }, [accessTokenHandler, apiCallPromise]);

  return (
    <div>
      <div
        style={{
          backgroundColor: "white",
          display: "flex",
          justifyContent: "flex-end",
          padding: "1em 1em 0 0",
        }}
      >
        <ButtonMui
          onClick={() => {
            window.location.href = "/Pages/NewPage";
          }}
          variant="contained"
        >
          <PlusSquare size={25} />
        </ButtonMui>
      </div>

      <DataGrid
        id="dataGrid"
        ref={dataGrid}
        dataSource={dataSource}
        rowAlternationEnabled
        allowColumnReordering
        allowColumnResizing
        showBorders
        // onInitNewRow={(e) => {
        //   window.location.href = "/Pages/NewPage";
        // }}
        onEditingStart={(e) => {
          e.cancel = true;
          window.location.href = `/Pages/EditPage?id=${e.data.id}`;
        }}
        columnAutoWidth
        remoteOperations={true}
      >
        <FilterRow visible={true} />
        <MasterDetail enabled={true} component={Detalji} />
        <Paging defaultPageSize={20} />
        <Editing mode="row" allowUpdating={true} />
        <Pager
          visible={true}
          showPageSizeSelector={true}
          showInfo={true}
          allowedPageSizes={allowedPageSizes}
          infoText={`Stranica {0} od {1} ({2} artikala)`}
        />
        <Selection mode="single" />
        <Column allowFiltering={false} width="5%" dataField="id" caption="ID" />
        <Column
          width="5%"
          allowEditing={true}
          dataField="name"
          caption="Name"
        ></Column>
        <Column
          width="55%"
          allowEditing={true}
          dataField="title"
          caption="Title"
        />
      </DataGrid>
    </div>
  );
};

export default PagesTablica;
