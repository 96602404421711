import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createStore } from "devextreme-aspnet-data-nojquery";
import useApiPromise from "../Hooks/useApiPromise";
import {
  DataGrid,
  Column,
  Selection,
  Editing,
  FilterRow,
  Toolbar,
  Item,
} from "devextreme-react/data-grid";
import { Pager, Paging } from "devextreme-react/tree-list";
import { Button as ButtonMui } from "@mui/material";
import { PlusSquare } from "react-bootstrap-icons";
// import AddModal from "./AddModal";
// import EditModal from "./EditModal";
import Swal from "sweetalert2";
import classes from "./DostavaUStan.module.css";
import useApi from "../Hooks/useApi";
import BackDrop from "../UnosAkcijePrilike/BackDrop";
import { DostavaUStan } from "./DostavaUStanTypes";
import AddModal from "./AddModal";
import EditModal from "./EditModal";

const DostavaStan = () => {
  const dataGrid = useRef<DataGrid>(null);
  const allowedPageSizes = [20, 50, 100];

  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [rowData, setRowData] = useState<DostavaUStan>({
    zip: -1,
    dostavaUStan: false,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deleteData, setDeleteData] = useState<number | null>(null);
  const apiCall = useApi();

  const apiCallPromise = useApiPromise();
  const accessTokenHandler = useCallback((accessToken: string) => {
    return (async () => {
      return accessToken;
    })();
  }, []);

  const dataSource = useMemo(() => {
    return createStore({
      loadUrl: `api/Dostava/GetDostaveUStan`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
        return apiCallPromise(accessTokenHandler).then((token: any) => {
          return (ajaxOptions.headers = {
            Authorization: `Bearer ${token}`,
          });
        });
      },
    });
  }, [accessTokenHandler, apiCallPromise]);

  const deleteDostavuUStan = useCallback(
    (accessToken: string) => {
      if (deleteData !== null) {
        setIsLoading(true);
        let formData = new FormData();
        formData.append("zip", deleteData.toString());

        fetch("api/Dostava/DeleteDostavuUStan", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          method: "post",
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.responseData) {
              setIsLoading(false);
              Swal.fire({
                icon: "success",
                title: "Obrisano",
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              });
            } else {
              setIsLoading(false);
              Swal.fire({
                title: data.error,
                showConfirmButton: false,
                toast: true,
                customClass: {
                  container: classes.swalContainer,
                  popup: classes.swal2IconError,
                },
                timer: 1500,
                iconColor: "white",
                timerProgressBar: true,
                icon: "warning",
                position: "top-right",
              });
            }
          });
      }
    },
    [deleteData]
  );

  useEffect(() => {
    apiCall(deleteDostavuUStan);
  }, [apiCall, deleteDostavuUStan]);

  return (
    <>
      <div>
        <DataGrid
          id="dataGrid"
          ref={dataGrid}
          dataSource={dataSource}
          rowAlternationEnabled
          allowColumnReordering
          allowColumnResizing
          showBorders
          onEditingStart={(e) => {
            e.cancel = true;
            setRowData({
              zip: e.data.zip,
              dostavaUStan: e.data.dostavaUStan,
            });
            setEditModalOpen(true);
          }}
          remoteOperations={true}
          onRowRemoving={(e: any) => {
            setDeleteData(e.data.zip);
            e.cancel = true;
          }}
        >
          <FilterRow visible={true} />
          <Paging defaultPageSize={20} />
          <Editing mode="row" allowUpdating={true} allowDeleting />
          <Pager
            visible={true}
            showPageSizeSelector={true}
            showInfo={true}
            allowedPageSizes={allowedPageSizes}
            infoText={`Stranica {0} od {1} ({2} artikala)`}
          />
          <Selection mode="single" />
          <Column
            allowFiltering={true}
            width="5%"
            dataField="zip"
            caption="Zip"
          />
          <Column
            allowEditing={true}
            width="75%"
            dataField="dostavaUStan"
            caption="Dostava u stan"
            dataType={"boolean"}
          />
          <Toolbar>
            <Item location="after">
              <ButtonMui
                onClick={() => {
                  setAddModalOpen(true);
                }}
                variant="contained"
              >
                <PlusSquare size={25} />
              </ButtonMui>
            </Item>
          </Toolbar>
        </DataGrid>
      </div>
      {addModalOpen && (
        <AddModal open={addModalOpen} setOpen={setAddModalOpen} />
      )}
      {editModalOpen && (
        <EditModal
          open={editModalOpen}
          setOpen={setEditModalOpen}
          data={rowData}
        />
      )}
      {isLoading && <BackDrop />}
    </>
  );
};

export default DostavaStan;
