import Input from "./Input";
import { Poklon, PokloniModel, TrajanjeModel } from "./UnosAkcijePrilikeTypes";
import classes from "./UnosAkcijePrilike.module.css";
import PokloniTablica from "./PokloniTablica";
import { faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as XLSX from "xlsx";
import { useCallback, useEffect, useRef, useState } from "react";
import useApi from "../Hooks/useApi";

type PokloniProps = {
  pokloniModel: PokloniModel;
  pokloniHandler: (pokloniModel: PokloniModel) => void;
  brisiPoklon: (value: string) => void;
  updatePoklon: (value: string) => void;
  dodajPoklon: (value: string) => void;
  setUvjetiPoklonaNotValid: (value: boolean) => void;
  uvjetiPoklonaNotValid: boolean;
  setSifraPoklonaNotValid: (value: boolean) => void;
  sifraPoklonaNotValid: boolean;
  trajanjeModel: TrajanjeModel;
  poklonNotValid: boolean;
  setPoklonNotValid: (value: boolean) => void;
};

const Pokloni = (props: PokloniProps) => {
  const [poklonGreska, setPoklonGreska] = useState<string>("");

  const apiCall = useApi();
  const readExcel = (file: Blob) => {
    if (file) {
      const promise = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onload = (e) => {
          if (e.target?.result) {
            let bufferArray = e.target.result;
            const wb = XLSX.read(bufferArray, { type: "buffer" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws);
            resolve(data);
          }
          fileReader.onerror = (error) => {
            reject(error);
          };
        };
      });
      promise.then((d: any) => {
        let temp: Poklon[] = [];
        d.forEach((element: any) => {
          temp.push({ SifraArtikla: element.SifraArtikla });
        });
        props.pokloniHandler({
          ...props.pokloniModel,
          uzStoSeDajePoklon: temp,
        });
      });
    }
  };

  const inputFile = useRef<HTMLInputElement>(null);
  const odabirExcelDatoteke = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  useEffect(() => {
    if (props.pokloniModel.uzStoSeDajePoklon.length > 0) {
      props.setUvjetiPoklonaNotValid(false);
    } else {
      props.setSifraPoklonaNotValid(false);
    }
  }, [props]);

  const checkPoklonCijena = useCallback(
    (accessToken: string) => {
      let formData = new FormData();
      let danPocetak;
      let mjesecPocetak;
      let godinaPocetak;
      let danKraj;
      let mjesecKraj;
      let godinaKraj;
      if (props.trajanjeModel.odabraniDatumPocetkaPrilike) {
        danPocetak = (
          "0" +
          new Date(props.trajanjeModel.odabraniDatumPocetkaPrilike).getDate()
        ).slice(-2);
        mjesecPocetak = (
          "0" +
          (new Date(
            props.trajanjeModel.odabraniDatumPocetkaPrilike
          ).getMonth() +
            1)
        ).slice(-2);
        godinaPocetak = new Date(
          props.trajanjeModel.odabraniDatumPocetkaPrilike
        ).getFullYear();
      }
      if (props.trajanjeModel.odabraniDatumKrajaPrilike) {
        danKraj = (
          "0" +
          new Date(props.trajanjeModel.odabraniDatumKrajaPrilike).getDate()
        ).slice(-2);
        mjesecKraj = (
          "0" +
          (new Date(props.trajanjeModel.odabraniDatumKrajaPrilike).getMonth() +
            1)
        ).slice(-2);
        godinaKraj = new Date(
          props.trajanjeModel.odabraniDatumKrajaPrilike
        ).getFullYear();
      }

      formData.append(
        "akcijaOd",
        `${godinaPocetak}-${mjesecPocetak}-${danPocetak}`
      );
      formData.append("akcijaDo", `${godinaKraj}-${mjesecKraj}-${danKraj}`);
      formData.append("sifraPoklona", props.pokloniModel.sifraPoklona);

      fetch("api/Akcija/CheckPoklonCijena", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "post",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.responseData[0].isOk === false) {
            setPoklonGreska(data.responseData[0].greska);
            props.setPoklonNotValid(true);
          }
        });
    },
    [props]
  );

  const checkPoklon = () => {
    if (props.pokloniModel.sifraPoklona.length > 0) {
      apiCall(checkPoklonCijena);
    }
  };

  return (
    <>
      <h4 className={classes.stepsNaslov}>Pokloni</h4>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom:
            props.sifraPoklonaNotValid || props.poklonNotValid ? "" : "60px",
        }}
      >
        <label style={{ marginRight: "2em" }}>
          <strong>Šifra poklona:</strong>
        </label>
        <Input
          value={props.pokloniModel.sifraPoklona}
          handleChange={(value: string) => {
            props.setUvjetiPoklonaNotValid(false);
            props.setSifraPoklonaNotValid(false);
            props.setPoklonNotValid(false);
            setPoklonGreska("");
            props.pokloniHandler({
              ...props.pokloniModel,
              sifraPoklona: value,
            });
          }}
          inputLabel="Šifra poklona"
          marginBottom=""
          rows={3}
          obavezno={false}
          error={false}
          multiline={false}
          inputPropsType="search"
          onBlur={() => {
            checkPoklon();
          }}
          children={undefined}
          disabled={false}
        ></Input>
      </div>
      {props.sifraPoklonaNotValid && (
        <div style={{ fontSize: "0.8em", color: "red", marginBottom: "60px" }}>
          Morate upisati šifru poklona!
        </div>
      )}
      {props.poklonNotValid && (
        <div style={{ fontSize: "0.8em", color: "red", marginBottom: "60px" }}>
          {poklonGreska}
        </div>
      )}
      <label style={{ marginBottom: "10px" }}>
        <strong>Uz što se daje poklon:</strong>
      </label>
      <div className={classes.container}>
        <input
          accept={
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"'
          }
          className={classes.hidden}
          type="file"
          multiple={false}
          onChange={(e) => {
            let file: any = [];
            if (e.target.files) {
              file = e.target.files[0];
            }
            readExcel(file);
            e.target.value = "";
          }}
          ref={inputFile}
        />
        <button
          type="button"
          className={classes.uploadButtonExcel}
          onClick={odabirExcelDatoteke}
        >
          <FontAwesomeIcon
            className={classes.uploadIcon}
            icon={faArrowUpFromBracket}
          />
          Odabir datoteka
        </button>
      </div>
      {props.uvjetiPoklonaNotValid && (
        <div style={{ fontSize: "0.8em", color: "red" }}>
          Morate odabrati uvjete za dobivanje poklona!
        </div>
      )}
      <PokloniTablica
        pokloni={props.pokloniModel.uzStoSeDajePoklon}
        brisiPoklon={props.brisiPoklon}
        updatePoklon={props.updatePoklon}
        dodajPoklon={props.dodajPoklon}
      />
    </>
  );
};

export default Pokloni;
