import { useEffect } from "react";
import { SlikaInfo } from "../../utils/utils";
import FileInput from "./FileInputAkcijePrilike";
import classes from "./UnosAkcijePrilike.module.css";

type DodavanjeSlikaProps = {
  ucitajSliku: (file: any, fileName: string | null) => void;
  slika506px: SlikaInfo | null;
  slika1140px: SlikaInfo | null;
  slika1200px: SlikaInfo | null;
  slika600px: SlikaInfo | null;
  setBanner1140pxNotValid: (value: boolean) => void;
  setBanner1200pxNotValid: (value: boolean) => void;
  setBanner506pxNotValid: (value: boolean) => void;
  setBanner600pxNotValid: (value: boolean) => void;
  banner1140pxNotValid: boolean;
  banner1200pxNotValid: boolean;
  banner600pxNotValid: boolean;
  banner506pxNotValid: boolean;
};

const DodavanjeSlika = (props: DodavanjeSlikaProps) => {
  useEffect(() => {
    if (props.slika506px) {
      props.setBanner506pxNotValid(false);
    }
    if (props.slika1140px) {
      props.setBanner1140pxNotValid(false);
    }
    if (props.slika1200px) {
      props.setBanner1200pxNotValid(false);
    }
    if (props.slika600px) {
      props.setBanner600pxNotValid(false);
    }
  }, [props]);
  return (
    <>
      <h4 className={classes.stepsNaslov}>Unos slika</h4>
      <div
        className={
          props.banner506pxNotValid
            ? classes.odabirWrapperError
            : classes.odabirWrapper
        }
      >
        <FileInput
          label="Banner 506px*230px jpg, obavezno bez CTA gumba, minimalna veličina slova 12px"
          multiple={false}
          extension={".jpg"}
          ucitajSliku={props.ucitajSliku}
          nazivSlike=""
        />
        {/* {slika506.length > 0 && slika506} */}
        {props.slika506px && (
          <div className={classes.nazivOdabraneSlikeWrapper}>
            <div className={classes.odabranaSlikaText}>Odabrana slika:</div>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {props.slika506px?.name}
            </div>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <img
            style={{ width: "30%" }}
            src={props.slika506px?.src}
            alt={props.slika506px?.name}
          />
        </div>
      </div>
      <div
        className={
          props.banner1140pxNotValid
            ? classes.odabirWrapperError
            : classes.odabirWrapper
        }
      >
        <FileInput
          label="Banner 1140px*230px jpg, obavezno bez CTA gumba, minimalna veličina slova 12p"
          multiple={false}
          extension={".jpg"}
          ucitajSliku={props.ucitajSliku}
          nazivSlike=""
        />
        {/* {slika1140.length > 0 && slika1140} */}
        {props.slika1140px && (
          <div className={classes.nazivOdabraneSlikeWrapper}>
            <div className={classes.odabranaSlikaText}>Odabrana slika:</div>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {props.slika1140px?.name}
            </div>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <img
            style={{ width: "30%" }}
            src={props.slika1140px?.src}
            alt={props.slika1140px?.name}
          />
        </div>
      </div>
      <div
        className={
          props.banner1200pxNotValid
            ? classes.odabirWrapperError
            : classes.odabirWrapper
        }
      >
        <FileInput
          label="Banner 1200px x 628px jpg, Banner za Facebook, obavezno bez CTA gumba, manje od 20% površine slike je text obavezno, po mogučnosti savjetuje se izbjegavati sva velika slova, velike uskličnike, ne koristiti riječi kao što su 'Sigurnost', 'Osiguranje', 'sigurno', 'garantiramo','zajamčeno','uz registraciju' ,'uz obaveznu registraciju', 'popust do xx%', 'do xx%'."
          multiple={false}
          extension={".jpg"}
          ucitajSliku={props.ucitajSliku}
          nazivSlike=""
        />
        {/* {slika1200.length > 0 && slika1200} */}
        {props.slika1200px && (
          <div className={classes.nazivOdabraneSlikeWrapper}>
            <div className={classes.odabranaSlikaText}>Odabrana slika:</div>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {props.slika1200px?.name}
            </div>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <img
            style={{ width: "30%" }}
            src={props.slika1200px?.src}
            alt={props.slika1200px?.name}
          />
        </div>
      </div>
      <div
        className={
          props.banner600pxNotValid
            ? classes.odabirWrapperError
            : classes.odabirWrapper
        }
      >
        <FileInput
          label="Banner 600px*500px jpg, obavezno sa CTA gumbom, minimalna veličina slova 12px, obavezno navesti puno trajanje akcije."
          multiple={false}
          extension={".jpg"}
          ucitajSliku={props.ucitajSliku}
          nazivSlike=""
        />
        {/* {slika600.length > 0 && slika600} */}
        {props.slika600px && (
          <div className={classes.nazivOdabraneSlikeWrapper}>
            <div className={classes.odabranaSlikaText}>Odabrana slika:</div>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {props.slika600px?.name}
            </div>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <img
            style={{ width: "30%" }}
            src={props.slika600px?.src}
            alt={props.slika600px?.name}
          />
        </div>
      </div>
    </>
  );
};

export default DodavanjeSlika;
