import React from "react";
import classes from "./NavigationCard.module.css";
import { Link } from "react-router-dom";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTags,
  faPlugCircleBolt,
  faWandSparkles,
  faCalendarDays,
  faFileImage,
  faBook,
  faFilePdf,
  faBoxArchive,
  faListCheck,
  faDollarSign,
  faTruck,
  faCreditCard,
  faBuildingFlag,
  faFileCode,
  faGear,
  faFilePen,
  faImage,
  faBars,
  faPalette,
  faFileLines,
  faCalendarWeek,
  faEnvelopesBulk,
  faClock,
  faMicroscope
} from "@fortawesome/free-solid-svg-icons";

const cards: Card[] = [
  {
    oznaka: "akcije",
    naslov: "Unos akcije prilike",
    ikona: faTags,
    urlTo: "/GrupaAkcije/UnosAkcijePrilike",
  },
  {
    oznaka: "testExcelTablice",
    naslov: "Test excel tablice",
    ikona: faMicroscope,
    urlTo: "/GrupaAkcije/TestExcelTablice",
  },
  {
    oznaka: "zadaci",
    naslov: "Zadaci za voditelje",
    ikona: faBook,
    urlTo: "/GrupaZadaci/ZadaciVoditelj",
  },
  {
    oznaka: "novizadatak",
    naslov: "Novi zadatak",
    ikona: faBook,
    urlTo: "/GrupaZadaci/NoviZadatak",
  },
  {
    oznaka: "energetskenaljepnice",
    naslov: "Unos energetskih naljepnica",
    ikona: faPlugCircleBolt,
    urlTo: "/GrupaDokumenti/EnergetskeNaljepnice",
  },
  {
    oznaka: "unosslika",
    naslov: "Unos slika",
    ikona: faFileImage,
    urlTo: "/GrupaDokumenti/UnosSlika",
  },
  {
    oznaka: "informacijskilistovi",
    naslov: "Unos informacijskih listova",
    ikona: faFilePdf,
    urlTo: "/GrupaDokumenti/UnosInformacijskihListova",
  },
  {
    oznaka: "slobodnidatumi",
    naslov: "Slobodni datumi",
    ikona: faCalendarDays,
    urlTo: "/GrupaAkcije/SlobodniDatumi",
  },
  {
    oznaka: "grupadokumenti",
    naslov: "Unos dokumenata",
    ikona: faCalendarDays,
    urlTo: "/GrupaDokumenti",
  },
  {
    oznaka: "grupaakcije",
    naslov: "Unos akcija i slobodnih termina",
    ikona: faCalendarDays,
    urlTo: "/GrupaAkcije",
  },
  {
    oznaka: "grupazadaci",
    naslov: "Unos i pregled zadataka",
    ikona: faCalendarDays,
    urlTo: "/GrupaZadaci",
  },
  {
    oznaka: "pregledAkcija",
    naslov: "Pregled akcija",
    ikona: faListCheck,
    urlTo: "/GrupaAkcije/AkcijePrikazTablica",
  },
  {
    oznaka: "grupaCijene",
    naslov: "Dostava",
    ikona: faTruck,
    urlTo: "/GrupaCijene",
  },
  {
    oznaka: "cijenaDostave",
    naslov: "Cijena dostave",
    ikona: faDollarSign,
    urlTo: "/GrupaCijene/CijenaDostave",
  },
  {
    oznaka: "dostavaUStan",
    naslov: "Dostava u stan",
    ikona: faTruck,
    urlTo: "/GrupaCijene/DostavaUStan",
  },
  {
    oznaka: "neRadniDani",
    naslov: "Ne radni dani",
    ikona: faCalendarWeek,
    urlTo: "/GrupaCijene/NeRadniDani"
  },
  {
    oznaka: "postanskiBrojevi",
    naslov: "Poštanski brojevi",
    ikona: faEnvelopesBulk,
    urlTo : "/GrupaCijene/GrupaPostanskiBrojevi"
  },
  {
    oznaka: "inTime",
    naslov: "InTime",
    ikona: faClock,
    urlTo : "/GrupaCijene/InTime"
  },
  {
    oznaka: "placanja",
    naslov: "Plaćanja",
    ikona: faCreditCard,
    urlTo: "/GrupaPlacanja/Placanja",
  },
  {
    oznaka: "grupaPlacanja",
    naslov: "Plaćanja",
    ikona: faCreditCard,
    urlTo: "/GrupaPlacanja",
  },
  {
    oznaka: "posebneOsobine",
    naslov: "Posebne osobine",
    ikona: faWandSparkles,
    urlTo: "/PosebneOsobine/PosebneOsobine",
  },
  {
    oznaka: "poslovnice",
    naslov: "Poslovnice",
    ikona: faBuildingFlag,
    urlTo: "/Poslovnice/Poslovnice",
  },
  {
    oznaka: "pages",
    naslov: "Pages",
    ikona: faFileCode,
    urlTo: "/Pages/Pages",
  },
  {
    oznaka: "seo",
    naslov: "Seo",
    ikona: faGear,
    urlTo: "/Seo/Seo",
  },
  {
    oznaka: "grupaHeaderFooter",
    naslov: "Header & Footer",
    ikona: faFilePen,
    urlTo: "/GrupaHeaderFooter",
  },
  {
    oznaka: "logo",
    naslov: "Unos loga",
    ikona: faImage,
    urlTo: "/GrupaHeaderFooter/Logo",
  },
  {
    oznaka: "navbarKlas",
    naslov: "Navbar klasifikacije",
    ikona: faBars,
    urlTo: "/GrupaHeaderFooter/NavbarKlas",
  },
  {
    oznaka: "boje",
    naslov: "Boje",
    ikona: faPalette,
    urlTo: "/GrupaHeaderFooter/Boje",
  },
  {
    oznaka: "tekstPolja",
    naslov: "Tekstualna polja",
    ikona: faFileLines,
    urlTo: "/GrupaHeaderFooter/TekstPolja",
  },
];

type Card = {
  oznaka: string;
  naslov: string;
  ikona: IconDefinition;
  urlTo: string;
};

type NavigationCardProps = {
  oznaka: string;
};

const NavigationCard = (props: NavigationCardProps) => {
  return (
    <Link
      style={{ width: "20%" }}
      to={cards.find((card) => card.oznaka === props.oznaka)?.urlTo ?? "/"}
    >
      <div className={classes.container}>
        <div>
          <FontAwesomeIcon
            fontSize="3em"
            icon={
              cards.find((card) => card.oznaka === props.oznaka)?.ikona ??
              faBoxArchive
            }
          />
        </div>
        <div>
          <h6 className={classes.naslov}>
            {cards.find((card) => card.oznaka === props.oznaka)?.naslov}
          </h6>
        </div>
      </div>
    </Link>
  );
};

export default NavigationCard;
