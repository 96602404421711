import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import useApi from "../Hooks/useApi";
import Swal from "sweetalert2";
import classes from "./NeRadniDani.module.css";
import BackDrop from "../UnosAkcijePrilike/BackDrop";
import { AlertModel } from "../UnosAkcijePrilike/UnosAkcijePrilikeTypes";
import {
  Dialog,
  DialogContent,
} from "@mui/material";
import { Calendar } from "react-multi-date-picker";
import { NeRadniDan } from "./NeRadniDaniTypes";
import moment from "moment";

const mjeseci = [
  "Siječanj",
  "Veljača",
  "Ožujak",
  "Travanj",
  "Svibanj",
  "Lipanj",
  "Srpanj",
  "Kolovoz",
  "Rujan",
  "Listopad",
  "Studeni",
  "Prosinac",
];
const dani = ["Ne", "Po", "Ut", "Sr", "Če", "Pe", "Su"];

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "98%",
  height: "98%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  overflow: "scroll !important",
};

type AddEditModalProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  neRadniDani: NeRadniDan[];
  setDatumi: (value: string) => void;
  changeDatum: (stari: string, novi: string) => void;
  addDani: NeRadniDan[];
};

const AddEditModal = (props: AddEditModalProps) => {
  const apiCall = useApi();
  const handleClose = () => {
    props.setOpen(false);
  };
  const [dialog, setOpen] = useState<AlertModel>({ open: false, message: "" });
  const handleDialogClose = () => {
    setOpen({ open: false, message: "" });
  };
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const checkDatumPostoji = (datum: string) => {
    let index = props.neRadniDani.findIndex(
      (date) =>
        new Date(date.datum).toLocaleDateString("hr-HR") ===
        new Date(datum).toLocaleDateString("hr-HR")
    );
    if (index === -1) {
      return false;
    } else {
      setOpen({ open: true, message: "Datum već postoji u bazi" });
      return true;
    }
  };

  const post = (accessToken: string) => {
    setIsLoading(true);
    let formData = new FormData();
    let dan;
    let mjesec;
    let godina;

    for (let i = 0; i < props.addDani.length; i++) {
      const element = props.addDani[i];
      dan = ("0" + new Date(element.datum).getDate()).slice(-2);
      mjesec = ("0" + (new Date(element.datum).getMonth() + 1)).slice(-2);
      godina = new Date(element.datum).getFullYear();
      formData.append(`neradniDani[${i}].id`, element.id.toString());
      formData.append(`neradniDani[${i}].Datum`, `${godina}-${mjesec}-${dan}`);
    }

    fetch("api/NeRadniDan/InsertUpdateNeradniDan", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      method: "post",
      body: formData,
    }).then((response) => {
      if (response.status === 200) {
        setIsLoading(false);
        Swal.fire({
          icon: "success",
          title: "Spremljeno",
          customClass: {
            container: classes.swalContainer,
          },
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/GrupaCijene/NeRadniDani";
          }
        });
      } else {
        setIsLoading(false);
        Swal.fire({
          title: response.statusText,
          showConfirmButton: false,
          toast: true,
          customClass: {
            container: classes.swalContainer,
            popup: classes.swal2IconError,
          },
          timer: 1500,
          iconColor: "white",
          timerProgressBar: true,
          icon: "warning",
          position: "top-right",
        });
      }
    });
  };

  const apiCallPost = () => {
    apiCall(post);
  };

  return (
    <>
      <div>
        <Modal
          open={props.open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <Calendar
                // numberOfMonths={6}
                // multiple
                months={mjeseci}
                weekDays={dani}
                value={props.addDani.map((date) => {
                  return moment(new Date(date.datum)).format("DD.MM.YYYY.");
                })}
                weekStartDayIndex={1}
                onFocusedDateChange={(dateFocused, dateClicked) => {
                  if (dateClicked !== undefined) {
                    if (!checkDatumPostoji(dateClicked.format("YYYY-MM-DD"))) {
                      props.setDatumi(dateClicked.format("YYYY-MM-DD"));
                    }
                  }
                }}
                disableMonthPicker={true}
                disableYearPicker={true}
                minDate={new Date(new Date().getFullYear(), 0, 1)}
                maxDate={new Date(new Date().getFullYear(), 11, 31)}
                format={"DD.MM.YYYY."}
                fullYear={true}
              />
              {props.addDani.length > 0 && (
                <div
                  style={{
                    overflow: "scroll",
                    marginTop: "1em",
                    marginLeft: "1em",
                  }}
                >
                  <table>
                    <thead>
                      <tr>
                        <th>Odabrani datumi</th>
                      </tr>
                      <hr />
                    </thead>
                    <tbody>
                      {props.addDani.map((datum) => {
                        return (
                          <tr key={datum.id}>
                            <td>
                              <span>
                                {moment(new Date(datum.datum)).format("DD-MM")}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>

            {props.addDani.length > 0 && (
              <Button
                style={{
                  backgroundColor: "rgb(26, 179, 148)",
                  marginTop: "1em",
                }}
                variant="contained"
                onClick={() => {
                  apiCallPost();
                }}
              >
                Spremi
              </Button>
            )}
          </Box>
        </Modal>
      </div>
      {dialog.open && (
        <Dialog onClose={handleDialogClose} open={dialog.open}>
          <DialogContent className={classes.dialog}>
            <label>{dialog.message}</label>
            <div style={{ textAlign: "end" }}>
              <Button onClick={handleDialogClose}>Zatvori</Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
      {isLoading && <BackDrop />}
    </>
  );
};

export default AddEditModal;
