import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import MenuIcon from "@mui/icons-material/Menu";
import classes from "./Sidebar.module.css";
import {
  faPlugCircleBolt,
  faFileImage,
  faFilePdf,
  faTags,
  faCalendarDays,
  faListCheck,
  faDollarSign,
  faCreditCard,
  faBuildingFlag,
  faWandSparkles,
  faFileCode,
  faXmark,
  faGear,
  faImage,
  faBars,
  faPalette,
  faFileLines,
  faCalendarWeek,
  faEnvelopesBulk,
  faClock,
  faMicroscope,
  faTruck
  
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { grupa1, grupa2, grupa3 } from "../../utils/grupePrava";

type Anchor = "left";

export default function TemporaryDrawer() {
  const { accounts } = useMsal();
  const username = accounts[0].username;
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const items = [
    {
      grupa: "Dokumenti",
      name: "Unos slika",
      link: "/GrupaDokumenti/UnosSlika",
      icon: faFileImage,
    },
    {
      grupa: "Dokumenti",
      name: "Unos energetskih naljepnica",
      link: "/GrupaDokumenti/EnergetskeNaljepnice",
      icon: faPlugCircleBolt,
    },
    {
      grupa: "Dokumenti",
      name: "Unos informacijskih listova",
      link: "/GrupaDokumenti/UnosInformacijskihListova",
      icon: faFilePdf,
    },
    {
      grupa: "Akcije",
      name: "Prikaz akcija",
      link: "/GrupaAkcije/AkcijePrikazTablica",
      icon: faListCheck,
    },
    {
      grupa: "Akcije",
      name: "Unos akcije prilike",
      link: "/GrupaAkcije/UnosAkcijePrilike",
      icon: faTags,
    },
    {
      grupa: "Akcije",
      name: "Test excel tablice",
      link: "/GrupaAkcije/TestExcelTablice",
      icon: faMicroscope,
    },
    {
      grupa: "Akcije",
      name: "Slobodni datumi",
      link: "/GrupaAkcije/SlobodniDatumi",
      icon: faCalendarDays,
    },
    {
      grupa: "Cijene",
      name: "Cijena dostave",
      link: "/GrupaCijene/CijenaDostave",
      icon: faDollarSign,
    },
    {
      grupa: "Cijene",
      name: "Ne radni dani",
      link: "/GrupaCijene/NeRadniDani",
      icon: faCalendarWeek
    },
    {
      grupa: "Cijene",
      name: "Poštanski brojevi",
      link: "/GrupaCijene/GrupaPostanskiBrojevi",
      icon: faEnvelopesBulk
    },
    {
      grupa: "Cijene",
      name: "InTime",
      link: "/GrupaCijene/InTime",
      icon: faClock
    },
    {
      grupa: "Placanja",
      name: "Plaćanja",
      link: "/GrupaPlacanja/Placanja",
      icon: faCreditCard,
    },
    {
      grupa: "PosebneOsobine",
      name: "PosebneOsobine",
      link: "/PosebneOsobine/PosebneOsobine",
      icon: faWandSparkles,
    },
    {
      grupa: "Poslovnice",
      name: "Poslovnice",
      link: "/Poslovnice/Poslovnice",
      icon: faBuildingFlag,
    },
    {
      grupa: "Pages",
      name: "Pages",
      link: "/Pages/Pages",
      icon: faFileCode,
    },
    {
      grupa: "Seo",
      name: "Seo",
      icon: faGear,
      link: "/Seo/Seo",
    },
    {
      grupa: "HeaderFooter",
      name: "Unos logotipa",
      icon: faImage,
      link: "/GrupaHeaderFooter/Logo",
    },
    {
      grupa: "HeaderFooter",
      name: "Navbar klasifikacije",
      icon: faBars,
      link: "/GrupaHeaderFooter/NavbarKlas",
    },
    {
      grupa: "HeaderFooter",
      name: "Boje",
      icon: faPalette,
      link: "/GrupaHeaderFooter/Boje",
    },
    {
      grupa: "HeaderFooter",
      name: "Tekstualna polja",
      icon: faFileLines,
      link: "/GrupaHeaderFooter/TekstPolja",
    },
    {
      grupa: "Cijene",
      name: "Dostava u stan",
      link: "/GrupaCijene/DostavaUStan",
      icon: faTruck
    },
    //{
    //  grupa: "Zadaci",
    //  name: "Zadaci za voditelje",
    //  link: "/GrupaZadaci/ZadaciVoditelj",
    //  icon: faBook,
    //},
    //{
    //  grupa: "Zadaci",
    //  name: "Novi zadatak",
    //  link: "/GrupaZadaci/NoviZadatak",
    //  icon: faBook,
    //},
  ];

  const grupa1Check = () => {
    if (grupa1.includes(username)) {
      return true;
    } else {
      return false;
    }
  };

  const grupa2Check = () => {
    if (grupa2.includes(username)) {
      return true;
    } else {
      return false;
    }
  };

  const grupa3Check = () => {
    if (grupa3.includes(username)) {
      return true;
    } else {
      return false;
    }
  };

  const list = (anchor: Anchor) => (
    <Box
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      style={{
        backgroundColor: "#2F4050",
        height: "100%",
        overflow: "scroll",
      }}
    >
      <Link to="/">
        <div className={classes.logoWrapper}>
          <img
            className={classes.logo}
            src="images/ElipsoLogoSvg.svg"
            alt="ElipsoGif"
          ></img>
        </div>
      </Link>
      {(grupa1Check() || grupa3Check()) && (
        <>
          <List>
            {items.map((item) => {
              if (item.grupa === "Dokumenti") {
                return (
                  <Link key={item.name} to={item.link}>
                    <ListItem>
                      <div className={classes.listItemWrapper}>
                        <FontAwesomeIcon
                          style={{
                            width: "30px",
                          }}
                          fontSize="30px"
                          icon={item.icon ?? faXmark}
                        />
                        <div className={classes.listText}>{item.name}</div>
                      </div>
                    </ListItem>
                  </Link>
                );
              } else {
                return null;
              }
            })}
          </List>
          <Divider style={{ color: "white" }} />
          <List>
            {items.map((item) => {
              if (item.grupa === "Akcije") {
                return (
                  <Link key={item.name} to={item.link}>
                    <ListItem>
                      <div className={classes.listItemWrapper}>
                        <FontAwesomeIcon
                          style={{
                            width: "30px",
                          }}
                          fontSize="30px"
                          icon={item.icon ?? faXmark}
                        />
                        <div className={classes.listText}>{item.name}</div>
                      </div>
                    </ListItem>
                  </Link>
                );
              } else {
                return null;
              }
            })}
          </List>
          <Divider style={{ color: "white" }} />
          <List>
            {items.map((item) => {
              if (item.grupa === "PosebneOsobine") {
                return (
                  <Link key={item.name} to={item.link}>
                    <ListItem>
                      <div className={classes.listItemWrapper}>
                        <FontAwesomeIcon
                          style={{
                            width: "30px",
                          }}
                          fontSize="30px"
                          icon={item.icon ?? faXmark}
                        />
                        <div className={classes.listText}>{item.name}</div>
                      </div>
                    </ListItem>
                  </Link>
                );
              } else {
                return null;
              }
            })}
          </List>
          <Divider style={{ color: "white" }} />
        </>
      )}
      {(grupa1Check() || grupa2Check()) && (
        <>
          <List>
            {items.map((item) => {
              if (item.grupa === "Poslovnice") {
                return (
                  <Link key={item.name} to={item.link}>
                    <ListItem>
                      <div className={classes.listItemWrapper}>
                        <FontAwesomeIcon
                          style={{
                            width: "30px",
                          }}
                          fontSize="30px"
                          icon={item.icon ?? faXmark}
                        />
                        <div className={classes.listText}>{item.name}</div>
                      </div>
                    </ListItem>
                  </Link>
                );
              } else {
                return null;
              }
            })}
          </List>
          <Divider style={{ color: "white" }} />
          <List>
            {items.map((item) => {
              if (item.grupa === "Placanja") {
                return (
                  <Link key={item.name} to={item.link}>
                    <ListItem>
                      <div className={classes.listItemWrapper}>
                        <FontAwesomeIcon
                          style={{
                            width: "30px",
                          }}
                          fontSize="30px"
                          icon={item.icon ?? faXmark}
                        />
                        <div className={classes.listText}>{item.name}</div>
                      </div>
                    </ListItem>
                  </Link>
                );
              } else {
                return null;
              }
            })}
          </List>
          <Divider style={{ color: "white" }} />
          <List>
            {items.map((item) => {
              if (item.grupa === "Cijene") {
                return (
                  <Link key={item.name} to={item.link}>
                    <ListItem>
                      <div className={classes.listItemWrapper}>
                        <FontAwesomeIcon
                          style={{
                            width: "30px",
                          }}
                          fontSize="30px"
                          icon={item.icon ?? faXmark}
                        />
                        <div className={classes.listText}>{item.name}</div>
                      </div>
                    </ListItem>
                  </Link>
                );
              } else {
                return null;
              }
            })}
          </List>
          <Divider style={{ color: "white" }} />
          <List>
            {items.map((item) => {
              if (item.grupa === "Pages") {
                return (
                  <Link key={item.name} to={item.link}>
                    <ListItem>
                      <div className={classes.listItemWrapper}>
                        <FontAwesomeIcon
                          style={{
                            width: "30px",
                          }}
                          fontSize="30px"
                          icon={item.icon ?? faXmark}
                        />
                        <div className={classes.listText}>{item.name}</div>
                      </div>
                    </ListItem>
                  </Link>
                );
              } else {
                return null;
              }
            })}
          </List>
          <Divider style={{ color: "white" }} />
        </>
      )}
      {grupa1Check() && (
        <>
          <List>
            {items.map((item) => {
              if (item.grupa === "Seo") {
                return (
                  <Link key={item.name} to={item.link}>
                    <ListItem>
                      <div className={classes.listItemWrapper}>
                        <FontAwesomeIcon
                          style={{
                            width: "30px",
                          }}
                          fontSize="30px"
                          icon={item.icon ?? faXmark}
                        />
                        <div className={classes.listText}>{item.name}</div>
                      </div>
                    </ListItem>
                  </Link>
                );
              } else {
                return null;
              }
            })}
          </List>
          <Divider style={{ color: "white" }} />
          <List>
            {items.map((item) => {
              if (item.grupa === "HeaderFooter") {
                return (
                  <Link key={item.name} to={item.link}>
                    <ListItem>
                      <div className={classes.listItemWrapper}>
                        <FontAwesomeIcon
                          style={{
                            width: "30px",
                          }}
                          fontSize="30px"
                          icon={item.icon ?? faXmark}
                        />
                        <div className={classes.listText}>{item.name}</div>
                      </div>
                    </ListItem>
                  </Link>
                );
              } else {
                return null;
              }
            })}
          </List>
        </>
      )}
    </Box>
  );

  return (
    <div>
      {(["left"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            onClick={toggleDrawer(anchor, true)}
            style={{
              backgroundColor: "#1ab394",
              color: "white",
              minWidth: 0,
              width: "3em",
              marginRight: "2em",
            }}
            size="small"
          >
            <MenuIcon />
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
