import { Card, CardMedia } from "@mui/material";

type PromoPopustSlikaTypes = {
  slika: string | null;
  setDetailViewOpen: (value: boolean) => void;
};

const PromoPopustSlika = (props: PromoPopustSlikaTypes) => {
  return (
    <Card
      style={{ margin: "1em", display: "flex" }}
      onClick={() => {
        props.setDetailViewOpen(true);
      }}
    >
      <CardMedia component="img" image={props.slika ?? ""} alt={"slika"} />
    </Card>
  );
};

export default PromoPopustSlika;
