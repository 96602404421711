import classes from "./NavigationCardContainer.module.css";
import NavigationCard from "../NavigationCard/NavigationCard";

type NavigationCardContainerProps = {
  navigacija: string[];
};

const NavigationCardContainer = (props: NavigationCardContainerProps) => {
  return (
    <div className={classes.container}>
      {props.navigacija.map((item) => {
        return <NavigationCard key={item} oznaka={item} />;
      })}
    </div>
  );
};

export default NavigationCardContainer;
