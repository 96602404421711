import produce, { Draft } from 'immer';
import { ZadaciVoditeljAction } from './ZadaciVoditeljaActions';
import { ZadaciTable } from './ZadaciVoditeljTable';

const zadaciVoditeljReducer = produce((oldState : Draft<ZadaciTable[]>, action: ZadaciVoditeljAction) => {
    switch(action.action) {
        case 'GET_VODITELJ_ZADACI':
            return action.value;
        default: 
            break;
    }
})

export default zadaciVoditeljReducer;