import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useCallback, useEffect, useState } from "react";
import useApi from "../Hooks/useApi";
import Swal from "sweetalert2";
import classes from "./Seo.module.css";
import BackDrop from "../UnosAkcijePrilike/BackDrop";
import { AlertModel } from "../UnosAkcijePrilike/UnosAkcijePrilikeTypes";
import { Dialog, DialogContent, TextField } from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
};

type AddModalProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  setSeoId: (value: number) => void;
};

const SeoAddModal = (props: AddModalProps) => {
  const apiCall = useApi();
  const [klas, setKlas] = useState<string>("");
  const [tekst, setTekst] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dialog, setOpen] = useState<AlertModel>({ open: false, message: "" });
  const [showSpremi, setShowSpremi] = useState<boolean>(false);
  const handleDialogClose = () => {
    setOpen({ open: false, message: "" });
  };
  const handleClose = () => {
    props.setSeoId(-1);
    setKlas("");
    setTekst("");
    props.setOpen(false);
  };

  const spremiSeo = useCallback(
    (accessToken: string) => {
      setIsLoading(true);
      let formData = new FormData();
      formData.append("klas", klas);
      formData.append("tekst", tekst);

      fetch("api/Seo/ExistsSeo", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "post",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.responseData) {
            setIsLoading(false);
            setOpen({ open: true, message: "Zapis već postoji!" });
          } else {
            fetch("api/Seo/NewSeos", {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
              method: "post",
              body: formData,
            }).then((response) => {
              if (response.status === 200) {
                props.setOpen(false);
                setIsLoading(false);
                Swal.fire({
                  icon: "success",
                  title: "Spremljeno",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.location.reload();
                  }
                });
              } else {
                setIsLoading(false);
                Swal.fire({
                  title: response.statusText,
                  showConfirmButton: false,
                  toast: true,
                  customClass: {
                    container: classes.swalContainer,
                    popup: classes.swal2IconError,
                  },
                  timer: 1500,
                  iconColor: "white",
                  timerProgressBar: true,
                  icon: "warning",
                  position: "top-right",
                });
              }
            });
          }
        });
    },
    [klas, props, tekst]
  );

  const post = () => {
    apiCall(spremiSeo);
  };

  useEffect(() => {
    if (klas === "" || tekst === "") {
      setShowSpremi(false);
    } else {
      setShowSpremi(true);
    }
  }, [klas, tekst]);

  return (
    <>
      <div>
        <Modal
          open={props.open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <TextField
              value={klas}
              style={{ marginBottom: "10px" }}
              fullWidth
              id="klas"
              label="Klas"
              variant="outlined"
              onChange={(e) => {
                setKlas(e.target.value.toUpperCase());
              }}
            />
            <TextField
              value={tekst}
              style={{ marginBottom: "10px" }}
              fullWidth
              id="tekst"
              label="Tekst"
              variant="outlined"
              onChange={(e) => {
                setTekst(e.target.value);
              }}
            />
            {showSpremi && (
              <Button
                style={{ backgroundColor: "rgb(26, 179, 148)" }}
                variant="contained"
                onClick={() => {
                  post();
                }}
              >
                Spremi
              </Button>
            )}
          </Box>
        </Modal>
      </div>
      {dialog.open && (
        <Dialog onClose={handleDialogClose} open={dialog.open}>
          <DialogContent className={classes.dialog}>
            <label>{dialog.message}</label>
            <div style={{ textAlign: "end" }}>
              <Button onClick={handleDialogClose}>Zatvori</Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
      {isLoading && <BackDrop />}
    </>
  );
};

export default SeoAddModal;
