import { Box, Button, Modal, TextField } from "@mui/material";
import {
  AkcijaPromoKodViewModel,
  AlertModel,
  PopustPromoIznos,
  PopustPromoPaymentType,
  PopustPromoPaymentTypeCode,
  PopustPromoPaymentTypeGroup,
  PromoPopustModel,
  SelectOpcije,
} from "../UnosAkcijePrilikeTypes";
import Select from "../Select";
import DatePicker from "../Datepicker";
import FileInput from "../FileInputAkcijePrilike";
import classes from "./../UnosAkcijePrilike.module.css";
import { SlikaInfo } from "../../../utils/utils";
import { useEffect, useState } from "react";
import { Dialog, DialogContent } from "@mui/material";
import BackDrop from "../../UnosAkcijePrilike/BackDrop";
import Swal from "sweetalert2";

type PromoPopustFormaProps = {
  promoKodNotValid: boolean;
  promoPopustModel: PromoPopustModel;
  setPromoKodNotValid: (value: boolean) => void;
  promoPopustHandler: (value: PromoPopustModel) => void;
  promoIznosNotValid: boolean;
  setPromoIznosNotValid: (value: boolean) => void;
  popustPromoIznos: PopustPromoIznos[];
  promoPaymentTypeNotValid: boolean;
  setPromoPaymentTypeNotValid: (value: boolean) => void;
  popustPromoPaymentType: PopustPromoPaymentType[];
  promoPaymentTypeCodesNotValid: boolean;
  setPromoPaymentTypeCodesNotValid: (value: boolean) => void;
  setPromoPaymentTypeGroupsNotValid: (value: boolean) => void;
  popustPromoPaymentTypeCode: PopustPromoPaymentTypeCode[];
  popustPromoPaymentTypeGroup: PopustPromoPaymentTypeGroup[];
  promoPaymentTypeGroupsNotValid: boolean;
  setPromoPocetakNotValid: (value: boolean) => void;
  promoPocetakNotValid: boolean;
  pocetakPrilike: string | undefined;
  krajPrilike: string | undefined;
  setPromoKrajNotValid: (value: boolean) => void;
  promoKrajNotValid: boolean;
  slike151px: SlikaInfo[] | null;
  ucitajSliku: (file: any, fileName: string | null) => void;
  slikaPromoNotValid: boolean;
  open: boolean;
  setOpen: (value: boolean) => void;
  data: AkcijaPromoKodViewModel | null;
  listaPromoKodova: AkcijaPromoKodViewModel[];
  brisiSliku: (value: null) => void;
  setSlikaPromoNotValid: (value: boolean) => void;
  listaPromoPopustHandler: (value: AkcijaPromoKodViewModel) => void;
  updatePromoKod: (promoKod: string, sifraArtikla: number) => void;
};
const PromoPopustForma = (props: PromoPopustFormaProps) => {
  const [dialog, setOpen] = useState<AlertModel>({ open: false, message: "" });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleDialogClose = () => {
    setOpen({ open: false, message: "" });
  };

  let validPromoKodRegex = /^[A-Z0-9]$/;

  useEffect(() => {
  let promoPopust = props.listaPromoKodova.find(l => l?.popustPromoCode === props.data?.popustPromoCode);
  let odabraniPopustPromoIznos = {id: props.popustPromoIznos.find(x => parseInt(x.naziv.substring(0, x.naziv.indexOf("%"))) === promoPopust?.popustPromoIznos)?.id??-1, label: props.popustPromoIznos.find(x => parseInt(x.naziv.substring(0, x.naziv.indexOf("%"))) === promoPopust?.popustPromoIznos)?.naziv??""};
  let odabraniPopustPromoType = {id: props.popustPromoPaymentType.find(x => x.naziv === promoPopust?.popustPromoPaymentType)?.id??-1, label: props.popustPromoPaymentType.find(x => x.naziv === promoPopust?.popustPromoPaymentType)?.naziv??""}
  let odabraniPopustPromoTypeCode = {id: props.popustPromoPaymentTypeCode.find(x => x.naziv === promoPopust?.popustPromoPaymentTypeCode)?.id??-1, label: props.popustPromoPaymentTypeCode.find(x => x.naziv === promoPopust?.popustPromoPaymentTypeCode)?.naziv??""}
  let odabraniPopustPromoTypeGroup = {id: props.popustPromoPaymentTypeGroup.find(x => x.naziv === promoPopust?.popustPromoPaymentTypeGroup)?.id??-1, label: props.popustPromoPaymentTypeGroup.find(x => x.naziv === promoPopust?.popustPromoPaymentTypeGroup)?.naziv??""}
  
  props.promoPopustHandler({
        ...props.promoPopustModel,
        odabraniDatumPocetkaPromo: props.pocetakPrilike,
        odabraniDatumKrajaPromo: props.krajPrilike,
        kodZaPopustPromo: promoPopust?.popustPromoCode??"",
        odabraniPopustPromoIznos: odabraniPopustPromoIznos.id === -1 ? null : odabraniPopustPromoIznos,
        odabraniPopustPromoType: odabraniPopustPromoType.id === -1 ? null : odabraniPopustPromoType,
        odabraniPopustPromoTypeCode: odabraniPopustPromoTypeCode.id === -1 ? null : odabraniPopustPromoTypeCode,
        odabraniPopustPromoTypeGroup: odabraniPopustPromoTypeGroup.id === -1 ? null : odabraniPopustPromoTypeGroup
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const handleClose = () => {
    props.setOpen(false);
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "background.paper",
    border: "1px solid #e0e0e0",
    boxShadow: 24,
    p: 2,
    height:'95%',
    overflow:'scroll !important'
  };

  const validacija = () => {
    let counter = 0;
    if (props.promoPopustModel.kodZaPopustPromo.length === 0) {
      props.setPromoKodNotValid(true);
      counter++;
    }
    if (!props.promoPopustModel.odabraniPopustPromoIznos) {
      props.setPromoIznosNotValid(true);
      counter++;
    }
    if (!props.promoPopustModel.odabraniPopustPromoType) {
      props.setPromoPaymentTypeNotValid(true);
      counter++;
    }
    if (
      !props.promoPopustModel.odabraniPopustPromoTypeCode &&
      !props.promoPopustModel.odabraniPopustPromoTypeGroup
    ) {
      props.setPromoPaymentTypeCodesNotValid(true);
      props.setPromoPaymentTypeGroupsNotValid(true);
      counter++;
    }
    if (!props.promoPopustModel.odabraniDatumPocetkaPromo) {
      props.setPromoPocetakNotValid(true);
      counter++;

    }
    if (!props.promoPopustModel.odabraniDatumKrajaPromo) {
      props.setPromoKrajNotValid(true);
      counter++;
    }
    if (props.slike151px?.findIndex(x => x.name === `${props.data?.popustPromoCode}.png`) === -1) {
      props.setSlikaPromoNotValid(true);
      counter++;
    }
    if (counter > 0) {
      return false;
    } else {
      return true;
    }
  };

  const post = () => {
    if (validacija()) {
      props.updatePromoKod(props.promoPopustModel.kodZaPopustPromo, parseInt(props.data?.popustPromoCode??""));
      props.listaPromoPopustHandler({
        isOk: true,
        krajPromo: props.promoPopustModel.odabraniDatumKrajaPromo ?? "",
        pocetakPromo: props.promoPopustModel.odabraniDatumPocetkaPromo ?? "",
        popustPromoCode: props.promoPopustModel.kodZaPopustPromo,
        popustPromoIznos: parseInt(props.promoPopustModel.odabraniPopustPromoIznos?.label.substring(0, props.promoPopustModel.odabraniPopustPromoIznos?.label.indexOf("%"))??""),
        popustPromoPaymentType:
          props.promoPopustModel.odabraniPopustPromoType?.label ?? "",
        popustPromoPaymentTypeCode:
          props.promoPopustModel.odabraniPopustPromoTypeCode?.label ?? "",
        popustPromoPaymentTypeGroup:
          props.promoPopustModel.odabraniPopustPromoTypeGroup?.label ?? "",
        // sifraArtikla: props.data?.sifraArtikla ?? "",
      });
      Swal.fire({
        title: "Spremljeno!",
        showConfirmButton: false,
        toast: true,
        customClass: {
          container: classes.swalContainer,
          popup: classes.swal2IconSuccess,
        },
        timer: 1500,
        iconColor: "white",
        timerProgressBar: true,
        icon: "warning",
        position: "top-right",
      });
      handleClose();
    }
  };

  return (
    <>
      <Modal open={props.open} onClose={handleClose}>
        <Box sx={style}>
          <TextField
          disabled={true}
            label="KOD ZA POPUST PROMO"
            style={{
              marginBottom: props.promoKodNotValid ? "" : "1em",
              backgroundColor: "white",
            }}
            size="small"
            value={props.promoPopustModel.kodZaPopustPromo}
            onChange={(e: any) => {
              props.setPromoKodNotValid(false);
              if (
                props.promoPopustModel.kodZaPopustPromo.toString().length >
                e.target.value.toString().length
              ) {
                props.promoPopustHandler({
                  ...props.promoPopustModel,
                  kodZaPopustPromo: e.target.value,
                });
              } else {
                if (props.promoPopustModel.kodZaPopustPromo.toString().length < 16) {
                  if (
                    e.target.value
                      .charAt(e.target.value.length - 1)
                      .match(validPromoKodRegex)
                  ) {
                    props.promoPopustHandler({
                      ...props.promoPopustModel,
                      kodZaPopustPromo: e.target.value,
                    });
                  }
                }
              }
              // if (e.target.value === "") {
              //   props.promoPopustHandler({
              //     ...props.promoPopustModel,
              //     odabraniDatumPocetkaPromo: "",
              //     odabraniDatumKrajaPromo: "",
              //   });
              // }
            }}
            fullWidth
          />
          {props.promoKodNotValid && (
            <div
              style={{
                fontSize: "0.8em",
                color: "red",
                marginBottom: "1em",
              }}
            >
              Obavezno polje!
            </div>
          )}
          <Select
            inputLabel="POPUST PROMO IZNOS"
            marginBottom={props.promoIznosNotValid ? "" : "1em !important"}
            tipoviAkcije={[]}
            handleChange={(value: SelectOpcije | null) => {
              props.setPromoIznosNotValid(false);
              props.promoPopustHandler({
                ...props.promoPopustModel,
                odabraniPopustPromoIznos: value,
              });
            }}
            value={props.promoPopustModel.odabraniPopustPromoIznos ?? null}
            voditeljiBrenda={[]}
            robneMarke={[]}
            kategorijeProizvodaNaAkciji={[]}
            vezanaKupnjaSnizenja={[]}
            naciniSnizenjaCijena={[]}
            vrstePoklonOsiguranja={[]}
            naciniPlacanjaNaRate={[]}
            registracija={[]}
            obavezno={false}
            error={false}
            referenca="promo"
            popustPromoIznos={props.popustPromoIznos}
            popustPromoPaymentTypes={[]}
            popustPromoPaymentTypeCodes={[]}
            popustPromoPaymentTypeGroups={[]}
            // disabled={props.promoPopustArtikli.length > 0 ? false : true}
            disabled={false}
          />
          {props.promoIznosNotValid && (
            <div
              style={{
                fontSize: "0.8em",
                color: "red",
                marginBottom: "1em",
              }}
            >
              Obavezno polje!
            </div>
          )}
          <Select
            inputLabel="POPUST PROMO PAYMENT TYPE"
            marginBottom={
              props.promoPaymentTypeNotValid ? "" : "1em !important"
            }
            tipoviAkcije={[]}
            handleChange={(value: SelectOpcije | null) => {
              props.setPromoPaymentTypeNotValid(false);
              props.promoPopustHandler({
                ...props.promoPopustModel,
                odabraniPopustPromoType: value,
              });
            }}
            value={props.promoPopustModel.odabraniPopustPromoType ?? null}
            voditeljiBrenda={[]}
            robneMarke={[]}
            kategorijeProizvodaNaAkciji={[]}
            vezanaKupnjaSnizenja={[]}
            naciniSnizenjaCijena={[]}
            vrstePoklonOsiguranja={[]}
            naciniPlacanjaNaRate={[]}
            registracija={[]}
            obavezno={false}
            error={false}
            referenca="promo"
            popustPromoPaymentTypes={props.popustPromoPaymentType}
            popustPromoIznos={[]}
            popustPromoPaymentTypeCodes={[]}
            popustPromoPaymentTypeGroups={[]}
            disabled={false}
          />
          {props.promoPaymentTypeNotValid && (
            <div
              style={{
                fontSize: "0.8em",
                color: "red",
                marginBottom: "1em",
              }}
            >
              Obavezno polje!
            </div>
          )}
          <Select
            inputLabel="POPUST PROMO PAYMENT TYPE CODES"
            marginBottom={
              props.promoPaymentTypeCodesNotValid ? "" : "1em !important"
            }
            tipoviAkcije={[]}
            handleChange={(value: SelectOpcije | null) => {
              props.setPromoPaymentTypeCodesNotValid(false);
              props.setPromoPaymentTypeGroupsNotValid(false);
              props.promoPopustHandler({
                ...props.promoPopustModel,
                odabraniPopustPromoTypeCode: value,
              });
            }}
            value={props.promoPopustModel.odabraniPopustPromoTypeCode}
            voditeljiBrenda={[]}
            robneMarke={[]}
            kategorijeProizvodaNaAkciji={[]}
            vezanaKupnjaSnizenja={[]}
            naciniSnizenjaCijena={[]}
            vrstePoklonOsiguranja={[]}
            naciniPlacanjaNaRate={[]}
            registracija={[]}
            obavezno={false}
            error={false}
            referenca="promo"
            popustPromoPaymentTypeCodes={props.popustPromoPaymentTypeCode}
            popustPromoIznos={[]}
            popustPromoPaymentTypes={[]}
            popustPromoPaymentTypeGroups={[]}
            disabled={
              props.promoPopustModel.odabraniPopustPromoTypeGroup ? true : false
            }
          />
          {props.promoPaymentTypeCodesNotValid && (
            <div
              style={{
                fontSize: "0.8em",
                color: "red",
                marginBottom: "1em",
              }}
            >
              Obavezno polje!
            </div>
          )}
          <Select
            inputLabel="POPUST PROMO PAYMENT TYPE GROUPS"
            marginBottom={
              props.promoPaymentTypeGroupsNotValid ? "" : "1em !important"
            }
            tipoviAkcije={[]}
            handleChange={(value: SelectOpcije | null) => {
              props.setPromoPaymentTypeCodesNotValid(false);
              props.setPromoPaymentTypeGroupsNotValid(false);
              props.promoPopustHandler({
                ...props.promoPopustModel,
                odabraniPopustPromoTypeGroup: value,
              });
            }}
            value={props.promoPopustModel.odabraniPopustPromoTypeGroup}
            voditeljiBrenda={[]}
            robneMarke={[]}
            kategorijeProizvodaNaAkciji={[]}
            vezanaKupnjaSnizenja={[]}
            naciniSnizenjaCijena={[]}
            vrstePoklonOsiguranja={[]}
            naciniPlacanjaNaRate={[]}
            registracija={[]}
            obavezno={false}
            error={false}
            referenca="promo"
            popustPromoPaymentTypeGroups={props.popustPromoPaymentTypeGroup}
            popustPromoIznos={[]}
            popustPromoPaymentTypes={[]}
            popustPromoPaymentTypeCodes={[]}
            disabled={
              props.promoPopustModel.odabraniPopustPromoTypeCode ? true : false
            }
          />
          {props.promoPaymentTypeGroupsNotValid && (
            <div
              style={{
                fontSize: "0.8em",
                color: "red",
                marginBottom: "1em",
              }}
            >
              Obavezno polje!
            </div>
          )}
          <DatePicker
            value={props.promoPopustModel.odabraniDatumPocetkaPromo ?? ""}
            handleChange={(value: string | null) => {
              props.promoPopustHandler({
                ...props.promoPopustModel,
                odabraniDatumPocetkaPromo: value ?? "",
              });
              props.setPromoPocetakNotValid(false);
            }}
            label="Početak promo popusta"
            error={false}
            marginBottom={props.promoPocetakNotValid ? "" : "1em"}
            obavezno={false}
            disabled={
              props.promoPopustModel.kodZaPopustPromo.length === 0
                ? true
                : false
            }
            maxDate={props.krajPrilike}
            minDate={props.pocetakPrilike}
          />
          {props.promoPocetakNotValid && (
            <div
              style={{
                fontSize: "0.8em",
                color: "red",
                marginBottom: "1em",
              }}
            >
              Obavezno polje!
            </div>
          )}
          <DatePicker
            value={props.promoPopustModel.odabraniDatumKrajaPromo ?? ""}
            handleChange={(value: string | null) => {
              props.promoPopustHandler({
                ...props.promoPopustModel,
                odabraniDatumKrajaPromo: value ?? "",
              });
              props.setPromoKrajNotValid(false);
            }}
            label="Kraj promo popusta"
            marginBottom={props.promoKrajNotValid ? "" : "1em"}
            obavezno={false}
            error={false}
            disabled={
              props.promoPopustModel.kodZaPopustPromo.length === 0
                ? true
                : false
            }
            maxDate={props.krajPrilike}
            minDate={props.pocetakPrilike}
          />
          {props.promoKrajNotValid && (
            <div
              style={{
                fontSize: "0.8em",
                color: "red",
                marginBottom: "1em",
              }}
            >
              Obavezno polje!
            </div>
          )}
          <FileInput
            label="Slika 151px*151px png"
            multiple={false}
            extension={".png"}
            ucitajSliku={props.ucitajSliku}
            nazivSlike={props.data?.popustPromoCode?.toString()??""}
          />
          {props.slikaPromoNotValid && (
            <div
              style={{
                fontSize: "0.8em",
                color: "red",
                marginBottom: "1em",
              }}
            >
              Obavezno polje!
            </div>
          )}
          {props.slike151px?.find(x => x.name === `${props.data?.popustPromoCode}.png`) && (
            <div className={classes.nazivOdabraneSlikeWrapper}>
              <div className={classes.odabranaSlikaText}>Odabrana slika:</div>
              <div
                style={{
                  fontWeight: 600,
                }}
              >
                {props.slike151px.find(x => x.name === `${props.data?.popustPromoCode}.png`)?.name}
              </div>
            </div>
          )}
          {props.slike151px?.find(x => x.name === `${props.data?.popustPromoCode}.png`) && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img
                style={{ width: "30%" }}
                src={props.slike151px.find(x => x.name === `${props.data?.popustPromoCode}.png`)?.src}
                alt={props.slike151px.find(x => x.name === `${props.data?.popustPromoCode}.png`)?.name}
              />
            </div>
          )}
          <Button
            style={{ marginTop: "1em" }}
            variant="contained"
            color="success"
            fullWidth
            onClick={post}
          >
            Spremi
          </Button>
        </Box>
      </Modal>
      <Dialog onClose={handleDialogClose} open={dialog.open}>
        <DialogContent className={classes.dialog}>
          <label>{dialog.message}</label>
          <div style={{ textAlign: "end" }}>
            <Button onClick={handleDialogClose}>Zatvori</Button>
          </div>
        </DialogContent>
      </Dialog>
      {isLoading && <BackDrop />}
    </>
  );
};

export default PromoPopustForma;
