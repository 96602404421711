type PoslovnicaModel = {
  id: number;
  mjesto: string;
  adresa: string;
  brojTelefona: string;
  radnoVrijeme: string;
  latitude: number;
  longitude: number;
  postanskiBroj: number;
  sifraZemlje: string;
  homePage: string;
  opis: string;
  email: string;
  radnoVrijemeArray: RadnoVrijemeDanModel;
};

type AddEditPoslovnicaType = {
  setOpen: (value: boolean) => void;
  poslovnica: any | null;
  savePoslovnicu: (poslovnica: any) => void;
};

type AdresaType = {
  adresa: string;
  mjesto: string;
  postanskiBroj: number;
  sifraZemlje: string;
  kucanskiBroj: string;
  zupanija: string;
  latitude: number;
  longitude: number;
};

const AdresaTypeDefault: AdresaType = {
  adresa: "",
  mjesto: "",
  postanskiBroj: 0,
  sifraZemlje: "",
  kucanskiBroj: "",
  zupanija: "",
  latitude: 0,
  longitude: 0,
};

type RadnoVrijemeDanModel = {
  "1": string;
  "2": string;
  "3": string;
  "4": string;
  "5": string;
  "6": string;
  "7": string;
};

const PoslovnicaModelDefault: PoslovnicaModel = {
  adresa: "",
  brojTelefona: "",
  email: "",
  homePage: "http://www.elipso.hr/",
  id: 0,
  latitude: 0,
  longitude: 0,
  mjesto: "",
  opis: "",
  postanskiBroj: 0,
  radnoVrijeme: "",
  sifraZemlje: "",
  radnoVrijemeArray: {
    "1": "",
    "2": "",
    "3": "",
    "4": "",
    "5": "",
    "6": "",
    "7": "",
  },
};

type InputErrorModel = {
  value: string;
  valid: boolean;
  touched: boolean;
  error: string;
};

type PoslovnicaAddEditModel = {
  submit: boolean | null;
  id: number;
  lokacija: AdresaType;
  adresa: InputErrorModel;
  mjesto: InputErrorModel;
  postanskiBroj: InputErrorModel;
  telefon: InputErrorModel;
  email: InputErrorModel;
  webStranica: InputErrorModel;
  prikaziNedjelju: boolean;
  //radno vrijeme i dvokratno radno vrijeme
  //nedjelja
  "1": {
    "1": InputErrorModel | null;
    "2": InputErrorModel | null;
  };
  "2": {
    "1": InputErrorModel;
    "2": InputErrorModel | null;
  };
  "3": {
    "1": InputErrorModel;
    "2": InputErrorModel | null;
  };
  "4": {
    "1": InputErrorModel;
    "2": InputErrorModel | null;
  };
  "5": {
    "1": InputErrorModel;
    "2": InputErrorModel | null;
  };
  "6": {
    "1": InputErrorModel;
    "2": InputErrorModel | null;
  };
  //subota
  "7": {
    "1": InputErrorModel;
    "2": InputErrorModel | null;
  };
  lokacijaError: string;
};

const InputErrorModelDefault: InputErrorModel = {
  value: "",
  valid: false,
  touched: false,
  error: "",
};

const PoslovnicaAddEditModelDefault: PoslovnicaAddEditModel = {
  submit: null,
  id: 0,
  lokacija: AdresaTypeDefault,
  adresa: InputErrorModelDefault,
  mjesto: InputErrorModelDefault,
  postanskiBroj: InputErrorModelDefault,
  telefon: InputErrorModelDefault,
  email: InputErrorModelDefault,
  webStranica: InputErrorModelDefault,
  prikaziNedjelju: false,
  //radno vrijeme i dvokratno radno vrijeme
  //nedjelja
  "1": {
    "1": null,
    "2": null,
  },
  "2": {
    "1": InputErrorModelDefault,
    "2": null,
  },
  "3": {
    "1": InputErrorModelDefault,
    "2": null,
  },
  "4": {
    "1": InputErrorModelDefault,
    "2": null,
  },
  "5": {
    "1": InputErrorModelDefault,
    "2": null,
  },
  "6": {
    "1": InputErrorModelDefault,
    "2": null,
  },
  //subota
  "7": {
    "1": InputErrorModelDefault,
    "2": null,
  },
  lokacijaError: "",
};

type RadnoVrijemeModel = {
  dan: number;
  prikazi: boolean;
  vrijednost: string;
  //jednokratno(1), dvokratno(2)
  tip: number;
};

type DetaljiModel = {
  adresa: string;
  brojTelefona: string;
  email: string;
  homePage: string;
  latitude: number;
  longitude: number;
  mjesto: string;
  poslovnicaRadnoVrijeme: RadnoVrijemeDanModel;
};

type NeradnaNedelja = {
    id: number;
    datum: string;
    poslovnicaId: number;
}

const DetaljiModelDefault = {
  adresa: "",
  brojTelefona: "",
  email: "",
  homePage: "",
  latitude: 45.815399,
  longitude: 15.966568,
  mjesto: "",
  poslovnicaRadnoVrijeme: {
    "1": "",
    "2": "",
    "3": "",
    "4": "",
    "5": "",
    "6": "",
    "7": "",
  },
};

export function separateFromTo(fromTo: string): string {
  if (fromTo.length !== 11) return "";
  return `${fromTo.substring(0, 5)}-${fromTo.substring(6)}`;
}

export function parseRadnoVrijeme(data: string[]): string {
  if (!data || data.length === 0) return "";
  let radnoVrijeme = "";
  let separator = ", ";

  for (let i = 0; i < data.length; i++) {
    const element = data[i].length < 11 ? `0${data[i]}` : data[i];
    radnoVrijeme += separateFromTo(element);
    if (i !== data.length - 1) radnoVrijeme += separator;
  }
  return radnoVrijeme;
}

type NeradneNedeljeModel = {
  neradneNedelje: string[];
}

const NeradneNedeljeDefault: NeradneNedeljeModel = {
  neradneNedelje: []
}

export type {
  AddEditPoslovnicaType,
  PoslovnicaModel,
  AdresaType,
  RadnoVrijemeDanModel,
  PoslovnicaAddEditModel,
  InputErrorModel,
  RadnoVrijemeModel,
  DetaljiModel,
  NeradnaNedelja,
  NeradneNedeljeModel
};

export {
  PoslovnicaModelDefault,
  PoslovnicaAddEditModelDefault,
  InputErrorModelDefault,
  DetaljiModelDefault,
  NeradneNedeljeDefault
};
