import { useCallback, useEffect, useReducer, useState } from "react";
import {
  DataGrid,
  Column,
  Selection,
  Editing,
  FilterRow,
  Toolbar,
  Item,
} from "devextreme-react/data-grid";
import { Pager, Paging } from "devextreme-react/tree-list";
import { Button as ButtonMui } from "@mui/material";
import { PencilSquare } from "react-bootstrap-icons";
import AddEditModal from "./AddEditModal";
import { NeRadniDan } from "./NeRadniDaniTypes";
import neRadniDaniReducer from "./neRadniDaniReducer";
import { defaultState } from "./deafultState";
import useApi from "../Hooks/useApi";
import Swal from "sweetalert2";
import classes from "./NeRadniDani.module.css";
import moment from "moment";
import BackDrop from "../UnosAkcijePrilike/BackDrop";

const NeRadniDani = () => {
  const apiCall = useApi();
  const [data, dispatcher] = useReducer(neRadniDaniReducer, defaultState);
  const allowedPageSizes = [20, 50, 100];

  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  let NeRadniDaniCancel = false;
  const [datumId, setDatumId] = useState<number>(-1);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getNeRadniDani = (accessToken: string) => {
    fetch("/api/NeradniDan/GetNeradniDani", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (NeRadniDaniCancel) return;
        dispatcher({
          action: "GET_NERADNI_DANI",
          value: data.responseData,
        });
      });
  };

  useEffect(() => {
    apiCall(getNeRadniDani);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      NeRadniDaniCancel = true;
    };
  }, [apiCall]);

  const deleteDate = useCallback(
    (accessToken: string) => {
      if (datumId !== -1) {
        setIsLoading(true);
        let formData = new FormData();
        formData.append("id", datumId.toString());

        fetch("api/NeradniDan/DeleteNeradniDan", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          method: "post",
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.responseData) {
              setIsLoading(false);
              Swal.fire({
                icon: "success",
                title: "Obrisano",
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              });
            } else {
              setIsLoading(false);
              Swal.fire({
                title: data.error,
                showConfirmButton: false,
                toast: true,
                customClass: {
                  container: classes.swalContainer,
                  popup: classes.swal2IconError,
                },
                timer: 1500,
                iconColor: "white",
                timerProgressBar: true,
                icon: "warning",
                position: "top-right",
              });
            }
          });
      }
    },
    [datumId]
  );

  useEffect(() => {
    apiCall(deleteDate);
  }, [apiCall, deleteDate]);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          marginTop: "1em",
        }}
      >
        <DataGrid
          id="dataGrid"
          //   ref={dataGrid}
          dataSource={data.neRadniDani.map((datum) => ({
            id: datum.id,
            datum: moment(new Date(datum.datum)).format("DD-MM"),
          }))}
          rowAlternationEnabled
          allowColumnReordering
          allowColumnResizing
          showBorders
          onEditingStart={(e) => {
            // e.cancel = true;
            // setRowData(e.data);
            // setEditModalOpen(true);
          }}
          remoteOperations={true}
          onRowRemoving={(e: any) => {
            setDatumId(e.data.id);
            e.cancel = true;
          }}
        >
          <FilterRow visible={true} />
          <Paging defaultPageSize={20} />
          <Editing mode="row" allowDeleting />
          <Pager
            visible={true}
            showPageSizeSelector={true}
            showInfo={true}
            allowedPageSizes={allowedPageSizes}
            infoText={`Stranica {0} od {1} ({2} artikala)`}
          />
          <Selection mode="single" />
          <Column
            allowEditing={true}
            // width="10%"
            dataField="datum"
            caption="Datum"
          />
          <Toolbar>
            <Item location="after">
              <ButtonMui
                onClick={() => {
                  setAddModalOpen(true);
                }}
                variant="contained"
              >
                <PencilSquare size={25} />
              </ButtonMui>
            </Item>
          </Toolbar>
        </DataGrid>
      </div>
      {addModalOpen && (
        <AddEditModal
          open={addModalOpen}
          setOpen={setAddModalOpen}
          neRadniDani={data.neRadniDani}
          addDani={data.addDani}
          setDatumi={(datumi: string) => {
            dispatcher({ action: "DATUM_ODABRAN", value: datumi });
          }}
          changeDatum={(stari: string, novi: string) => {
            dispatcher({
              action: "DATUM_CHANGE",
              value: { old: stari, new: novi },
            });
          }}
        />
      )}
      {isLoading && <BackDrop />}
    </>
  );
};

export default NeRadniDani;
export type { NeRadniDan };
