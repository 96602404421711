import {  useEffect, useReducer } from 'react';
import { Box, TextField, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import '../../custom.css';
import classes from './AddEditPoslovnica.module.css';
import { AddEditPoslovnicaType, PoslovnicaAddEditModelDefault, AdresaType, RadnoVrijemeModel } from './PoslovniceTypes';
import GoogleMapsAutocomplete from './GoogleMapsAutocomplete';
import { Map } from './Map';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faPhone, faEnvelope, faClock } from '@fortawesome/free-solid-svg-icons';
import InputRadnoVrijeme from './InputRadnoVrijeme';
import PoslovniceReducer from './PoslovniceReducer';

const style = {
    overflow: "auto",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "98%",
    height: "98%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    display: "flex",
    //   alignItems: "center",
    flexDirection: "column",
};

const AddEditPoslovnica = ({ setOpen, poslovnica, savePoslovnicu }: AddEditPoslovnicaType) => {
    const [model, dispatcher] = useReducer(PoslovniceReducer, PoslovnicaAddEditModelDefault);
    const handleClose = () => {
        setOpen(false);
    };

    const handleSpremi = () => {
        dispatcher({ action: "FORMA_VALIDIRANA", value: null });
    };
    useEffect(() => {
        if (poslovnica) {
            let adresa: AdresaType = {
                adresa: poslovnica.adresa,
                mjesto: poslovnica.mjesto,
                postanskiBroj: poslovnica.postanskiBroj,
                kucanskiBroj: '',
                latitude: poslovnica.latitude,
                longitude: poslovnica.longitude,
                sifraZemlje: poslovnica.sifraZemlje,
                zupanija: ''
            }
            dispatcher({ action: "POSLOVNICAID_POSTAVLJEN", value: poslovnica.sifra })
            dispatcher({ action: "ADRESA_POSTAVLJENA", value: adresa });
            dispatcher({ action: "TELEFON_UNESEN", value: poslovnica.brojTelefona });
            dispatcher({ action: "EMAIL_UNESEN", value: poslovnica.email });
            dispatcher({ action: "WEBSTRANICA_UNESENA", value: poslovnica.homePage });
            dispatcher({ action: "RADNOVRIJEME_POSTAVLJENO", value: poslovnica.poslovnicaRadnoVrijeme })

        }
    }, [poslovnica])

    useEffect(() => {

        if (model.submit) {
            dispatcher({ action: "SUBMIT_ONEMOGUCEN", value: null });
            let poslovnica = {
                id: model.id,
                adresa: model.adresa.value,
                mjesto: model.lokacija.mjesto,
                brojTelefona: model.telefon.value,
                homePage: model.webStranica.value,
                email: model.email.value,
                postanskiBroj: model.lokacija.postanskiBroj,
                radnoVrijeme: formatirajRadnoVrijeme(),
                latitude: model.lokacija.latitude.toString(),
                longitude: model.lokacija.longitude.toString(),
                sifraZemlje: model.lokacija.sifraZemlje,
                opis: ''
            }
            savePoslovnicu(poslovnica);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [model.submit])

    const handleAdresaChange = (adresaModel: AdresaType) => {
        dispatcher({ action: "ADRESA_POSTAVLJENA", value: adresaModel })
    }

    const handleDodajDvokratnoRV = (dvokratno: RadnoVrijemeModel) => {
        dispatcher({ action: "DVOKRATNORV_PROMJENA", value: dvokratno });
    }

    const handleRadnoVrijemeChange = (radnoVrijeme: RadnoVrijemeModel) => {
        dispatcher({ action: "RADNOVRIJEME_PROMJENJENO", value: radnoVrijeme });
    }

    function formatirajRadnoVrijeme(): string {
        let ponedjeljak = `2:${model[2][1].value.replace("-", ":")},`;
        if (model[2][2]) ponedjeljak += `2:${model[2][2].value.replace("-", ":")},`;

        let utorak = `3:${model[3][1].value.replace("-", ":")},`;
        if (model[3][2]) utorak += `3:${model[3][2].value.replace("-", ":")},`;

        let srijeda = `4:${model[4][1].value.replace("-", ":")},`;
        if (model[4][2]) srijeda += `4:${model[4][2].value.replace("-", ":")},`;

        let cetvrtak = `5:${model[5][1].value.replace("-", ":")},`;
        if (model[5][2]) cetvrtak += `5:${model[5][2].value.replace("-", ":")},`;

        let petak = `6:${model[6][1].value.replace("-", ":")},`;
        if (model[6][2]) petak += `6:${model[6][2].value.replace("-", ":")},`;

        let subota = `7:${model[7][1].value.replace("-", ":")}`;
        if (model[7][2]) subota += `,7:${model[7][2].value.replace("-", ":")}`;

        let nedjelja = "";
        if (model[1][1]) nedjelja += `,1:${model[1][1].value.replace("-", ":")}`;
        if (model[1][2]) nedjelja += `,1:${model[1][2].value.replace("-", ":")}`;

        return `${ponedjeljak}${utorak}${srijeda}${cetvrtak}${petak}${subota}${nedjelja}`;
    }

    return <>
        <Modal
            open={true}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                <div className={classes.container}>
                    <div>
                        <div className={classes.lokacija}>
                            <FontAwesomeIcon icon={faLocationDot} className={classes.icon} />
                            <div style={{ marginBottom: "1rem", width: "100%" }}>
                                <GoogleMapsAutocomplete setAdresa={handleAdresaChange} />
                                <div>{model.lokacijaError.length > 0 && <span className="text-danger small-text">{model.lokacijaError}</span>}</div>
                            </div>
                            <div className={classes.mjesto}>
                                <div className="mb-2">
                                    <TextField
                                        style={{ width: "100%" }}
                                        label="Adresa"
                                        size="small"
                                        name="adresa"
                                        value={model.adresa.value}
                                        onChange={(e) => { dispatcher({ action: "ADRESA_UNESENA", value: e.target.value }) }}
                                    />
                                    <div>{!model.adresa.valid && <div className="text-danger small-text">{model.adresa.error}</div>}</div>
                                </div>
                                <div style={{ display: "grid", gridTemplateColumns: "60% 40%"}}>
                                    <div>
                                        <TextField
                                            style={{ width: "100%"}}
                                            label="Mjesto"
                                            size="small"
                                            name="mjesto"
                                            value={model.mjesto.value}
                                            onChange={(e) => { dispatcher({ action: "MJESTO_UNESENO", value: e.target.value }) }}
                                        />
                                        <div>{!model.mjesto.valid && <div className="text-danger small-text">{model.mjesto.error}</div>}</div>
                                    </div>
                                    <div style={{ marginLeft: "1rem"}}>
                                        <TextField                                            
                                            style={{ width:"100%" }}
                                            label="Poštanski broj"
                                            size="small"
                                            name="postanskiBroj"
                                            value={model.postanskiBroj.value}
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                if(value.length === 0) value = "0";
                                                if(isNaN(parseInt(e.target.value)) && value.length > 1) return;
                                                dispatcher({ action: "POSTANSKIBROJ_UNESEN", value: parseInt(value) })
                                            }}

                                        />
                                        <div>{!model.postanskiBroj.valid && <div className="text-danger small-text">{model.postanskiBroj.error}</div>}</div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className={classes.telefon}>
                            <FontAwesomeIcon icon={faPhone} className={classes.icon} />
                            <TextField
                                label="Telefon"
                                size="small"
                                name="sadrzaj"
                                style={{ width: "60%" }}
                                value={model.telefon.value}
                                onChange={(e) => { dispatcher({ action: "TELEFON_UNESEN", value: e.target.value }) }}
                            />
                            {!model.telefon.valid && <div className="text-danger small-text">{model.telefon.error}</div>}
                        </div>
                        <div className={classes.email}>
                            <FontAwesomeIcon icon={faEnvelope} className={classes.icon} />
                            <div style={{ display: "grid", gridTemplateColumns: "60% 40%" }}>
                                <div>
                                    <TextField
                                        style={{ width: "100%" }}
                                        label="Email"
                                        size="small"
                                        name="sadrzaj"
                                        value={model.email.value}
                                        onChange={(e) => { dispatcher({ action: "EMAIL_UNESEN", value: e.target.value }) }}
                                    />
                                    {!model.email.valid && <div className="text-danger small-text">{model.email.error}</div>}
                                </div>

                                <div style={{ marginLeft: "1rem" }}>
                                    <TextField
                                        style={{ width: "100%" }}
                                        label="Web stranica"
                                        size="small"
                                        name="sadrzaj"
                                        value={model.webStranica.value}
                                        onChange={(e) => { dispatcher({ action: "WEBSTRANICA_UNESENA", value: e.target.value }) }}
                                    />
                                    {!model.webStranica.valid && <div className="text-danger small-text">{model.webStranica.error}</div>}
                                </div>

                            </div>
                        </div>
                        <div className={classes.radnoVrijeme}>
                            <FontAwesomeIcon icon={faClock} className={classes.icon} />
                            <div>
                                <label>Ponedjeljak:</label>
                                <InputRadnoVrijeme radnoVrijemeChange={handleRadnoVrijemeChange} prikaziDvokratnoRV={handleDodajDvokratnoRV} dan={2} jednokratnoRV={model[2][1]} dvokratnoRV={model[2][2]} />
                            </div>
                            <div>
                                <label>Utorak:</label>
                                <InputRadnoVrijeme radnoVrijemeChange={handleRadnoVrijemeChange} prikaziDvokratnoRV={handleDodajDvokratnoRV} dan={3} jednokratnoRV={model[3][1]} dvokratnoRV={model[3][2]} />
                            </div>
                            <div>
                                <label>Srijeda:</label>
                                <InputRadnoVrijeme radnoVrijemeChange={handleRadnoVrijemeChange} prikaziDvokratnoRV={handleDodajDvokratnoRV} dan={4} jednokratnoRV={model[4][1]} dvokratnoRV={model[4][2]} />
                            </div>
                            <div>
                                <label>Četvrtak:</label>
                                <InputRadnoVrijeme radnoVrijemeChange={handleRadnoVrijemeChange} prikaziDvokratnoRV={handleDodajDvokratnoRV} dan={5} jednokratnoRV={model[5][1]} dvokratnoRV={model[5][2]} />
                            </div>
                            <div>
                                <label>Petak:</label>
                                <InputRadnoVrijeme radnoVrijemeChange={handleRadnoVrijemeChange} prikaziDvokratnoRV={handleDodajDvokratnoRV} dan={6} jednokratnoRV={model[6][1]} dvokratnoRV={model[6][2]} />
                            </div>
                            <div><label>Subota:</label>
                                <InputRadnoVrijeme radnoVrijemeChange={handleRadnoVrijemeChange} prikaziDvokratnoRV={handleDodajDvokratnoRV} dan={7} jednokratnoRV={model[7][1]} dvokratnoRV={model[7][2]} />
                            </div>
                            <div>
                                <div><label>Nedjelja: <input type="checkbox" defaultChecked={model.prikaziNedjelju} onClick={(e: any) => dispatcher({ action: "NEDJELJA_PRIKAZANA", value: e.target.checked })} /></label></div>
                                <InputRadnoVrijeme radnoVrijemeChange={handleRadnoVrijemeChange} prikaziDvokratnoRV={handleDodajDvokratnoRV} dan={1} jednokratnoRV={model[1][1]} dvokratnoRV={model[1][2]} />
                            </div>
                        </div>
                    </div>
                    <div className={classes.karta}>
                        <Map lat={model.lokacija.latitude} lng={model.lokacija.longitude} />
                    </div>
                </div>
                <div className={classes.buttonContainer}>
                    <Button onClick={handleSpremi} style={{ backgroundColor: "rgb(26, 179, 148)", marginRight: "1rem" }} variant="contained">Spremi</Button>
                    <Button onClick={handleClose} style={{ backgroundColor: "rgb(217, 83, 79)" }} variant="contained">Odustani</Button>
                </div>
            </Box>
        </Modal>
    </>
}

export default AddEditPoslovnica;