import produce, { Draft } from "immer";
import type { UnosAkcijePrilikeAction } from "./UnosAkcijePrilikeActions";
import type { TextMobileStepperModel } from "./UnosAkcijePrilike";
import { AkcijaPromoKodViewModel, Artikl } from "./UnosAkcijePrilikeTypes";

const unosAkcijePrilikeReducer = produce(
  (
    oldState: Draft<TextMobileStepperModel>,
    action: UnosAkcijePrilikeAction
  ) => {
    switch (action.action) {
      case "GET_VODITELJI":
        if (action.value) {
          oldState.voditelji = action.value;
        }
        break;
      case "GET_TIPOVI_AKCIJE":
        if (action.value) {
          oldState.tipoviAkcije = action.value;
        }
        break;
      case "GET_ROBNE_MARKE":
        if (action.value) {
          oldState.robneMarke = action.value;
        }
        break;
      case "GET_KATEGORIJE_PROIZVODA_NA_AKCIJI":
        if (action.value) {
          oldState.kategorijeProizvodaNaAkciji = action.value;
        }
        break;
      case "GET_NACINE_SNIZENJA_CIJENA":
        if (action.value) {
          oldState.naciniSnizenjaCijena = action.value;
        }
        break;
      case "GET_VRSTE_POKLON_OSIGURANJA":
        if (action.value) {
          oldState.vrstePoklonOsiguranja = action.value;
          oldState.vrstePoklonOsiguranja.unshift({
            artiklID: -1,
            kod: "",
            naziv: "Nema poklona",
            stopa: -1,
          });
        }
        break;
      case "GET_NACINE_PLACANJA":
        if (action.value) {
          oldState.naciniPlacanjaNaRate = action.value;
        }
        break;
      case "OSNOVE_HANDLER":
        oldState.osnoveModel = action.value;
        break;
      case "TRAJANJE_HANDLER":
        oldState.trajanjeModel = action.value;
        break;
      case "PROIZVODI_HANDLER":
        // if (oldState.osnoveModel.odabraniTipAkcije?.id === 3) {
        //   oldState.proizvodiModel.proizvodiNakonCheckCijena = [];
        // }
        oldState.proizvodiModel.proizvodiNakonCheckCijena = [];
        oldState.proizvodiModel.uneseniProizvodiNaPrilici = [];
        oldState.listaPromoKodova = [];
        oldState.slike151px = [];

        let temp: any = [];
        for (
          let i = 0;
          i < action.value.uneseniProizvodiNaPrilici.length;
          i++
        ) {
          const element = action.value.uneseniProizvodiNaPrilici[i];
          if (element.Cijena) {
            temp.push({
              ...element,
              Cijena: parseFloat(element.Cijena.toString()).toFixed(2),
            });
          } else {
            temp.push({ ...element, Cijena: 0.0 });
          }
          if (element.BesplatnaDostava) {
            temp[i].BesplatnaDostava = true;
          } else {
            temp[i].BesplatnaDostava = false;
          }
        }
        if (temp.length === action.value.uneseniProizvodiNaPrilici.length) {
          oldState.proizvodiModel.uneseniProizvodiNaPrilici = temp;
        }
        oldState.proizvodiModel.kljuc = action.value.kljuc;
        break;
      case "PROIZVODI_CHECK_CIJENA":
        if (action.value.uneseniProizvodiNaPrilici) {
          oldState.proizvodiModel.proizvodiNakonCheckCijena =
            action.value.proizvodiNakonCheckCijena;
        }
        break;
      case "POKLONI_HANDLER":
        oldState.pokloniModel = action.value;
        break;
      case "POPUST_VEZANA_KUPNJA_HANDLER":
        oldState.popustVezanaKupnjaModel = action.value;
        break;
      case "POKLON_OSIGURANJA_HANDLER":
        oldState.poklonOsiguranjaModel = action.value;
        break;
      case "CIJENE_HANDLER":
        oldState.cijeneModel = action.value;
        break;
      case "REGISTRACIJA_HANDLER":
        oldState.registracijaModel = action.value;
        break;
      case "RATE_MODEL":
        oldState.rateModel = action.value;
        break;
      case "UPLOAD_SLIKE":
        if (
          action.value.file.inputLabel ===
          "Banner 506px*230px jpg, obavezno bez CTA gumba, minimalna veličina slova 12px"
        ) {
          oldState.slika506px = action.value.file;
        }
        if (
          action.value.file.inputLabel ===
          "Banner 1140px*230px jpg, obavezno bez CTA gumba, minimalna veličina slova 12p"
        ) {
          oldState.slika1140px = action.value.file;
        }
        if (
          action.value.file.inputLabel ===
          "Banner 1200px x 628px jpg, Banner za Facebook, obavezno bez CTA gumba, manje od 20% površine slike je text obavezno, po mogučnosti savjetuje se izbjegavati sva velika slova, velike uskličnike, ne koristiti riječi kao što su 'Sigurnost', 'Osiguranje', 'sigurno', 'garantiramo','zajamčeno','uz registraciju' ,'uz obaveznu registraciju', 'popust do xx%', 'do xx%'."
        ) {
          oldState.slika1200px = action.value.file;
        }
        if (
          action.value.file.inputLabel ===
          "Banner 600px*500px jpg, obavezno sa CTA gumbom, minimalna veličina slova 12px, obavezno navesti puno trajanje akcije."
        ) {
          oldState.slika600px = action.value.file;
        }
        if (action.value.file.inputLabel === "Slika 151px*151px png") {
          if (
            oldState.slike151px &&
            oldState.slike151px?.findIndex(
              (x) => x.name === `${action.value.fileName}.png`
            ) !== -1
          ) {
            let index = oldState.slike151px?.findIndex(
              (x) => x.name === `${action.value.fileName}.png`
            );
            oldState.slike151px.splice(index, 1);
          }
          let tempSlike = oldState.slike151px;
          let slika = action.value;
          let tempFile = new File(
            [slika.file.file],
            `${slika.fileName}.png` ?? ""
          );
          slika.file.file = tempFile;
          slika.file.name = `${slika.fileName}.png` ?? "";
          tempSlike?.push(slika.file);
          oldState.slike151px = tempSlike;
        }
        break;
      case "BRISI_PROIZVOD":
        const arr = oldState.proizvodiModel.uneseniProizvodiNaPrilici;
        // const promoLista = oldState.listaPromoKodova;
        // const promoArtikl = oldState.listaPromoKodova.find(
        //   (x) => x.sifraArtikla === action.value
        // );
        // const slikePromo = oldState.slike151px;
        const indexOfObject = arr.findIndex((object) => {
          return object.SifraArtikla === action.value;
        });
        // const indexPromo = promoLista.findIndex((object) => {
        //   return object.sifraArtikla === action.value;
        // });
        // const indexSlika = slikePromo?.findIndex((object) => {
        //   return object.name === `${promoArtikl?.popustPromoCode}.png`;
        // });
        arr.splice(indexOfObject, 1);
        oldState.proizvodiModel.uneseniProizvodiNaPrilici = arr;
        // promoLista.splice(indexPromo, 1);
        // oldState.listaPromoKodova = promoLista;
        // slikePromo?.splice(indexSlika ?? -1, 1);
        // oldState.slike151px = slikePromo;

        if (oldState.proizvodiModel.proizvodiNakonCheckCijena.length > 0) {
          const arrCheck = oldState.proizvodiModel.proizvodiNakonCheckCijena;
          const indexOfObjectCheck = arrCheck.findIndex((object) => {
            return object.SifraArtikla === action.value;
          });
          arrCheck.splice(indexOfObjectCheck, 1);
          oldState.proizvodiModel.proizvodiNakonCheckCijena = arrCheck;
        }
        break;
      case "UPDATE_PROIZVOD":
        const arrNakonCheckCijena =
          oldState.proizvodiModel.proizvodiNakonCheckCijena;
        const arrPrijeCheckCijena =
          oldState.proizvodiModel.uneseniProizvodiNaPrilici;

        const indexOfObject2 = arrNakonCheckCijena.findIndex((object) => {
          return (
            object.SifraArtikla.toString() ===
            action.value.old.SifraArtikla.toString()
          );
        });
        const indexOfObject5 = arrPrijeCheckCijena.findIndex((object) => {
          return (
            object.SifraArtikla.toString() ===
            action.value.old.SifraArtikla.toString()
          );
        });

        if (action.value.new.Cijena) {
          oldState.proizvodiModel.uneseniProizvodiNaPrilici[
            indexOfObject5
          ].Cijena = action.value.new.Cijena;
          if (oldState.osnoveModel.odabraniTipAkcije?.id !== 3) {
            oldState.proizvodiModel.uneseniProizvodiNaPrilici[
              indexOfObject5
            ].isOk = false;
          }
        } else {
          oldState.proizvodiModel.uneseniProizvodiNaPrilici[
            indexOfObject5
          ].Cijena = action.value.old.Cijena;
        }

        if (action.value.new.DodatniRabatKomisija) {
          oldState.proizvodiModel.uneseniProizvodiNaPrilici[
            indexOfObject5
          ].DodatniRabatKomisija = action.value.new.DodatniRabatKomisija;
            oldState.proizvodiModel.uneseniProizvodiNaPrilici[
              indexOfObject5
            ].isOk = false;
        } else {
          oldState.proizvodiModel.uneseniProizvodiNaPrilici[
            indexOfObject5
          ].DodatniRabatKomisija = action.value.old.DodatniRabatKomisija;
        }

        if (action.value.new.AkcijskaNettoNabavnaCijenaKomisija) {
          oldState.proizvodiModel.uneseniProizvodiNaPrilici[
            indexOfObject5
          ].AkcijskaNettoNabavnaCijenaKomisija = action.value.new.AkcijskaNettoNabavnaCijenaKomisija;
            oldState.proizvodiModel.uneseniProizvodiNaPrilici[
              indexOfObject5
            ].isOk = false;
        } else {
          oldState.proizvodiModel.uneseniProizvodiNaPrilici[
            indexOfObject5
          ].AkcijskaNettoNabavnaCijenaKomisija = action.value.old.AkcijskaNettoNabavnaCijenaKomisija;
        }


        if (action.value.new.SifraArtikla) {
          oldState.proizvodiModel.uneseniProizvodiNaPrilici[
            indexOfObject5
          ].SifraArtikla = action.value.new.SifraArtikla;
          if (oldState.osnoveModel.odabraniTipAkcije?.id !== 3) {
            oldState.proizvodiModel.uneseniProizvodiNaPrilici[
              indexOfObject5
            ].isOk = false;
          }
        } else {
          oldState.proizvodiModel.uneseniProizvodiNaPrilici[
            indexOfObject5
          ].SifraArtikla = action.value.old.SifraArtikla;
        }
        if (
          action.value.new.PromoKod ||
          action.value.new.PromoKod === null ||
          action.value.new.PromoKod === ""
        ) {
          oldState.proizvodiModel.uneseniProizvodiNaPrilici[
            indexOfObject5
          ].PromoKod =
            action.value.new.PromoKod === null ? "" : action.value.new.PromoKod;
        } else {
          oldState.proizvodiModel.uneseniProizvodiNaPrilici[
            indexOfObject5
          ].PromoKod = action.value.old.PromoKod;
        }
        if (
          action.value.new.BesplatnaDostava === true ||
          action.value.new.BesplatnaDostava === false
        ) {
          oldState.proizvodiModel.uneseniProizvodiNaPrilici[
            indexOfObject5
          ].BesplatnaDostava = action.value.new.BesplatnaDostava;
        } else {
          oldState.proizvodiModel.uneseniProizvodiNaPrilici[
            indexOfObject5
          ].BesplatnaDostava = action.value.old.BesplatnaDostava;
        }
        if (
          action.value.new.SifraOsiguranja ||
          action.value.new.SifraOsiguranja === null
        ) {
          oldState.proizvodiModel.uneseniProizvodiNaPrilici[
            indexOfObject5
          ].SifraOsiguranja = action.value.new.SifraOsiguranja ?? "";
          if (oldState.osnoveModel.odabraniTipAkcije?.id !== 3) {
            oldState.proizvodiModel.uneseniProizvodiNaPrilici[
              indexOfObject5
            ].isOk = false;
          }
        } else {
          oldState.proizvodiModel.uneseniProizvodiNaPrilici[
            indexOfObject5
          ].SifraOsiguranja = action.value.old.SifraOsiguranja;
        }
        if (action.value.new.support) {
          oldState.proizvodiModel.uneseniProizvodiNaPrilici[
            indexOfObject5
          ].Support = action.value.new.Support;
          oldState.proizvodiModel.uneseniProizvodiNaPrilici[
            indexOfObject5
          ].isOk = false;
        } else {
          oldState.proizvodiModel.uneseniProizvodiNaPrilici[
            indexOfObject5
          ].Support = action.value.old.Support;
        }
        if (
          action.value.new.ReferentniBroj ||
          action.value.new.ReferentniBroj === null ||
          action.value.new.ReferentniBroj === ""
        ) {
          oldState.proizvodiModel.uneseniProizvodiNaPrilici[
            indexOfObject5
          ].ReferentniBroj =
            action.value.new.ReferentniBroj === null
              ? ""
              : action.value.new.ReferentniBroj;
        } else {
          oldState.proizvodiModel.uneseniProizvodiNaPrilici[
            indexOfObject5
          ].ReferentniBroj = action.value.old.ReferentniBroj;
        }

        if (oldState.proizvodiModel.proizvodiNakonCheckCijena.length > 0) {
          if (action.value.new.Cijena) {
            oldState.proizvodiModel.proizvodiNakonCheckCijena[
              indexOfObject2
            ].Cijena = action.value.new.Cijena;
            if (oldState.osnoveModel.odabraniTipAkcije?.id !== 3) {
              oldState.proizvodiModel.proizvodiNakonCheckCijena[
                indexOfObject2
              ].isOk = false;
            }
          } else {
            oldState.proizvodiModel.proizvodiNakonCheckCijena[
              indexOfObject2
            ].Cijena = action.value.old.Cijena;
          }

          if (action.value.new.DodatniRabatKomisija) {
            oldState.proizvodiModel.proizvodiNakonCheckCijena[
              indexOfObject2
            ].DodatniRabatKomisija = action.value.new.DodatniRabatKomisija;
              oldState.proizvodiModel.proizvodiNakonCheckCijena[
                indexOfObject2
              ].isOk = false;
          } else {
            oldState.proizvodiModel.proizvodiNakonCheckCijena[
              indexOfObject2
            ].DodatniRabatKomisija = action.value.old.DodatniRabatKomisija;
          }
  
          if (action.value.new.AkcijskaNettoNabavnaCijenaKomisija) {
            oldState.proizvodiModel.proizvodiNakonCheckCijena[
              indexOfObject2
            ].AkcijskaNettoNabavnaCijenaKomisija = action.value.new.AkcijskaNettoNabavnaCijenaKomisija;
              oldState.proizvodiModel.proizvodiNakonCheckCijena[
                indexOfObject2
              ].isOk = false;
          } else {
            oldState.proizvodiModel.proizvodiNakonCheckCijena[
              indexOfObject2
            ].AkcijskaNettoNabavnaCijenaKomisija = action.value.old.AkcijskaNettoNabavnaCijenaKomisija;
          }

          if (action.value.new.SifraArtikla) {
            oldState.proizvodiModel.proizvodiNakonCheckCijena[
              indexOfObject2
            ].SifraArtikla = action.value.new.SifraArtikla;
            if (oldState.osnoveModel.odabraniTipAkcije?.id !== 3) {
              oldState.proizvodiModel.proizvodiNakonCheckCijena[
                indexOfObject2
              ].isOk = false;
            }
          } else {
            oldState.proizvodiModel.proizvodiNakonCheckCijena[
              indexOfObject2
            ].SifraArtikla = action.value.old.SifraArtikla;
          }
          if (
            action.value.new.PromoKod ||
            action.value.new.PromoKod === null ||
            action.value.new.PromoKod === ""
          ) {
            oldState.proizvodiModel.proizvodiNakonCheckCijena[
              indexOfObject2
            ].PromoKod =
              action.value.new.PromoKod === null
                ? ""
                : action.value.new.PromoKod;
            oldState.proizvodiModel.proizvodiNakonCheckCijena[
              indexOfObject2
            ].isOk = false;
          } else {
            oldState.proizvodiModel.proizvodiNakonCheckCijena[
              indexOfObject2
            ].PromoKod = action.value.old.PromoKod;
          }
          if (
            action.value.new.BesplatnaDostava === true ||
            action.value.new.BesplatnaDostava === false
          ) {
            oldState.proizvodiModel.proizvodiNakonCheckCijena[
              indexOfObject2
            ].BesplatnaDostava = action.value.new.BesplatnaDostava;
          } else {
            oldState.proizvodiModel.proizvodiNakonCheckCijena[
              indexOfObject2
            ].BesplatnaDostava = action.value.old.BesplatnaDostava;
          }

          if (
            action.value.new.SifraOsiguranja ||
            action.value.new.SifraOsiguranja === null
          ) {
            oldState.proizvodiModel.proizvodiNakonCheckCijena[
              indexOfObject2
            ].SifraOsiguranja = action.value.new.SifraOsiguranja ?? "";
            if (oldState.osnoveModel.odabraniTipAkcije?.id !== 3) {
              oldState.proizvodiModel.uneseniProizvodiNaPrilici[
                indexOfObject2
              ].isOk = false;
            }
          } else {
            oldState.proizvodiModel.proizvodiNakonCheckCijena[
              indexOfObject2
            ].SifraOsiguranja = action.value.old.SifraOsiguranja;
          }
          if (action.value.new.support) {
            oldState.proizvodiModel.proizvodiNakonCheckCijena[
              indexOfObject2
            ].Support = action.value.new.Support;
            oldState.proizvodiModel.proizvodiNakonCheckCijena[
              indexOfObject2
            ].isOk = false;
          } else {
            oldState.proizvodiModel.proizvodiNakonCheckCijena[
              indexOfObject2
            ].Support = action.value.old.Support;
          }
          if (
            action.value.new.ReferentniBroj ||
            action.value.new.ReferentniBroj === null ||
            action.value.new.ReferentniBroj === ""
          ) {
            oldState.proizvodiModel.proizvodiNakonCheckCijena[
              indexOfObject2
            ].ReferentniBroj =
              action.value.new.ReferentniBroj === null
                ? ""
                : action.value.new.ReferentniBroj;
          } else {
            oldState.proizvodiModel.proizvodiNakonCheckCijena[
              indexOfObject2
            ].ReferentniBroj = action.value.old.ReferentniBroj;
          }
          if (action.value.old.greska) {
            oldState.proizvodiModel.proizvodiNakonCheckCijena[
              indexOfObject2
            ].greska = action.value.old.greska;
          }
        }

        break;
      case "DODAJ_PROIZVOD":
        oldState.proizvodiModel.uneseniProizvodiNaPrilici.unshift(action.value);
        if (oldState.proizvodiModel.proizvodiNakonCheckCijena.length > 0) {
          oldState.proizvodiModel.proizvodiNakonCheckCijena.unshift(
            action.value
          );
        }
        break;
      case "VALIDACIJA":
        oldState.validacijaModel = action.value;
        break;
      case "BRISI_POKLON":
        const arr3 = oldState.pokloniModel.uzStoSeDajePoklon;
        const indexOfObject3 = arr3.findIndex((object) => {
          return object.SifraArtikla === action.value;
        });
        arr3.splice(indexOfObject3, 1);
        oldState.pokloniModel.uzStoSeDajePoklon = arr3;
        break;
      case "UPDATE_POKLON":
        const arr4 = oldState.pokloniModel.uzStoSeDajePoklon;
        for (let i = 0; i < arr4.length; i++) {
          const element = arr4[i];
          if (element.SifraArtikla === action.value.old.SifraArtikla)
            arr4[i].SifraArtikla = action.value.new.SifraArtikla;
        }
        break;
      case "DODAJ_POKLON":
        oldState.pokloniModel.uzStoSeDajePoklon.unshift({
          SifraArtikla: action.value,
        });
        break;
      case "MERGE_PROIZVOD":
        if (action.value) {
          oldState.proizvodiModel = action.value;
        }
        break;
      case "DODAJ_PROIZVOD_VEZANA_KUPNJA":
        oldState.popustVezanaKupnjaModel.uneseneSifreProizvodaZaPopust.unshift({
          SifraArtikla: action.value,
        });
        break;
      case "DODAJ_UVJET_VEZANA_KUPNJA":
        oldState.popustVezanaKupnjaModel.uneseneSifreArtiklaZaOstvarenjePopusta.unshift(
          { SifraArtikla: action.value }
        );
        break;
      case "BRISI_PROIZVOD_VEZANA_KUPNJA":
        const arr5 =
          oldState.popustVezanaKupnjaModel.uneseneSifreProizvodaZaPopust;
        const indexOfObject4 = arr5.findIndex((object) => {
          return object.SifraArtikla === action.value;
        });
        arr5.splice(indexOfObject4, 1);
        oldState.popustVezanaKupnjaModel.uneseneSifreProizvodaZaPopust = arr5;
        break;
      case "BRISI_UVJET_VEZANA_KUPNJA":
        const arr6 =
          oldState.popustVezanaKupnjaModel
            .uneseneSifreArtiklaZaOstvarenjePopusta;
        const indexOfObject6 = arr6.findIndex((object) => {
          return object.SifraArtikla === action.value;
        });
        arr6.splice(indexOfObject6, 1);
        oldState.popustVezanaKupnjaModel.uneseneSifreArtiklaZaOstvarenjePopusta =
          arr6;
        break;
      case "UPDATE_PROIZVOD_VEZANA_KUPNJA":
        const arr7 =
          oldState.popustVezanaKupnjaModel.uneseneSifreProizvodaZaPopust;
        for (let i = 0; i < arr7.length; i++) {
          const element = arr7[i];
          if (element.SifraArtikla === action.value.old.SifraArtikla)
            arr7[i].SifraArtikla = action.value.new.SifraArtikla;
        }
        break;
      case "UPDATE_UVJET_VEZANA_KUPNJA":
        const arr8 =
          oldState.popustVezanaKupnjaModel
            .uneseneSifreArtiklaZaOstvarenjePopusta;
        for (let i = 0; i < arr8.length; i++) {
          const element = arr8[i];
          if (element.SifraArtikla === action.value.old.SifraArtikla)
            arr8[i].SifraArtikla = action.value.new.SifraArtikla;
        }
        break;
      case "UKLONI_ROBNU_MARKU":
        oldState.osnoveModel.odabranaRobnaMarka = null;
        break;
      case "UKLONI_IME_PRILIKE":
        oldState.osnoveModel.imePrilike = "";
        break;
      case "GET_POPUST_PROMO_IZNOS":
        oldState.popustPromoIznos = action.value;
        break;
      case "GET_POPUST_PROMO_PAYMENT_TYPE":
        oldState.popustPromoPaymentType = action.value;
        break;
      case "GET_POPOUST_PROMO_PAYMENT_TYPE_CODE":
        oldState.popustPromoPaymentTypeCode = action.value;
        break;
      case "GET_POPUST_PROMO_PAYMENT_TYPE_GROUP":
        oldState.popustPromoPaymentTypeGroup = action.value;
        break;
      case "PROMO_POPUST_HANDLER":
        oldState.promoPopustModel = action.value;
        break;
      case "GET_ARTIKLI_PROMO_POPUST":
        if (action.value.length > 0) {
          oldState.artikliPromoPopust = action.value;
        }
        break;
      case "BRISI_PROMO_POPUST_ARTIKL":
        const arr9 = oldState.artikliPromoPopust;
        const indexOfObject9 = arr9.findIndex((object) => {
          return object.SifraArtikla === action.value;
        });
        arr9.splice(indexOfObject9, 1);
        oldState.artikliPromoPopust = arr9;
        break;
      case "UPDATE_PROMO_POPUST_ARTIKL":
        const arr10 = oldState.proizvodiModel.uneseniProizvodiNaPrilici;
        const indexOfObject10 = arr10.findIndex((object) => {
          return object.SifraArtikla === action.value.old.SifraArtikla;
        });
        if (action.value.new.SifraArtikla) {
          oldState.artikliPromoPopust[indexOfObject10].SifraArtikla =
            action.value.new.SifraArtikla;
        }
        break;
      case "DODAJ_PROMO_POPUST_ARTIKL":
        oldState.artikliPromoPopust.unshift({ SifraArtikla: action.value });
        break;
      case "UKLONI_PROIZVODE":
        oldState.proizvodiModel.proizvodiNakonCheckCijena = action.value;
        oldState.proizvodiModel.uneseniProizvodiNaPrilici = action.value;
        oldState.listaPromoKodova = [];
        oldState.slike151px = [];
        break;
      case "FILTER_PROMO_KODOVE":
        let proizvodi = action.value.filter(
          (ele, ind) =>
            ind ===
            action.value.findIndex(
              (elem: Artikl) =>
                elem.PromoKod !== undefined &&
                elem.PromoKod?.toString().length > 0 &&
                elem.PromoKod?.toString() === ele.PromoKod?.toString()
            )
        );
        let promoKodoviTemp = oldState.listaPromoKodova;
        let slikeTemp = oldState.slike151px;
        let tempLista: AkcijaPromoKodViewModel[] = [];

        if (proizvodi.length > 0) {
          if (oldState.listaPromoKodova.length > 0) {
            promoKodoviTemp.forEach((promo) => {
              if (
                proizvodi.findIndex(
                  (x) =>
                    x.PromoKod.toString() === promo.popustPromoCode?.toString()
                ) === -1
              ) {
                let promoKodIndex = oldState.listaPromoKodova.findIndex(
                  (x) =>
                    x.popustPromoCode?.toString() ===
                    promo.popustPromoCode?.toString()
                );
                let promoKod = oldState.listaPromoKodova.find(
                  (x) =>
                    x.popustPromoCode?.toString() ===
                    promo.popustPromoCode?.toString()
                );
                let slikaIndex =
                  oldState.slike151px?.findIndex(
                    (x) => x.name === `${promoKod?.popustPromoCode}.png`
                  ) ?? -1;
                promoKodoviTemp.splice(promoKodIndex, 1);
                slikeTemp?.splice(slikaIndex, 1);
              }
            });
          }
          oldState.listaPromoKodova = promoKodoviTemp;
          oldState.slike151px = slikeTemp;
        } else {
          oldState.listaPromoKodova = [];
          oldState.slike151px = [];
        }

        let promoKodovi = oldState.listaPromoKodova;

        if (proizvodi.length > 0) {
          if (oldState.listaPromoKodova.length > 0) {
            proizvodi.forEach((proizvod) => {
              if (
                promoKodovi.findIndex(
                  (x) => x.popustPromoCode === proizvod.PromoKod
                ) !== -1
              ) {
                let promoKod = oldState.listaPromoKodova.find(
                  (x) => x.popustPromoCode === proizvod.PromoKod
                );
                let tempPromo: AkcijaPromoKodViewModel = {
                  isOk: false,
                  krajPromo: "",
                  pocetakPromo: "",
                  popustPromoCode: null,
                  popustPromoIznos: null,
                  popustPromoPaymentType: null,
                  popustPromoPaymentTypeCode: null,
                  popustPromoPaymentTypeGroup: null,
                };
                tempPromo.isOk = promoKod?.isOk ?? false;
                tempPromo.krajPromo = promoKod?.krajPromo ?? "";
                tempPromo.pocetakPromo = promoKod?.pocetakPromo ?? "";
                tempPromo.popustPromoCode = promoKod?.popustPromoCode ?? null;
                tempPromo.popustPromoIznos = promoKod?.popustPromoIznos ?? null;
                tempPromo.popustPromoPaymentType =
                  promoKod?.popustPromoPaymentType ?? null;
                tempPromo.popustPromoPaymentTypeCode =
                  promoKod?.popustPromoPaymentTypeCode ?? null;
                tempPromo.popustPromoPaymentTypeGroup =
                  promoKod?.popustPromoPaymentTypeGroup ?? null;
                tempLista.push(tempPromo);
              } else {
                let tempPromo: AkcijaPromoKodViewModel = {
                  isOk: false,
                  krajPromo: "",
                  pocetakPromo: "",
                  popustPromoCode: null,
                  popustPromoIznos: null,
                  popustPromoPaymentType: null,
                  popustPromoPaymentTypeCode: null,
                  popustPromoPaymentTypeGroup: null,
                };
                tempPromo.isOk = false;
                tempPromo.krajPromo = "";
                tempPromo.pocetakPromo = "";
                tempPromo.popustPromoCode = proizvod.PromoKod;
                tempPromo.popustPromoIznos = null;
                tempPromo.popustPromoPaymentType = null;
                tempPromo.popustPromoPaymentTypeCode = null;
                tempPromo.popustPromoPaymentTypeGroup = null;
                tempLista.push(tempPromo);
              }
            });
            oldState.listaPromoKodova = [];
            oldState.listaPromoKodova = tempLista;
          } else {
            proizvodi.forEach((proizvod) => {
              let tempPromo: AkcijaPromoKodViewModel = {
                isOk: false,
                krajPromo: "",
                pocetakPromo: "",
                popustPromoCode: null,
                popustPromoIznos: null,
                popustPromoPaymentType: null,
                popustPromoPaymentTypeCode: null,
                popustPromoPaymentTypeGroup: null,
              };
              tempPromo.isOk = false;
              tempPromo.krajPromo = "";
              tempPromo.pocetakPromo = "";
              tempPromo.popustPromoCode = proizvod.PromoKod;
              tempPromo.popustPromoIznos = null;
              tempPromo.popustPromoPaymentType = null;
              tempPromo.popustPromoPaymentTypeCode = null;
              tempPromo.popustPromoPaymentTypeGroup = null;
              tempLista.push(tempPromo);
            });
            oldState.listaPromoKodova = [];
            oldState.listaPromoKodova = tempLista;
          }
        }
        break;
      case "UREDI_PROMO_POPUST":
        const newState = oldState.listaPromoKodova.map((obj) => {
          if (obj.popustPromoCode === action.value.popustPromoCode) {
            return {
              ...obj,
              isOk: action.value.isOk,
              krajPromo: action.value.krajPromo,
              pocetakPromo: action.value.pocetakPromo,
              popustPromoCode: action.value.popustPromoCode,
              popustPromoIznos: action.value.popustPromoIznos,
              popustPromoPaymentType: action.value.popustPromoPaymentType,
              popustPromoPaymentTypeCode:
                action.value.popustPromoPaymentTypeCode,
              popustPromoPaymentTypeGroup:
                action.value.popustPromoPaymentTypeGroup,
              // sifraArtikla: action.value.sifraArtikla,
            };
          } else {
            return obj;
          }
        });
        oldState.listaPromoKodova = newState;
        break;
      case "BRISI_SLIKU:151X151":
        oldState.slike151px = null;
        break;
      case "UPDATE_PROMO_KOD":
        if (oldState.slike151px && oldState.slike151px?.length > 0) {
          let promo = oldState.listaPromoKodova.find(
            (x) =>
              x.popustPromoCode?.toString() === action.value.promoKod.toString()
          );
          let slika = oldState.slike151px.find(
            (x) => x.name === `${promo?.popustPromoCode}.png`
          );
          let slikaIndex = oldState.slike151px.findIndex(
            (x) => x.name === `${promo?.popustPromoCode}.png`
          );

          if (slika) {
            let tempFile = new File(
              [slika?.file],
              `${action.value.promoKod}.png` ?? ""
            );
            slika.file = tempFile;
            slika.name = `${action.value.promoKod}.png`;
            oldState.slike151px.splice(slikaIndex, 1);
            oldState.slike151px.push(slika);
          }
        }
        break;
      default:
        break;
    }
  }
);

export default unosAkcijePrilikeReducer;
