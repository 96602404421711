type BodyTabProps = {
    body: string;
}

const BodyTab = (props: BodyTabProps) => {
    return(
        <div dangerouslySetInnerHTML={{__html: props.body}}/>
    )
}

export default BodyTab;