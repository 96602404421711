import React from "react";
import { useMsal } from "@azure/msal-react";
import Button from "react-bootstrap/Button";
import {loginRequest} from '../authConfig';
import { IPublicClientApplication } from "@azure/msal-browser";


function handleLogin(instance: IPublicClientApplication) {
    instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInButton = () => {
    const { instance } = useMsal();

    return (
        <Button
            style={{
                width:'300px'
            }}
            variant="outline-primary"
            className="ml-auto"
            onClick={() => handleLogin(instance)}
            size='lg'
            >
                Prijava
        </Button>
    );
}