import TabPanel, { Item } from "devextreme-react/tab-panel";
import BodyTab from "./BodyTab";
import DetaljiTab from "./DetaljiTab";

const Detalji = (props: any) => {
  
  const renderDetaljiTab = () => {
    return <DetaljiTab
    h1={props.data.data.h1}
    h2={props.data.data.h2}
    dir={props.data.data.dir}
    code={props.data.data.code}
    description={props.data.data.description}
    text={props.data.data.text}
    type={props.data.data.type}
    javascript={props.data.data.javascript}
    css={props.data.data.css}
    />;
  };

  const renderBodyTab = () => {
    return <BodyTab body={props.data.data.body} />;
  };

  return (
    <div>
      <TabPanel className="tabsContainer">
        <Item title="Detalji" render={renderDetaljiTab}></Item>
        <Item title="Body" render={renderBodyTab}></Item>
      </TabPanel>
    </div>
  );
};

export default Detalji;
