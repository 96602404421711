import { useRef } from "react";
import classes from './FileInput.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpFromBracket} from '@fortawesome/free-solid-svg-icons'

type FileInputProps = {
    label: string,
    multiple: boolean,
    extension: string,
    ucitajSliku: (event:React.SyntheticEvent) => void
}

const FileInput = ({ label, multiple, extension, ucitajSliku } : FileInputProps) => {
    const inputFile = useRef<HTMLInputElement>(null);
    const odabirDatotekaHandler = () => {
        if (inputFile.current) {
            inputFile.current.click()
        }
    }

    return (
        <div className={classes.container}>
            {/* <label className={classes.label}>{label}</label> */}
            <input
                data-input={label}
                accept={extension}
                className={classes.hidden}
                type="file" 
                multiple={multiple}
                onChange={ucitajSliku}
                ref={inputFile} />
            <button type="button" className={classes.uploadButton} onClick={odabirDatotekaHandler}>
               <FontAwesomeIcon className={classes.uploadIcon} icon={faArrowUpFromBracket} />
                 Odabir datoteka</button>
        </div>
    )
}

export default FileInput;