import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import BodyEditor from "./BodyEditor";
import classes from "./Pages.module.css";
import useApi from "../Hooks/useApi";
import Swal from "sweetalert2";
import { Button } from "@mui/material";
import BackDrop from "../UnosAkcijePrilike/BackDrop";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";

const EditPage = () => {
  let pageCancel = false;
  const params = new URLSearchParams(window.location.search);
  const id = params.get("id");
  const [name, setName] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [dir, setDir] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [h1, setH1] = useState<string>("");
  const [h2, setH2] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [text, setText] = useState<string>("");
  const apiCall = useApi();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [body, setBody] = useState<string>("");
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const [dirNotValid, setDirNotValid] = useState<boolean>(false);
  useState<boolean>(false);
  const [type, setType] = useState<string>("");
  const [css, setCss] = useState<boolean>(false);
  const [javascript, setJavascript] = useState<boolean>(false);
  const [typeNotValid, setTypeNotValid] = useState<boolean>(false);

  function validacija() {
    let counter = 0;
    if (type.length === 0) {
      setTypeNotValid(true);
      counter++;
    }
    if (counter > 0) {
      return false;
    } else {
      return true;
    }
  }

  function MaxChar255(oldValue: number, newValue: number) {
    if (oldValue < 256 && newValue < 256) {
      return true;
    } else {
      return false;
    }
  }

  function MaxChar3(oldValue: number, newValue: number) {
    if (oldValue < 4 && newValue < 4) {
      return true;
    } else {
      return false;
    }
  }

  const getPage = (accessToken: string) => {
    fetch(`/api/Pages/GetPage?id=${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (pageCancel) return;
        setName(data.responseData.name);
        setDir(data.responseData.dir);
        setTitle(data.responseData.title);
        setCode(data.responseData.code);
        setH1(data.responseData.h1);
        setH2(data.responseData.h2);
        setDescription(data.responseData.description);
        setText(data.responseData.text??"");
        setBody(data.responseData.body);
        setType(data.responseData.type??"");
        if (data.responseData.css === null || data.responseData.css === 0) {
          setCss(false);
        } else {
          setCss(true);
        }
        if (
          data.responseData.javascript === null ||
          data.responseData.javascript === 0
        ) {
          setJavascript(false);
        } else {
          setJavascript(true);
        }
      });
  };

  useEffect(() => {
    apiCall(getPage);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      pageCancel = true;
    };
  }, [apiCall]);

  const post = (accessToken: string) => {
    if (validacija()) {
      setIsLoading(true);
      let formData = new FormData();
      formData.append("id", id ?? "");
      formData.append("name", name ?? "");
      formData.append("dir", dir ?? "");
      formData.append("title", title ?? "");
      formData.append("code", code ?? "");
      formData.append("h1", h1 ?? "");
      formData.append("h2", h2 ?? "");
      formData.append("description", description ?? "");
      formData.append("text", text ?? "");
      formData.append("body", body ?? "");
      formData.append("type", type ?? "");
      formData.append("css", css === true ? "1" : "0");
      formData.append("javascript", javascript === true ? "1" : "0");

      fetch("api/Pages/EditPage", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "post",
        body: formData,
      }).then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          Swal.fire({
            icon: "success",
            title: "Spremljeno",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = "/Pages/Pages";
            }
          });
        } else {
          setIsLoading(false);
          Swal.fire({
            title: response.statusText,
            showConfirmButton: false,
            toast: true,
            customClass: {
              container: classes.swalContainer,
              popup: classes.swal2IconError,
            },
            timer: 1500,
            iconColor: "white",
            timerProgressBar: true,
            icon: "warning",
            position: "top-right",
          });
        }
      });
    }
  };

  const apiCallPost = () => {
    apiCall(post);
  };

  const steps = [
    {
      label: "Detalji",
      description: (
        <div className={classes.newPageWrapper}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className={classes.backButton}>
              <Link to="/Pages/Pages">
                <ArrowBackIosIcon />
              </Link>
            </div>
            <span className={classes.stepsNaslov}>Edit page</span>
          </div>
          <div style={{ backgroundColor: "rgb(243, 243, 244)" }}>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              style={{ padding: "2em" }}
            >
              <TextField
                size="small"
                fullWidth
                id={"name"}
                label={"Name"}
                variant="outlined"
                onChange={(event) => {
                  if (MaxChar255(name.length, event.target.value.length)) {
                    setName(event.target.value);
                  }
                }}
                value={name}
                style={{
                  backgroundColor: "white",
                  marginBottom: "1em",
                }}
              />
              <TextField
                size="small"
                fullWidth
                id={"title"}
                label={"Title"}
                variant="outlined"
                onChange={(event) => {
                  if (MaxChar255(title.length, event.target.value.length)) {
                    setTitle(event.target.value);
                  }
                }}
                value={title}
                style={{
                  backgroundColor: "white",
                  marginBottom: "1em",
                }}
                multiline
                rows={3}
              />
              <TextField
                size="small"
                fullWidth
                id={"dir"}
                label={"Dir"}
                variant="outlined"
                onChange={(event) => {
                  if (MaxChar255(dir.length, event.target.value.length)) {
                    setDir(event.target.value);
                    setDirNotValid(false);
                  }
                }}
                value={dir??""}
                style={{
                  backgroundColor: "white",
                }}
                multiline
                rows={3}
              />
              {dirNotValid && (
                <div className={classes.warning}>
                  <span>Obavezno polje!</span>
                </div>
              )}
              {!dirNotValid && (
                <label
                  style={{ marginBottom: "1em" }}
                  className="lightBlue small-text"
                >
                  Obavezno polje *
                </label>
              )}
              <TextField
                size="small"
                fullWidth
                id={"code"}
                label={"Code"}
                variant="outlined"
                onChange={(event) => {
                  if (MaxChar3(code.length, event.target.value.length)) {
                    setCode(event.target.value);
                  }
                }}
                value={code}
                style={{
                  backgroundColor: "white",
                  marginBottom: "1em",
                }}
              />
              <TextField
                size="small"
                fullWidth
                id={"h1"}
                label={"H1"}
                variant="outlined"
                onChange={(event) => {
                  if (MaxChar255(h1.length, event.target.value.length)) {
                    setH1(event.target.value);
                  }
                }}
                value={h1}
                style={{
                  backgroundColor: "white",
                  marginBottom: "1em",
                }}
                multiline
                rows={3}
              />
              <TextField
                size="small"
                fullWidth
                id={"h2"}
                label={"H2"}
                variant="outlined"
                onChange={(event) => {
                  if (MaxChar255(h2.length, event.target.value.length)) {
                    setH2(event.target.value);
                  }
                }}
                value={h2}
                style={{
                  backgroundColor: "white",
                  marginBottom: "1em",
                }}
                multiline
                rows={3}
              />
              <TextField
                size="small"
                fullWidth
                id={"description"}
                label={"Description"}
                variant="outlined"
                onChange={(event) => {
                  if (
                    MaxChar255(description.length, event.target.value.length)
                  ) {
                    setDescription(event.target.value);
                  }
                }}
                value={description}
                style={{
                  backgroundColor: "white",
                  marginBottom: "1em",
                }}
                multiline
                rows={3}
              />
              <TextField
                size="small"
                fullWidth
                id={"text"}
                label={"Text"}
                variant="outlined"
                onChange={(event) => {
                  if (MaxChar255(text.length, event.target.value.length)) {
                    setText(event.target.value);
                  }
                }}
                value={text ?? ""}
                style={{
                  backgroundColor: "white",
                  marginBottom: "1em",
                }}
                multiline
                rows={3}
              />
              <TextField
                size="small"
                fullWidth
                id={"type"}
                label={"Type"}
                variant="outlined"
                onChange={(event) => {
                  if (MaxChar255(type.length, event.target.value.length)) {
                    setType(event.target.value);
                  }
                }}
                value={type ?? ""}
                style={{
                  backgroundColor: "white",
                }}
                multiline
                rows={3}
              />
              {typeNotValid && (
                <div className={classes.warning}>
                  <span>Obavezno polje!</span>
                </div>
              )}
              {!typeNotValid && (
                <label
                  style={{ marginBottom: "1em" }}
                  className="lightBlue small-text"
                >
                  Obavezno polje *
                </label>
              )}
              <div>
                <label style={{width:'8%'}}>CSS</label>
                <Checkbox value={css} onChange={(e) => {setCss(e.target.checked)}} />
              </div>
              <div>
                <label style={{width:'8%'}}>Javascript</label>
                <Checkbox value={javascript} onChange={(e) => {setJavascript(e.target.checked)}}/>
              </div>
            </Box>
          </div>
        </div>
      ),
    },
    {
      label: "Body",
      description: (
        <div style={{ height: "95%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "white",
              marginBottom: "1em",
            }}
          >
            <div className={classes.backButton}>
              <Link to="/Pages/Pages">
                <ArrowBackIosIcon />
              </Link>
            </div>
            <span className={classes.stepsNaslov}>Edit page</span>
          </div>
          <BodyEditor html={body} setHtml={setBody} />
        </div>
      ),
    },
  ];
  const maxSteps = steps.length;

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <>
      <Box
        style={{
          height: "95%",
          overflow: "scroll",
        }}
      >
        <Box
          sx={{
            width: "100%",
            p: 2,
            // marginBottom: "70px",
            backgroundColor: "rgb(243, 243, 244)",
            height: "95%",
            overflow: "scroll",
          }}
        >
          {steps[activeStep].description}
        </Box>
        {!isLoading && (
          <Button
            variant="contained"
            onClick={() => {
              apiCallPost();
            }}
            style={{
              width: "100%",
              marginBottom: "2%",
            }}
          >
            Završi
          </Button>
        )}
        {isLoading && <BackDrop />}
        <div className={classes.stepper}>
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
          <div className={classes.dots}></div>
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        </div>
      </Box>
    </>
  );
};

export default EditPage;
