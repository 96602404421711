import { PageLayout } from "./components/PageLayout";
import "./custom.css";
import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";

function App() {
  const isProduction = process.env.NODE_ENV === "production";

  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      // loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <PageLayout>
        <></>
      </PageLayout>
    </CacheBuster>
  );
}

export default App;
