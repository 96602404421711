import * as React from 'react'
import Button from '@mui/material/Button';
import { AkcijaPromoKodViewModel } from '../UnosAkcijePrilikeTypes';

type UrediPromoButtonProps = {
  openForma: boolean;
  setOpenForma: (value: boolean) => void;
  data: any;
  setData: (value: AkcijaPromoKodViewModel) => void;
}

export default function UrediPromoButton(props: UrediPromoButtonProps) {
  return (
    <div>
      <Button
        id="basic-button"
        onClick={(e: any) => {props.setData(props.data.data); props.setOpenForma(true);}}
        variant='contained'
        size='small'
      >
        Uredi promo podatke
      </Button>
    </div>
  );
}