import {
  faInfoCircle,
  faCheck,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./Pages.module.css";

type DetaljiTabProps = {
  dir: string;
  code: string;
  h1: string;
  h2: string;
  description: string;
  text: string;
  type: string;
  javascript: number;
  css: number;
};

const DetaljiTab = (props: DetaljiTabProps) => {
  const checkCss = () => {
    if (props.css === null || props.css === 0) {
      return <FontAwesomeIcon icon={faXmark} className="xIcon" />;
    } else {
      return <FontAwesomeIcon icon={faCheck} className="checkIcon" />;
    }
  };

  const checkJavascript = () => {
    if (props.javascript === null || props.javascript === 0) {
      return <FontAwesomeIcon icon={faXmark} className="xIcon" />;
    } else {
      return <FontAwesomeIcon icon={faCheck} className="checkIcon" />;
    }
  };
  
  return (
    <div className={classes.container}>
      <div className={`${classes.column} text-center`}>
        <FontAwesomeIcon icon={faInfoCircle} className="tabsIcon" />
      </div>
      <div className={classes.column}>
        <div>
          <label className={classes.label}>H1: </label>
          <span>{props.h1}</span>
        </div>
        <div>
          <label className={classes.label}>H2: </label>
          <span>{props.h2}</span>
          <hr />
        </div>
        <div>
          <label className={classes.label}>Dir: </label>
          <span>{props.dir}</span>
        </div>
        <div>
          <label className={classes.label}>Code: </label>
          <span>{props.code}</span>
          <hr />
        </div>
        <div>
          <label className={classes.label}>Description: </label>
          <span>{props.description}</span>
          <hr />
        </div>
        <div>
          <label className={classes.label}>Text: </label>
          <span>{props.text}</span>
        </div>
        <div>
          <label className={classes.label}>Type: </label>
          <span>{props.type ?? ""}</span>
          <hr />
        </div>
        <div>
          <label className={classes.label}>CSS: </label>
          <span>{checkCss()}</span>
        </div>
        <div>
          <label className={classes.label}>Javascript: </label>
          <span>{checkJavascript()}</span>
        </div>
      </div>
    </div>
  );
};

export default DetaljiTab;
