import { OsnoveModel, TrajanjeModel } from "../UnosAkcijePrilike/UnosAkcijePrilikeTypes";

const step1Dummy: OsnoveModel = {
imePrilike: `testIme ${new Date()}`,
odabraniTipAkcije:{
    id: 1,
    label:"Akcija"
},
odabraniVoditelj: {
    id:210,
    label: "9500000002109"
},
vasEmail: "testEmail@test.hr",
odabranaRobnaMarka: {
    id: 1590,
    label: "ELIPSO"
},
tekstPrilike: "TEST",
odabranaKategorijaProizvodaNaAkciji:{
    id: 999999,
    label: "Miješano – iz više različitih klasifikacija"
},
label:"",
id:-1
}

const step2Dummy: TrajanjeModel = {
    odabraniDatumKrajaPrilike: new Date(new Date().setHours(new Date().getHours() + 200)).toString(),
    odabraniDatumPocetkaPrilike: new Date().toString()
}

export {step1Dummy,step2Dummy};