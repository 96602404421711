import classes from "./WelcomeMessage.module.css";
import { useIsAuthenticated } from "@azure/msal-react";

const WelcomeMessage = () => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <>
      {isAuthenticated && (
        <div className={classes.welcomeWrapper}>
          <div>
            <div className={classes.imageMessageWrapper}>
              <div className={classes.welcomeMessage}>Dobrodošli!</div>
            </div>
              <div className={classes.appName}>ELIPSO ADMINISTRACIJA</div>
              {/* <div className={classes.adminLogoWrapper}>
              <img
                className={classes.admin}
                src="/images/adminLogo.jpg"
                alt="handWave"
              />
              </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default WelcomeMessage;
