import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #e0e0e0',
    boxShadow: 24,
    p: 1 
  };

type OdobrenjeAkcijePotvrdaProps = {
    closeHandler: (e:any) => void,
    saveHandler: (nazivAkcije:string ) => void,
    rowData: any
}

const OdobrenjeAkcijePotvrda = (props: OdobrenjeAkcijePotvrdaProps) => {
    
    const odobriAkciju = (e:any) => {
        props.saveHandler(props.rowData.data.nazivAkcije);
    }

    return (
        <div>            
            <Modal
                open={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{borderBottom:"1px solid #e0e0e0", padding:"10px", color: "#1976D2", fontSize:"1.1rem", display:"flex", justifyContent:"center", alignItems:"center", gap:"10px"} }>
                        <FontAwesomeIcon icon={faExclamationCircle} style={{fontSize:"1.4rem"}} />
                        <span>ODOBRENJE AKCIJE</span>
                    </div>
                    <div style={{minHeight:"100px", display:"flex",flexDirection:"column", alignItems:"center", padding:"20px"}}>
                        <div>Da li ste sigurni da želite odobriti akciju </div>
                        <div style={{fontSize:"1.2rem", marginTop:"5px", textAlign:"center"}}>
                        '{props.rowData.data.nazivAkcije}'?
                        </div>
                    </div>
                    <div style={{textAlign:"end", borderTop:"1px solid #e0e0e0", paddingTop:"10px"}}>
                        <Button variant="outlined" color="error" onClick={props.closeHandler} style={{marginRight:"10px"}}>Odustani</Button>
                        <Button variant="outlined" color="success" onClick={odobriAkciju}>Da, želim</Button>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default OdobrenjeAkcijePotvrda;