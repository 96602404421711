import React from "react";
import DodavanjeSlika from "./DodavanjeSlika";
import Osnovne from "./Osnovne";
import Pokloni from "./Pokloni";
import PoklonOsiguranje from "./PoklonOsiguranje";
import PopustVezanaKupnja from "./PopustVezanaKupnja";
import Proizvodi from "./Proizvodi";
import Rate from "./Rate";
import Registracija from "./Registracija";
import Trajanje from "./Trajanje";
import { UnosAkcijePrilikeAction } from "./UnosAkcijePrilikeActions";
import {
  OsnoveModel,
  TrajanjeModel,
  ProizvodiModel,
  Artikl,
  PokloniModel,
  PopustVezanaKupnjaModel,
  PoklonOsiguranjaModel,
  RateModel,
  RegistracijaModel,
  TextMobileStepperModel,
  PromoPopustModel,
  PromoPopustArtikli,
  AkcijaPromoKodViewModel,
} from "./UnosAkcijePrilikeTypes";
import Zavrsi from "./Zavrsi";
import PromoPopust from "./PromoPopust/PromoPopust";

const steps = (
  data: TextMobileStepperModel,
  dispatcher: ({ action, value }: UnosAkcijePrilikeAction) => void,
  ucitajSliku: (event: React.SyntheticEvent, fileName: string | null) => void,
  tekstPrilikeNotValid: boolean,
  voditeljNotValid: boolean,
  tipNotValid: boolean,
  imePrilikeNotValid: boolean,
  emailNotValid: boolean,
  robnaMarkaNotValid: boolean,
  kategorijeProizvodaNotValid: boolean,
  setTekstPrilikeNotValid: (value: boolean) => void,
  setVoditeljNotValid: (value: boolean) => void,
  setTipNotValid: (value: boolean) => void,
  setImePrilikeNotValid: (value: boolean) => void,
  setEmailNotValid: (value: boolean) => void,
  setRobnaMarkaNotValid: (value: boolean) => void,
  setKategorijaProizvodaNotValid: (value: boolean) => void,
  pocetakNotValid: boolean,
  krajNotValid: boolean,
  setPocetakNotValid: (value: boolean) => void,
  setKrajNotValid: (value: boolean) => void,
  setProizvodiCheckCijena: (value: Artikl[]) => void,
  proizvodiCheckCijena: Artikl[],
  setBanner1140pxNotValid: (value: boolean) => void,
  setBanner1200pxNotValid: (value: boolean) => void,
  setBanner506pxNotValid: (value: boolean) => void,
  setBanner600pxNotValid: (value: boolean) => void,
  banner1140pxNotValid: boolean,
  banner1200pxNotValid: boolean,
  banner506pxNotValid: boolean,
  banner600pxNotValid: boolean,
  setUvjetiPoklonaNotValid: (value: boolean) => void,
  uvjetiPoklonaNotValid: boolean,
  setRegistracijaNotValid: (value: boolean) => void,
  registracijaNotValid: boolean,
  setLinkNotValid: (value: boolean) => void,
  linkNotValid: boolean,
  setProizvodiNotValid: (value: boolean) => void,
  proizvodiNotValid: boolean,
  poklonOsiguranjeNotValid: boolean,
  setPoklonOsiguranjeNotValid: (value: boolean) => void,
  checkIme: () => void,
  setRateNotValid: (value: boolean) =>void,
  rateNotValid: boolean,
  setSifraPoklonaNotValid: (value: boolean) => void,
  sifraPoklonaNotValid: boolean,
  promoKodNotValid: boolean,
  setPromoKodNotValid: (value: boolean) => void,
  promoIznosNotValid: boolean,
  setPromoIznosNotValid: (value: boolean) => void,
  promoPaymentTypeNotValid: boolean,
  setPromoPaymentTypeNotValid: (value: boolean) => void,
  promoPaymentTypeCodesNotValid: boolean,
  setPromoPaymentTypeCodesNotValid: (value: boolean) => void,
  promoPaymentTypeGroupsNotValid: boolean,
  setPromoPaymentTypeGroupsNotValid: (value: boolean) => void,
  poklonNotValid: boolean,
  setPoklonNotValid: (value: boolean) => void,
  promoPocetakNotValid: boolean,
  setPromoPocetakNotValid: (value: boolean) => void,
  promoKrajNotValid: boolean,
  setPromoKrajNotValid: (value: boolean) => void,
  slikaPromoNotValid: boolean,
  setSlikaPromoNotValid: (value: boolean) => void
) => {
  return [
    {
      label: "Osnovne informacije prilike",
      description: (
        <Osnovne
          voditeljiBrenda={data.voditelji}
          osnoveHandler={(osnoveModel: OsnoveModel) =>
            dispatcher({ action: "OSNOVE_HANDLER", value: osnoveModel })
          }
          tipoviAkcije={data.tipoviAkcije}
          robneMarke={data.robneMarke}
          kategorijeProizvodaNaAkciji={data.kategorijeProizvodaNaAkciji}
          osnoveModel={data.osnoveModel}
          tekstPrilikeNotValid={tekstPrilikeNotValid}
          voditeljNotValid={voditeljNotValid}
          tipNotValid={tipNotValid}
          imePrilikeNotValid={imePrilikeNotValid}
          emailNotValid={emailNotValid}
          robnaMarkaNotValid={robnaMarkaNotValid}
          kategorijeProizvodaNotValid={kategorijeProizvodaNotValid}
          setTekstPrilikeNotValid={setTekstPrilikeNotValid}
          setVoditeljNotValid={setVoditeljNotValid}
          setTipNotValid={setTipNotValid}
          setImePrilikeNotValid={setImePrilikeNotValid}
          setEmailNotValid={setEmailNotValid}
          setRobnaMarkaNotValid={setRobnaMarkaNotValid}
          setKategorijaProizvodaNotValid={setKategorijaProizvodaNotValid}
          checkIme={checkIme}
        />
      ),
    },
    {
      label: "Početak i kraj prilike",
      description: (
        <Trajanje
          trajanjeHandler={(trajanjeModel: TrajanjeModel) =>
            dispatcher({ action: "TRAJANJE_HANDLER", value: trajanjeModel })
          }
          trajanjeModel={data.trajanjeModel}
          pocetakNotValid={pocetakNotValid}
          krajNotValid={krajNotValid}
          setPocetakNotValid={setPocetakNotValid}
          setKrajNotValid={setKrajNotValid}
          tipAkcije={data.osnoveModel.odabraniTipAkcije?.id}
        />
      ),
    },
    {
      label: "Slike",
      description: (
        <DodavanjeSlika
          ucitajSliku={ucitajSliku}
          slika506px={data.slika506px}
          slika1140px={data.slika1140px}
          slika1200px={data.slika1200px}
          slika600px={data.slika600px}
          setBanner1140pxNotValid={setBanner1140pxNotValid}
          setBanner506pxNotValid={setBanner506pxNotValid}
          setBanner1200pxNotValid={setBanner1200pxNotValid}
          setBanner600pxNotValid={setBanner600pxNotValid}
          banner506pxNotValid={banner506pxNotValid}
          banner1140pxNotValid={banner1140pxNotValid}
          banner1200pxNotValid={banner1200pxNotValid}
          banner600pxNotValid={banner600pxNotValid}
          />
      ),
    },
    {
      label: "Proizvodi na prilici",
      description: (
        <Proizvodi
          proizvodiModel={data.proizvodiModel}
          proizvodiHandler={(proizvodiModel: ProizvodiModel) =>
            dispatcher({ action: "PROIZVODI_HANDLER", value: proizvodiModel })
          }
          brisiProizvod={(value: string) =>
            dispatcher({ action: "BRISI_PROIZVOD", value: value })
          }
          updateProizvod={(value: Artikl) =>
            dispatcher({ action: "UPDATE_PROIZVOD", value: value })
          }
          dodajProizvod={(value: Artikl) =>
            dispatcher({ action: "DODAJ_PROIZVOD", value: value })
          }
          proizvodiCheckCijenaHandler={(proizvodiModel: ProizvodiModel) =>
            dispatcher({
              action: "PROIZVODI_CHECK_CIJENA",
              value: proizvodiModel,
            })
          }
          pocetakPrilike={data.trajanjeModel.odabraniDatumPocetkaPrilike}
          krajPrilike={data.trajanjeModel.odabraniDatumKrajaPrilike}
          mergeProizvodi={(value: ProizvodiModel) => {
            dispatcher({ action: "MERGE_PROIZVOD", value: value });
          }}
          setProizvodiCheckCijena={setProizvodiCheckCijena}
          proizvodiCheckCijena={proizvodiCheckCijena}
          odabraniTip={data.osnoveModel.odabraniTipAkcije?.id}
          setProizvodiNotValid={setProizvodiNotValid}
          kategorijaID={data.osnoveModel.odabranaKategorijaProizvodaNaAkciji?.id}
          ukloniProizvode={() => {
            dispatcher({
              action:"UKLONI_PROIZVODE",
              value:[]
            })
          }}
        />
      ),
    },
    {
      label: "Pokloni",
      description: (
        <Pokloni
          pokloniModel={data.pokloniModel}
          pokloniHandler={(pokloniModel: PokloniModel) =>
            dispatcher({ action: "POKLONI_HANDLER", value: pokloniModel })
          }
          brisiPoklon={(value: string) => {
            dispatcher({ action: "BRISI_POKLON", value: value });
          }}
          updatePoklon={(value: any) => {
            dispatcher({ action: "UPDATE_POKLON", value: value });
          }}
          dodajPoklon={(value: string) => {
            dispatcher({ action: "DODAJ_POKLON", value: value });
          }}
          setUvjetiPoklonaNotValid={setUvjetiPoklonaNotValid}
          uvjetiPoklonaNotValid={uvjetiPoklonaNotValid}
          setSifraPoklonaNotValid={setSifraPoklonaNotValid}
          sifraPoklonaNotValid={sifraPoklonaNotValid}
          trajanjeModel={data.trajanjeModel}
          poklonNotValid={poklonNotValid}
          setPoklonNotValid={setPoklonNotValid}
        />
      ),
    },
    {
      label: "Popust za vezanu kupnju",
      description: (
        <PopustVezanaKupnja
          naciniSnizenjaCijena={data.naciniSnizenjaCijena}
          popustVezanaKupnjaModel={data.popustVezanaKupnjaModel}
          popustVezanaKupanjaHandler={(
            popustVezanaKupnjaModel: PopustVezanaKupnjaModel
          ) =>
            dispatcher({
              action: "POPUST_VEZANA_KUPNJA_HANDLER",
              value: popustVezanaKupnjaModel,
            })
          }
          dodajProizvod={(value: string) =>
            dispatcher({
              action: "DODAJ_PROIZVOD_VEZANA_KUPNJA",
              value: value,
            })
          }
          dodajUvjet={(value: string) => {
            dispatcher({ action: "DODAJ_UVJET_VEZANA_KUPNJA", value: value });
          }}
          brisiProizvod={(value: string) => {
            dispatcher({
              action: "BRISI_PROIZVOD_VEZANA_KUPNJA",
              value: value,
            });
          }}
          brisiUvjet={(value: string) =>
            dispatcher({
              action: "BRISI_UVJET_VEZANA_KUPNJA",
              value: value,
            })
          }
          updateProizvod={(value: any) =>
            dispatcher({
              action: "UPDATE_PROIZVOD_VEZANA_KUPNJA",
              value: value,
            })
          }
          updateUvjet={(value: any) =>
            dispatcher({
              action: "UPDATE_UVJET_VEZANA_KUPNJA",
              value: value,
            })
          }
        />
      ),
    },
    {
      label: "Promo popust",
      description: (
        <PromoPopust
          popustPromoIznos={data.popustPromoIznos}
          popustPromoPaymentType={data.popustPromoPaymentType}
          popustPromoPaymentTypeCode={data.popustPromoPaymentTypeCode}
          popustPromoPaymentTypeGroup={data.popustPromoPaymentTypeGroup}
          promoPopustModel={data.promoPopustModel}
          promoPopustHandler={(value: PromoPopustModel) => {
            dispatcher({
              action: "PROMO_POPUST_HANDLER",
              value: value
            })
          }}
          artikli={data.proizvodiModel.proizvodiNakonCheckCijena.length > 0 ? data.proizvodiModel.proizvodiNakonCheckCijena : data.proizvodiModel.uneseniProizvodiNaPrilici}
          brisiProizvod={(value: string) =>
            dispatcher({ action: "BRISI_PROMO_POPUST_ARTIKL", value: value })
          }
          updateProizvod={(value: {new: PromoPopustArtikli, old: PromoPopustArtikli}) =>
            dispatcher({ action: "UPDATE_PROMO_POPUST_ARTIKL", value: value })
          }
          dodajProizvod={(value: string) =>
            dispatcher({ action: "DODAJ_PROMO_POPUST_ARTIKL", value: value })
          }
          odabraniTip={data.osnoveModel.odabraniTipAkcije?.id}
          promoPopustArtikli={data.artikliPromoPopust}
          artikliPromoPopustHandler={(value: PromoPopustArtikli[]) => {
            dispatcher({
              action: "GET_ARTIKLI_PROMO_POPUST",
              value: value
            })
          }}
          promoKodNotValid={promoKodNotValid}
          setPromoKodNotValid={setPromoKodNotValid}
          promoIznosNotValid={promoIznosNotValid}
          setPromoIznosNotValid={setPromoIznosNotValid}
          promoPaymentTypeNotValid={promoPaymentTypeNotValid}
          setPromoPaymentTypeNotValid={setPromoPaymentTypeNotValid}
          promoPaymentTypeCodesNotValid={promoPaymentTypeCodesNotValid}
          setPromoPaymentTypeCodesNotValid={setPromoPaymentTypeCodesNotValid}
          promoPaymentTypeGroupsNotValid={promoPaymentTypeGroupsNotValid}
          setPromoPaymentTypeGroupsNotValid={setPromoPaymentTypeGroupsNotValid}
          pocetakPrilike={data.trajanjeModel.odabraniDatumPocetkaPrilike??""}
          krajPrilike={data.trajanjeModel.odabraniDatumKrajaPrilike??""}
          ucitajSliku={ucitajSliku}
          slike151px={data.slike151px}
          promoPocetakNotValid={promoPocetakNotValid}
          setPromoPocetakNotValid={setPromoPocetakNotValid}
          promoKrajNotValid={promoKrajNotValid}
          setPromoKrajNotValid={setPromoKrajNotValid}
          slikaPromoNotValid={slikaPromoNotValid}
          setSlikaPromoNotValid={setSlikaPromoNotValid}
          listaPromoKodova={data.listaPromoKodova}
          filterPromoKodove={(value: Artikl[]) => dispatcher({action: "FILTER_PROMO_KODOVE", value: value})}
          brisiSliku={(value: null) =>dispatcher({action: "BRISI_SLIKU:151X151", value: value})}
          listaPromoPopustHandler={(value: AkcijaPromoKodViewModel) => dispatcher({action: "UREDI_PROMO_POPUST", value: value})}
          updatePromoKod={(promoKod: string, sifraArtikla: number) => dispatcher({action: "UPDATE_PROMO_KOD", value: {promoKod: promoKod, sifraArtikla: sifraArtikla}})}
        />
      )
    },
    {
      label: "Poklon osiguranje",
      description: (
        <PoklonOsiguranje
          poklonOsiguranjaHandler={(
            poklonOsiguranjaModel: PoklonOsiguranjaModel
          ) =>
            dispatcher({
              action: "POKLON_OSIGURANJA_HANDLER",
              value: poklonOsiguranjaModel,
            })
          }
          poklonOsiguranjaModel={data.poklonOsiguranjaModel}
          vrstePoklonOsiguranja={data.vrstePoklonOsiguranja}
          tipAkcije={data.osnoveModel.odabraniTipAkcije?.id}
          proizvodi={data.proizvodiModel.proizvodiNakonCheckCijena.length > 0 ? data.proizvodiModel.proizvodiNakonCheckCijena : data.proizvodiModel.uneseniProizvodiNaPrilici}
          setPoklonOsiguranjeNotValid={setPoklonOsiguranjeNotValid}
          sifraPoklona={data.pokloniModel.sifraPoklona}
          nacinSnizenjaCijena={data.popustVezanaKupnjaModel.odabraniNacinSnizenjaCijena}
        />
      ),
    },
    // {
    //   label: "Iznos popusta u vezanoj kupnji",
    //   description: (
    //     <Cijene
    //       cijeneHandler={(cijeneModel: CijeneModel) =>
    //         dispatcher({ action: "CIJENE_HANDLER", value: cijeneModel })
    //       }
    //       cijeneModel={data.cijeneModel}
    //     />
    //   ),
    // },
    {
      label: "Plaćanja na rate",
      description: (
        <Rate
          rateHandler={(rateModel: RateModel) =>
            dispatcher({ action: "RATE_MODEL", value: rateModel })
          }
          rateModel={data.rateModel}
          naciniPlacanjaNaRate={data.naciniPlacanjaNaRate}
          rateNotValid={rateNotValid}
          setRateNotValid={setRateNotValid}
        />
      ),
    },
    {
      label:
        "Produljeno jamstvo ili poklon uz registraciju/prijavu/pupunjavanja vaučera",
      description: (
        <Registracija
          registracijaHandler={(registracijaModel: RegistracijaModel) =>
            dispatcher({
              action: "REGISTRACIJA_HANDLER",
              value: registracijaModel,
            })
          }
          registracijaModel={data.registracijaModel}
          setRegistracijaNotValid={setRegistracijaNotValid}
          setLinkNotValid={setLinkNotValid}
          registracijaNotValid={registracijaNotValid}
          linkNotValid={linkNotValid}
        />
      ),
    },
    {
      label: "Završi",
      description: (
        <Zavrsi
          osnoveModel={data.osnoveModel}
          trajanjeModel={data.trajanjeModel}
          proizvodiModel={data.proizvodiModel}
          pokloniModel={data.pokloniModel}
          popustVezanaKupnjaModel={data.popustVezanaKupnjaModel}
          poklonOsiguranjaModel={data.poklonOsiguranjaModel}
          cijeneModel={data.cijeneModel}
          rateModel={data.rateModel}
          registracijaModel={data.registracijaModel}
          slika506px={data.slika506px}
          slika1140px={data.slika1140px}
          slika1200px={data.slika1200px}
          slika600px={data.slika600px}
          naciniPlacanjaNaRate={data.naciniPlacanjaNaRate}
          promoPopustModel={data.promoPopustModel}
          popustPromoIznos={data.popustPromoIznos}
          popustPromoPaymentType={data.popustPromoPaymentType}
          popustPromoPaymentTypeCode={data.popustPromoPaymentTypeCode}
          popustPromoPaymentTypeGroup={data.popustPromoPaymentTypeGroup}
          slike151px={data.slike151px}
          listaPromo={data.listaPromoKodova}
        />
      ),
    },
  ];
};

export { steps };
