import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useState, useEffect, useReducer, useCallback } from "react";
import useApi from "../Hooks/useApi";
import unosAkcijePrilikeReducer from "./unosAkcijePrilikeReducer";
import Dialog from "@mui/material/Dialog";
import { DialogContent } from "@mui/material";
import classes from "./UnosAkcijePrilike.module.css";
import { provjeraDatoteke } from "./provjeraDatoteke";
import { steps } from "./steps";
import { Artikl, TextMobileStepperModel } from "./UnosAkcijePrilikeTypes";
import { defaultState } from "./defaultState";
import { Dots } from "./Dots";
import DevTools from "../DevTools/DevTools";
import { useMsal } from "@azure/msal-react";
import { devToolsGrupa } from "../../utils/grupePrava";
import { useIsAuthenticated } from "@azure/msal-react";

type AlertModel = {
  open: boolean;
  message: string;
};

export default function TextMobileStepper() {
  const isAuthenticated = useIsAuthenticated();
  const apiCall = useApi();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [data, dispatcher] = useReducer(unosAkcijePrilikeReducer, defaultState);
  const [tekstPrilikeNotValid, setTekstPrilikeNotValid] =
    useState<boolean>(false);
  const [voditeljNotValid, setVoditeljNotValid] = useState<boolean>(false);
  const [tipNotValid, setTipNotValid] = useState<boolean>(false);
  const [imePrilikeNotValid, setImePrilikeNotValid] = useState<boolean>(false);
  const [emailNotValid, setEmailNotValid] = useState<boolean>(false);
  const [robnaMarkaNotValid, setRobnaMarkaNotValid] = useState<boolean>(false);
  const [kategorijeProizvodaNotValid, setKategorijaProizvodaNotValid] =
    useState<boolean>(false);
  const [pocetakNotValid, setPocetakNotValid] = useState<boolean>(false);
  const [krajNotValid, setKrajNotValid] = useState<boolean>(false);
  const [proizvodiCheckCijena, setProizvodiCheckCijena] = useState<Artikl[]>(
    []
  );
  const [banner506pxNotValid, setBanner506pxNotValid] =
    useState<boolean>(false);
  const [banner1140pxNotValid, setBanner1140pxNotValid] =
    useState<boolean>(false);
  const [banner1200pxNotValid, setBanner1200pxNotValid] =
    useState<boolean>(false);
  const [banner600pxNotValid, setBanner600pxNotValid] =
    useState<boolean>(false);
  const [uvjetiPoklonaNotValid, setUvjetiPoklonaNotValid] =
    useState<boolean>(false);
  const [registracijaNotValid, setRegistracijaNotValid] =
    useState<boolean>(false);
  const [linkNotValid, setLinkNotValid] = useState<boolean>(false);
  const [proizvodiNotValid, setProizvodiNotValid] = useState<boolean>(false);
  const [poklonOsiguranjeNotValid, setPoklonOsiguranjeNotValid] =
    useState<boolean>(false);
  const [rateNotValid, setRateNotValid] = useState<boolean>(false);
  const [sifraPoklonaNotValid, setSifraPoklonaNotValid] =
    useState<boolean>(false);
  const [promoKodNotValid, setPromoKodNotValid] = useState<boolean>(false);
  const [promoIznosNotValid, setPromoIznosNotValid] = useState<boolean>(false);
  const [promoPaymentTypeNotValid, setPromoPaymentTypeNotValid] =
    useState<boolean>(false);
  const [promoPaymentTypeCodesNotValid, setPromoPaymentTypeCodesNotValid] =
    useState<boolean>(false);
  const [promoPaymentTypeGroupsNotValid, setPromoPaymentTypeGroupsNotValid] =
    useState<boolean>(false);
  const [poklonNotValid, setPoklonNotValid] = useState<boolean>(false);
  const [promoPocetakNotValid, setPromoPocetakNotValid] =
    useState<boolean>(false);
  const [promoKrajNotValid, setPromoKrajNotValid] = useState<boolean>(false);
  const [slikaPromoNotValid, setSlikaPromoNotValid] = useState<boolean>(false);

  let voditeljiCancel = false;
  let tipoviCancel = false;
  let robneCancel = false;
  let kategorijeCancel = false;
  let snizenjaCancel = false;
  let poklonCancel = false;
  let placanjaCancel = false;
  let promoIznosCancel = false;
  let promoPaymentTypeCancel = false;
  let promoPaymentTypeCodeCancel = false;
  let promoPaymentTypeGroupCancel = false;

  const getVoditelji = (accessToken: string) => {
    fetch("/api/MasterData/GetReferenti", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (voditeljiCancel) return;
        dispatcher({
          action: "GET_VODITELJI",
          value: data.responseData,
        });
      });
  };

  useEffect(() => {
    apiCall(getVoditelji);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      voditeljiCancel = true;
    };
  }, [apiCall]);

  const getTipoviAkcije = (accessToken: string) => {
    fetch("/api/MasterData/GetTipoviAkcije", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (tipoviCancel) return;
        dispatcher({
          action: "GET_TIPOVI_AKCIJE",
          value: data.responseData,
        });
      });
  };

  useEffect(() => {
    apiCall(getTipoviAkcije);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      tipoviCancel = true;
    };
  }, [apiCall]);

  const getRobneMarke = (accessToken: string) => {
    fetch("/api/MasterData/GetRobneMarke", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (robneCancel) return;
        dispatcher({
          action: "GET_ROBNE_MARKE",
          value: data.responseData,
        });
      });
  };

  useEffect(() => {
    apiCall(getRobneMarke);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      robneCancel = true;
    };
  }, [apiCall]);

  const getKategorijeProizvoda = (accessToken: string) => {
    fetch("/api/MasterData/GetKategorijeProizvoda", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (kategorijeCancel) return;
        dispatcher({
          action: "GET_KATEGORIJE_PROIZVODA_NA_AKCIJI",
          value: data.responseData,
        });
      });
  };

  useEffect(() => {
    apiCall(getKategorijeProizvoda);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      kategorijeCancel = true;
    };
  }, [apiCall]);

  const getNacineSnizenjaCijena = (accessToken: string) => {
    fetch("/api/MasterData/GetSnizenjaCijena", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (snizenjaCancel) return;
        dispatcher({
          action: "GET_NACINE_SNIZENJA_CIJENA",
          value: data.responseData,
        });
      });
  };

  useEffect(() => {
    apiCall(getNacineSnizenjaCijena);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      snizenjaCancel = true;
    };
  }, [apiCall]);

  const getVrstePoklonOsiguranja = (accessToken: string) => {
    if (data.osnoveModel.odabranaKategorijaProizvodaNaAkciji?.id) {
      fetch(
        `/api/MasterData/GetVrsteOsiguranja?kategorijaID=${data.osnoveModel.odabranaKategorijaProizvodaNaAkciji.id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (poklonCancel) return;
          dispatcher({
            action: "GET_VRSTE_POKLON_OSIGURANJA",
            value: data.responseData,
          });
        });
    }
  };

  useEffect(() => {
    if (data.osnoveModel.odabranaKategorijaProizvodaNaAkciji?.id) {
      apiCall(getVrstePoklonOsiguranja);
      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        poklonCancel = true;
      };
    }
  }, [apiCall, data.osnoveModel.odabranaKategorijaProizvodaNaAkciji?.id]);

  const getNacinePlacanja = (accessToken: string) => {
    fetch("/api/MasterData/GetNaciniPlacanja", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (placanjaCancel) return;
        dispatcher({
          action: "GET_NACINE_PLACANJA",
          value: data.responseData,
        });
      });
  };

  useEffect(() => {
    apiCall(getNacinePlacanja);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      placanjaCancel = true;
    };
  }, [apiCall]);

  const NeDozvoljeneRijeci = [
    "registracija",
    "registracije",
    "registraciju",
    "prijavu",
    "prijave",
    "prijava",
  ];

  useEffect(() => {
    if (data.slike151px) {
      setSlikaPromoNotValid(false);
    }
  }, [data.slike151px]);

  const checkStepPoklonOsiguranje = () => {
    let artikli =
      data.proizvodiModel.proizvodiNakonCheckCijena.length > 0
        ? data.proizvodiModel.proizvodiNakonCheckCijena
        : data.proizvodiModel.uneseniProizvodiNaPrilici;
    let index = artikli.findIndex((artikl) => artikl.SifraOsiguranja);
    if (index === -1) {
      return false;
    } else {
      return true;
    }
  };

  const handleNext = () => {
    // VALIDACIJA PRVI STEP
    if (activeStep === 0) {
      let tekst = data.osnoveModel.tekstPrilike;
      let counterTekstPrilike = 0;
      let counter = 0;
      let counterImePrilike = 0;
      let validEmailRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (data.osnoveModel.tekstPrilike.length > 0) {
        NeDozvoljeneRijeci.forEach((element) => {
          if (tekst.toLowerCase().includes(element)) {
            counterTekstPrilike++;
          }
        });
      } else {
        setTekstPrilikeNotValid(true);
      }

      if (!data.osnoveModel.odabraniVoditelj) {
        counter++;
        setVoditeljNotValid(true);
      } else {
        setVoditeljNotValid(false);
      }

      if (!data.osnoveModel.odabraniTipAkcije) {
        counter++;
        setTipNotValid(true);
      } else {
        setTipNotValid(false);
      }

      if (data.osnoveModel.imePrilike.length > 0) {
        data.kategorijeProizvodaNaAkciji.forEach((element) => {
          if (
            data.osnoveModel.imePrilike.toLowerCase() ===
            element.naziv.toLowerCase()
          ) {
            counterImePrilike++;
          }
        });
      } else {
        setImePrilikeNotValid(true);
      }

      if (!data.osnoveModel.vasEmail.match(validEmailRegex)) {
        setEmailNotValid(true);
        counter++;
      } else {
        setEmailNotValid(false);
      }
      if (!data.osnoveModel.odabranaRobnaMarka) {
        setRobnaMarkaNotValid(true);
        counter++;
      } else {
        setRobnaMarkaNotValid(false);
      }
      if (!data.osnoveModel.odabranaKategorijaProizvodaNaAkciji) {
        setKategorijaProizvodaNotValid(true);
      } else {
        setKategorijaProizvodaNotValid(false);
      }

      if (counterTekstPrilike > 0) {
        setTekstPrilikeNotValid(true);
      }
      if (counterImePrilike > 0) {
        setImePrilikeNotValid(true);
      }
      if (
        counter === 0 &&
        counterTekstPrilike === 0 &&
        counterImePrilike === 0
      ) {
        setTekstPrilikeNotValid(false);
        setVoditeljNotValid(false);
        setTipNotValid(false);
        setImePrilikeNotValid(false);
        setEmailNotValid(false);
        setRobnaMarkaNotValid(false);
        setKategorijaProizvodaNotValid(false);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      // VALIDACIJA DRUGI STEP
    } else if (activeStep === 1) {
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      let razlikaUDanima = 0;
      // a and b are javascript Date objects
      function dateDiffInDays(a: Date, b: Date) {
        // Discard the time and time-zone information.
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
      }
      if (!data.trajanjeModel.odabraniDatumPocetkaPrilike)
        setPocetakNotValid(true);
      if (!data.trajanjeModel.odabraniDatumKrajaPrilike) setKrajNotValid(true);
      if (
        data.trajanjeModel.odabraniDatumPocetkaPrilike &&
        data.trajanjeModel.odabraniDatumKrajaPrilike
      ) {
        razlikaUDanima = dateDiffInDays(
          new Date(data.trajanjeModel.odabraniDatumPocetkaPrilike),
          new Date(data.trajanjeModel.odabraniDatumKrajaPrilike)
        );
        if (razlikaUDanima < 0) {
          setOpen({
            open: true,
            message:
              "Datum kraja prilike mora biti veći ili jednak od datuma početka prilike",
          });
        } else {
          if (razlikaUDanima + 1 > 93) {
            setOpen({
              open: true,
              message: "Promocija ne smije trajati duže od 93 dana",
            });
          } else {
            if (data.osnoveModel.odabraniTipAkcije?.id === 1) {
              if (
                new Date(
                  data.trajanjeModel.odabraniDatumPocetkaPrilike
                ).getDay() !== 1
              ) {
                setOpen({
                  open: true,
                  message: "Akcija može početi samo u Ponedeljak!",
                });
              } else if (
                new Date(
                  data.trajanjeModel.odabraniDatumKrajaPrilike
                ).getDay() !== 0
              ) {
                setOpen({
                  open: true,
                  message: "Akcija može završiti samo u Nedelju!",
                });
              } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
              }
            } else {
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
          }
        }
      }
      // VALIDACIJA TRECI STEP
    } else if (activeStep === 2) {
      let counter = 0;
      if (!data.slika506px) {
        setBanner506pxNotValid(true);
        counter++;
      }
      if (!data.slika600px) {
        setBanner600pxNotValid(true);
        counter++;
      }
      if (!data.slika1200px) {
        setBanner1200pxNotValid(true);
        counter++;
      }
      if (!data.slika1140px) {
        setBanner1140pxNotValid(true);
        counter++;
      }
      if (counter > 0) {
        setOpen({ open: true, message: "Morate odabrati bannere" });
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      // VALIDACIJA CETVRTI STEP
    } else if (activeStep === 3) {
      // if (
      //   data.osnoveModel.odabraniTipAkcije?.id === 1 ||
      //   data.osnoveModel.odabraniTipAkcije?.id === 2
      // ) {
      if (proizvodiNotValid) {
        setOpen({ open: true, message: "Morate provjeriti artikle" });
      } else {
        if (
          data.proizvodiModel.uneseniProizvodiNaPrilici.length === 0 &&
          data.proizvodiModel.proizvodiNakonCheckCijena.length === 0
        ) {
          setOpen({ open: true, message: "Unesite artikle" });
        } else if (
          data.proizvodiModel.uneseniProizvodiNaPrilici.length > 0 &&
          data.proizvodiModel.proizvodiNakonCheckCijena.length === 0
        ) {
          setOpen({ open: true, message: "Morate provjeriti artikle" });
        } else if (
          data.proizvodiModel.proizvodiNakonCheckCijena.find(
            (x) => x.isOk === false
          )
        ) {
          setOpen({ open: true, message: "Postoje artikli sa greškom !" });
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      }
      // } else if (
      //   data.osnoveModel.odabraniTipAkcije?.id === 3 &&
      //   data.proizvodiModel.uneseniProizvodiNaPrilici.length === 0
      // ) {
      //   setOpen({ open: true, message: "Unesite artikle" });
      // } else {
      //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
      // }
      // VALIDACIJA PETI STEP
    } else if (activeStep === 4) {
      if (
        data.pokloniModel.sifraPoklona.length > 0 &&
        data.pokloniModel.uzStoSeDajePoklon.length === 0
      ) {
        setUvjetiPoklonaNotValid(true);
      } else if (
        data.pokloniModel.uzStoSeDajePoklon.length > 0 &&
        data.pokloniModel.sifraPoklona.length === 0
      ) {
        setSifraPoklonaNotValid(true);
      } else if (data.pokloniModel.sifraPoklona.length > 0 && poklonNotValid) {
        return;
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      // VALIDACIJA SESTI STEP
    } else if (activeStep === 5) {
      if (data.popustVezanaKupnjaModel.odabraniNacinSnizenjaCijena) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        setOpen({
          open: true,
          message: "Morate odabrati način sniženja cijena",
        });
      }
      // VALIDACIJA SEDMI STEP
    } else if (activeStep === 6) {
      if (data.listaPromoKodova.find((x) => x.isOk === false)) {
        setOpen({
          open: true,
          message: "Morate unijeti podatke za sve artikle!",
        });
      } else {
        if (checkStepPoklonOsiguranje()) {
          setActiveStep((prevActiveStep) => prevActiveStep + 2);
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      }
      // VALIDACIJA OSMI STEP
    } else if (activeStep === 7) {
      if (
        data.poklonOsiguranjaModel.odabranaVrstaPoklonOsiguranja &&
        poklonOsiguranjeNotValid
      ) {
        setOpen({ open: true, message: "Postoje artikli sa greškom" });
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      // VALIDACIJA DEVETI STEP
    } else if (activeStep === 8) {
      if (!data.rateModel.odabraniNacinPlacanja) {
        setRateNotValid(true);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      // VALIDACIJA DESETI STEP
    } else if (activeStep === 9) {
      let counter = 0;
      if (!data.registracijaModel.odabranaRegistracija) {
        counter++;
        setRegistracijaNotValid(true);
      }
      if (
        data.registracijaModel.odabranaRegistracija?.label === "Da" &&
        data.registracijaModel.linkZaPrijavuRegistraciju.length === 0
      ) {
        counter++;
        setLinkNotValid(true);
      }
      if (counter === 0) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 8) {
      if (checkStepPoklonOsiguranje()) {
        setActiveStep((prevActiveStep) => prevActiveStep - 2);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const [dialog, setOpen] = useState<AlertModel>({ open: false, message: "" });
  const handleDialogClose = () => {
    setOpen({ open: false, message: "" });
  };

  const ucitajSliku = (
    event: React.SyntheticEvent,
    fileName: string | null
  ) => {
    if (!(event.target instanceof HTMLInputElement)) return;
    if (event.target.files) {
      const input = event.target.getAttribute("data-input");
      provjeraDatoteke(
        event.target.files,
        input,
        setOpen,
        dispatcher,
        fileName
      );
    }
  };

  const checkIme = () => {
    let specialChar = /[`!@#$%^&*()_+\-=[\]{};':"\\|<>/?~]/;
    if (data.osnoveModel.imePrilike.length > 0) {
      if (specialChar.test(data.osnoveModel.imePrilike)) {
        dispatcher({
          action: "OSNOVE_HANDLER",
          value: { ...data.osnoveModel, imePrilike: "" },
        });
        setOpen({
          open: true,
          message: "Ime prilike ne smije sadržavati specijalne znakove!",
        });
      } else if (specialChar.test(data.osnoveModel.imePrilike.charAt(0))) {
        dispatcher({
          action: "OSNOVE_HANDLER",
          value: { ...data.osnoveModel, imePrilike: "" },
        });
        setOpen({
          open: true,
          message:
            "Ime prilike ne smije počinjati sa razmakom ili nekim drugim ne vidljivim znakom!",
        });
      } else if (
        specialChar.test(
          data.osnoveModel.imePrilike.charAt(
            data.osnoveModel.imePrilike.length - 1
          )
        )
      ) {
        dispatcher({
          action: "OSNOVE_HANDLER",
          value: { ...data.osnoveModel, imePrilike: "" },
        });
        setOpen({
          open: true,
          message:
            "Ime prilike ne smije završavati sa razmakom ili nekim drugim ne vidljivim znakom!",
        });
      } else {
        apiCall(checkImeAkcije);
      }
    }
  };

  const stepovi = steps(
    data,
    dispatcher,
    ucitajSliku,
    tekstPrilikeNotValid,
    voditeljNotValid,
    tipNotValid,
    imePrilikeNotValid,
    emailNotValid,
    robnaMarkaNotValid,
    kategorijeProizvodaNotValid,
    setTekstPrilikeNotValid,
    setVoditeljNotValid,
    setTipNotValid,
    setImePrilikeNotValid,
    setEmailNotValid,
    setRobnaMarkaNotValid,
    setKategorijaProizvodaNotValid,
    pocetakNotValid,
    krajNotValid,
    setPocetakNotValid,
    setKrajNotValid,
    setProizvodiCheckCijena,
    proizvodiCheckCijena,
    setBanner1140pxNotValid,
    setBanner1200pxNotValid,
    setBanner506pxNotValid,
    setBanner600pxNotValid,
    banner1140pxNotValid,
    banner1200pxNotValid,
    banner506pxNotValid,
    banner600pxNotValid,
    setUvjetiPoklonaNotValid,
    uvjetiPoklonaNotValid,
    setRegistracijaNotValid,
    registracijaNotValid,
    setLinkNotValid,
    linkNotValid,
    setProizvodiNotValid,
    proizvodiNotValid,
    poklonOsiguranjeNotValid,
    setPoklonOsiguranjeNotValid,
    checkIme,
    setRateNotValid,
    rateNotValid,
    setSifraPoklonaNotValid,
    sifraPoklonaNotValid,
    promoKodNotValid,
    setPromoKodNotValid,
    promoIznosNotValid,
    setPromoIznosNotValid,
    promoPaymentTypeNotValid,
    setPromoPaymentTypeNotValid,
    promoPaymentTypeCodesNotValid,
    setPromoPaymentTypeCodesNotValid,
    promoPaymentTypeGroupsNotValid,
    setPromoPaymentTypeGroupsNotValid,
    poklonNotValid,
    setPoklonNotValid,
    promoPocetakNotValid,
    setPromoPocetakNotValid,
    promoKrajNotValid,
    setPromoKrajNotValid,
    slikaPromoNotValid,
    setSlikaPromoNotValid
  );
  const maxSteps = stepovi.length;

  const checkBrojPromocija = useCallback(
    (accessToken: string) => {
      let formData = new FormData();
      let danPocetak;
      let mjesecPocetak;
      let godinaPocetak;
      let danKraj;
      let mjesecKraj;
      let godinaKraj;
      if (data.trajanjeModel.odabraniDatumPocetkaPrilike) {
        danPocetak = (
          "0" +
          new Date(data.trajanjeModel.odabraniDatumPocetkaPrilike).getDate()
        ).slice(-2);
        mjesecPocetak = (
          "0" +
          (new Date(data.trajanjeModel.odabraniDatumPocetkaPrilike).getMonth() +
            1)
        ).slice(-2);
        godinaPocetak = new Date(
          data.trajanjeModel.odabraniDatumPocetkaPrilike
        ).getFullYear();
      }
      if (data.trajanjeModel.odabraniDatumKrajaPrilike) {
        danKraj = (
          "0" + new Date(data.trajanjeModel.odabraniDatumKrajaPrilike).getDate()
        ).slice(-2);
        mjesecKraj = (
          "0" +
          (new Date(data.trajanjeModel.odabraniDatumKrajaPrilike).getMonth() +
            1)
        ).slice(-2);
        godinaKraj = new Date(
          data.trajanjeModel.odabraniDatumKrajaPrilike
        ).getFullYear();
      }
      if (data.osnoveModel.odabranaRobnaMarka?.label) {
        formData.append("brand", data.osnoveModel.odabranaRobnaMarka?.label);
      }
      if (data.trajanjeModel.odabraniDatumPocetkaPrilike) {
        formData.append(
          "start",
          `${godinaPocetak}-${mjesecPocetak}-${danPocetak}`
        );
      }
      if (data.trajanjeModel.odabraniDatumKrajaPrilike) {
        formData.append("end", `${godinaKraj}-${mjesecKraj}-${danKraj}`);
      }
      fetch("api/Akcija/CheckBrojPromocija", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "post",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.responseData) {
            dispatcher({ action: "UKLONI_ROBNU_MARKU", value: null });
            setOpen({ open: true, message: data.error });
          }
        });
    },
    [
      data.osnoveModel.odabranaRobnaMarka?.label,
      data.trajanjeModel.odabraniDatumKrajaPrilike,
      data.trajanjeModel.odabraniDatumPocetkaPrilike,
    ]
  );

  useEffect(() => {
    if (
      data.osnoveModel.odabraniTipAkcije?.id !== 1 &&
      data.osnoveModel.odabranaRobnaMarka?.label &&
      data.trajanjeModel.odabraniDatumPocetkaPrilike &&
      data.trajanjeModel.odabraniDatumKrajaPrilike
    ) {
      apiCall(checkBrojPromocija);
    }
  }, [
    apiCall,
    checkBrojPromocija,
    data.osnoveModel.odabraniTipAkcije,
    data.trajanjeModel.odabraniDatumKrajaPrilike,
    data.trajanjeModel.odabraniDatumPocetkaPrilike,
    data.osnoveModel.odabranaRobnaMarka,
  ]);

  const checkImeAkcije = useCallback(
    (accessToken: string) => {
      let formData = new FormData();
      if (data.osnoveModel.imePrilike.length > 0) {
        formData.append("imeAkcije", data.osnoveModel.imePrilike);
      }
      fetch("api/Akcija/CheckImeAkcije", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "post",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.responseData) {
            dispatcher({ action: "UKLONI_IME_PRILIKE", value: null });
            setOpen({
              open: true,
              message: "Već postoji akcija sa tim imenom",
            });
          }
        });
    },
    [data.osnoveModel.imePrilike]
  );

  const getPopustPromoIznos = (accessToken: string) => {
    fetch("/api/MasterData/GetPopustPromoIznos", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (promoIznosCancel) return;
        dispatcher({
          action: "GET_POPUST_PROMO_IZNOS",
          value: data.responseData,
        });
      });
  };

  useEffect(() => {
    apiCall(getPopustPromoIznos);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      promoPaymentTypeCancel = true;
    };
  }, [apiCall]);

  const getPopustPromoPaymentType = (accessToken: string) => {
    fetch("/api/MasterData/GetPopustPromoPaymentType", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (promoPaymentTypeCancel) return;
        dispatcher({
          action: "GET_POPUST_PROMO_PAYMENT_TYPE",
          value: data.responseData,
        });
      });
  };

  useEffect(() => {
    apiCall(getPopustPromoPaymentType);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      promoPaymentTypeCodeCancel = true;
    };
  }, [apiCall]);

  const getPopustPromoPaymentTypeCode = (accessToken: string) => {
    fetch("/api/MasterData/GetPopustPromoPaymentTypeCode", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (promoPaymentTypeCodeCancel) return;
        dispatcher({
          action: "GET_POPOUST_PROMO_PAYMENT_TYPE_CODE",
          value: data.responseData,
        });
      });
  };

  useEffect(() => {
    apiCall(getPopustPromoPaymentTypeCode);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      promoPaymentTypeGroupCancel = true;
    };
  }, [apiCall]);

  const getPopustPromoPaymentTypeGroup = (accessToken: string) => {
    fetch("/api/MasterData/GetPopustPromoPaymentTypeGroup", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (promoPaymentTypeGroupCancel) return;
        dispatcher({
          action: "GET_POPUST_PROMO_PAYMENT_TYPE_GROUP",
          value: data.responseData,
        });
      });
  };

  useEffect(() => {
    apiCall(getPopustPromoPaymentTypeGroup);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      promoIznosCancel = true;
    };
  }, [apiCall]);

  const { accounts } = useMsal();
  const checkDevToolsPrava = () => {
    let username = "";
    if (isAuthenticated) {
      username = accounts[0].username;
    }
    if (devToolsGrupa.includes(username)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Box
        style={{
          height: "90%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            p: 2,
            marginBottom: "70px",
            backgroundColor: "rgb(243, 243, 244)",
          }}
        >
          {checkDevToolsPrava() && (
            <DevTools data={data} dispatcher={dispatcher} />
          )}
          {stepovi[activeStep].description}
        </Box>
        <div className={classes.stepper}>
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
          <div className={classes.dots}>
            {Dots(
              stepovi,
              activeStep,
              classes,
              setActiveStep,
              data,
              setTekstPrilikeNotValid,
              setVoditeljNotValid,
              setTipNotValid,
              setImePrilikeNotValid,
              setEmailNotValid,
              setRobnaMarkaNotValid,
              setKategorijaProizvodaNotValid,
              setPocetakNotValid,
              setKrajNotValid,
              setOpen,
              setBanner1140pxNotValid,
              setBanner1200pxNotValid,
              setBanner506pxNotValid,
              setBanner600pxNotValid,
              setUvjetiPoklonaNotValid,
              setRegistracijaNotValid,
              setLinkNotValid,
              proizvodiNotValid,
              setRateNotValid,
              rateNotValid,
              setSifraPoklonaNotValid,
              sifraPoklonaNotValid,
              promoKodNotValid,
              setPromoKodNotValid,
              promoIznosNotValid,
              setPromoIznosNotValid,
              promoPaymentTypeNotValid,
              setPromoPaymentTypeNotValid,
              promoPaymentTypeCodesNotValid,
              setPromoPaymentTypeCodesNotValid,
              promoPaymentTypeGroupsNotValid,
              setPromoPaymentTypeGroupsNotValid,
              poklonOsiguranjeNotValid,
              poklonNotValid,
              setPromoPocetakNotValid,
              setPromoKrajNotValid,
              setSlikaPromoNotValid
            )}
          </div>
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        </div>
      </Box>
      <Dialog onClose={handleDialogClose} open={dialog.open}>
        <DialogContent className={classes.dialog}>
          <label>{dialog.message}</label>
          <div style={{ textAlign: "end" }}>
            <Button onClick={handleDialogClose}>Zatvori</Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export type { TextMobileStepperModel };
