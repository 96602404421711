import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { UnosAkcijePrilikeAction } from "../UnosAkcijePrilike/UnosAkcijePrilikeActions";
import { TextMobileStepperModel } from "../UnosAkcijePrilike/UnosAkcijePrilikeTypes";
import { step1Dummy, step2Dummy } from "./UnosAkcijePrilikeDummy";
import axios from "axios";

type DevToolsProps = {
  data: TextMobileStepperModel;
  dispatcher: ({ action, value }: UnosAkcijePrilikeAction) => void;
};

export default function DevTools(props: DevToolsProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const uploadFile = () => {
    // const formData = new FormData();
    // const res = axios.post("/api/File/Post");
    // console.log(res, "res");
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="contained"
        style={{ position: "absolute", right: 30 }}
        size="small"
      >
        DevTools
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            props.dispatcher({ action: "OSNOVE_HANDLER", value: step1Dummy });
            props.dispatcher({ action: "TRAJANJE_HANDLER", value: step2Dummy });
            uploadFile();
          }}
        >
          Ispuni 1. step
        </MenuItem>
      </Menu>
    </div>
  );
}
