import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import classes from "./PromjenaOpisa.module.css";
import { useState, useCallback, useEffect } from "react";
import useApi from "../Hooks/useApi";
// import { AlertModel } from "../UnosAkcijePrilike/UnosAkcijePrilikeTypes";
import { TextField } from "@mui/material";
import Swal from "sweetalert2";
import BackDrop from "../UnosAkcijePrilike/BackDrop";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  //   p: 4,
  overflow: "auto",
  overflowX: "hidden",
  backgroundColor: "rgb(243, 243, 244)",
  width: "60%",
};

type PromjenaBanneraProps = {
  openPromjenaOpisa: boolean;
  setOpenPromjenaOpisa: (value: boolean) => void;
  rowData: any;
};

export default function PromjenaOpisa(props: PromjenaBanneraProps) {
  // const [dialog, setOpen] = useState<AlertModel>({ open: false, message: "" });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [opis, setOpis] = useState<string>("");
  const [data, setData] = useState<any>(null);
  const [opisNotValid, setOpisNotValid] = useState<boolean>(false);

//   const handleDialogClose = () => {
//     setOpen({ open: false, message: "" });
//   };

  const ApiCall = useApi();
  const getAkcijaDetaljiHandler = useCallback(
    (accessToken: string) => {
      (async () => {
        const response = await fetch(
          `/api/Akcija/GetAkcijaDetails?imeAkcije=${props.rowData.data.nazivAkcije}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        const data = await response.json();
        setData(data.responseData);
        if (data.responseData) {
          setOpis(data.responseData.opis);
        }
      })();
    },
    [props]
  );

  useEffect(() => {
    if (props.rowData) {
      ApiCall(getAkcijaDetaljiHandler);
    }
  }, [ApiCall, getAkcijaDetaljiHandler, props.rowData]);

  const post = (accessToken: string) => {
    props.setOpenPromjenaOpisa(false);
    setIsLoading(true);
    let formData = new FormData();
    formData.append("imeAkcije", data.imeAkcije);
    formData.append("opisAkcije", opis);
    fetch("api/Akcija/UpdateOpisaAkcije", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      method: "post",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseData) {
          setIsLoading(false);
          Swal.fire({
            icon: "success",
            title: "Spremljeno",
          });
        } else {
          setIsLoading(false);
          Swal.fire({
            title: "Greška!",
            showConfirmButton: false,
            toast: true,
            customClass: {
              container: classes.swalContainer,
              popup: classes.swal2IconError,
            },
            timer: 1500,
            iconColor: "white",
            timerProgressBar: true,
            icon: "warning",
            position: "top-right",
          });
        }
      });
  };

  const updateOpis = () => {
    if (opis.length === 0) {
      setOpisNotValid(true);
    } else {
      ApiCall(post);
    }
  };

  return (
    <>
      <Modal
        open={props.openPromjenaOpisa}
        onClose={() => {
          setOpis("");
          setData(null);
          props.setOpenPromjenaOpisa(false);
        }}
        aria-labelledby="promjenaOpisa"
        aria-describedby="promjenaOpisa"
      >
        <Box sx={style}>
          <div className={classes.headerWrapper}>
            <span className={classes.naslov}>Promjena opisa</span>
            <Button
              onClick={() => {
                setOpis("");
                props.setOpenPromjenaOpisa(false);
              }}
            >
              Zatvori
            </Button>
          </div>
          <div className={classes.modalContentWrapper}>
            <TextField
              id="outlined-basic"
              label="Promjena opisa (do 1000 znakova max)"
              variant="outlined"
              value={opis}
              multiline
              rows={5}
              onChange={(e) => {
                if (opis.length < 1000) {
                  setOpis(e.target.value);
                }
                if (opis.length > e.target.value.length) {
                  setOpis(e.target.value);
                }
              }}
              style={{
                marginBottom: opisNotValid ? "" : "20px",
              }}
            />
            {opisNotValid && (
              <div
                style={{
                  fontSize: "0.8em",
                  color: "red",
                  marginBottom: "20px",
                }}
              >
                Obavezan unos!
              </div>
            )}
            <Button
              onClick={() => {
                updateOpis();
              }}
              style={{ marginBottom: "10px" }}
              color="success"
              fullWidth
              variant="contained"
            >
              Spremi opis
            </Button>
          </div>
        </Box>
      </Modal>
      {/* <Dialog onClose={handleDialogClose} open={dialog.open}>
        <DialogContent className={classes.dialog}>
          <label>{dialog.message}</label>
          <div style={{ textAlign: "end" }}>
            <Button onClick={handleDialogClose}>Zatvori</Button>
          </div>
        </DialogContent>
      </Dialog> */}
      {isLoading && <BackDrop />}
    </>
  );
}
