import { SlikaInfo } from "../../utils/utils";
import {
  OsnoveModel,
  TrajanjeModel,
  ProizvodiModel,
  PokloniModel,
  PopustVezanaKupnjaModel,
  PoklonOsiguranjaModel,
  CijeneModel,
  RateModel,
  RegistracijaModel,
  PromoPopustModel,
  AkcijaPromoKodViewModel,
} from "./UnosAkcijePrilikeTypes";
import classes from "./Summary.module.css";
import DataGrid, { Column } from "devextreme-react/data-grid";

type SummaryProps = {
  osnoveModel: OsnoveModel;
  trajanjeModel: TrajanjeModel;
  proizvodiModel: ProizvodiModel;
  pokloniModel: PokloniModel;
  popustVezanaKupnjaModel: PopustVezanaKupnjaModel;
  poklonOsiguranjaModel: PoklonOsiguranjaModel;
  cijeneModel: CijeneModel;
  rateModel: RateModel;
  registracijaModel: RegistracijaModel;
  slika506px: SlikaInfo | null;
  slika1200px: SlikaInfo | null;
  slika1140px: SlikaInfo | null;
  slika600px: SlikaInfo | null;
  promoPopustModel: PromoPopustModel;
  slike151px: SlikaInfo[] | null;
  listaPromo: AkcijaPromoKodViewModel[];
};

const Summary = (props: SummaryProps) => {
  let slika506px: JSX.Element[] = [];
  let slika1140px: JSX.Element[] = [];
  let slika1200px: JSX.Element[] = [];
  let slika600px: JSX.Element[] = [];
  if (props.slika506px) {
    slika506px.push(
      <img
        key={props.slika506px.name}
        src={props.slika506px.src}
        alt="banner506px"
        style={{ width: "30%" }}
      />
    );
  }
  if (props.slika1140px) {
    slika1140px.push(
      <img
        key={props.slika1140px.name}
        src={props.slika1140px.src}
        alt="banner1140px"
        style={{ width: "30%" }}
      ></img>
    );
  }
  if (props.slika1200px) {
    slika1200px.push(
      <img
        key={props.slika1200px.name}
        src={props.slika1200px.src}
        alt="banner1200px"
        style={{ width: "30%" }}
      ></img>
    );
  }
  if (props.slika600px) {
    slika600px.push(
      <img
        key={props.slika600px.name}
        src={props.slika600px.src}
        alt="banner600px"
        style={{ width: "30%" }}
      ></img>
    );
  }
  // if(props.slika151px){
  //   slika151px.push(
  //     <img
  //       key={props.slika151px.name}
  //       src={props.slika151px.src}
  //       alt="slika151px"
  //       style={{width:'30%'}}
  //     ></img>
  //   );
  // }
  return (
    <div className={classes.summaryWrapper}>
      <h4 className={classes.stepsNaslov}>Sažetak</h4>
      <div className={classes.karticaWrapper}>
        <div className={classes.stavkaWrapper}>
          <div className={classes.stavkaLabel}>Voditelj</div>
          <div>{props.osnoveModel.odabraniVoditelj?.label}</div>
        </div>
        <div className={classes.stavkaWrapper}>
          <div className={classes.stavkaLabel}>Tip akcije</div>
          <div>{props.osnoveModel.odabraniTipAkcije?.label}</div>
        </div>
        <div className={classes.stavkaWrapper}>
          <div className={classes.stavkaLabel}>Ime prilike</div>
          <div>{props.osnoveModel.imePrilike}</div>
        </div>
        <div className={classes.stavkaWrapper}>
          <div className={classes.stavkaLabel}>Email</div>
          <div>{props.osnoveModel.vasEmail}</div>
        </div>
        <div className={classes.stavkaWrapper}>
          <div className={classes.stavkaLabel}>Robna marka</div>
          <div>{props.osnoveModel.odabranaRobnaMarka?.label}</div>
        </div>
        <div className={classes.stavkaWrapper}>
          <div className={classes.stavkaLabel}>Tekst prilike</div>
          <div>{props.osnoveModel.tekstPrilike}</div>
        </div>
        <div className={classes.stavkaWrapper}>
          <div className={classes.stavkaLabel}>
            Kategorija proizvoda na akciji
          </div>
          <div>
            {props.osnoveModel.odabranaKategorijaProizvodaNaAkciji?.label}
          </div>
        </div>
        <div className={classes.stavkaWrapper}>
          <div className={classes.stavkaLabel}>Datum početka prilike</div>
          <div>
            {props.trajanjeModel.odabraniDatumPocetkaPrilike &&
              new Date(
                props.trajanjeModel.odabraniDatumPocetkaPrilike
              ).toLocaleDateString("HR-hr")}
          </div>
        </div>
        <div className={classes.stavkaWrapper}>
          <div className={classes.stavkaLabel}>Datum kraja prilike</div>
          <div>
            {props.trajanjeModel.odabraniDatumKrajaPrilike &&
              new Date(
                props.trajanjeModel.odabraniDatumKrajaPrilike
              ).toLocaleDateString("HR-hr")}
          </div>
        </div>
      </div>
      <div className={classes.karticaWrapper}>
        <div className={classes.stavkaLabel}>
          Unesene šifre proizvoda na prilici
        </div>
        <div style={{ display: "flex" }}>
          <DataGrid
            id="proizvodi"
            dataSource={
              props.proizvodiModel.proizvodiNakonCheckCijena.length > 0
                ? props.proizvodiModel.proizvodiNakonCheckCijena
                : props.proizvodiModel.uneseniProizvodiNaPrilici
            }
            showBorders
          >
            <Column dataField="SifraArtikla" caption="Šifra artikla" />
            <Column dataField="Cijena" caption="Cijena" />
            <Column dataField="Support" caption="Support"/>
            <Column dataField="PromoKod" caption="Promo kod" />
            <Column dataField="BesplatnaDostava" caption="Besplatna dostava" />
            <Column dataField="SifraOsiguranja" caption="Šifra osiguranja" />
            <Column dataField="DodatniRabatKomisija" caption="Dodatni rabat komisija" />
            <Column dataField="AkcijskaNettoNabavnaCijenaKomisija" caption="Akcijska netto nabavna cijena komisija" />


          </DataGrid>
        </div>
      </div>
      {props.pokloniModel.sifraPoklona.length > 0 && <div className={classes.karticaWrapper}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              fontWeight: 600,
              marginRight: "50px",
              marginBottom: "1em",
            }}
          >
            Šifra poklona
          </div>
          <div>{props.pokloniModel.sifraPoklona}</div>
        </div>
        <div className={classes.stavkaLabel}>Uvjeti za dobivanje poklona</div>
        <div className={classes.stavkaWrapper}>
          <DataGrid
            id="pokloni"
            dataSource={props.pokloniModel.uzStoSeDajePoklon}
            showBorders
          >
            <Column
              alignment="center"
              dataField="SifraArtikla"
              caption="Šifra artikla"
            />
          </DataGrid>
        </div>
      </div>}
      {props.popustVezanaKupnjaModel.uneseneSifreProizvodaZaPopust.length > 0 && <div className={classes.karticaWrapper}>
        <div className={classes.stavkaLabel}>Šifre proizvoda za popust</div>
        <div className={classes.stavkaWrapper}>
          <DataGrid
            id="proizvodiPopust"
            dataSource={
              props.popustVezanaKupnjaModel.uneseneSifreProizvodaZaPopust
            }
            showBorders
          >
            <Column
              alignment="center"
              dataField="SifraArtikla"
              caption="Šifra artikla"
            />
          </DataGrid>
        </div>
        <div className={classes.stavkaLabel}>
          Uvjeti za dobivanje popusta vezanje kupnje
        </div>
        <div className={classes.stavkaWrapper}>
          <DataGrid
            id="uvjeti"
            dataSource={
              props.popustVezanaKupnjaModel
                .uneseneSifreArtiklaZaOstvarenjePopusta
            }
            showBorders
          >
            <Column
              alignment="center"
              dataField="SifraArtikla"
              caption="Šifra artikla"
            />
          </DataGrid>
        </div>
      </div>}
      {props.listaPromo && props.listaPromo.length > 0 && <div className={classes.karticaWrapper}>
        <div style={{ fontWeight: "bold", marginBottom: "1em" }}>
          Lista promo kodova
        </div>
        {props.listaPromo.map((promo) => {
          return (
            <div className={classes.promoWrapper} key={promo.popustPromoCode}>
              <div style={{display:'grid', gridTemplateColumns: "20% 20% 1fr", alignItems:'center'}}>
                {/* <div style={{ width:'50%', fontWeight:'bold' }}>{promo.sifraArtikla}</div> */}
                <div style={{width:'30%'}}>{promo.popustPromoCode}</div>
                <img
                style={{width:'15%'}}
                  alt=""
                  src={
                    props.slike151px?.find(
                      (x) => x.name === `${promo.popustPromoCode}.png`
                    )?.src
                  }
                ></img>
              </div>
            </div>
          );
        })}
      </div>}
      <div className={classes.karticaWrapper}>
        <div className={classes.stavkaWrapper}>
          <div className={classes.stavkaLabel}>Način sniženja cijena</div>
          <div>
            {props.popustVezanaKupnjaModel.odabraniNacinSnizenjaCijena?.label}
          </div>
        </div>
        <div className={classes.stavkaWrapper}>
          <div className={classes.stavkaLabel}>Vrsta osiguranja</div>
          <div>
            {props.poklonOsiguranjaModel.odabranaVrstaPoklonOsiguranja?.label}
          </div>
        </div>
        <div className={classes.stavkaWrapper}>
          <div className={classes.stavkaLabel}>
            Iznos popusta uz vezanoj kupnji
          </div>
          <div>{props.cijeneModel.vezanaKupnjaOdabranoSnizenje?.label}</div>
        </div>
        <div className={classes.stavkaWrapper}>
          <div className={classes.stavkaLabel}>Način plačanja</div>
          <div>{props.rateModel.odabraniNacinPlacanja?.label}</div>
        </div>
        <div className={classes.stavkaWrapper}>
          <div className={classes.stavkaLabel}>
            Registracija/Prijava/Popunjavanja vaučera
          </div>
          <div>{props.registracijaModel.odabranaRegistracija?.label}</div>
        </div>
        <div className={classes.stavkaWrapper}>
          <div className={classes.stavkaLabel}>
            Link za prijavu ili registraciju
          </div>
          <div>{props.registracijaModel.linkZaPrijavuRegistraciju}</div>
        </div>
      </div>
      <div className={classes.karticaWrapper}>
        <div className={classes.bannerWrapper}>
          <div className={classes.stavkaLabel}>Banner 506px*230px</div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {slika506px}
          </div>
        </div>
        <div className={classes.bannerWrapper}>
          <div className={classes.stavkaLabel}>Banner 1140px*230px</div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {slika1140px}
          </div>
        </div>
        <div className={classes.bannerWrapper}>
          <div className={classes.stavkaLabel}>Banner 1200px*628px</div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {slika1200px}
          </div>
        </div>
        <div className={classes.bannerWrapper}>
          <div className={classes.stavkaLabel}>Banner 600px*500px</div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {slika600px}
          </div>
        </div>
        {/* <div className={classes.bannerWrapper}>
          <div className={classes.stavkaLabel}>Slika 151px*151px</div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {slike151px}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Summary;
