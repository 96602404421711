import Select from "./Select";
import Input from "./Input";
import { useEffect, useState } from "react";
import { RegistracijaModel, SelectOpcije } from "./UnosAkcijePrilikeTypes";
import classes from "./UnosAkcijePrilike.module.css";

type RegistracijaProps = {
  registracijaHandler: (registracijaModel: RegistracijaModel) => void;
  registracijaModel: RegistracijaModel;
  setRegistracijaNotValid: (value: boolean) => void;
  setLinkNotValid: (value: boolean) => void;
  registracijaNotValid: boolean;
  linkNotValid: boolean;
};

const Registracija = (props: RegistracijaProps) => {
  useEffect(() => {
    if (props.registracijaModel.odabranaRegistracija) {
      props.setRegistracijaNotValid(false);
    }
  }, [props]);
  const [registracija] = useState([
    {
      id: 0,
      label: "Ne",
    },
    {
      id: 1,
      label: "Da",
    },
  ]);
  
  return (
    <>
      <h4 className={classes.stepsNaslov}>
        Produljeno jamstvo ili poklon uz registraciju/prijavu/pupunjavanja
        vaučera
      </h4>
      <Select
        inputLabel="Registracija/prijava/pupunjavanja vaučera"
        marginBottom={props.registracijaNotValid ? "" : "1em !important"}
        registracija={registracija}
        handleChange={(value: SelectOpcije | null) =>
          {
            if(value?.id === 0){
              props.registracijaHandler({
                ...props.registracijaModel,
                linkZaPrijavuRegistraciju: "",
                odabranaRegistracija: value
              })
            }else{
              props.registracijaHandler({
                ...props.registracijaModel,
                odabranaRegistracija: value,
              })
            }
        }
        }
        value={props.registracijaModel.odabranaRegistracija}
        voditeljiBrenda={[]}
        tipoviAkcije={[]}
        robneMarke={[]}
        kategorijeProizvodaNaAkciji={[]}
        vezanaKupnjaSnizenja={[]}
        naciniSnizenjaCijena={[]}
        vrstePoklonOsiguranja={[]}
        naciniPlacanjaNaRate={[]}
        obavezno={false}
        error={false}
        referenca="registracija"
        popustPromoIznos={[]}
        popustPromoPaymentTypes={[]}
        popustPromoPaymentTypeCodes={[]}
        popustPromoPaymentTypeGroups={[]}
        disabled={false}
      />
      {props.registracijaNotValid && (
        <div style={{ fontSize: "0.8em", color: "red", marginBottom: "1em" }}>
          Obavezno polje!
        </div>
      )}
      <Input
        inputLabel="Link za prijavu ili registraciju"
        marginBottom={props.linkNotValid ? "" : "1em"}
        handleChange={(value: string) =>
          props.registracijaHandler({
            ...props.registracijaModel,
            linkZaPrijavuRegistraciju: value,
          })
        }
        value={props.registracijaModel.linkZaPrijavuRegistraciju}
        multiline={false}
        rows={0}
        children={undefined}
        obavezno={false}
        error={false}
        inputPropsType=""
        onBlur={() => {}}
        disabled={props.registracijaModel.odabranaRegistracija?.id === 0 ? true : false}
      />
      {props.linkNotValid && (
        <div style={{ fontSize: "0.8em", color: "red", marginBottom: "1em" }}>
          Obavezno polje!
        </div>
      )}
    </>
  );
};
export default Registracija;
