import { TextMobileStepperModel } from "./UnosAkcijePrilikeTypes";

type stepovi = {
  label: string;
  description: JSX.Element;
};

type SetOpenTypes = {
  open: boolean;
  message: string;
};

const Dots = (
  stepovi: stepovi[],
  activeStep: number,
  classes: any,
  setActiveStep: (active: number) => void,
  data: TextMobileStepperModel,
  setTekstPrilikeNotValid: (value: boolean) => void,
  setVoditeljNotValid: (value: boolean) => void,
  setTipNotValid: (value: boolean) => void,
  setImePrilikeNotValid: (value: boolean) => void,
  setEmailNotValid: (value: boolean) => void,
  setRobnaMarkaNotValid: (value: boolean) => void,
  setKategorijaProizvodaNotValid: (value: boolean) => void,
  setPocetakNotValid: (value: boolean) => void,
  setKrajNotValid: (value: boolean) => void,
  setOpen: ({ open, message }: SetOpenTypes) => void,
  setBanner1140pxNotValid: (value: boolean) => void,
  setBanner1200pxNotValid: (value: boolean) => void,
  setBanner506pxNotValid: (value: boolean) => void,
  setBanner600pxNotValid: (value: boolean) => void,
  setUvjetiPoklonaNotValid: (value: boolean) => void,
  setRegistracijaNotValid: (value: boolean) => void,
  setLinkNotValid: (value: boolean) => void,
  proizvodiNotValid: boolean,
  setRateNotValid: (value: boolean) => void,
  rateNotValid: boolean,
  setSifraPoklonaNotValid: (value: boolean) => void,
  sifraPoklonaNotValid: boolean,
  promoKodNotValid: boolean,
  setPromoKodNotValid: (value: boolean) => void,
  promoIznosNotValid: boolean,
  setPromoIznosNotValid: (value: boolean) => void,
  promoPaymentTypeNotValid: boolean,
  setPromoPaymentTypeNotValid: (value: boolean) => void,
  promoPaymentTypeCodesNotValid: boolean,
  setPromoPaymentTypeCodesNotValid: (value: boolean) => void,
  promoPaymentTypeGroupsNotValid: boolean,
  setPromoPaymentTypeGroupsNotValid: (value: boolean) => void,
  poklonOsiguranjeNotValid: boolean,
  poklonNotValid: boolean,
  setPromoPocetakNotValid: (value: boolean) => void,
  setPromoKrajNotValid: (value: boolean) => void,
  setSlikaPromoNotValid: (value: boolean) => void
) => {
  let dots: JSX.Element[] = [];
  const NeDozvoljeneRijeci = [
    "registracija",
    "registracije",
    "registraciju",
    "prijavu",
    "prijave",
    "prijava",
  ];

  const checkStepPoklonOsiguranje = () => {
    let artikli =
      data.proizvodiModel.proizvodiNakonCheckCijena.length > 0
        ? data.proizvodiModel.proizvodiNakonCheckCijena
        : data.proizvodiModel.uneseniProizvodiNaPrilici;
    let index = artikli.findIndex((artikl) => artikl.SifraOsiguranja);
    if (index === -1) {
      return false;
    } else {
      return true;
    }
  };

  const validacija = (i: number) => {
    if (activeStep === 0) {
      let tekst = data.osnoveModel.tekstPrilike;
      let counterTekstPrilike = 0;
      let counter = 0;
      let counterImePrilike = 0;
      let validEmailRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (data.osnoveModel.tekstPrilike.length > 0) {
        NeDozvoljeneRijeci.forEach((element) => {
          if (tekst.toLowerCase().includes(element)) {
            counterTekstPrilike++;
          }
        });
      } else {
        setTekstPrilikeNotValid(true);
      }

      if (!data.osnoveModel.odabraniVoditelj) {
        counter++;
        setVoditeljNotValid(true);
      } else {
        setVoditeljNotValid(false);
      }

      if (!data.osnoveModel.odabraniTipAkcije) {
        counter++;
        setTipNotValid(true);
      } else {
        setTipNotValid(false);
      }

      if (data.osnoveModel.imePrilike.length > 0) {
        data.kategorijeProizvodaNaAkciji.forEach((element) => {
          if (
            data.osnoveModel.imePrilike.toLowerCase() ===
            element.naziv.toLowerCase()
          ) {
            counterImePrilike++;
          }
        });
      } else {
        setImePrilikeNotValid(true);
      }

      if (!data.osnoveModel.vasEmail.match(validEmailRegex)) {
        setEmailNotValid(true);
        counter++;
      } else {
        setEmailNotValid(false);
      }
      if (!data.osnoveModel.odabranaRobnaMarka) {
        setRobnaMarkaNotValid(true);
        counter++;
      } else {
        setRobnaMarkaNotValid(false);
      }
      if (!data.osnoveModel.odabranaKategorijaProizvodaNaAkciji) {
        setKategorijaProizvodaNotValid(true);
      } else {
        setKategorijaProizvodaNotValid(false);
      }

      if (counterTekstPrilike > 0) {
        setTekstPrilikeNotValid(true);
      }
      if (counterImePrilike > 0) {
        setImePrilikeNotValid(true);
      }
      if (
        counter === 0 &&
        counterTekstPrilike === 0 &&
        counterImePrilike === 0
      ) {
        setTekstPrilikeNotValid(false);
        setVoditeljNotValid(false);
        setTipNotValid(false);
        setImePrilikeNotValid(false);
        setEmailNotValid(false);
        setRobnaMarkaNotValid(false);
        setKategorijaProizvodaNotValid(false);
        setActiveStep(i);
      }
    } else if (activeStep === 1) {
      // VALIDACIJA DRUGI STEP
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      let razlikaUDanima = 0;
      // a and b are javascript Date objects
      function dateDiffInDays(a: Date, b: Date) {
        // Discard the time and time-zone information.
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
      }
      if (!data.trajanjeModel.odabraniDatumPocetkaPrilike)
        setPocetakNotValid(true);
      if (!data.trajanjeModel.odabraniDatumKrajaPrilike) setKrajNotValid(true);
      if (
        data.trajanjeModel.odabraniDatumPocetkaPrilike &&
        data.trajanjeModel.odabraniDatumKrajaPrilike
      ) {
        razlikaUDanima = dateDiffInDays(
          new Date(data.trajanjeModel.odabraniDatumPocetkaPrilike),
          new Date(data.trajanjeModel.odabraniDatumKrajaPrilike)
        );
        if (razlikaUDanima < 0) {
          setOpen({
            open: true,
            message:
              "Datum kraja prilike mora biti veći ili jednak od datuma početka prilike",
          });
        } else {
          // if (
          //   data.osnoveModel.odabraniTipAkcije?.id === 1 ||
          //   data.osnoveModel.odabraniTipAkcije?.id === 3
          // ) {
          if (razlikaUDanima + 1 > 93) {
            setOpen({
              open: true,
              message: "Promocija ne smije trajati duže od 93 dana",
            });
          } else {
            if (data.osnoveModel.odabraniTipAkcije?.id === 1) {
              if (
                new Date(
                  data.trajanjeModel.odabraniDatumPocetkaPrilike
                ).getDay() !== 1
              ) {
                setOpen({
                  open: true,
                  message: "Akcija može početi samo u Ponedeljak!",
                });
              } else if (
                new Date(
                  data.trajanjeModel.odabraniDatumKrajaPrilike
                ).getDay() !== 0
              ) {
                setOpen({
                  open: true,
                  message: "Akcija može završiti samo u Nedelju!",
                });
              } else {
                setActiveStep(i);
              }
            } else {
              setActiveStep(i);
            }
          }
          // }
          // if (data.osnoveModel.odabraniTipAkcije?.id === 2) {
          //   if (razlikaUDanima > 90) {
          //     setOpen({
          //       open: true,
          //       message: "Prilika ne smije trajati duže od 90 dana",
          //     });
          //   } else {
          //     setActiveStep(i);
          //   }
          // }
        }
      }
    } else if (activeStep === 2) {
      let counter = 0;
      if (!data.slika506px) {
        setBanner506pxNotValid(true);
        counter++;
      }
      if (!data.slika600px) {
        setBanner600pxNotValid(true);
        counter++;
      }
      if (!data.slika1200px) {
        setBanner1200pxNotValid(true);
        counter++;
      }
      if (!data.slika1140px) {
        setBanner1140pxNotValid(true);
        counter++;
      }
      if (counter > 0) {
        setOpen({ open: true, message: "Morate odabrati bannere" });
      } else {
        setActiveStep(i);
      }
    } else if (activeStep === 3) {
      // if (
      //   data.osnoveModel.odabraniTipAkcije?.id === 1 ||
      //   data.osnoveModel.odabraniTipAkcije?.id === 2
      // ) {
      if (
        data.proizvodiModel.uneseniProizvodiNaPrilici.length === 0 &&
        data.proizvodiModel.proizvodiNakonCheckCijena.length === 0
      ) {
        setOpen({ open: true, message: "Unesite artikle" });
      } else if (
        data.proizvodiModel.uneseniProizvodiNaPrilici.length > 0 &&
        data.proizvodiModel.proizvodiNakonCheckCijena.length === 0
      ) {
        setOpen({ open: true, message: "Morate provjeriti artikle" });
      } else if (
        data.proizvodiModel.proizvodiNakonCheckCijena.find(
          (x) => x.isOk === false
        )
      ) {
        setOpen({ open: true, message: "Postoje artikli sa greškom !" });
      } else {
        setActiveStep(i);
      }
      // } else if (
      //   data.osnoveModel.odabraniTipAkcije?.id === 3 &&
      //   data.proizvodiModel.uneseniProizvodiNaPrilici.length === 0
      // ) {
      //   setOpen({ open: true, message: "Unesite artikle" });
      // } else {
      //   setActiveStep(i);
      // }
    } else if (activeStep === 4) {
      if (
        data.pokloniModel.sifraPoklona.length > 0 &&
        data.pokloniModel.uzStoSeDajePoklon.length === 0
      ) {
        setUvjetiPoklonaNotValid(true);
      } else if (
        data.pokloniModel.uzStoSeDajePoklon.length > 0 &&
        data.pokloniModel.sifraPoklona.length === 0
      ) {
        setSifraPoklonaNotValid(true);
      } else if (data.pokloniModel.sifraPoklona.length > 0 && poklonNotValid) {
        return;
      } else {
        setActiveStep(i);
      }
    } else if (activeStep === 5) {
      if (data.popustVezanaKupnjaModel.odabraniNacinSnizenjaCijena) {
        setActiveStep(i);
      } else {
        setOpen({
          open: true,
          message: "Morate odabrati način sniženja cijena",
        });
      }
    } else if (activeStep === 6) {
      if (data.listaPromoKodova.find((x) => x.isOk === false)) {
        setOpen({
          open: true,
          message: "Morate unijeti podatke za sve artikle!",
        });
      } else {
        setActiveStep(i);
      }
    } else if (activeStep === 7) {
      if (
        data.poklonOsiguranjaModel.odabranaVrstaPoklonOsiguranja &&
        poklonOsiguranjeNotValid
      ) {
        setOpen({ open: true, message: "Postoje artikli sa greškom" });
      } else {
        setActiveStep(i);
      }
    } else if (activeStep === 8) {
      if (!data.rateModel.odabraniNacinPlacanja) {
        setRateNotValid(true);
      } else {
        setActiveStep(i);
      }
    } else if (activeStep === 9) {
      let counter = 0;
      if (!data.registracijaModel.odabranaRegistracija) {
        counter++;
        setRegistracijaNotValid(true);
      }
      if (
        data.registracijaModel.odabranaRegistracija?.label === "Da" &&
        data.registracijaModel.linkZaPrijavuRegistraciju.length === 0
      ) {
        counter++;
        setLinkNotValid(true);
      }
      if (counter === 0) {
        setActiveStep(i);
      }
    } else {
      setActiveStep(i);
    }
  };

  for (let i = 0; i < stepovi.length; i++) {
    if (i === activeStep) {
      dots.push(
        <div
          className={classes.dotWrapper}
          key={i}
          // onClick={() => {
          //   if(activeStep > i){
          //     setActiveStep(i)
          //   }else{
          //     validacija(i);
          //   }
          // }}
        >
          <div className={classes.activeDot}></div>
        </div>
      );
    } else {
      dots.push(
        <div
          className={classes.dotWrapper}
          key={i}
          onClick={() => {
            if (activeStep > i) {
              if (activeStep === 8) {
                if (i === 7) {
                  if (checkStepPoklonOsiguranje()) {
                    setActiveStep(i - 1);
                  } else {
                    setActiveStep(i);
                  }
                } else {
                  setActiveStep(i);
                }
              } else {
                setActiveStep(i);
              }
            }
            if (activeStep + 1 === i) {
              if (activeStep === 6) {
                if (i === 7) {
                  if (checkStepPoklonOsiguranje()) {
                    validacija(i + 1);
                  } else {
                    validacija(i);
                  }
                } else {
                  validacija(i);
                }
              } else {
                validacija(i);
              }
            }
            if (checkStepPoklonOsiguranje() && activeStep === 6 && i === 8) {
              validacija(i);
            }
            //MAKNUTI!!!!!
            // setActiveStep(i);
          }}
        >
          <div className={classes.dot} />
        </div>
      );
    }
  }
  return dots;
};

export { Dots };
