import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useCallback, useEffect, useState } from "react";
import {
  AutocompleteOptions,
  CijenaDostave,
  CijenaDostaveModel,
} from "./CijenaDostaveTypes";
import { Autocomplete, Dialog, DialogContent, TextField } from "@mui/material";
import useApi from "../Hooks/useApi";
import Swal from "sweetalert2";
import classes from "./CijenaDostave.module.css";
import BackDrop from "../UnosAkcijePrilike/BackDrop";
import { AlertModel } from "../UnosAkcijePrilike/UnosAkcijePrilikeTypes";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
};

type EditModalProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  data: CijenaDostave;
  setData: (value: CijenaDostave) => void;
  masterData: CijenaDostaveModel;
};

const EditModal = (props: EditModalProps) => {
  const [tezinaKoleta, setTezinaKoleta] = useState<string>(
    props.data.tezinaKoleta.toString()
  );
  const [dostavaCijena, setDostavaCijena] = useState<string>(
    props.data.dostavaCijena.toString()
  );
  const [prijevoznik, setPrijevoznik] = useState<AutocompleteOptions>({
    id: props.masterData.prijevoznik.find(
      (x) => x.naziv === props.data.prijevoznik
    )?.id,
    label: props.data.prijevoznik,
  });

  const apiCall = useApi();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showSpremi, setShowSpremi] = useState<boolean>(false);
  const [dialog, setOpen] = useState<AlertModel>({ open: false, message: "" });
  const handleDialogClose = () => {
    setOpen({ open: false, message: "" });
  };

  useEffect(() => {
    if (
      props.data.dostavaCijena !== +dostavaCijena ||
      props.data.prijevoznik !== prijevoznik.label ||
      props.data.tezinaKoleta !== +tezinaKoleta
    ) {
      setShowSpremi(true);
    } else {
      setShowSpremi(false);
    }
  }, [
    dostavaCijena,
    prijevoznik.label,
    props.data.dostavaCijena,
    props.data.prijevoznik,
    props.data.tezinaKoleta,
    tezinaKoleta,
  ]);

  const handleClose = () => {
    props.setOpen(false);
  };

  const spremiCijenuDostava = useCallback(
    (accessToken: string) => {
      let formData = new FormData();
      let formDataExists = new FormData();
      formData.append("tezinaKoleta", tezinaKoleta.replace(".",","));
      formData.append("cijenaDostave", dostavaCijena.replace(".",","));
      formData.append("prijevoznik", prijevoznik.label.toUpperCase());
      formDataExists.append("tezinaKoleta", tezinaKoleta.replace(".",","));
      formDataExists.append("cijenaDostave", dostavaCijena.replace(".",","));
      formDataExists.append("prijevoznik", prijevoznik.label.toUpperCase());
      formData.append("oldTezinaKoleta", props.data.tezinaKoleta.toString().replace(".",","));
      formData.append("oldCijenaDostave", props.data.dostavaCijena.toString().replace(".",","));
      formData.append("oldPrijevoznik", props.data.prijevoznik.toUpperCase());

      fetch("api/Dostava/ExistsDostavaCijene", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "post",
        body: formDataExists,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.responseData) {
            setIsLoading(false);
            setOpen({ open: true, message: "Zapis već postoji!" });
          } else {
            fetch("api/Dostava/EditDostavaCijene", {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
              method: "post",
              body: formData,
            }).then((response) => {
              if (response.status === 200) {
                props.setOpen(false);
                setIsLoading(false);
                Swal.fire({
                  icon: "success",
                  title: "Spremljeno",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.location.reload();
                  }
                });
              } else {
                setIsLoading(false);
                Swal.fire({
                  title: response.statusText,
                  showConfirmButton: false,
                  toast: true,
                  customClass: {
                    container: classes.swalContainer,
                    popup: classes.swal2IconError,
                  },
                  timer: 1500,
                  iconColor: "white",
                  timerProgressBar: true,
                  icon: "warning",
                  position: "top-right",
                });
              }
            });
          }
        });
    },
    [dostavaCijena, prijevoznik.label, props, tezinaKoleta]
  );

  const post = () => {
    apiCall(spremiCijenuDostava);
  };

  return (
    <>
      <div>
        <Modal
          open={props.open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Autocomplete
              disablePortal
              id="odabirVozila"
              options={props.masterData.prijevoznik.map((value) => {
                return {
                  id: value.id,
                  label: value.naziv,
                };
              })}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Odaberite prijevoznika" />
              )}
              size="small"
              style={{
                width: "100%",
                marginBottom: "10px",
                backgroundColor: "white",
              }}
              getOptionLabel={(option) => {
                return `${option.label}`;
              }}
              isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
              }}
              onChange={(e: any, value, reason) => {
                if (reason === "clear") {
                  setPrijevoznik({ id: -1, label: "" });
                } else {
                  if (e.target.innerHTML) {
                    setPrijevoznik({
                      id: props.masterData.prijevoznik.find(
                        (x) => x.naziv === e.target.innerHTML
                      )?.id,
                      label: e.target.innerHTML,
                    });
                  }
                }
              }}
              value={prijevoznik}
            />

            <TextField
              value={dostavaCijena}
              style={{ marginBottom: "10px" }}
              fullWidth
              id="cijena"
              label="Dostavna cijena"
              variant="outlined"
              onChange={(e) => {
                setDostavaCijena(e.target.value);
              }}
            />
            <TextField
              value={tezinaKoleta}
              style={{ marginBottom: "10px" }}
              fullWidth
              id="tezina"
              label="Težina koleta"
              variant="outlined"
              onChange={(e) => {
                setTezinaKoleta(e.target.value);
              }}
            />
            {showSpremi && (
              <Button
                style={{ backgroundColor: "rgb(26, 179, 148)" }}
                variant="contained"
                onClick={() => {
                  post();
                }}
              >
                Spremi
              </Button>
            )}
          </Box>
        </Modal>
        {isLoading && <BackDrop />}
      </div>
      {dialog.open && <Dialog onClose={handleDialogClose} open={dialog.open}>
        <DialogContent className={classes.dialog}>
          <label>{dialog.message}</label>
          <div style={{ textAlign: "end" }}>
            <Button onClick={handleDialogClose}>Zatvori</Button>
          </div>
        </DialogContent>
      </Dialog>}
    </>
  );
};

export default EditModal;
