

const Select = () => {
    return (
        <div>
            <label>Prilika za koju se banner mjenja: </label>
            <select>
                <option key={1} value="1">Acer akcija</option>
                <option key={2} value="2">Akcija bijela tehnika</option>
                <option key={3} value="3">Akcija bosch</option>
                <option key={4} value="4">Akcija samsung</option>
            </select>
        </div>
    )
}

export default Select;